import {InjectorProvider} from "./injector-provider";
import {QueryGateway, QueryOptions} from "./query-gateway";
import {CommandGateway} from "./command-gateway";
import {Observable, Subscriber} from "rxjs";
import {AppContext} from "../app-context";
import {EventGateway} from './event-gateway';

export function sendQuery(type: string, payload = {}, options ?: QueryOptions): Observable<any> {
  if (!InjectorProvider.injector) {
    return new Observable((subscriber: Subscriber<any>) => {
      InjectorProvider.injector.get(QueryGateway).send(type, payload, options).subscribe(subscriber);
    });
  }
  return InjectorProvider.injector.get(QueryGateway).send(type, payload, options);
}

export function sendCommand(type: string, payload: any,
                            successHandler?: (value: any) => void,
                            errorHandler?: (error: any) => void, options ?: QueryOptions) {
  AppContext.clearAlerts();
  return AppContext.waitForProcess(InjectorProvider.injector.get(CommandGateway).send(type, payload, options))
      .subscribe(successHandler ? successHandler : () => AppContext.registerSuccess("Operation has completed successfully"),
          errorHandler ? errorHandler : (error) => AppContext.registerError(error));
}

export function publishEvent(type: string, payload?: any) {
  InjectorProvider.injector.get(EventGateway).publish(type, payload || {});
}
