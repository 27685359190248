import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {NgChartsModule} from "ng2-charts";
import {TypeaheadWindowComponent} from "./typeahead/typeahead-window.component";
import {PopoverComponent} from "./popover/popover.component";
import {TooltipComponent} from "./tooltip/tooltip.component";
import {PopoverDirective} from "./popover/popover.directive";
import {DateRangeComponent} from "./date/date-range/date-range.component";
import {DateFieldComponent} from "./date/date-field/date-field.component";
import {DatePickerComponent} from "./date/date-picker/date-picker.component";
import {SelectComponent} from "./select/select.component";
import {TimestampPipe} from "./date/timestamp.pipe";
import {LocalFilterComponent} from "./search/local-filter/local-filter.component";
import {TagsComponent} from "./tags/tags.component";
import {ConfirmationToggleComponent} from "./confirmation-toggle/confirmation-toggle.component";
import {FormFieldComponent} from "./form-field/form-field.component";
import {TabPanelComponent} from "./tab-panel/tab-panel.component";
import {TabItemComponent} from "./tab-panel/tab-item/tab-item.component";
import {TypeaheadDirective} from "./typeahead/typeahead.directive";
import {TimePickerComponent} from "./date/time-picker/time-picker.component";
import {AutoFocusDirective} from "./auto-focus.directive";
import {SearchComponent} from "./search/search/search.component";
import {FormComponent} from "./form/form.component";
import {PaginationComponent} from "./pagination/pagination.component";
import {SentenceCasePipe} from "./sentence-case.pipe";
import {ScrollerComponent} from "./scroller/scroller.component";
import {MultiInputComponent} from "./multi-input/multi-input.component";
import {ModalConfirmAutofocus} from "./modal-confirm/modal-confirm.component";
import {LabelComponent} from './label/label.component';
import {FacetDateRangeComponent} from "./facets/facet-date-range/facet-date-range.component";
import {FacetFilterComponent} from "./facets/facet-filter/facet-filter.component";
import {FacetFilterValueComponent} from "./facets/facet-filter/facet-filter-value/facet-filter-value.component";
import {
    FacetFilterBooleanValueComponent
} from "./facets/facet-filter/facet-filter-boolean-value/facet-filter-boolean-value.component";
import {ScreenshotButtonComponent} from './screenshot-button/screenshot-button.component';

export const commonDeclarations = [
    // Pipes
    TimestampPipe,
    SentenceCasePipe,

    // Directives
    PopoverDirective,
    AutoFocusDirective,
    TypeaheadDirective,

    // Components
    TooltipComponent,
    DateFieldComponent,
    DateRangeComponent,
    DatePickerComponent,
    SelectComponent,
    SearchComponent,
    LocalFilterComponent,
    MultiInputComponent,
    TagsComponent,
    ConfirmationToggleComponent,
    ScrollerComponent,
    FormFieldComponent,
    TabPanelComponent,
    TabItemComponent,
    TimePickerComponent,
    FormComponent,
    FacetDateRangeComponent,
    FacetFilterComponent,
    FacetFilterValueComponent,
    FacetFilterBooleanValueComponent,
]

@NgModule({
    declarations: [
        commonDeclarations,
        PaginationComponent,
        ModalConfirmAutofocus,
        LabelComponent,
        ScreenshotButtonComponent
    ],
    providers: [
        SentenceCasePipe
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        NgChartsModule,
        TypeaheadWindowComponent,
        PopoverComponent,
    ],
    exports: [
        commonDeclarations,
        PaginationComponent,
        LabelComponent,
        ScreenshotButtonComponent
    ]
})
export class CommonsModule {

}
