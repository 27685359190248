<app-form (submit)="updateInfo()">
    <div class="card">
        <div class="card-header">
            <h3 *ngIf="isNewLocation" class="card-header-title">New location</h3>
            <h3 *ngIf="!isNewLocation" class="card-header-title notranslate">{{info.name + ' - ' + refDataUtils.addressFormatter(info.address)}}</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div *ngIf="!data.organisationId" class="col-12 col-md-6">
                    <app-form-field label="Organisation">
                        <app-search [minCharacters]="0" [(ngModel)]="organisation" required [searchFunction]="refDataUtils.searchOrganisation"
                                    [inputFormatter]="refDataUtils.organisationFormatter" dataKey="info.name"
                                    placeholder="Search for an organisation" ></app-search>
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Name">
                        <input required [(ngModel)]="info.name" type="text" class="form-control">
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Building code">
                        <input [(ngModel)]="info.buildingCode" type="text" class="form-control">
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Department">
                        <input [(ngModel)]="info.department" type="text" class="form-control">
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Energy label">
                        <app-select class="notranslate" [(ngModel)]="info.energyLabel" [options]="energyLabels"></app-select>
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Building type">
                        <app-select [(ngModel)]="buildingType" [formatter]="buildingTypeFormatter"
                                    [selectedFormatter]="buildingTypeFormatter" dataKey="buildingTypeId"
                                    [optionsProvider]="energyCompassUtils.findBuildingTypes()"></app-select>
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Object number">
                        <input [(ngModel)]="info.objectNumber" type="text" class="form-control">
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Area">
                        <input [(ngModel)]="info.area" type="number" class="form-control">
                    </app-form-field>
                </div>
                <h6 class="header-pretitle">Address</h6>
                <app-address-form class="p-0" [address]="info.address"></app-address-form>
            </div>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>