import {Component, Input} from '@angular/core';
import {ExtendedLocation} from "../../refdata/locations/locations-list/locations-list.component";
import {Connection, ConnectionType, Location, Meter, MeterType} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {openModal} from "../../../app-utils";
import {cloneDeep} from "lodash";
import {AnimationTypes} from "../../../common/modal/modal.component";
import {MeterComponentData, MeterDetailsComponent} from "../../refdata/meters/meter-details/meter-details.component";
import {AppContext} from "../../../app-context";

@Component({
    selector: 'app-location-meters',
    templateUrl: './location-meters.component.html',
    styleUrls: ['./location-meters.component.scss']
})
export class LocationMetersComponent {
    appContext = AppContext;
    refDataUtils = RefdataUtils;
    @Input() location: ExtendedLocation;

    getMetersForConnectionType = (connectionType: ConnectionType | string, primaryOnly: boolean): MeterWithConnection[] => {
        return this.location.connections
            .filter(c => c.info.connectionType === connectionType)
            .flatMap(c => c.meters.map(m => <MeterWithConnection>{
                connection: c,
                meter: m,
                location: this.location
            }))
            .filter(c => {
                const type = c.meter.details?.type || c.meter.info.type;
                return primaryOnly ? type === MeterType.PRIMARY : type !== MeterType.PRIMARY;
            });
    }

    getSubMeters = (connectionType?: ConnectionType | string): MeterWithConnection[] => {
        return this.location.connections
            .filter(c => !connectionType || c.info.connectionType === connectionType)
            .flatMap(c => c.meters.map(m => <MeterWithConnection>{
                connection: c,
                meter: m,
                location: this.location
            }))
            .filter(c => {
                const type = c.meter.details?.type || c.meter.info.type;
                return type !== MeterType.PRIMARY;
            });
    }

    editMeter = (meter: MeterWithConnection) => {
        openModal(MeterDetailsComponent, <MeterComponentData>{
            organisationId: this.location.organisationId,
            locationId: this.location.locationId,
            connection: cloneDeep(meter.connection),
            meterId: meter.meter.meterId,
            meterInfo: meter.meter.info,
            meterDetails: meter.meter.details || cloneDeep(meter.meter.info)
        }, {
            cssClass: "modal-xl",
            animation: AnimationTypes.fade
        });
    }

    trackBy: any = (index: number, item: MeterWithConnection) => {
        return item?.meter?.meterId;
    };
}

export interface MeterWithConnection {
    meter: Meter;
    connection: Connection;
    location: Location;
}