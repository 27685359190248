<div *ngIf="!readonly" class="align-items-center mb-4 row">
    <div class="col d-none">
        <app-local-filter [appAutoFocus]="true" placeholder="Search" [data]="data"
                          (updated)="filteredData = $event"></app-local-filter>
    </div>
    <div *ngIf="appContext.isAdminOrIntermediary()" class="col">
        <button (click)="addNewContractPeriod()" type="button" class="btn btn-primary ms-auto d-block">
            <i class="bi bi-plus me-md-2"></i>
            <span class="d-none d-md-inline">Add contract period</span>
        </button>
    </div>
</div>

<div class="row">
    <ng-container *ngFor="let period of contract.periods">
        <div role="row" class="col-xl-4 col-md-6 col-12 mb-4">
            <div class="card hover-show w-100 overflow-auto h-100 d-flex flex-column position-relative">
                <div class="card-body flex-grow-1 d-flex flex-column">
                    <app-confirmation-toggle *ngIf="appContext.isAdminOrIntermediary()">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title">Remove period</h3>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p>Are you sure you want to remove this contract period?</p>
                            </div>
                            <div class="modal-footer justify-content-between">
                                <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                                <button type="button" class="btn btn-primary" (click)="deleteContractPeriod(period)"
                                        data-bs-dismiss="modal">
                                    Remove period
                                </button>
                            </div>
                        </div>
                        <button type="button" class="btn position-absolute top-0 start-0 to-show" role="button"
                                aria-expanded="false">
                            <i class="bi bi-trash"></i>
                        </button>
                    </app-confirmation-toggle>

                    <button *ngIf="appContext.isAdminOrIntermediary()" type="button"
                            class="btn position-absolute top-0 end-0 to-show" role="button" aria-expanded="false"
                            (click)="openContractPeriodModal(contract, period)">
                        <i class="bi bi-pencil"></i>
                    </button>

                    <h2 class="card-title mb-0 text-center notranslate">
                        <app-contract-range [dateRange]="period.data.dateRange" [isReadOnly]="true"></app-contract-range>
                    </h2>
                    <hr class="card-divider mb-4">
                    <div class="d-flex justify-content-between align-items-center hover-show">
                        <div class="flex-grow-1">
                            <p *ngIf="period.data.rate !== null && period.data.rate !== undefined"
                               class="mb-1 d-flex justify-content-between">
                                <span>{{contract.contractData.connectionType == "Electricity"? "Normal" : ""}} Rate</span>
                                <span class="notranslate">{{ period.data.rate | number:'1.5-5' }} {{ costUnit() }}
                                    / {{ getConsumptionUnit(contract.contractData.connectionType) }}</span>
                            </p>
                            <ng-container
                                    *ngIf="contract.contractData.connectionType === 'Electricity'">
                                <p *ngIf="period.data.offPeakRate !== null && period.data.offPeakRate !== undefined"
                                   class="mb-1 d-flex justify-content-between">
                                    <span>Off Peak Rate</span>
                                    <span class="notranslate">{{ period.data.offPeakRate | number:'1.5-5' }} {{ costUnit() }}
                                        / {{ getConsumptionUnit(contract.contractData.connectionType) }}</span>
                                </p>
                                <p *ngIf="period.data.feedInRate !== null && period.data.feedInRate !== undefined"
                                   class="mb-1 d-flex justify-content-between">
                                    <span>Normal Feed-in rate</span>
                                    <span class="notranslate">{{ period.data.feedInRate | number:'1.5-5' }} {{ costUnit() }}
                                        / {{ getConsumptionUnit(contract.contractData.connectionType) }}</span>
                                </p>
                                <p *ngIf="period.data.feedInOffPeakRate !== null && period.data.feedInOffPeakRate !== undefined"
                                   class="mb-1 d-flex justify-content-between">
                                    <span>Feed-In Off Peak Rate</span>
                                    <span class="notranslate">{{ period.data.feedInOffPeakRate | number:'1.5-5' }} {{ costUnit() }}
                                        / {{ getConsumptionUnit(contract.contractData.connectionType) }}</span>
                                </p>
                            </ng-container>

                            <ng-container
                                    *ngIf="contract.contractData.connectionType === 'Electricity' || contract.contractData.connectionType === 'Gas'">
                                <p *ngIf="period.data.standingCharge !== null && period.data.standingCharge !== undefined"
                                   class="mb-1 d-flex justify-content-between">
                                    <span>Standing Charge</span>
                                    <span class="notranslate">{{ period.data.standingCharge | number:'1.5-5' }} {{ costUnit() }}
                                        / {{ standingChargeUnit() }}</span>
                                </p>
                                <p class="mb-1 d-flex justify-content-between">
                                    <span>Sustainability Source</span>
                                    <span class="notranslate">{{ period.data.sustainabilitySource?.name || '—' }}</span>
                                </p>
                                <p class="mb-1 d-flex justify-content-between">
                                    <span>Supplier</span>
                                    <span class="notranslate">{{ period.data.supplier?.name  || '—' }}</span>
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
