<ng-container *ngIf="appContext.isAdminOrIntermediary()">
    <div *ngIf="!readonly" class="align-items-center mb-3 row g-3">
        <div class="col-auto">
            <div class="dropdown">
                <button class="dropdown-toggle btn border-0 text-start d-flex justify-content-between align-items-center w-100 p-2 pe-4"
                        type="button"
                        [ngClass]="{'active': selectedConnectionTypes.length > 0}" data-bs-toggle="dropdown"
                        [style.max-width.px]="200">
                    <div class="d-flex flex-column mw-0 flex-grow-1 me-3 mw-100">
                        <span class="d-inline-block text-truncate">Connection Type</span>
                        <div *ngIf="selectedConnectionTypes.length > 0"
                             class="mt-1 text-secondary d-inline-block text-truncate notranslate">
                            {{ selectedConnectionTypes.join(', ') }}
                        </div>
                    </div>
                    <i *ngIf="selectedConnectionTypes.length === 0" class="fa fa-fw fa-plus text-secondary"></i>
                </button>
                <div class="btn-close-wrapper rounded-circle position-absolute end-0 me-1 mt-1 top-0"
                     [ngClass]="{'d-none': selectedConnectionTypes.length === 0}">
                    <button type="button" class="btn-close" (click)="clearFilter()" aria-label="Remove filter"></button>
                </div>
                <div class="dropdown-menu min-w-100 overflow-auto" [style.max-height.px]="320" [style.z-index]="1050">
                    <ul class="list-group">
                        <ng-container *ngFor="let connectionType of connectionTypes; index as i; trackBy: trackByConnectionType">
                            <li class="list-group-item list-group-item-action border-0 d-flex align-items-stretch">
                                <div class="d-flex align-items-center">
                                    <input class="form-check-input m-0 me-1" type="checkbox" [checked]="selectedConnectionTypes.includes(connectionType)"
                                           (change)="toggleConnectionType($event)" [value]="connectionType" [id]="connectionType">
                                    <label class="d-flex flex-grow-1" [for]="connectionType" style="cursor: pointer">
                                        <span class="text-truncate py-1">{{ connectionType }}</span>
                                    </label>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col">
            <app-local-filter [appAutoFocus]="true" placeholder="Search" [data]="data"
                              (updated)="filteredData = $event"></app-local-filter>
        </div>
        <div *ngIf="appContext.isAdminOrIntermediary()" class="col-auto">
            <button (click)="addContract()" type="button" class="btn btn-primary">
                <i class="bi bi-plus me-md-2"></i>
                <span class="d-none d-md-inline">Add Contract</span>
            </button>
        </div>
    </div>
    <div class="col-12 d-flex justify-content-end mb-3"></div>
    <app-scroller (endReached)="loadNextPage()" [windowScroll]="true">
        <div class="row">
            <ng-container *ngFor="let contract of dataSorted; let i = index; trackBy: trackByForRecord">
                <div role="row" class="col-xl-4 col-md-6 col-12 mb-4">
                    <div class="card hover-show w-100 overflow-auto h-100 d-flex flex-column position-relative">
                        <div class="card-body flex-grow-1 d-flex flex-column">
                            <div class="text-center mb-3 pointer notranslate"
                                 (click)="navigateToContract(contract.contractId)">
                                <h2 class="card-title">
                                    <app-connection-image [connectionType]="contract.contractData.connectionType">
                                    </app-connection-image>
                                    {{ contract.contractData.name || "" }}
                                </h2>
                                <h5 class="text-muted">
                                    {{ contract.organisationInfo ? contract.organisationInfo.name : "" }}
                                </h5>
                            </div>
                            <hr class="card-divider mb-0">
                            <div class="list-group-flush mb-n3 list-group flex-grow-1">
                                <div *ngFor="let period of contract.periods;"
                                     class="list-group-item py-3">
                                    <div class="row">
                                        <div class="col-auto">
                                            <i class="bi bi-calendar"></i>
                                        </div>
                                        <div class="col">
                                            <small class="text-muted">
                                                <app-contract-range [dateRange]="period.data.dateRange" [isReadOnly]="true"></app-contract-range>
                                            </small>
                                        </div>
                                        <div class="col-auto my-3 ms-2" *ngIf="appContext.isAdminOrIntermediary()">
                                            <button type="button" class="btn position-absolute top-0 end-0 to-show"
                                                    style="right: 24px !important;"
                                                    role="button" aria-expanded="false"
                                                    (click)="openContractPeriodModal(contract, period)">
                                                <i class="bi bi-pencil"></i>
                                            </button>
                                        </div>
                                        <div class="col-auto my-3">
                                            <app-confirmation-toggle *ngIf="appContext.isAdminOrIntermediary()">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h3 class="modal-title">Remove period</h3>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                                aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <p>Are you sure you want to remove this contract period?</p>
                                                    </div>
                                                    <div class="modal-footer justify-content-between">
                                                        <a type="button" class="btn btn-secondary"
                                                           data-bs-dismiss="modal">Cancel</a>
                                                        <button type="button" class="btn btn-primary"
                                                                (click)="deleteContractPeriod(period, contract)"
                                                                data-bs-dismiss="modal">
                                                            Remove period
                                                        </button>
                                                    </div>
                                                </div>

                                                <button type="button" class="btn position-absolute top-0 end-0 to-show"
                                                        role="button" aria-expanded="false">
                                                    <i class="bi bi-trash"></i>
                                                </button>
                                            </app-confirmation-toggle>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="appContext.isAdminOrIntermediary()"
                                     class="list-group-item py-3 pointer hover-underline mt-auto"
                                     (click)="addNewContractPeriod(contract)">
                                    <div class="row">
                                        <div class="col-auto">
                                            <i class="bi me-2 bi-plus-circle"></i>
                                        </div>
                                        <div class="col text-truncate"><small>Add period</small></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </app-scroller>
</ng-container>
