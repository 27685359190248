import {Component, Input} from '@angular/core';
import {ElectricityConnectionInfo} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-electricity-details',
  templateUrl: './electricity-details.component.html',
  styleUrls: ['./electricity-details.component.scss']
})
export class ElectricityDetailsComponent {
  @Input() info: ElectricityConnectionInfo;

  get contractedCapacity() {
    return this.info.connectionValue?.contractedCapacity || this.info.contractedCapacity;
  }
}
