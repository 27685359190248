import {Component, Input, NgZone} from '@angular/core';
import moment, { unitOfTime } from "moment";
import {DashboardContext} from "../dashboard.context";
import {MeasurementsDataProvider} from "../../../utils/measurements-data-provider";
import {AppContext} from "../../../app-context";
import {DateFieldRange, MomentDateFieldRange} from "../../../common/date/date-range/date-field-range";
import {TranslateDirective} from "../../../common/translate.directive";

@Component({
  selector: 'app-data-provider-date-range',
  templateUrl: './data-provider-date-range.component.html',
  styleUrls: ['./data-provider-date-range.component.scss']
})
export class DataProviderDateRangeComponent {
  context = DashboardContext;
  _dataProvider: MeasurementsDataProvider<any>;

  @Input() set dataProvider(provider: MeasurementsDataProvider<any>) {
    this._dataProvider = provider;
    this.dateRangeChange({
      start: this._dataProvider.info.timeRange.start,
      end: this._dataProvider.info.timeRange.end,
      label: this._dataProvider.info.predefinedTimeRange
    });
  }

  constructor(private ngZone: NgZone) {
  }

  movePeriodBack = () => {
    this.dateRangeChange(this._dataProvider.getPreviousDateTimeRange());
  }

  movePeriodForward = () => {
    this.dateRangeChange(this._dataProvider.getNextDateTimeRange());
  }

  dateRangeChange(dateTimeRange: DateFieldRange) {
    this.ngZone.runOutsideAngular(() => {
      this._dataProvider.timeChanged({
        resolution: AppContext.resolutionForTimeRange(dateTimeRange),
        start: dateTimeRange.start,
        end: dateTimeRange.end,
        label: dateTimeRange.label
      });
    });
  }

  get dateLabel(): string {
    if (!this._dataProvider.info) {
      return "";
    }
    const timeRange: MomentDateFieldRange = {
      start: moment(this._dataProvider.info.timeRange.start),
      end: moment(this._dataProvider.info.timeRange.end).subtract(1, "day"),
      label: this._dataProvider.info.predefinedTimeRange
    };
    if (timeRange.label) {
      return TranslateDirective.getTranslation(timeRange.label, true);
    }
    const isSame = {
      day: timeRange.start.isSame(timeRange.end, 'day'),
      month: timeRange.start.isSame(timeRange.end, 'month'),
      wholeMonth: timeRange.start.isSame(timeRange.end, 'month') && this.isSame(timeRange, 'month'),
      year: timeRange.start.isSame(timeRange.end, 'year'),
      wholeYear: timeRange.start.isSame(timeRange.end, 'year') && this.isSame(timeRange, 'year')
    };
    if (isSame.day && isSame.month && isSame.year) {
      return moment(this._dataProvider.info.timeRange.start).format("DD MMM YYYY");
    }
    if (isSame.month && isSame.wholeMonth && isSame.year) {
      return moment(this._dataProvider.info.timeRange.start).format("MMMM YYYY");
    }
    if (isSame.wholeYear) {
      return moment(this._dataProvider.info.timeRange.start).format("YYYY");
    }
    return `${timeRange.start.format(AppContext.displayFormat)} - ${timeRange.end.format(AppContext.displayFormat)}`;
  }

  private isSame(timeRange: MomentDateFieldRange, unitOfTime: unitOfTime.StartOf) {
    if (unitOfTime === 'year' && !this.isSame(timeRange, 'month')) {
      return false;
    }
    return timeRange.start.isSame(timeRange.start.clone().startOf(unitOfTime), 'day')
        && timeRange.end.isSame(timeRange.end.clone().endOf(unitOfTime), 'day');
  }
}
