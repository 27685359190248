import {AlertStatus} from "@flowmaps/flowmaps-typescriptmodels";

export class AlertUtils {

    static alertStatusPriority = (alertStatus: AlertStatus) => {
        switch (alertStatus) {
            case AlertStatus.OPEN: return 1;
            case AlertStatus.MUTED: return 2;
            case AlertStatus.RESOLVED: return 3;
        }
    }
}