import {Component, Input, OnInit} from '@angular/core';
import {getConsumptionUnit, uuid} from "../../../../common/utils";
import {ConsumptionTax, TaxPeriod, TaxPeriodData, UpsertTaxPeriod} from "@flowmaps/flowmaps-typescriptmodels";
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {closeModal} from "../../../../app-utils";

@Component({
  selector: 'app-add-tax-period',
  templateUrl: './add-tax-period.component.html',
  styleUrls: ['./add-tax-period.component.css']
})
export class AddTaxPeriodComponent implements OnInit{
  @Input() data: TaxPeriodComponentData;
  isNewRecord: boolean;

  ngOnInit(): void {
    this.isNewRecord = !this.data || !this.data.period.periodId;
    if (this.isNewRecord) {
      this.data.period = {
        periodId: uuid(),
        data: {
          period: {
            start: "",
            end: ""
          },
          residentialRate: null,
          taxBrackets: []
        } as TaxPeriodData,
      };
    }
  }

  updateTaxPeriod = () => {
    const command: UpsertTaxPeriod = {
      periodId: this.data.period.periodId,
      data: this.data.period.data,
      taxId: this.data.tax.taxId
    }
    sendCommand("com.flowmaps.api.organisation.UpsertTaxPeriod", command, () => {
      const periodIndex = this.data.tax.taxPeriods.findIndex(p => p.periodId === this.data.period.periodId);
      if (periodIndex > -1) {
        this.data.tax.taxPeriods[periodIndex] = this.data.period;
      } else {
        this.data.tax.taxPeriods.push(this.data.period);
      }
      closeModal();
      publishEvent('taxPeriodUpdated', command);
    });
  }

  addTaxBracket() {
    this.data.period.data.taxBrackets.push({ minLimit: null, maxLimit: null, rate: null });
  }

  removeTaxBracket(index: number) {
    this.data.period.data.taxBrackets.splice(index, 1);
  }
  costUnit = () => "€"

  getConsumptionUnit = getConsumptionUnit;
}

export interface TaxPeriodComponentData {
  period: TaxPeriod;
  tax: ConsumptionTax;
}
