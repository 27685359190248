import {Component, EventEmitter, Output} from '@angular/core';
import {
    ConnectionType,
    Contract,
    ContractPeriod,
    ContractPeriodData,
    DeleteContractPeriod,
    OrganisationInfo,
    UpsertContractPeriod,
} from "@flowmaps/flowmaps-typescriptmodels";
import {closeModal, openModal} from "../../../../app-utils";

import {AnimationTypes} from '../../../../common/modal/modal.component';
import {ComparatorChain} from '../../../../common/comparator-chain';
import {AbstractOverviewComponent} from '../../../../common/abstract-overview/abstract-overview.component';
import {RefdataUtils} from '../../refdata-utils';
import {EventGateway} from '../../../../flux/event-gateway';
import {AppContext} from '../../../../app-context';
import {publishEvent, sendCommand} from '../../../../flux/flux-utils';
import {Router} from '@angular/router';
import {
    ContractPeriodComponentData,
    ContractPeriodDetailsComponent
} from '../contract-period-details/contract-period-details.component';
import {AddContractComponent, AddContractComponentData} from '../add-contract/add-contract.component';
import {cloneDeep} from "lodash";
import {computeItem} from "../../../../common/utils";

@Component({
    selector: 'app-contracts-list',
    templateUrl: './contracts-list.component.html'
})
export class ContractsListComponent
    extends AbstractOverviewComponent<ExtendedContract> {
    comparator: ComparatorChain = RefdataUtils.contractsComparator;
    connectionTypes = ConnectionTypeValues;
    selectedConnectionTypes: string[] = [];
    @Output() filterChanged = new EventEmitter<string[]>();

    readonly appContext = AppContext;
    protected readonly RefdataUtils = RefdataUtils;

    constructor(protected eventGateway: EventGateway, private router: Router) {
        super(eventGateway);
    }

    trackByForRecord(index: number, record: ExtendedContract) {
        return record.contractId;
    }

    trackByConnectionType(index: number, connectionType: string): string {
        return connectionType;
    }

    contractPeriodUpdated = (event: UpsertContractPeriod) => {
        this.computeItem(i => i.contractId === event.contractId, c => {
            c.periods = computeItem(
                c.periods, p => p.periodId === event.periodId, p => {
                    p = p || {periodId: event.periodId}
                    p.data = event.data;
                    return p;
                });
            return c;
        })
    }

    recordAdded(upsertCommand: any) {
        let item: ExtendedContract = {
            contractId: upsertCommand.contractId,
            contractData: upsertCommand.contractData,
            defaultContract: upsertCommand.defaultContract,
            periods: [],
            organisationId: upsertCommand.organisationId,
            organisationInfo: upsertCommand.organisationInfo
        };
        if (this.selectedConnectionTypes.length == 0 || this.selectedConnectionTypes.includes(item.contractData.connectionType)) {
            this.addItem(item);
        } else {
            publishEvent('reloadOverview');
        }
    }

    recordUpdated(upsertCommand: any) {
        publishEvent('reloadOverview');
    }

    addContract = () => openModal(AddContractComponent, <AddContractComponentData>{
        contract: AddContractComponent.newContract()
    }, {
        cssClass: "modal-xl",
        animation: AnimationTypes.fade
    });

    navigateToContract(contractId: string, ): void {
        this.router.navigate(['/contract', contractId]);
    }

    addNewContractPeriod(contract: ExtendedContract): void {
        const newPeriod: ContractPeriod = {
            periodId: '',
            data: {
                dateRange: {
                    start: '',
                    end: ''
                },
                offPeakRate: null,
                rate: null,
                feedInRate: null,
                feedInOffPeakRate: null,
                standingCharge: null,
                supplier: null,
                sustainabilitySource: null
            } as ContractPeriodData,
        };
        this.openContractPeriodModal(contract, newPeriod);
    }

    deleteContractPeriod = (record: ContractPeriod, contract: ExtendedContract) => {
        const command: DeleteContractPeriod = {
            periodId: record.periodId,
            contractId: contract.contractId,
            organisationId: contract.organisationId
        };
        sendCommand("com.flowmaps.api.organisation.DeleteContractPeriod", command, () => {
            const contractToUpdate = this.data.find(c => c.contractId === contract.contractId);
            if (contractToUpdate) {
                contractToUpdate.periods = contractToUpdate.periods.filter(
                    period => period.periodId !== record.periodId
                );
            }
            closeModal();
        });

    }

    openContractPeriodModal(contract: ExtendedContract, contractPeriod?: ContractPeriod): void {
        openModal(ContractPeriodDetailsComponent, cloneDeep(<ContractPeriodComponentData>{
            period: contractPeriod,
            contract: contract,
            organisationId: contract.organisationId,
            supplier: contractPeriod.data.supplier,
            sustainabilitySource: contractPeriod.data.sustainabilitySource
        }), {
            cssClass: "modal-xl custom-modal-wide",
            animation: AnimationTypes.fade
        });
    }

    toggleConnectionType(event: Event): void {
        const target = event.target as HTMLInputElement;
        const value = target.value;

        if (target.checked) {
            this.selectedConnectionTypes.push(value);
        } else {
            const index = this.selectedConnectionTypes.indexOf(value);
            if (index >= 0) {
                this.selectedConnectionTypes.splice(index, 1);
            }
        }
        this.filterChanged.emit(this.selectedConnectionTypes);
    }

    clearFilter(): void {
        this.selectedConnectionTypes = [];
        this.filterChanged.emit(this.selectedConnectionTypes);
    }
}

export interface ExtendedContract extends Contract {
    organisationInfo: OrganisationInfo;
    organisationId: string;
}

export const ConnectionTypeValues = [
    ConnectionType.Electricity,
    ConnectionType.Gas,
    ConnectionType.Water,
    ConnectionType.Heat,
    ConnectionType.Cooling,
];
