<div class="p-0 notranslate" [style.width]="(280 * sortedCalendar.length) + 'px'">
  <div class="row bg-light gx-0 flex-nowrap">
    <div class="col-auto px-1 d-flex align-content-center">
      <button class="btn btn-outline" (click)="previous('month')"><i class="bi bi-caret-left-fill"></i></button>
    </div>
    <div class="row col flex-md-nowrap header-select justify-content-center">
      <div class="col-6 col-md p-1" style="max-width: 200px">
        <app-select [(ngModel)]="month" [options]="monthNames" (ngModelChange)="selectMonth($event)"></app-select>
      </div>
      <div class="col-6 col-md p-1" style="max-width: 200px">
        <app-select [(ngModel)]="year" [options]="years" (ngModelChange)="selectYear($event)"></app-select>
      </div>
    </div>
    <div class="col-auto px-1 d-flex align-content-center">
      <button class="btn btn-outline" (click)="next('month')"><i class="bi bi-caret-right-fill"></i></button>
    </div>
  </div>
  <div class="flex-nowrap">
    <div class="row gx-0 bg-light">
      <div *ngFor="let c of sortedCalendar" class="col text-center">
        <span class="h2">{{c.month}}</span>
      </div>
    </div>
    <div class="row gx-0 bg-light mb-2">
      <ng-container *ngFor="let c of sortedCalendar">
        <div *ngFor="let day of days" class="col text-center px-0">{{day}}</div>
      </ng-container>
    </div>
    <div class="row gx-1 notranslate">
      <div *ngFor="let c of sortedCalendar" class="col">
        <div *ngFor="let w of c.weeks | keyvalue" class="row gx-0">
          <button *ngFor="let d of w.value.days" class="col day-cell btn btn-light p-0 m-0 border-0" [disabled]="d.disabled"
                  [ngClass]="d.cssClasses" (click)="changeDate(d.date)" [appAutoFocus]="isSelectedDate(d.date)" (mouseenter)="cellMouseEnter(d)"
                  (keydown.arrowLeft)="previous('day'); $event.preventDefault()" (keydown.arrowRight)="next('day'); $event.preventDefault()"
                  (keydown.arrowUp)="previous('week'); $event.preventDefault();" (keydown.arrowDown)="next('week'); $event.preventDefault()"
                  (keydown.enter)="updateDate(); $event.preventDefault()">
            {{d.date.date()}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
