import {Component, Input} from '@angular/core';
import {BuildingType, Location} from "@flowmaps/flowmaps-typescriptmodels";
import {EnergyLabels} from "../../../common/energy-label/energy-label.component";
import {EnergyCompassUtils} from "../../charts/energy-compass-chart/energy-compass.utils";
import moment from "moment/moment";
import {RefdataUtils} from "../../refdata/refdata-utils";


@Component({
    selector: 'app-location-energy-label-info',
    templateUrl: './location-energy-label-info.component.html',
    styleUrls: ['./location-energy-label-info.component.scss']
})
export class LocationEnergyLabelInfoComponent {
    refdataUtils = RefdataUtils;
    _location: Location;
    buildingType: BuildingType;

    energyLabels: EnergyLabels[] = [
         EnergyLabels.G, EnergyLabels.F, EnergyLabels.E, EnergyLabels.D, EnergyLabels.C, EnergyLabels.B, EnergyLabels.A
    ];

    @Input()
    set location(location: Location) {
        this._location = location;
        if (location) {
            EnergyCompassUtils.findBuildingTypes(location.info.buildingType)
                .subscribe(b => this.buildingType = b[0]);
        }
    }

    getEnergyLabelClass = (label: EnergyLabels) => {
        let classes = {
            active: this.isActiveEnergyLabel(label),
            'before-active': this.isNextLabelActive(label)
        }
        classes['energy-label-' + label.toLowerCase()] = true;
        return classes;
    }

    getLabel = (label: EnergyLabels) => {
        return this.isActiveEnergyLabel(label) ? this._location.info.energyLabel || this._location.refdata?.energyLabel : label;
    }

    isNextLabelActive = (label: EnergyLabels) => {
        return label === EnergyLabels.A ? false : this.isActiveEnergyLabel(this.energyLabels[this.energyLabels.indexOf(label) + 1])
    }

    isActiveEnergyLabel = (label: EnergyLabels) => {
        const energyLabel = this._location.info.energyLabel || this._location.refdata?.energyLabel;
        return energyLabel ? (energyLabel as string).includes(label) : false;
    }

    formatDate = (date: string) => moment(date).format("DD MMM YYYY");
}
