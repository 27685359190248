<div class="main-content">
    <div class="container-fluid">
        <div class="justify-content-center row">
            <div class="col-12 min-vh-100 d-flex flex-column">
                <div class="header">
                    <div class="header-body">
                        <div class="align-items-center row">
                            <div class="col"><h6 class="header-pretitle">Overview</h6>
                                <h1 class="header-title text-truncate">Connections</h1></div>
                            <div class="col-auto d-none">
                                <button type="button" class="ms-2 btn btn-primary">Add organisation</button>
                            </div>
                        </div>
                    </div>
                </div>
                <app-connections-list [loadCount]="loadCount" [data]="connections" class="d-flex flex-column flex-grow-1"></app-connections-list>
            </div>
        </div>
    </div>
</div>