<div class="d-flex align-items-stretch" style="flex: 1">

    <div class="container-fluid mt-3">
        <div class="card border-0">
            <div class="card-body">

                <div class="row">

                    <div class="col-md-8">
                        <div class="row border mb-3 mx-1">
                            <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Error
                                ID
                            </div>
                            <div class="col flex-fill p-3 text-break notranslate">
                                {{ entry.errorId || "-" }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="d-flex flex-column mb-3">
                                    <div class="d-flex border row mx-1">
                                        <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Error
                                            message
                                        </div>
                                        <div class="col flex-fill p-3 text-break notranslate">
                                            {{ entry.errorDetails.errorMessage || "-" }}
                                        </div>
                                    </div>
                                    <div class="d-flex border row mx-1">
                                        <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Error
                                        </div>
                                        <div class="col flex-fill p-3 text-break notranslate">
                                            <a [href]="getKibanaUrl(entry.errorDetails.error)"
                                               target="_blank">{{ entry.errorDetails.error || "-" }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="d-flex flex-column mb-3">
                                    <div class="d-flex border row mx-1">
                                        <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Level
                                        </div>
                                        <div class="col flex-fill p-3 notranslate">
                                            {{ formattedLevel || "-" }}
                                        </div>
                                    </div>
                                    <div class="d-flex border row mx-1">
                                        <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">
                                            Message
                                        </div>
                                        <div class="col flex-fill p-3 text-break notranslate">
                                            {{ entry.errorDetails.message || "-" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="info-group">
                                <div class="border d-flex mx-1">
                                    <div class="overflow-auto w-100">
                                        <div class="d-flex justify-content-between align-items-center p-3 bg-light fw-bold border-end notranslate w-100">
                                            <span>Stack Trace</span>
                                            <div class="d-flex align-items-center">
                                                <label class="me-2 mb-0">Application Only</label>
                                                <label class="switch ms-2">
                                                    <input type="checkbox" (click)="toggleStackTrace()">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col flex-fill p-3 notranslate stack-trace"
                                             *ngIf="isFullStackTraceDisplayed">
                                            {{ entry.errorDetails.stackTrace }}
                                        </div>
                                        <div class="col flex-fill p-3 notranslate stack-trace"
                                             *ngIf="!isFullStackTraceDisplayed">
                                            {{ getStackTrace() }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex border row mx-1">
                            <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Message Index</div>
                            <div class="col flex-fill p-3 text-break notranslate">
                                <a [href]="getKibanaUrl(entry.errorMetadata.messageIndex.toString())"
                                   target="_blank">{{ entry.errorMetadata.messageIndex || "-" }}</a>
                            </div>
                        </div>
                        <div class="d-flex border row mx-1">
                            <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Message ID</div>
                            <div class="col flex-fill p-3 text-break notranslate">
                                <a [href]="getKibanaUrl(entry.errorMetadata.messageId)"
                                   target="_blank">{{ entry.errorMetadata.messageId || "-" }}</a>
                            </div>
                        </div>
                        <div class="d-flex border row mx-1">
                            <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Invocation ID</div>
                            <div class="col flex-fill p-3 text-break notranslate">
                                <a [href]="getKibanaUrl(entry.errorMetadata.invocationId)"
                                   target="_blank">{{ entry.errorMetadata.invocationId || "-" }}</a>
                            </div>
                        </div>
                        <div class="d-flex border row mx-1">
                            <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Correlation ID
                            </div>
                            <div class="col flex-fill p-3 text-break notranslate">
                                <a [href]="getKibanaUrl(entry.errorMetadata.correlationId)"
                                   target="_blank">{{ entry.errorMetadata.correlationId || "-" }}</a>
                            </div>
                        </div>
                        <div class="d-flex border row mx-1">
                            <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Trace ID</div>
                            <div class="col flex-fill p-3 text-break notranslate">
                                <a [href]="getKibanaUrl(entry.errorMetadata.traceId.toString())"
                                   target="_blank">{{ entry.errorMetadata.traceId || "-" }}</a>
                            </div>
                        </div>
                        <div class="d-flex border row mx-1">
                            <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Client ID</div>
                            <div class="col flex-fill p-3 text-break notranslate">
                                <a [href]="getKibanaUrl(entry.errorMetadata.clientId)"
                                   target="_blank">{{ entry.errorMetadata.clientId || "-" }}</a>
                            </div>
                        </div>
                        <div class="d-flex border row mx-1">
                            <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Trace Element</div>
                            <div class="col flex-fill p-3 text-break notranslate">
                                <a [href]="getKibanaUrl(entry.errorMetadata.traceElement)"
                                   target="_blank">{{ entry.errorMetadata.traceElement || "-" }}</a>
                            </div>
                        </div>
                        <div class="d-flex flex-column mb-3">
                            <div class="d-flex border row mx-1">
                                <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Logger name
                                </div>
                                <div class="col flex-fill p-3 text-break notranslate">
                                    <a [href]="getKibanaUrl(entry.errorDetails.loggerName)"
                                       target="_blank">{{ entry.errorDetails.loggerName || "-" }}</a>
                                </div>
                            </div>
                            <div class="d-flex border row mx-1">
                                <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Trigger</div>
                                <div class="col flex-fill p-3 text-break notranslate">
                                    <a [href]="getKibanaUrl(entry.errorDetails.trigger)"
                                       target="_blank">{{ entry.errorDetails.trigger || "-" }}</a>
                                </div>
                            </div>

                            <div class="d-flex border row mx-1">
                                <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Trigger type
                                </div>
                                <div class="col flex-fill p-3 text-break notranslate">
                                    {{ entry.errorDetails.triggerType || "-" }}
                                </div>
                            </div>
                            <div class="d-flex border row mx-1">
                                <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Consumer</div>
                                <div class="col flex-fill p-3 text-break notranslate">
                                    <a [href]="getKibanaUrl(entry.errorMetadata.consumer)"
                                       target="_blank">{{ entry.errorMetadata.consumer || "-" }}</a>
                                </div>
                            </div>

                            <div class="d-flex border row mx-1">
                                <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Message
                                    Template
                                </div>
                                <div class="col flex-fill p-3 text-break notranslate">
                                    <a [href]="getKibanaUrl(entry.errorDetails.messageTemplate)"
                                       target="_blank">{{ entry.errorDetails.messageTemplate || "-" }}</a>
                                </div>
                            </div>
                            <div class="d-flex border row mx-1">
                                <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Sequence</div>
                                <div class="col flex-fill p-3 text-break notranslate">{{ entry.sequence || "-" }}</div>
                            </div>
                            <div class="d-flex border row mx-1">
                                <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Application
                                </div>
                                <div class="col flex-fill p-3 text-break notranslate">
                                    <a [href]="getKibanaUrl(entry.errorDetails.application)"
                                       target="_blank">{{ entry.errorDetails.application || "-" }}</a>
                                </div>
                            </div>
                            <div class="d-flex border row mx-1">
                                <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate">Environment
                                </div>
                                <div class="col flex-fill p-3 text-break notranslate">{{ formattedEnvironment || "-" }}</div>
                            </div>
                            <div class="d-flex border row mx-1">
                                <div class="col-3 flex-fill p-3 bg-light fw-bold border-end notranslate ">Version ID
                                </div>
                                <div class="col flex-fill p-3 text-break notranslate ">{{ entry.errorMetadata.versionId || "-" }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>