<div class="row m-0 p-0">
    <div class="col-12 col-md-7">
        <app-form-field label="Street">
            <input [(ngModel)]="address.street" type="text" class="form-control" required>
        </app-form-field>
    </div>
    <div class="col-8 col-md-3">
        <app-form-field label="House number">
            <input [(ngModel)]="address.number" type="text" class="form-control" required>
        </app-form-field>
    </div>
    <div class="col-4 col-md-2">
        <app-form-field label="Addition">
            <input [(ngModel)]="address.addition" type="text" class="form-control">
        </app-form-field>
    </div>
    <div class="col-12 col-md-6">
        <app-form-field label="Zipcode">
            <input [(ngModel)]="address.zipCode" type="text" class="form-control" required
                   (ngModelChange)="address.zipCode = upperCase(address.zipCode)">
        </app-form-field>
    </div>
    <div class="col-12 col-md-6">
        <app-form-field label="City">
            <input [(ngModel)]="address.city" type="text" class="form-control" required>
        </app-form-field>
    </div>
    <div class="col-12 col-md-6">
        <app-form-field label="Country">
            <input [(ngModel)]="address.country" type="text" class="form-control" required
                   (ngModelChange)="address.country = upperCase(address.country)">
        </app-form-field>
    </div>
</div>
<div class="row m-0 p-0">
    <h6 class="col-12 header-pretitle">Geo location</h6>
    <div class="col-12 col-md-6">
        <app-form-field label="Latitude">
            <input required [(ngModel)]="address.geoLocation.latitude" type="text" class="form-control">
        </app-form-field>
    </div>
    <div class="col-12 col-md-6">
        <app-form-field label="Longitude">
            <input required [(ngModel)]="address.geoLocation.longitude" type="text" class="form-control">
        </app-form-field>
    </div>
</div>