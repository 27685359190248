import {ComparatorChain} from "../../common/comparator-chain";
import {combineLatest, map, Observable} from "rxjs";
import {
    Address,
    AlertType,
    Connection,
    ConnectionType,
    ConnectionValue,
    Contract,
    ContractData,
    Error,
    ErrorEntry,
    FacetMatch,
    FindConnectionValues,
    FindMyAlertsResult,
    FindUsers,
    GetError,
    GetErrorEntries,
    GetErrors,
    GetErrorsResult,
    GetMyAlerts,
    GetMyConsumptionTaxes,
    GetMyMeterViews,
    GetMyOrganisations,
    GetServiceProviders,
    GetTaxesResult,
    Location,
    LocationInfo,
    MeterType,
    MeterView,
    Organisation,
    OrganisationInfo,
    Role,
    ServiceProvider,
    ServiceProviderType,
    SourceIds,
    SustainabilitySource,
    UserProfile
} from "@flowmaps/flowmaps-typescriptmodels";
import {sendQuery} from "../../flux/flux-utils";
import {filterByTerm, toTitleCase} from "../../common/utils";
import {SourceType} from "../dashboard/dashboard.context";
import {ExtendedLocation} from "./locations/locations-list/locations-list.component";
import {SourceInfo} from "../../utils/source-providers/sources-provider";
import {MeterWithConnection} from "../location-dashboard/location-meters/location-meters.component";
import {ExtendedConnection} from "./connections/connections-overview/connections-overview.component";
import lodash from "lodash";
import {ExtendedContract} from "./contracts/contracts-list/contracts-list.component";
import {DateFieldRange} from '../../common/date/date-range/date-field-range';

export class RefdataUtils {

    static organisationComparator: ComparatorChain = new ComparatorChain('info.name', 'organisationId');
    static locationsComparator: ComparatorChain = new ComparatorChain('info.name', 'info.address.street', 'locationId');
    static usersComparator: ComparatorChain = new ComparatorChain('info.lastName', 'info.firstName');
    static connectionsComparator: ComparatorChain = new ComparatorChain('info.connectionType', 'location.info.name', 'location.info.address.street', 'connection.info.code');
    static reportsComparator: ComparatorChain = new ComparatorChain('info.name', 'reportId');
    static buildingTypesComparator: ComparatorChain = new ComparatorChain('info.name', 'buildingTypeId');
    static locationEnergyCompassYearsComparator: ComparatorChain = new ComparatorChain('year');
    static alertsComparator: ComparatorChain = new ComparatorChain('alertStatusPriority', '-timestamp',
        'meter.connection.info.code', 'alertId');
    static errorsComparator: ComparatorChain = new ComparatorChain( '-lastSeen', 'errorId');
    static contractsComparator: ComparatorChain = new ComparatorChain( 'contractData.name', 'contractId');
    static taxesComparator: ComparatorChain = new ComparatorChain( 'taxInfo.country','taxInfo.connectionType', 'taxId');
    static enumFormatter: (value: string) => string = v => {
        return toTitleCase(v).replace(/_/g, " ");
    };

    static searchOrganisation = (term: string): Observable<Organisation[]> => RefdataUtils.getMyOrganisations(term)
        .pipe(map(values => values.sort(RefdataUtils.organisationComparator.compare)));

    static getMyContracts = (term?: string): Observable<ExtendedContract[]> =>
        combineLatest([this.getMyOrganisations(), sendQuery("com.flowmaps.api.organisation.GetMyContracts", {})])
            .pipe(map((result : [Organisation[], Contract[]])  => {
                const organisations = result[0];
                const contracts = result[1];
                return contracts
                    .map(c => RefdataUtils.addOrganisationIdsToContracts(
                    c,
                    organisations.find(o => o.contracts.some(c2 => c.contractId === c2.contractId)))
                );
            }))
            .pipe(map(values => values.filter(filterByTerm(term))))
            .pipe(map(values => values.sort(this.contractsComparator.compare)));

    static getConsumptionTaxes = (facetFilters: FacetMatch[] = null): Observable<GetTaxesResult> =>
        sendQuery("com.flowmaps.api.organisation.GetMyConsumptionTaxes", <GetMyConsumptionTaxes>{
        facetFilters: facetFilters,
    }, {});

    static getContractDescription(contractId: string): Observable<string> {
        return RefdataUtils.getContract(contractId).pipe(map(c => c?.contractData.name));
    }

    static getContractType(contractId: string): Observable<string> {
        return RefdataUtils.getContract(contractId).pipe(map(c => c?.contractData.connectionType));
    }

    static getMyOrganisations = (term?: string): Observable<Organisation[]> => sendQuery("com.flowmaps.api.organisation.GetMyOrganisations", <GetMyOrganisations>{}, {
        caching: true
    }).pipe(map(values => values.filter(filterByTerm(term))))
        .pipe(map(values => values.sort(this.organisationComparator.compare)));

    static getMyMeterViewsWithoutSources = (primaryMetersOnly: boolean): Observable<ExtendedMeterView[]> =>
        this.getMyMeterViews({}, primaryMetersOnly);

    static getMyAlerts = (facetFilters: FacetMatch[] = null, dateRange: DateFieldRange): Observable<FindMyAlertsResult> => {
        return sendQuery('com.flowmaps.api.monitoring.alerting.GetMyAlerts', <GetMyAlerts>{
            facetFilters: facetFilters,
            timeRange: dateRange
        }, {caching: false});
    }

    static getErrors = (facetFilters: FacetMatch[] = null, dateRange: DateFieldRange): Observable<GetErrorsResult> => {
        return sendQuery('com.flowmaps.api.monitoring.errors.GetErrors', <GetErrors>{
            facetFilters: facetFilters,
            timeRange: dateRange
        }, {caching: false});
    }

    static getOpenErrors = (): Observable<GetErrorsResult> => {
        return sendQuery('com.flowmaps.api.monitoring.errors.GetErrors', <GetErrors>{
            facetFilters: [
                {
                    "facetName": "status",
                    "values": ["OPEN"]
                }
            ]
        }, {caching: true});
    }

    static getErrorEntries = (
        errorId: string = null,
        ascending: boolean = true
    ): Observable<Array<ErrorEntry>> => {
        return sendQuery('com.flowmaps.api.monitoring.errors.GetErrorEntries', <GetErrorEntries>{
            errorId: errorId,
            size: 100,
            ascending: ascending,
        }, { caching: false });
    }

    static getError = (
        errorId: string = null): Observable<Error> => {
        return sendQuery('com.flowmaps.api.monitoring.errors.GetError', <GetError>{
            errorId: errorId,
        }, { caching: false });
    }

    static getMyOpenAlerts = (): Observable<FindMyAlertsResult> => {
        return sendQuery('com.flowmaps.api.monitoring.alerting.GetMyAlerts', <GetMyAlerts>{
            facetFilters: [{
                facetName: "alertStatus",
                values: ["OPEN"]
            }]
        }, {caching: true});
    }

    static getMyMeterViews = (sources: SourceIds = {}, primaryMetersOnly: boolean = false): Observable<ExtendedMeterView[]> =>
        combineLatest([this.getMeterViews(sources, primaryMetersOnly), this.getAllMeters()])
            .pipe(map(result => {
                const meters = lodash.keyBy(result[1], "meter.meterId");
                return result[0].map(meterView => {
                    const meter = meters[meterView.meterId];
                    meterView.dataPeriods = meterView.dataPeriods.map(d => ({
                        start: new Date(d.start),
                        end: new Date(d.end)
                    }));
                    const extendedMeterView: ExtendedMeterView = {
                        meterView: meterView,
                        connectionId: meterView.connectionId,
                        connectionType: meterView.connectionType
                    };
                    if (meter && meter.meter) {
                        extendedMeterView.desiredStartDate = meter.connection.info.desiredStartDate ? new Date(meter.connection.info.desiredStartDate) : null;
                        extendedMeterView.activeRange = {
                            start: meter.meter.timeRange?.start ? new Date(meter.meter.timeRange.start) : null,
                            end: meter.meter.timeRange?.end ? new Date(meter.meter.timeRange.end) : null
                        }
                        extendedMeterView.authorizedRange = {
                            start: meter.meter.info?.authorizedFrom ? new Date(meter.meter.info.authorizedFrom) : null,
                            end: meter.meter.info?.authorizedUntil ? new Date(meter.meter.info.authorizedUntil) : null
                        }
                    }
                    return extendedMeterView;
                })
            }));

    private static getMeterViews = (sources: SourceIds = {}, primaryMetersOnly: boolean = false): Observable<MeterView[]> =>
        sendQuery("com.flowmaps.api.monitoring.GetMyMeterViews", <GetMyMeterViews>{sources: sources}, {
            caching: true
        }).pipe(map((meterViews: MeterView[]) => meterViews.filter(
            v => !primaryMetersOnly || v.meterType === MeterType.PRIMARY)));

    static findUsers = (term?: string): Observable<UserProfile[]> => sendQuery("com.flowmaps.api.user.FindUsers", <FindUsers>{term: term}, {
        caching: true
    }).pipe(map(values => values.sort(this.usersComparator.compare)));

    static getOrganisation = (organisationId: string): Observable<Organisation> => this.getMyOrganisations()
        .pipe(map(orgs => orgs.find(o => o.organisationId === organisationId)));

    static organisationFormatter = (organisation: Organisation) =>
        organisation ? this.organisationInfoFormatter(organisation.info) : null;

    static contractsFormatter = (contract: Contract) =>
        contract ? this.contractInfoFormatter(contract.contractData) : null;

    static organisationInfoFormatter = (organisationInfo: OrganisationInfo) => organisationInfo?.name;

    static contractInfoFormatter = (contractData: ContractData) => contractData?.name;

    static organisationFormatterAsync = (o: Observable<Organisation>) => o.pipe(map(this.organisationFormatter));

    static locationInfoFormatter = (info: LocationInfo) => info ?
        `${info.name} - ${(this.addressFormatter(info.address))}` : null;

    static locationsFormatter = (loc: Location) => loc ?
        `${loc.info.name} - ${(this.addressFormatter(loc.info.address))}` : null;

    static addressFormatter = (address: Address, includeZipCode: boolean = true) => address
        ? includeZipCode
            ? `${address.street} ${address.number}${address.addition ? ' ' + address.addition : ''}, ${address.zipCode} ${address.city}`
            : `${address.street} ${address.number}${address.addition ? ' ' + address.addition : ''}, ${address.city}`
        : "";

    static connectionFormatter = (connection: Connection) => `${connection.info.label || connection.info.code}`;

    static meterFormatter = (m: MeterWithConnection) => {
        const meterCode = m.meter.details?.label || m.meter.info.accessPointId;
        const surveyor = m.meter.info.surveyor?.name;
        return `${m.connection && m.connection.info.code !== meterCode ? m.connection.info.code + ' – ' : ''}${meterCode} (${surveyor === 'EDSN' ? 'KV' : surveyor})`.trim();
    };

    static getLocations = (organisationId: string): Observable<ExtendedLocation[]> => this.getOrganisation(organisationId)
        .pipe(map(o => o.locations.map(l => this.addOrganisationIdsToLocation(l, o))));

    static getLocation = (locationId: string): Observable<ExtendedLocation> => this.getMyOrganisations()
        .pipe(map(orgs => {
            return orgs.flatMap(o => o.locations
                .map(l => this.addOrganisationIdsToLocation(l, o))
                .find(l => l.locationId === locationId || l.aliasIds.includes(locationId)))
                .filter(l => l)[0];
        }));

    static getMeters = (locationId: string): Observable<MeterWithConnection[]> => this.getLocation(locationId)
        .pipe(map(o => o.connections.flatMap(c => c.meters.map(m => (<MeterWithConnection>{
            meter: m,
            connection: c,
            location: o
        })))));

    static getConnections = (organisationId: string): Observable<Connection[]> => this.getLocations(organisationId)
        .pipe(map(o => o.flatMap(l => l.connections)));

    static getConnection = (organisationId: string, connectionId: string): Observable<Connection> => this.getConnections(organisationId)
        .pipe(map(connections => connections.find(m => m.connectionId === connectionId)));

    static getAllLocations = (term?: string): Observable<ExtendedLocation[]> => this.getMyOrganisations()
        .pipe(map(orgs => orgs.flatMap(o => o.locations.map(
            l => this.addOrganisationIdsToLocation(l, o)))))
        .pipe(map(values => values.filter(filterByTerm(term))))
        .pipe(map(values => values.sort(this.locationsComparator.compare)));

    static getProviders = (type: ServiceProviderType) => (<Observable<ServiceProvider[]>>sendQuery("com.flowmaps.api.organisation.GetServiceProviders", <GetServiceProviders>{
        type: type
    }, {caching: true})).pipe(map(s => s.sort(new ComparatorChain('name', 'code').compare)));

    static getSustainabilitySources(connectionType?: string): Observable<SustainabilitySource[]> {
        return <Observable<SustainabilitySource[]>>sendQuery(
            "com.flowmaps.api.organisation.GetSustainabilitySources",
            { connectionType },
            { caching: true }
        ).pipe(
            map(sources => sources.sort(new ComparatorChain('name', 'code').compare))
        );
    }
    static getConnectionValues = (connectionType?: ConnectionType): Observable<ConnectionValue[]> =>
        sendQuery("com.flowmaps.api.refdata.FindConnectionValues", <FindConnectionValues>{
            connectionType: connectionType
        }, {caching: true});

    static addOrganisationIdsToLocation = (l: Location, organisation: Organisation): ExtendedLocation => {
        const loc = l as ExtendedLocation;
        loc.organisationId = organisation.organisationId;
        loc.intermediaryId = organisation.intermediaryId;
        loc.organisationInfo = organisation.info;
        return loc;
    }

    static addOrganisationIdsToContracts = (c: Contract, organisation?: Organisation): ExtendedContract => {
        const con = c as ExtendedContract;
        if (organisation && organisation.organisationId) {
            con.organisationId = organisation.organisationId;
            con.organisationInfo = organisation.info;
        }
        return con;
    }

    static getAllConnections = (): Observable<ExtendedConnection[]> => this.getAllLocations()
        .pipe(map(locs => locs.flatMap(l => l.connections
            .map(c => (<ExtendedConnection>{
                intermediaryId: l.intermediaryId,
                organisationId: l.organisationId,
                locationId: l.locationId,
                connection: c,
                organisationInfo: l.organisationInfo,
                locationInfo: l.info
            })))))

    static getAllMeters = (): Observable<MeterWithConnection[]> => this.getAllConnections().pipe(map(
        con => con.flatMap(o => o.connection.meters.map(
            m => (<MeterWithConnection>{
                meter: m,
                connection: o.connection,
                location: {
                    locationId: o.locationId,
                    info: o.locationInfo
                }
            })))));

    static getContract = (id: string): Observable<ExtendedContract> => this.getMyContracts()
        .pipe(map(contracts =>contracts.find(c => c.contractId === id)))

    static authorisationOptions(): string[] {
        return [Role.owner, Role.manager, Role.viewer];
    }

    static alertTypeOptions(): AlertType[] {
        return [AlertType.MissingData, AlertType.Disconnected, AlertType.Peak, AlertType.ContractedPowerExceeded];
    }

    static getLocationArea = (location: Location): number => location?.info.area || location?.refdata?.area;

    static organisationToSourceInfo = (o: Organisation) => (<SourceInfo>{
        id: o.organisationId,
        aliases: [],
        name: o.info.name,
        source: o,
        type: SourceType.organisation
    });

    static locationToSourceInfo = (l: Location) => (<SourceInfo>{
        id: l.locationId,
        aliases: l.aliasIds,
        name: this.locationInfoFormatter(l.info),
        source: l,
        type: SourceType.location
    });

    static connectionToSourceInfo = (c: Connection, l: LocationInfo) => (<SourceInfo>{
        id: c.connectionId,
        aliases: [],
        name: c.info.code || c.connectionId,
        filteredNameOverride: `${c.info.code || c.connectionId} – ${this.locationInfoFormatter(l)}`,
        source: c,
        type: SourceType.connection,
        connectionType: c.info.connectionType,
    });

    static meterToSourceInfo = (m: MeterWithConnection) => (<SourceInfo>{
        id: m.meter.meterId,
        aliases: [],
        name: this.meterFormatter(m),
        source: m,
        type: SourceType.meter,
        meterType: m.meter.details?.type || m.meter.info.type,
    });
}

export interface ExtendedMeterView {
    connectionId: string;
    connectionType: ConnectionType;
    meterView: MeterView;
    activeRange?: DateRange;
    authorizedRange?: DateRange;
    desiredStartDate?: Date;
}

interface DateRange {
    start: Date;
    end: Date;
}