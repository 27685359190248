import {Component, forwardRef} from '@angular/core';
import {ConnectionType, DataType, MeterType} from "@flowmaps/flowmaps-typescriptmodels";
import {BaseMeasurementChartComponent, MeasurementDataset} from "../base-measurement-chart";
import {DashboardContext, SourceType} from "../../dashboard/dashboard.context";
import {AppContext} from "../../../app-context";
import moment from "moment";
import {localTimeFormat} from '../../../common/utils';
import {TranslateDirective} from "../../../common/translate.directive";
import {MeasurementsDataProvider} from "../../../utils/measurements-data-provider";

@Component({
    selector: 'app-electricity-chart',
    templateUrl: './electricity-chart.component.html',
    styleUrls: ['./electricity-chart.component.scss'],
    providers: [{provide: BaseMeasurementChartComponent, useExisting: forwardRef(() => ElectricityChartComponent)}]
})
export class ElectricityChartComponent extends BaseMeasurementChartComponent {

    showDataNormally = (): boolean => this.options.splitOffPeak;

    measurementTypes = (): DataType[] => {
        let baseMeasurements = this.showConsumption() ? [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak] : [];
        if (this.options.showReactive) {
            baseMeasurements = baseMeasurements.concat(DataType.electricityConsumptionReactive, DataType.electricityFeedInReactive,
                DataType.electricityConsumptionReactiveOffPeak, DataType.electricityFeedInReactiveOffPeak);
        }
        if (this.options.showGrossProduction) {
            baseMeasurements = baseMeasurements.concat([DataType.electricityGrossProduction]);
            if (this.options.showReactive) {
                baseMeasurements = baseMeasurements.concat([DataType.electricityGrossProductionReactive]);
            }
        }
        if (this.showConsumption() && this.groupByEntityIdEnabled()) {
            baseMeasurements = baseMeasurements.concat([DataType.electricityIntermediateConsumption]);
            if (this.options.showReactive) {
                baseMeasurements = baseMeasurements.concat(DataType.electricityIntermediateConsumptionReactive);
            }
        }
        return baseMeasurements;
    };

    productionDataTypes = (): DataType[] => this.showFeedIn() ? [DataType.electricityFeedIn, DataType.electricityFeedInOffPeak] : [];

    consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>()
        .set(DataType.electricityConsumption, DataType.electricityFeedIn)
        .set(DataType.electricityConsumptionOffPeak, DataType.electricityFeedInOffPeak);

    measurementIntermediateLink = (): Map<DataType, DataType[]> => {
        const intermediateValues = [DataType.electricityIntermediateConsumption]
            .concat(this.options.showGrossProduction ? [DataType.electricityGrossProduction] : []);
        return new Map<DataType, DataType[]>()
            .set(DataType.electricityConsumption, intermediateValues)
            .set(DataType.electricityConsumptionOffPeak, intermediateValues);
    };

    connectionType = (): ConnectionType => ConnectionType.Electricity;

    openModal = () => this.openModalWithType(ElectricityChartComponent);

    measurementUnit = () => this.context.getMeasurementUnit(DataType.electricityConsumption);

    measurementTypesMapped = (): {[key: string]: DataType[]} => {
        const measurementTypes = {
            [DataType.electricityConsumption]: [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak],
            [DataType.electricityIntermediateConsumption]: [DataType.electricityIntermediateConsumption],
            [DataType.electricityGrossProduction]: [DataType.electricityGrossProduction]
        };
        if (this.options.showReactive) {
            measurementTypes[DataType.electricityConsumptionReactive] = [DataType.electricityConsumptionReactive, DataType.electricityConsumptionReactiveOffPeak];
            measurementTypes[DataType.electricityFeedInReactive] = [DataType.electricityFeedInReactive, DataType.electricityFeedInReactiveOffPeak];
        }
        if (this.options.showGrossProduction) {
            measurementTypes[DataType.electricityGrossProduction] = [DataType.electricityGrossProduction];
            if (this.options.showReactive) {
                measurementTypes[DataType.electricityGrossProductionReactive] = [DataType.electricityGrossProductionReactive];
            }
        }
        return measurementTypes;
    }

    productionDataTypesMapped = (): {[key: string]: DataType[]} => ({
        [DataType.electricityFeedIn]: [DataType.electricityFeedIn, DataType.electricityFeedInOffPeak],
    })

    get y2AxisTitle(): string {
        const hasPowerSelected = this.getSelectedConnections().length === 1 && this.options.showPower;
        return !hasPowerSelected ? super.y2AxisTitle : null;
    }

    get powerAxisTitle(): string {
        const hasWeatherSelected = this.options.selectedWeatherTypes?.length > 0;
        const hasPowerSelected = this.getSelectedConnections().length === 1 && this.options.showPower;
        return !hasWeatherSelected && hasPowerSelected ? "kW" : null;
    }

    powerAllowed = (): boolean => this._dataOptions.dataProvider.isPortfolioProvider()
        ? this.getSelectedConnections().length === 1 : !!this.showPowerForEan;

    addConnectionTypeSpecificData(data: MeasurementDataset[]) {
        if (this.options.showPower && this.powerAllowed()) {
            const contractedCapacity = this.contractedCapacity;
            if (this.hasElectricityPower()) {
                data.push(this.getElectricityPowerData());
            }
            if (contractedCapacity) {
                const contractedCapacityColor = "#a2a2a2";
                data.push({
                    dataset: {
                        type: "line",
                        borderDash: [5],
                        order: AppContext.indexOfDataType(DataType.electricityPower),
                        yAxisID: "power",
                        spanGaps: false,
                        label: TranslateDirective.getTranslation("Contracted power", true),
                        borderColor: contractedCapacityColor,
                        backgroundColor: contractedCapacityColor,
                        pointBorderColor: contractedCapacityColor,
                        data: MeasurementsDataProvider
                            .getSlots(this._dataOptions.dataProvider.info.timeRange, this._dataOptions.dataProvider.info.resolution)
                            .map(i => contractedCapacity),
                        stack: "contracted-capacity",
                        tooltip: {
                            formatter: this.chartUtils.getCustomTooltipFormatter(DataType.electricityPower),
                            stack: "Maximum power"
                        }
                    }
                });
            }
        }
    }

    get contractedCapacity() {
        const connections = this.getSelectedConnections();
        if (this.powerAllowed()) {
            if (this._dataOptions.dataProvider.isPortfolioProvider()) {
                return connections.length === 1 ? connections[0]?.contractedCapacity : null;
            } else {
                return connections.find(c => c.info.code === this.showPowerForEan)?.contractedCapacity;
            }
        }
        return null;
    }

    private getElectricityPowerData(): MeasurementDataset {
        const measurementType = DataType.electricityPower;
        const color = DashboardContext.getMeasurementColor(DashboardContext.stacks.currentPeriod, measurementType);
        return {
            measurementType: measurementType,
            dataset: {
                type: "line",
                borderDash: [5],
                order: AppContext.indexOfDataType(measurementType),
                yAxisID: "power",
                spanGaps: false,
                borderColor: color,
                backgroundColor: color,
                pointBorderColor: color,
                label: AppContext.measurementName(measurementType),
                data: this.electricityPowerData(),
                stack: "Maximum power",
                tooltip: {
                    formatter: this.chartUtils.getCustomTooltipFormatter(measurementType)
                }
            }
        };
    }

    getElectricityEans = (): string[] => this.getSelectedConnections().map(c => c.info.code);

    hasElectricityPower = (): boolean => this.electricityPowerData().some(s => s !== 0);

    electricityPowerData() {
        const dateRange = {
            start: moment(this._dataOptions.dataProvider.info.timeRange.start).format(localTimeFormat),
            end: moment(this._dataOptions.dataProvider.info.timeRange.end).format(localTimeFormat)
        };
        if (this._dataOptions.dataProvider.isPortfolioProvider()) {
            return this._dataOptions.dataProvider.getChartDataForMeasurement(
                DataType.electricityPower, dateRange, false).data;
        } else {
            let selectedEan = this.getSelectedConnections().find(c => c.info.code === this.showPowerForEan);
            let meters = selectedEan.meters.map(m => m.meterId);
            let data = this._dataOptions.dataProvider.dashboardData.currentData.totals.filter(
                e => meters.includes(e.entityId));
            return this._dataOptions.dataProvider.getChartDataForMeasurements(
                DataType.electricityPower, dateRange, data).data;
        }
    }

    hasProductionMeter = (): boolean =>
        this._dataOptions.dataProvider.sourceProvider.getAllSourcesByType(SourceType.meter)
            .some(s => s.meterType === MeterType.GROSS_PRODUCTION);

    get showPowerForEan(): string {
        if (this._dataOptions.dataProvider.isPortfolioProvider()) {
            return null;
        }
        if (this.getSelectedConnections().length === 1) {
            return this.options.showPower ? this.getSelectedConnections()[0].info.code : null
        }
        return this.options.showPowerForEan;
    }
}
