import {Component} from '@angular/core';
import {AbstractOverviewComponent} from "../../../../common/abstract-overview/abstract-overview.component";
import {
  CreateOrganisation,
  DeleteOrganisation,
  GetOrganisation,
  LinkedContract,
  Organisation
} from "@flowmaps/flowmaps-typescriptmodels";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {RefdataUtils} from "../../refdata-utils";
import {lodash} from "../../../../common/utils";
import {openModal} from "../../../../app-utils";
import {AddOrganisationComponent, AddOrganisationComponentData} from "../add-organisation/add-organisation.component";
import {AnimationTypes} from "../../../../common/modal/modal.component";
import {sendCommand, sendQuery} from "../../../../flux/flux-utils";
import {AppContext} from '../../../../app-context';
import {DashboardContext} from '../../../dashboard/dashboard.context';
import {
  LinkContractOrganisationComponent,
  LinkContractToOrganisationData
} from "../link-contract-organisation/link-contract-organisation.component";
import {cloneDeep} from "lodash";

@Component({
  selector: 'app-organisations-list',
  templateUrl: './organisations-list.component.html',
  styleUrls: ['./organisations-list.component.scss']
})
export class OrganisationsListComponent extends AbstractOverviewComponent<Organisation> {
  comparator: ComparatorChain = RefdataUtils.organisationComparator;

  getConnectionCount = (organisation: Organisation) => lodash.sum(organisation.locations?.map(l => l.connections.length));

  addOrganisation = () => openModal(AddOrganisationComponent, <AddOrganisationComponentData>{
    organisation: AddOrganisationComponent.newOrganisation()
  }, {
    cssClass: "modal-xl",
    animation: AnimationTypes.fade
  });

  trackByForRecord = (index: number, record: Organisation) => record.organisationId;

  recordAdded(upsertCommand: CreateOrganisation) {
    sendQuery("com.flowmaps.api.organisation.GetOrganisation", <GetOrganisation> {
      organisationId: upsertCommand.organisationId
    }).subscribe((o: Organisation) => this.addItem(o));
  }

  deleteOrganisation = (organisation: Organisation) => sendCommand("com.flowmaps.api.organisation.DeleteOrganisation", <DeleteOrganisation>{
    organisationId: organisation.organisationId
  }, () => {
    this.removeItem(organisation);
    AppContext.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
  });

  openOrganisationGraph = (organisation: Organisation) => AppContext.navigateToUrl('/dashboard/' + DashboardContext.dashboardToBase64(DashboardContext.organisationDashboard(organisation.organisationId)));

  linkNewContract(organisation: Organisation) {
    const linkedContract: LinkedContract = {
      contractId: '',
      linkId: '',
      dateRange: {
        start: organisation.linkedContractsLastEndDate || '',
        end: ''
      },
    } as LinkedContract;
    openLinkContractModal(organisation.organisationId, linkedContract)
  }
}

export function openLinkContractModal(organisationId: string, record?: LinkedContract) {
  openModal(LinkContractOrganisationComponent, <LinkContractToOrganisationData>{
    organisationId: organisationId,
    linkedContract: cloneDeep(record),
  }, {
    cssClass: 'modal-xl custom-modal-wide',
    animation: AnimationTypes.fade
  });
}