<ng-content select=".input-group-prepend"></ng-content>
<ng-template #rt let-r="result">
  <span [ngStyle]="styling(r)">{{inputFormatter(r)}}</span>
</ng-template>
<input [(ngModel)]="inputModel" (ngModelChange)="!$event ? onBlur() : null"
       [typeahead]="onInput" [resultFormatter]="resultFormatter"
       (selectItem)="onSelect($event.item)" [inputFormatter]="inputFormatter"
       [resultTemplate]= "styling?rt:null" [minCharacters]="minCharacters"
       (focus)="onFocus()" (blur)="onBlur()" [placement]="placement"
       [attr.disabled]="disabled ? true : null" [placeholder]="placeholder" [id]="id ? id + '-input' : ''"
       [required]="required" autocomplete="chrome-off"
       [attr.type]="searching ? 'text' : 'search'" class="form-control rounded-end"/>
<span class="input-group-text border-0 search-icon bg-transparent"
      *ngIf="!inputModel || inputModel.requiresEditing">
  <span class="bi {{newValueTemplate ? 'bi-plus-circle' : 'bi-search'}}"></span>
</span>
<span class="input-group-text border-0 search-icon bg-transparent" *ngIf="searching && inputModel">
  <span class="spinner-border spinner-border-sm text-secondary" role="status"></span>
</span>
<ng-content select=".invalid-feedback"></ng-content>
<ng-content select=".valid-feedback"></ng-content>

<ng-container *ngIf="newValueTemplate && inputModel?.requiresEditing === true">
  <div #newValueModal class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" [ngClass]="newValueModalClass" role="document">
      <div class="modal-content" >
        <div class="modal-header">
          <h5 class="modal-title">{{'Edit ' + valueType}}</h5>
          <button type="button" class="btn-close" (click)="onCancelEditing()">
          </button>
        </div>
        <div class="modal-body">
          <form (ngSubmit)="onCompleteEditing() ? null : $event.stopPropagation();">
            <ng-container [ngTemplateOutlet]="newValueTemplate" [ngTemplateOutletContext]="{model : inputModel}"></ng-container>
            <div class="row justify-content-between">
              <div class="col-auto">
                <button class="btn btn-secondary me-auto" type="button"
                        style="min-width: 8rem" (click)="onCancelEditing()">Cancel</button>
              </div>
              <div class="col-auto">
                <button class="btn btn-info ms-auto" type="submit" style="min-width: 8rem">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>
