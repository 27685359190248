<app-form (submit)="updateContractPeriod()">
    <div class="card">
        <div class="card-header">
            <h3 class="card-header-title">
                <span>{{ isNewRecord ? 'Add contract period' : 'Edit contract period' }}</span>
            </h3>
        </div>
        <div class="card-body">
            <app-contract-range
                    [dateRange]="data.period.data.dateRange"
                    [isReadOnly]="false">
            </app-contract-range>
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-form-field label="Normal Rate">
                        <div class="input-group">
                            <input [(ngModel)]="data.period.data.rate" type="number" class="form-control" required>
                            <span class="input-group-text notranslate rounded-end">{{ costUnit() }}
                                / {{ getConsumptionUnit(data.contract.contractData.connectionType) }}</span>
                        </div>
                    </app-form-field>
                </div>
                <ng-container *ngIf="data.contract.contractData.connectionType === 'Electricity'">
                    <div class="col-12 col-md-6">
                        <app-form-field label="Off Peak Rate">
                            <div class="input-group">
                                <input [(ngModel)]="data.period.data.offPeakRate" type="number" class="form-control">
                                <span class="input-group-text notranslate rounded-end">{{ costUnit() }}
                                    / {{ getConsumptionUnit(data.contract.contractData.connectionType) }}</span>
                            </div>
                        </app-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <app-form-field label="Normal Feed-In Rate">
                            <div class="input-group">
                                <input [(ngModel)]="data.period.data.feedInRate" type="number" class="form-control">
                                <span class="input-group-text notranslate rounded-end">{{ costUnit() }}
                                    / {{ getConsumptionUnit(data.contract.contractData.connectionType) }}</span>
                            </div>
                        </app-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <app-form-field label="Feed-In Off Peak Rate">
                            <div class="input-group">
                                <input [(ngModel)]="data.period.data.feedInOffPeakRate" type="number"
                                       class="form-control">
                                <span class="input-group-text notranslate rounded-end">{{ costUnit() }}
                                    / {{ getConsumptionUnit(data.contract.contractData.connectionType) }}</span>
                            </div>
                        </app-form-field>
                    </div>
                </ng-container>
                <ng-container
                        *ngIf="data.contract.contractData.connectionType === 'Electricity' || data.contract.contractData.connectionType === 'Gas'">
                    <div class="col-12 col-md-6">
                        <app-form-field label="Standing Charge">
                            <div class="input-group">
                                <input [(ngModel)]="data.period.data.standingCharge" type="number" class="form-control">
                                <span class="input-group-text notranslate rounded-end">{{ costUnit() }}
                                    / {{ standingChargeUnit() }}</span>
                            </div>
                        </app-form-field>
                    </div>
                    <div class="col-12 col-md-6 ">
                        <app-form-field label="Sustainability Source">
                            <app-sustainability-source-select
                                                              [(ngModel)]="data.period.data.sustainabilitySource"
                                                              [connectionType]="data.contract.contractData.connectionType">
                            </app-sustainability-source-select>
                        </app-form-field>
                    </div>
                </ng-container>
                <div class="col-12 col-md-6">
                    <app-form-field label="Supplier">
                        <app-service-provider-select class="notranslate" [type]="'supplier'" [(ngModel)]="data.period.data.supplier"></app-service-provider-select>
                    </app-form-field>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-between">
            <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
            <div>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>
