<div class="row">
    <div class="col-12 col-md-6">
        <app-form-field label="Name">
            <input required [(ngModel)]="organisationInfo.name" type="text" class="form-control">
        </app-form-field>
    </div>
    <div class="col-12 col-md-6">
        <app-form-field label="Chamber of commerce number">
            <input [(ngModel)]="organisationInfo.chamberOfCommerceNumber" type="text" class="form-control">
        </app-form-field>
    </div>
</div>
