import {ChangeDetectorRef, Component, forwardRef} from '@angular/core';
import {BaseMeasurementChartComponent, ChartDatasetExtended, MeasurementDataset} from '../base-measurement-chart';
import {ConnectionType, CostsMeasurementResult, CostType, DataType} from '@flowmaps/flowmaps-typescriptmodels';
import {lodash} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {ChartUtilsService} from "../chart-utils.service";
import {TooltipModel} from "chart.js/dist/types";
import {ChartDataPerMeasurement} from "../../../utils/measurements-data-provider";
import {TranslateDirective} from "../../../common/translate.directive";
import {DecimalPipe} from "@angular/common";

@Component({
    selector: 'app-costs-chart',
    templateUrl: './costs-chart.component.html',
    styleUrls: ['./costs-chart.component.css'],
    providers: [{provide: BaseMeasurementChartComponent, useExisting: forwardRef(() => CostsChartComponent)}]
})
export class CostsChartComponent extends BaseMeasurementChartComponent {
    totalCosts: number = 0;
    allConnectionTypes: ConnectionType[] = [ConnectionType.Electricity, ConnectionType.Gas, ConnectionType.Heat, ConnectionType.Water];

    constructor(protected changeDetectorRef: ChangeDetectorRef, protected chartUtils: ChartUtilsService, private decimalPipe: DecimalPipe) {
        super(changeDetectorRef, chartUtils);
    }

    connectionType(): ConnectionType {
        return null;
    }

    consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>();

    measurementIntermediateLink = (): Map<DataType, DataType[]> => new Map<DataType, DataType[]>();

    measurementTypes(): DataType[] {
        return [];
    }

    measurementUnit(): string {
        return "€";
    }

    productionDataTypes(): DataType[] {
        return [];
    }

    consumptionProductionCostsLink = (): Map<CostType, CostType> => new Map<CostType, CostType>()
        .set(CostType.consumption, CostType.feedIn)
        .set(CostType.consumptionOffPeak, CostType.feedInOffPeak);

    setData(result: ChartDataPerMeasurement, measurements: MeasurementDataset[], estimatedMeasurements: MeasurementDataset[]) {
        const data: MeasurementDataset[] = this.allConnectionTypes.map((c, i) => {
            const filteredResults = lodash.sortBy(result.costsByConnectionType
                .filter(item => item.connectionType === c)
                .map(item => {
                    item["indexOfCostType"] = this.costsOrdering.indexOf(item.costType);
                    return item;
                }), ["estimated", "indexOfCostType"]);
            const dataset: MeasurementDataset = {
                measurementType: this.getDataTypeForConnectionType(c),
                dataset: this.getDataset(c, filteredResults, i + 1)
            };
            return dataset;
        });
        this.totalCosts = Math.round(lodash.sum(result.costsByConnectionType.map(item => item.value)));
        this.changeDetectorRef.detectChanges();
        this.chartDataProvider.emit({
            datasets: data.flatMap(d => [d, <any>{
                dataset: {
                    weight: 0.7
                }
            }])
        });
    }

    private getDataset = (connectionType: ConnectionType, data: CostsMeasurementResult[], order?: number): ChartDatasetExtended => {
        const measurementType = this.getDataTypeForConnectionType(connectionType);
        const measurementName = AppContext.measurementName(measurementType);
        const connectionConfig = AppContext.getConnectionConfig(connectionType);

        const costValues = this.getNettedCostValues(data);
        const costTypes = data.map(item => {
            if (item.estimated) {
                return `${this.transformCostType(item.costType)} (estimated)`;
            } else {
                return this.transformCostType(item.costType) ?? null;
            }
        });

        const colors = data.map(item => {
            if (item.estimated) {
                return 'white';
            } else {
                if (item.costType) {
                    const costsColor = connectionConfig.costsColors ? connectionConfig.costsColors[item.costType] : connectionConfig.color;
                    if ([CostType.feedIn, CostType.feedInOffPeak].includes(item.costType)) {
                        return this.createDiagonalPattern(costsColor, 'ltr');
                    }
                    return costsColor;
                }
            }
            return null;
        });

        const costsColors = data.map(item => item.costType && connectionConfig.costsColors
            ? connectionConfig.costsColors[item.costType] : connectionConfig.color);
        return ({
            label: measurementName,
            data: costValues.length ? costValues : [0],
            backgroundColor: colors,
            borderColor: costsColors,
            hoverBackgroundColor: costsColors,
            borderWidth: 1,
            order: order,
            measurementType: measurementType,
            tooltipLabels: costTypes,
            tooltip: {
                formatter: this.getTooltipFormatter(),
                labelOverride: costTypes.map(c => TranslateDirective.getTranslation(`${connectionType} ${c}`, true)),
                borderColors: costsColors,
                data: data.map(item => item.value ?? 0)
            }
        });
    };

    private transformCostType(costType: string): string {
        switch (costType) {
            case 'consumptionOffPeak':
                return 'Consumption Off-Peak';
            case 'feedIn':
                return 'Feed-In';
            case 'feedInOffPeak':
                return 'Feed-In Off-Peak';
            case 'standingCharge':
                return 'Standing Charge';
            default:
                return costType.replace(/([a-z])([A-Z])/g, '$1 $2')
                    .replace(/\b([a-z])/g, (char) => char.toUpperCase());
        }
    }

    private getNettedCostValues(data: CostsMeasurementResult[]) {
        return data.map(item => {
            const mappedFeedIn = this.consumptionProductionCostsLink().get(item.costType);
            const foundFeedInCost = data.find(d =>
                d.estimated === item.estimated && d.costType === mappedFeedIn);
            if (mappedFeedIn && foundFeedInCost) {
                return Math.max(item.value + (foundFeedInCost.value ?? 0), 0);
            }
            return Math.abs(item.value);
        });
    }

    private getDataTypeForConnectionType = (connectionType: ConnectionType): DataType => {
        switch (connectionType) {
            case ConnectionType.Electricity:
                return DataType.electricityConsumptionCosts;
            case ConnectionType.Gas:
                return DataType.gasConsumptionCosts;
            case ConnectionType.Heat:
                return DataType.heatConsumptionCosts;
            case ConnectionType.Water:
                return DataType.waterConsumptionCosts;
        }
    }

    getTooltipFormatter(): (ctx: TooltipModel<any>, value: number) => string {
        return (ctx, value) => `${this.measurementUnit()} ${this.decimalPipe.transform(value, '1.0-0')}`;
    }

    costsOrdering: CostType[] = [
        CostType.consumption,
        CostType.feedIn,
        CostType.consumptionOffPeak,
        CostType.feedInOffPeak,
        CostType.standingCharge,
        CostType.consumptionTax
    ]
}
