import {Component, Input, OnDestroy} from '@angular/core';
import {RefdataUtils} from "../../refdata-utils";
import {QueryGateway} from "../../../../flux/query-gateway";
import {EventGateway} from "../../../../flux/event-gateway";
import {ContractPeriod} from "@flowmaps/flowmaps-typescriptmodels";
import {ExtendedContract} from "../contracts-list/contracts-list.component";

@Component({
    selector: 'app-contract-periods-overview',
    templateUrl: './contract-periods-overview.component.html',
    styleUrls: ['./contract-periods-overview.component.css']
})
export class ContractPeriodsOverviewComponent implements OnDestroy {
    periods: ContractPeriod[];
    loadCount: number = 0;
    @Input() contract: ExtendedContract;

    private readonly registration: () => void;

    constructor(protected queryGateway: QueryGateway, protected eventGateway: EventGateway) {
        this.registration = this.eventGateway.registerLocalHandler(this);
    }

    ngOnDestroy(): void {
        this.registration();
    }

    reloadOverview = () => {
        this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyContracts");
        this.reload();
    }

    reload = () =>
        RefdataUtils.getContract(this.contract.contractId)
            .subscribe(c => {
                this.periods = c.periods;
                this.contract.periods = c.periods;
                this.loadCount++;
            });
}
