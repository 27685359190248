import {Component, Input} from '@angular/core';
import {
  Authorisation,
  AuthorisationType,
  ConnectionInfo,
  GiveAuthorisation,
  LocationInfo,
  Organisation,
  RevokeAuthorisation
} from "@flowmaps/flowmaps-typescriptmodels";
import {AbstractOverviewComponent} from "../../../../common/abstract-overview/abstract-overview.component";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {sendCommand} from "../../../../flux/flux-utils";
import {openModal} from "../../../../app-utils";
import {AuthorisationDetailsComponent} from "../authorisation-details/authorisation-details.component";
import {AnimationTypes} from "../../../../common/modal/modal.component";
import {map, Observable, of} from "rxjs";
import {RefdataUtils} from "../../refdata-utils";
import {AppContext} from "../../../../app-context";
import {cloneDeep} from 'lodash';

@Component({
  selector: 'app-authorisations-list',
  templateUrl: './authorisations-list.component.html',
  styleUrls: ['./authorisations-list.component.scss']
})
export class AuthorisationsListComponent extends AbstractOverviewComponent<Authorisation> {
  @Input() organisation: Organisation;

  refDataUtils = RefdataUtils;

  comparator: ComparatorChain = new ComparatorChain('revoked', 'nominee');

  getIcon = (authorisation: Authorisation) => authorisation.type === AuthorisationType.user ? "bi-person-fill" : "bi-people-fill";

  revoke = (authorisation: Authorisation) => sendCommand("com.flowmaps.api.organisation.RevokeAuthorisation", <RevokeAuthorisation> {
    organisationId: this.organisation.organisationId,
    authorisationId: authorisation.authorisationId
  }, () => {
    AppContext.registerSuccess("Operation has completed successfully");
    this.removeItem(authorisation);
  });

  authorisationHeader(authorisation: Authorisation): Observable<string> {
    return authorisation.type === AuthorisationType.organisation
        ? RefdataUtils.organisationFormatterAsync(RefdataUtils.getOrganisation(authorisation.nominee))
        : of(authorisation.nominee);
  }

  getEntity(entityId : string) : Observable<Entity> {
    if (this.organisation) {
      return of(findInOrganisation(this.organisation));
    }
    RefdataUtils.getMyOrganisations().pipe(map(orgs => {
      for (let o of orgs) {
        const result = findInOrganisation(o);
        if (result) {
          return result;
        }
      }
      return null;
    }));

    function findInOrganisation(organisation : Organisation) : Entity {
      for (let location of organisation.locations) {
        if (location.locationId === entityId) {
          return {locationInfo : location.info, connectionInfo : null};
        }
        for (let connection of location.connections) {
          if (connection.connectionId === entityId) {
            return {locationInfo : location.info, connectionInfo : connection.info};
          }
        }
      }
      return null;
    }
  }

  editAuthorisation = (authorisation: Authorisation) => openModal(AuthorisationDetailsComponent,
      authorisation ? cloneDeep(authorisation) : { nominator: this.organisation?.organisationId }, {
    cssClass: "modal-xl",
    animation: AnimationTypes.fade
  });

  trackByForRecord = (index: number, record: Authorisation) => record.authorisationId;

  recordAdded(upsertCommand: GiveAuthorisation) {
    RefdataUtils.getOrganisation(upsertCommand.organisationId).subscribe(o => {
      this.addItem(upsertCommand.authorisation);
    });
  }

  recordUpdated(upsertCommand: GiveAuthorisation) {
    RefdataUtils.getOrganisation(upsertCommand.organisationId).subscribe(o => {
      this.replaceItem(
          (i : Authorisation) => i.authorisationId === upsertCommand.authorisation.authorisationId,
          upsertCommand.authorisation);
    });
  }
}

interface Entity {
  connectionInfo : ConnectionInfo;
  locationInfo: LocationInfo;
}
