import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {ExportConnectionData, TimeResolution} from "@flowmaps/flowmaps-typescriptmodels";
import {checkValidity, localTimeFormat} from "../../../../common/utils";
import {sendQuery} from "../../../../flux/flux-utils";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {DateFieldRange} from "../../../../common/date/date-range/date-field-range";
import {DashboardContext} from "../../../dashboard/dashboard.context";
import moment from "moment/moment";

@Component({
  selector: 'app-connection-selection',
  templateUrl: './connection-selection.component.html',
  styleUrls: ['./connection-selection.component.scss']
})
export class ConnectionSelectionComponent {
    dashboardContext = DashboardContext;
    appContext = AppContext;

    @Input() comparator: ComparatorChain;
    @Input() selectedItems: any[] = [];
    @Input() sheetTemplate: any;
    @Input() csvMapper: (csv: string) => any[];
    @Input() downloadDataMethod: (connections: any[], data: any[], sheetTemplate: any) => void;
    @Input() cancelMethod: () => void;

    defaultDownloadRange = DashboardContext.defaultRange;

    resolution: TimeResolution;
    timeRange: DateFieldRange = this.defaultDownloadRange();
    @ViewChild("downloadDataBar") downloadDataBar: ElementRef;

    downloadData = () => {
        if (checkValidity(this.downloadDataBar.nativeElement)) {
            if (this.selectedItems.length === 0) {
                AppContext.registerError("Please select at least 1 record");
                return;
            }
            sendQuery("com.flowmaps.api.measurements.ExportConnectionData", <ExportConnectionData>{
                timeRange: {
                    start: moment(this.timeRange.start).format(localTimeFormat),
                    end: moment(this.timeRange.end).format(localTimeFormat)
                },
                resolution: this.resolution,
                ids: this.selectedItems.map(c => c.connection.connectionId)
            }, {
                showSpinner: true,
                responseType: "text"
            }).subscribe(csv => this.downloadDataMethod(
                this.selectedItems.sort(this.comparator.compare), this.csvMapper(csv), this.sheetTemplate))
        }
    }
}