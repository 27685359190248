import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {EventGateway} from "../../flux/event-gateway";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements AfterViewInit, OnDestroy {

  @ViewChild('modal', {static: true}) modal: ElementRef;
  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;
  private readonly registration: () => void;
  options: ModalOptions;

  constructor(private eventGateway: EventGateway) {
    this.registration = this.eventGateway.registerLocalHandler(this);
  }

  ngAfterViewInit(): void {
    $(this.modal.nativeElement).on('hidden.bs.modal', () => {
      this.container.clear();
      if (this.options.closeCallback) {
        this.options.closeCallback();
      }
    })
  }

  'openModal' = (event: ModalEvent) => {
    this.container.clear();
    this.options = event.options;

    setTimeout(() => {
      if (event.component instanceof TemplateRef) {
        const componentRef = this.container.createEmbeddedView(event.component);
      } else {
        const componentRef = this.container.createComponent(<any>event.component);
        const component = componentRef.instance;
        component['data'] = event.data;
        $(componentRef.location.nativeElement).addClass('modal-content');
      }
      $(this.modal.nativeElement).modal('show');
    }, 0);
  }

  'closeModal' = () => {
    $(this.modal.nativeElement).modal('hide');
  }

  ngOnDestroy(): void {
    this.registration();
  }
}

export interface ModalEvent {
  component;
  data?;
  options?: ModalOptions;
}

export interface ModalOptions {
  cssClass?: string;
  style?: string;
  animation?: AnimationTypes;
  closeCallback?: () => any;
}

export enum AnimationTypes {
  fade = "fade",
  zoomIn = "zoom-in"
}

export declare interface ClosableModal {
  isModalClosable: boolean;

  setModalClosable(closable: boolean);

  closeModal();

  switchModal();

  getCloseDialogComponentType(): Type<unknown>;
}
