import {Component, forwardRef, Input} from '@angular/core';
import {AbstractValueAccessorComponent} from "../../../common/component/value-accessor.component";
import {ConnectionType, ConnectionValue} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../refdata-utils";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-connection-value-select',
  templateUrl: './connection-value-select.component.html',
  styleUrls: ['./connection-value-select.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ConnectionValueSelectComponent), multi: true}
  ]
})
export class ConnectionValueSelectComponent extends AbstractValueAccessorComponent<ConnectionValue> {
  @Input() type: ConnectionType | string;
  @Input() required: boolean;
  @Input() disabled: boolean;

  private _value: ConnectionValue;

  get value(): ConnectionValue {
    return this._value;
  }

  set value(value: ConnectionValue) {
    this.writeValue(value);
  }

  writeValue(value: ConnectionValue): void {
    this._value = value;
    this.onModelChange();
  }

  getConnectionValues = () => RefdataUtils.getConnectionValues(this.type as ConnectionType);

  connectionValueFormatter = (value: ConnectionValue) => value ? `${value.name}` : null;
}
