import {Component, Input} from '@angular/core';
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-meter-image',
  templateUrl: './meter-image.component.html',
  styleUrls: ['./meter-image.component.scss']
})
export class MeterImageComponent {
  appContext = AppContext;
  @Input() meterType: "PRIMARY" | "INTERMEDIATE" | "GROSS_PRODUCTION" | "INTERMEDIARY";
  @Input() connectionType: "Electricity" | "Gas" | "Heat" | "Water";
  @Input() width: number = 20;
}
