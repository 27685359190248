import {Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RefdataUtils} from '../../refdata-utils';
import {DeleteContract, Organisation, UpsertContract} from '@flowmaps/flowmaps-typescriptmodels';
import {publishEvent, sendCommand} from '../../../../flux/flux-utils';
import {QueryGateway} from '../../../../flux/query-gateway';
import {AppContext} from "../../../../app-context";
import {ExtendedContract} from "../contracts-list/contracts-list.component";
import {closeModal} from "../../../../app-utils";

@Component({
    selector: 'app-contract-details',
    templateUrl: './contract-details.component.html',
    styleUrls: ['./contract-details.component.css']
})
export class ContractDetailsComponent implements OnInit {
    contractId: string;
    organisationId: string;
    contract: ExtendedContract;
    organisation: Organisation;
    appContext = AppContext;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private queryGateway: QueryGateway,
        private elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.contractId = params.get('contractId');
            this.fetchContractDetails();
        });
    }

    fetchContractDetails(): void {
        RefdataUtils.getContract(this.contractId).subscribe(contract => {
            this.contract = contract;
            this.organisationId = contract.organisationId;
            this.fetchOrganisation();
        });
    }

    fetchOrganisation(): void {
        RefdataUtils.getOrganisation(this.organisationId).subscribe(org => {
            this.organisation = org;
        });
    }

    updateInfo = () =>
        sendCommand(
            'com.flowmaps.api.organisation.UpsertContract',
            <UpsertContract>{
                organisationId: this.organisationId,
                contractId: this.contractId,
                defaultContract: this.contract.defaultContract,
                contractData: this.contract.contractData
            },
            () => {
                this.appContext.registerSuccess('Contract details updated');
                this.queryGateway.removeFromCache('com.flowmaps.api.organisation.GetMyContracts');
            }
        );

    deleteContract = () => {
        const command: DeleteContract = {
            contractId: this.contract.contractId,
            organisationId: this.contract.organisationId,
        };
        sendCommand("com.flowmaps.api.organisation.DeleteContract", command, () => {
            closeModal();
            this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyContracts");
            this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
            publishEvent('reloadOverview');
            this.router.navigateByUrl(`/contracts`);

        });
    }
}