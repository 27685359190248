import {Component, OnDestroy} from '@angular/core';
import {AppContext} from "../../app-context";
import {Dashboard, GetMyReports, Report} from "@flowmaps/flowmaps-typescriptmodels";
import {DashboardContext} from "../dashboard/dashboard.context";
import {environment} from '../../../environments/environment';
import {openModal} from '../../app-utils';
import {EncryptComponent} from '../admin/encrypt/encrypt.component';
import {DashboardType} from "../dashboard/dashboard.types";
import {EventGateway} from "../../flux/event-gateway";
import {sendQuery} from "../../flux/flux-utils";
import {cloneObject} from "../../common/utils";
import {ReportComponent} from "../reports/report/report.component";
import {RefdataUtils} from "../refdata/refdata-utils";
import {ReportUtils} from "../reports/report-utils";
import {AuthService} from '../../utils/auth.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnDestroy {
  context = AppContext;
  refdataUtils = RefdataUtils;
  reportUtils = ReportUtils;
  reports: Report[];
  private readonly registration: () => void;

  defaultReport: Report = cloneObject(ReportComponent.defaultReport);

  constructor(private eventGateway: EventGateway, private authService: AuthService) {
    this.registration = this.eventGateway.registerLocalHandler(this);
  }

  getDashboardsForType = (type: DashboardType | string) => {
    return AppContext.userProfile?.dashboards.filter(d => d.info.type === type) || []
  }

  ngOnInit() {
    this.getReports();
  }

  ngOnDestroy(): void {
    this.registration();
  }

  'reportChange' = () => {
    this.getReports();
  }

  getReports = () => sendQuery("com.flowmaps.api.reporting.GetMyReports", <GetMyReports>{}, {caching: false})
      .subscribe(r => this.reports = r.sort(RefdataUtils.reportsComparator.compare));

  signOut() {
    AppContext.httpClient().get(environment.apiHost + "/api/logout", {withCredentials : true}).subscribe();
    AppContext.setUserProfile(undefined);
    AppContext.navigateToUrl("/login");
  }

  encoded = (info: Dashboard): string => {
    return DashboardContext.dashboardToBase64(info);
  };

  trackBy: any = (index: number, item: Dashboard) => {
    return item?.dashboardId;
  };

  openEncrypt() {
    openModal(EncryptComponent, {}, {
      cssClass: "modal-xl"
    });
  }

  stopImpersonatingUser = () => {
    this.authService.stopImpersonating();
  };
}
