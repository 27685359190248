import {Component, Input, OnInit} from '@angular/core';
import {DateRange} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-contract-range',
  templateUrl: './contract-range.component.html',
  styleUrls: ['./contract-range.component.css']
})
export class ContractRangeComponent implements OnInit {
  @Input() dateRange: DateRange;
  @Input() isReadOnly: boolean = false;
  @Input() isRequired: boolean = false;

  constructor() { }

  ngOnInit(): void { }
}
