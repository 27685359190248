<ng-container *ngIf="location else noLocation">
    <div class="accordion accordion-flush" id="locationMeterAccordion">
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#electricityMeterCollapse" aria-expanded="false"
                        aria-controls="electricityMeterCollapse">
                    <app-connection-image [connectionType]="'Electricity'"></app-connection-image>
                    <span>Electricity meter</span>
                </button>
            </h2>
            <div id="electricityMeterCollapse" class="accordion-collapse collapse"
                 data-bs-parent="#locationMeterAccordion">
                <div class="accordion-body">
                    <ul class="list-group list-group-flush">
                        <ng-container *ngFor="let subMeter of getMetersForConnectionType('Electricity', true); trackBy : trackBy">
                            <li class="py-1 list-group-item d-flex align-items-center hover-show">
                                <app-connection-image [connectionType]="'Electricity'" class="col-auto"></app-connection-image>
                                <span class="notranslate">{{refDataUtils.meterFormatter(subMeter)}}</span>
                                <button *ngIf="appContext.isManagerForLocation(location)"
                                        type="button" class="btn position-absolute end-0 to-show" role="button"
                                        aria-expanded="false" (click)="editMeter(subMeter)">
                                    <i class="bi bi-pencil"></i>
                                </button>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#gasMeterCollapse" aria-expanded="false" aria-controls="gasMeterCollapse">
                    <app-connection-image [connectionType]="'Gas'"></app-connection-image>
                    <span>Gas/Heat meter</span>
                </button>
            </h2>
            <div id="gasMeterCollapse" class="accordion-collapse collapse" data-bs-parent="#locationMeterAccordion">
                <div class="accordion-body">
                    <ul class="list-group list-group-flush">
                        <ng-container *ngFor="let subMeter of getMetersForConnectionType('Gas', true); trackBy : trackBy">
                            <li class="py-1 list-group-item d-flex align-items-center hover-show">
                                <app-connection-image [connectionType]="'Gas'" class="col-auto"></app-connection-image>
                                <span class="notranslate">{{refDataUtils.meterFormatter(subMeter)}}</span>
                                <button *ngIf="appContext.isManagerForLocation(location)"
                                        type="button" class="btn position-absolute end-0 to-show" role="button"
                                        aria-expanded="false" (click)="editMeter(subMeter)">
                                    <i class="bi bi-pencil"></i>
                                </button>
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let subMeter of getMetersForConnectionType('Heat', true); trackBy : trackBy">
                            <li class="py-1 list-group-item d-flex align-items-center hover-show">
                                <app-connection-image [connectionType]="'Heat'" class="col-auto"></app-connection-image>
                                <span class="notranslate">{{refDataUtils.meterFormatter(subMeter)}}</span>
                                <button *ngIf="appContext.isManagerForLocation(location)"
                                         type="button" class="btn position-absolute end-0 to-show" role="button"
                                        aria-expanded="false" (click)="editMeter(subMeter)">
                                    <i class="bi bi-pencil"></i>
                                </button>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#subMeterCollapse" aria-expanded="false" aria-controls="subMeterCollapse">
                    <div class="row p-0 w-100 pe-3 g-0">
                        <div class="col-auto">
                            <img width="20" height="20" src="assets/images/icons/sub-meters.png" alt="Sub meter icon" class="me-2"/>
                        </div>
                        <span class="col">Sub meters</span>
                        <span class="col-auto notranslate">{{getSubMeters().length}}</span>
                    </div>
                </button>
            </h2>
            <div id="subMeterCollapse" class="accordion-collapse collapse" data-bs-parent="#locationMeterAccordion">
                <div class="accordion-body py-0">
                    <ul class="list-group list-group-flush">
                        <ng-container *ngFor="let subMeter of getSubMeters(); trackBy : trackBy">
                            <li class="py-1 list-group-item d-flex align-items-center hover-show">
                                <app-meter-image [connectionType]="subMeter.connection.info.connectionType"
                                                 [meterType]="subMeter.meter.details?.type || subMeter.meter.info.type"
                                                 class="d-inline-block"></app-meter-image>
                                <span class="notranslate">{{refDataUtils.meterFormatter(subMeter)}}</span>
                                <button *ngIf="appContext.isManagerForLocation(location)"
                                         type="button" class="btn position-absolute end-0 to-show" role="button"
                                        aria-expanded="false" (click)="editMeter(subMeter)">
                                    <i class="bi bi-pencil"></i>
                                </button>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#waterMeterCollapse" aria-expanded="false" aria-controls="waterMeterCollapse">
                    <div class="row p-0 w-100 pe-3 g-0">
                        <app-connection-image [connectionType]="'Water'" class="col-auto"></app-connection-image>
                        <span class="col">Water meters</span>
                        <span class="col-auto notranslate">{{getSubMeters('Water').length + getMetersForConnectionType('Water', true).length}}</span>
                    </div>
                </button>
            </h2>
            <div id="waterMeterCollapse" class="accordion-collapse collapse" data-bs-parent="#locationMeterAccordion">
                <div class="accordion-body">
                    <ul class="list-group list-group-flush">
                        <ng-container *ngFor="let subMeter of getMetersForConnectionType('Water', true); trackBy : trackBy">
                            <li class="py-1 list-group-item d-flex align-items-center hover-show">
                                <app-connection-image [connectionType]="'Water'" class="col-auto"></app-connection-image>
                                <span class="notranslate">{{refDataUtils.meterFormatter(subMeter)}}</span>
                                <button *ngIf="appContext.isManagerForLocation(location)"
                                        type="button" class="btn position-absolute end-0 to-show" role="button"
                                        aria-expanded="false" (click)="editMeter(subMeter)">
                                    <i class="bi bi-pencil"></i>
                                </button>
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let subMeter of getSubMeters('Water'); trackBy : trackBy">
                            <li class="py-1 list-group-item d-flex align-items-center hover-show">
                                <app-meter-image [connectionType]="subMeter.connection.info.connectionType"
                                                 [meterType]="subMeter.meter.details?.type || subMeter.meter.info.type" class="d-inline-block"></app-meter-image>
                                <span class="notranslate">{{refDataUtils.meterFormatter(subMeter)}}</span>
                                <button *ngIf="appContext.isManagerForLocation(location)"
                                        type="button" class="btn position-absolute end-0 to-show" role="button"
                                        aria-expanded="false" (click)="editMeter(subMeter)">
                                    <i class="bi bi-pencil"></i>
                                </button>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #noLocation>
    <div class="card-subtitle">
        <h2>No location selected</h2>
    </div>
</ng-template>