import {Component, Input, OnInit} from '@angular/core';
import {
    ContractPeriod,
    ContractPeriodData,
    DeleteContractPeriod,
    UpsertContractPeriod
} from "@flowmaps/flowmaps-typescriptmodels";
import {closeModal, openModal} from "../../../../app-utils";
import {
    ContractPeriodComponentData,
    ContractPeriodDetailsComponent
} from "../contract-period-details/contract-period-details.component";
import {AnimationTypes} from "../../../../common/modal/modal.component";
import {ExtendedContract} from "../contracts-list/contracts-list.component";
import {AppContext} from "../../../../app-context";
import {AbstractOverviewComponent} from "../../../../common/abstract-overview/abstract-overview.component";
import {RefdataUtils} from "../../refdata-utils";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {sendCommand} from "../../../../flux/flux-utils";
import {DashboardContext} from "../../../dashboard/dashboard.context";
import {QueryGateway} from "../../../../flux/query-gateway";
import {cloneDeep} from "lodash";
import {computeItem, getConsumptionUnit} from "../../../../common/utils";

@Component({
    selector: 'app-contract-periods-list',
    templateUrl: './contract-periods-list.component.html',
    styleUrls: ['./contract-periods-list.component.css']
})
export class ContractPeriodsListComponent extends AbstractOverviewComponent<ContractPeriod> implements OnInit {
    @Input() contract: ExtendedContract;
    refDataUtils = RefdataUtils;
    comparator: ComparatorChain = new ComparatorChain('data.dateRange');
    appContext = AppContext;
    context = DashboardContext;
    protected queryGateway: QueryGateway
    trackByForRecord = (index: number, record: ContractPeriod) => record.periodId;

    costUnit = () => "€"
    standingChargeUnit = () => "p.m."
    getConsumptionUnit = getConsumptionUnit;

    contractPeriodUpdated = (event: UpsertContractPeriod) => {
        computeItem(this.contract.periods, p => p.periodId === event.periodId, p => {
            p = p || {periodId: event.periodId}
            p.data = event.data;
            return p;
        });
    }

    addNewContractPeriod(): void {
        const newPeriod: ContractPeriod = {
            periodId: '',
            data: {
                dateRange: {
                    start: '',
                    end: ''
                },
                offPeakRate: null,
                rate: null,
                feedInRate: null,
                feedInOffPeakRate: null,
                standingCharge: null,
                supplier: null,
                sustainabilitySource: null
            } as ContractPeriodData,
        };
        this.openContractPeriodModal(this.contract, newPeriod);
    }

    deleteContractPeriod = (record: ContractPeriod) => {
        const command: DeleteContractPeriod = {
            periodId: record.periodId,
            contractId: this.contract.contractId,
            organisationId: this.contract.organisationId
        };
        sendCommand("com.flowmaps.api.organisation.DeleteContractPeriod", command, () => {
            this.contract.periods = this.contract.periods.filter(
                period => period.periodId !== record.periodId
            );
            closeModal();
        });
    }

    openContractPeriodModal(contract: ExtendedContract, contractPeriod?: ContractPeriod): void {
        openModal(ContractPeriodDetailsComponent, cloneDeep(<ContractPeriodComponentData>{
            period: contractPeriod,
            contract: contract,
            organisationId: contract.organisationId,
            sustainabilitySource: contractPeriod.data.sustainabilitySource,
            supplier: contractPeriod.data.supplier
        }), {
            cssClass: "modal-xl custom-modal-wide",
            animation: AnimationTypes.fade
        });
    }
}
