import {AfterViewInit, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {
    AbstractOverviewComponent,
    addZeroFacetResults
} from '../../../../common/abstract-overview/abstract-overview.component';
import {
    AddAlert,
    Alert,
    AlertStatus,
    AlertType,
    FacetResult,
    MuteAlert,
    ResolveAlert,
    UnmuteAlert
} from '@flowmaps/flowmaps-typescriptmodels';
import {RefdataUtils} from '../../../refdata/refdata-utils';
import moment from 'moment';
import {openConfirmationModalWithCallback, openModal} from '../../../../app-utils';
import {AddAlertComponent} from '../add-alert/add-alert.component';
import {sendCommand} from "../../../../flux/flux-utils";
import {AppContext} from "../../../../app-context";
import {
    ModalConfirmAutofocus,
    ModalConfirmAutofocusData
} from "../../../../common/modal-confirm/modal-confirm.component";
import {Dashboard} from "../../../dashboard/dashboard.types";
import {LocationDashboardComponent} from "../../../location-dashboard/location-dashboard.component";
import {DashboardContext} from "../../../dashboard/dashboard.context";
import {EventGateway} from "../../../../flux/event-gateway";
import {combineLatest, map, Observable, of} from "rxjs";
import {SentenceCasePipe} from "../../../../common/sentence-case.pipe";
import {FacetFilters, FacetStats} from "../../../../common/facets/facet-filter/facet-filter.component";
import {DateFieldRange} from '../../../../common/date/date-range/date-field-range';
import {lodash} from '../../../../common/utils';
import {MeterWithConnection} from '../../../location-dashboard/location-meters/location-meters.component';
import {AlertUtils} from '../alert-utils';

@Component({
    selector: 'app-alerts-list',
    templateUrl: './alerts-list.component.html',
    styleUrls: ['./alerts-list.component.css']
})
export class AlertsListComponent extends AbstractOverviewComponent<ExtendedAlert> implements AfterViewInit{
    comparator = RefdataUtils.alertsComparator;
    muteDate: string;

    @Input() dateRange: DateFieldRange;
    @ViewChild("muteAlertTemplate") muteAlertTemplate: TemplateRef<any>;

    filterLocalStorageKey: string = "alerts-list";
    ignoredFacets: string[] = ["entityIds"];

    hasSelectableItems: boolean;

    tomorrow = (): string => moment().startOf("day").add(1, "day").toISOString();

    constructor(protected eventGateway: EventGateway) {
        super(eventGateway);
    }

    ngAfterViewInit(): void {
        this.setFilters(this.getFilters());
        this.reload();
    }

    toggleSelection = () => {
        this.hasSelectableItems = this.dataSorted.some(a => a.alertStatus !== AlertStatus.RESOLVED);
        this.selecting = !this.selecting;
        if (!this.selecting) {
            this.selectedItems.forEach(c => delete c['selected']);
            this.selectedItems = [];
        }
    };

    recordAdded(upsertCommand: AddAlert) {
        this.reload(true);
    }

    trackByForRecord(index: number, record: ExtendedAlert) {
        return record.alertId;
    }

    addAlert = () => openModal(AddAlertComponent);

    getAlertStatusConfig = (alert: ExtendedAlert): AlertStatusConfig => {
        switch (alert.alertStatus) {
            case AlertStatus.RESOLVED: return {
                colorClass: "success",
                icon: "bi-check-circle"
            };
            case AlertStatus.MUTED: return {
                colorClass: "muted",
                icon: "bi-volume-mute"
            };
            default: return {
                colorClass: this.badgeColorForAlertType(alert),
                icon: "bi-record-circle"
            };
        }
    }

    openResolveAlertModal = (alert: ExtendedAlert) => {
        openConfirmationModalWithCallback((confirmed) => {
            if (confirmed) {
                sendCommand("com.flowmaps.api.monitoring.alerting.ResolveAlert", <ResolveAlert> {
                    alertId: alert.alertId
                }, () => {
                    alert.alertStatus = AlertStatus.RESOLVED;
                });
            }
        }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
            type: "info",
            title: "Resolve alert",
            message: "Are you sure you want to resolve this alert?",
            confirmText: "Resolve alert",
            cancelText: "Cancel"
        }, 'static');
    }

    openMuteAlertModal = (alert: ExtendedAlert) => {
        this.muteDate = alert.muteDeadline;
        openConfirmationModalWithCallback((confirmed) => {
            if (confirmed) {
                sendCommand("com.flowmaps.api.monitoring.alerting.MuteAlert", <MuteAlert> {
                    alertId: alert.alertId,
                    deadline: this.muteDate
                }, () => {
                    alert.alertStatus = AlertStatus.MUTED;
                    alert.muteDeadline = this.muteDate;
                    this.muteDate = null;
                });
            }
        }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
            type: "info",
            title: "Mute alert",
            body: this.muteAlertTemplate,
            confirmText: "Mute alert",
            cancelText: "Cancel"
        }, 'static');
    }

    openUnmuteAlertModal = (alert: ExtendedAlert) => {
        sendCommand("com.flowmaps.api.monitoring.alerting.UnmuteAlert", <UnmuteAlert> {
            alertId: alert.alertId
        }, () => {
            alert.alertStatus = AlertStatus.OPEN;
            alert.muteDeadline = null;
        });
    }

    formatTimestamp = (date: string) => moment(date).format(AppContext.displayFormat);

    badgeColorForAlertType = (alert: Alert) => {
        if (alert.alertStatus === AlertStatus.RESOLVED) {
            return "light";
        }
        switch (alert.details.type) {
            case AlertType.Disconnected: return "danger";
            case AlertType.MissingData: return "warning";
            case AlertType.ContractedPowerExceeded: return "dark";
            case AlertType.Peak: return "secondary";
            default: return "info";
        }
    }

    getLocationDashboardLink = (alert: ExtendedAlert): string => {
        const locationDashboard: Dashboard = LocationDashboardComponent.defaultDashboard();
        locationDashboard.info.sources.locationIds = [alert.meter.location.locationId];
        return `/location/dashboard/${DashboardContext.dashboardToBase64(locationDashboard)}`;
    }

    isResolved = (alert: ExtendedAlert): boolean => AlertStatus.RESOLVED == alert.alertStatus;
    isMuted = (alert: ExtendedAlert): boolean => AlertStatus.MUTED == alert.alertStatus;

    facetNameFormatter = (name: string): string => {
        switch (name) {
            case "alertStatus": return "Alert status";
            case "type": return "Alert type";
            case "organisationId": return "Organisation";
            case "surveyor": return "Data source";
            default: return SentenceCasePipe.formatWithSpaces(name);
        }
    }

    getTranslateValues = (name: string): boolean => {
        switch (name) {
            case "organisationId": case "entityIds": case "message": case "surveyor": return false;
            default: return true;
        }
    }

    @Input() set facetResult(result: FacetResult) {
        super.facetResult = addZeroFacetResults(result, "alertStatus", "OPEN", "RESOLVED", "MUTED");
    }

    getFacetValueFormatter = (name: string): (value: string) => Observable<string> => {
        switch (name) {
            case "alertStatus": return v => of(RefdataUtils.enumFormatter(v));
            case "organisationId": return this.organisationFormatter;
        }
    }

    private organisationFormatter: (value: string) => Observable<string> = v =>
        RefdataUtils.getOrganisation(v).pipe(map(o => RefdataUtils.organisationFormatter(o)))

    getDefaultFacetFilters = (): FacetFilters => ({
        facets: {
            alertStatus: ["OPEN"]
        }
    });

    onFacetValuesChange(facet: FacetStats, selectedValues: string[]) {
        super.onFacetValuesChange(facet, selectedValues);
        this.reload();
    }

    reloadOverview = () => {
        this.reload(true);
    }

    recordUpdated() {
        this.reload(true);
    }

    reload = (clearCache = false) => {
        if (clearCache) {
            AppContext.removeFromCache("com.flowmaps.api.monitoring.alerting.GetMyAlerts");
        }
        if (!this.dateRange?.start) {
            return;
        }
        combineLatest([RefdataUtils.getMyAlerts(this.getFacetFilters(), this.dateRange), RefdataUtils.getAllMeters(), RefdataUtils.getAllConnections()])
            .subscribe(result => {
                const meters = lodash.keyBy(result[1], m => m.meter.meterId);
                const connections = lodash.keyBy(result[2], c => c.connection.connectionId);
                this.data = result[0].alerts.map((a: ExtendedAlert) => {
                    a.meter = <MeterWithConnection>meters[a.details.entityIds[0]];
                    if (!a.meter) {
                        const connectionId = a.details.entityIds.find(c => connections[c]);
                        if (connectionId) {
                            const connection = connections[a.details.entityIds[1]];
                            if (connection) {
                                a.meter = {
                                    connection: connection.connection,
                                    location: {
                                        locationId: connection.locationId,
                                        info: connection.locationInfo
                                    },
                                    meter: null
                                }
                            }
                        }
                    }
                    a.alertStatusPriority = AlertUtils.alertStatusPriority(a.alertStatus);
                    return a;
                });
                this.facetResult = addZeroFacetResults(result[0].facets, "alertStatus", "OPEN", "RESOLVED", "MUTED");
                this.loadCount++;
            });
    }
}

interface AlertStatusConfig {
    colorClass: string;
    icon: string;
}

export interface ExtendedAlert extends Alert {
    meter: MeterWithConnection;
    alertStatusPriority: number;
}