<div class="main-content">
    <div class="container-fluid">
        <div class="justify-content-between align-content-center row">
            <div class="col-12">
                <div class="header">
                    <div class="header-body">
                        <div class="align-items-center row">
                            <div class="col">
                                <h6 class="header-pretitle">Overview</h6>
                                <h1 class="header-title text-truncate">Errors</h1>
                            </div>
                            <div class="col-auto">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button"
                                            id="settingsDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        Settings
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="settingsDropdown">
                                        <li class="notranslate" *ngFor="let channel of slackChannels">
                                            <a class="dropdown-item d-flex align-items-center  justify-content-between  pointer" (click)="openSlackModal(channel)">
                                                <div class="d-flex align-items-center">

                                                <i class="bi bi-slack"></i>
                                                <span class="ms-2 me-2">
                                                {{ channel.info.name }}
                                                </span>
                                                </div>

                                                <i class="bi bi-pencil ms-auto"></i>
                                            </a>
                                        </li>
                                        <li><a class="dropdown-item pointer" (click)="addNewSlackChannel()">Add New Channel</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-errors-list></app-errors-list>
            </div>
        </div>
    </div>
</div>