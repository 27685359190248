<app-local-filter #localFilter [inputModel]="filterTerm" (updated)="filterTermUpdated($event)"
                  [data]="dataProvider.data" [filterFunction]="filterSources" [inputBufferTime]="200"
                  [placeholder]="'Filter...'" [selectedItems]="selectedItems" (termChanged)="filterTermChanged($event);"
                  [itemTemplate]="rt" (deleted)="deleteSource($event)">
    <ng-template #rt let-item>
        <span class="notranslate">
            <i class="me-2 bi" [ngClass]="appContext.getIconOfSourceType(item.info.type, item.info.connectionType, item.info.meterType)"></i>
            <span class="notranslate" data-bs-toggle="tooltip" [attr.data-bs-title]="item.info.filteredNameOverride || item.info.name">
                {{item.info.name}}
            </span>
        </span>
    </ng-template>
    <div #sourceListDropdownMenu class="dropdown-menu overflow-auto py-0" [ngClass]="positionClass"
         style="max-height: 400px; max-width: 100vw; width: 800px;">
        <app-dashboard-sources-list [data]="dataProvider.filteredData" [filterTerm]="filterTerm"
                                    [dataProvider]="dataProvider" (selectionChange)="selectionChanged()" recordCls="dropdown-item"></app-dashboard-sources-list>
    </div>
</app-local-filter>
