import {Component, OnInit} from '@angular/core';
import {RefdataUtils} from "../../refdata-utils";
import {map} from "rxjs";
import {ExtendedLocation} from "../locations-list/locations-list.component";

@Component({
  selector: 'app-locations-overview',
  templateUrl: './locations-overview.component.html',
  styleUrls: ['./locations-overview.component.scss']
})
export class LocationsOverviewComponent implements OnInit {
  locations: ExtendedLocation[];
  loadCount: number = 0;
  ngOnInit() {
    RefdataUtils.getMyOrganisations()
        .pipe(map(orgs => orgs.flatMap(o => o.locations
            .map(l => RefdataUtils.addOrganisationIdsToLocation(l, o)))))
        .subscribe(l => {
          this.locations = l;
          this.loadCount++;
        });
  }
}


