import {Component, Input, OnInit} from '@angular/core';
import {AppContext} from '../../../../app-context';
import {RefdataUtils} from '../../refdata-utils';
import {
  Authorisation,
  AuthorisationType,
  ManagerSignUpUser,
  Organisation, TimeRange,
  UpdateUserInfo,
  UserInfo,
  UserProfile
} from '@flowmaps/flowmaps-typescriptmodels';
import {publishEvent, sendCommand} from '../../../../flux/flux-utils';
import {closeModal} from '../../../../app-utils';
import {SourceInfo, SourcesProvider} from "../../../../utils/source-providers/sources-provider";
import {OrganisationProvider} from "../../../../utils/source-providers/organisation-provider";
import moment from "moment/moment";

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  appContext = AppContext;
  refDataUtils = RefdataUtils;
  @Input() data: UserProfile;
  isNew: boolean;
  createDefaultReport: boolean = true;
  organisationId: string;
  authorisation: Authorisation = {
    type: AuthorisationType.user,
    timeRange: {}
  }

  organisation: Organisation;
  sourceProvider: SourcesProvider<any>;

  getMinStartDate = (): string => this.authorisation.timeRange?.start ? this.authorisation.timeRange?.start : AppContext.today();


  get info(): UserInfo {
    return this.data.info;
  }

  ngOnInit(): void {
    if (!this.data.userId) {
      this.isNew = true;
    }
  }

  setOrganisation = (organisation: Organisation) => {
    this.organisation = organisation;
    if (organisation) {
      this.sourceProvider = new OrganisationProvider(organisation.organisationId, []);
    }
  }

  updateInfo = () => {
    if (this.isNew) {
      if (this.organisation) {
        this.authorisation.nominee = this.data.info.email;
        this.authorisation.nominator = this.organisation.organisationId;
      }
      if (this.authorisation.timeRange) {
        this.authorisation.timeRange = asTimeRange(this.authorisation.timeRange);
      }
      const command: ManagerSignUpUser = {
        info: this.data.info,
        createDefaultReport: this.createDefaultReport,
        authorisation: this.authorisation.nominator ? this.authorisation : null
      }
      sendCommand("com.flowmaps.api.authentication.ManagerSignUpUser", command, () => {
        closeModal();
        AppContext.removeFromCache("com.flowmaps.api.user.FindUsers");
        publishEvent("recordAdded", command);
      });
    } else {
      const command: UpdateUserInfo = {
        userId: this.data.userId,
        info: this.data.info
      }
      sendCommand("com.flowmaps.api.user.UpdateUserInfo", command, () => {
        closeModal();
        AppContext.removeFromCache("com.flowmaps.api.user.FindUsers");
      });
    }

    function asTimeRange(dateRange : TimeRange) : TimeRange {
      if (dateRange?.start || dateRange?.end) {
        return {
          start: dateRange.start && moment(dateRange.start).startOf('day').toISOString(),
          end: dateRange.end && moment(dateRange.end).endOf('day').toISOString(),
        }
      }
      return null;
    }
  };

  updateEntityIds(sources: SourceInfo[]) {
    const sourceIds = this.sourceProvider.sourceSelectionAfterCleanup();
    this.authorisation.entityIds = sourceIds.locationIds.concat(sourceIds.connectionIds).concat(sourceIds.meterIds);
  }
}