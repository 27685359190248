<div class="btn-group dropup d-md-block" [ngClass]="appContext.userProfile ? 'd-none' : 'd-block'">
  <button class="p-0 bg-transparent border-0" type="button" data-bs-toggle="dropdown">
    <img [src]="'assets/images/' + language + '.svg'" alt="Select language" width="40" class="notranslate">
  </button>

  <ul class="dropdown-menu">
    <li><button class="dropdown-item" (click)="changeLanguage('nl')">Dutch</button></li>
    <li><button class="dropdown-item" (click)="changeLanguage('en')">English</button></li>
  </ul>
</div>

<li class="dropdown-submenu dropstart d-md-none" id="sub-dropdown"  [ngClass]="appContext.userProfile ? 'd-block' : 'd-none'">
  <button class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-target="#sub-dropdown">Language</button>
  <ul class="dropdown-menu">
    <li><button class="dropdown-item" (click)="changeLanguage('nl')">Dutch</button></li>
    <li><button class="dropdown-item" (click)="changeLanguage('en')">English</button></li>
  </ul>
</li>