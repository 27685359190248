<div class="w-100">
    <div class="row m-0 g-1 narrow-gutters d-flex form-control rounded-end dropdown" style="padding-right: 40px;">
        <div #dropdownToggle class="col overflow-x-auto overflow-y-hidden" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
            <div class="d-flex flex-row m-0 g-1 column-gap-1">
                <ng-container *ngFor="let v of selectedItems">
                    <div class="col-auto">
                        <span class="d-flex badge text-bg-info fw-normal" style="font-size: 1rem !important;">
                            <span class="text-truncate">
                                <ng-container *ngIf="itemTemplate; else noTemplate"
                                              [ngTemplateOutlet]="itemTemplate"
                                              [ngTemplateOutletContext]="{$implicit: v}">
                                </ng-container>
                            </span>
                            <a *ngIf="!disabled" (click)="deleteValue(v)" class="text-decoration-none ms-2"
                               type="button" style="color: white;">
                                <span class="notranslate">×</span>
                            </a>
                        </span>
                        <ng-template #noTemplate>{{formatter(v)}}</ng-template>
                    </div>
                </ng-container>
            </div>
            <div class="row g-0">
                <div class="col px-1 m-0" style="min-width: 8rem">
                    <ng-content select=".input-group-prefix"></ng-content>
                    <input [(ngModel)]="inputModel" [placeholder]="placeholder"
                           [attr.type]="searching ? 'text' : 'search'" autocomplete="off" class="w-100 h-100 border-0 p-0 m-0"
                           (keydown.backspace)="removeSelectedItemWhenEmpty();"
                           [typeahead]="onInput" (selectItem)="onSelect($event.item); $event.preventDefault();"/>
                </div>
            </div>
        </div>
        <div class="col-auto m-0">
            <span class="input-group-append input-group-text border-0 search-icon bg-transparent align-items-start">
                <span *ngIf="selectedItems.length > 0" (click)="clearSelection()"
                      class="bi bi-x h2"></span>
                <span *ngIf="selectedItems.length === 0 && (!inputModel || inputModel.requiresEditing)"
                      class="bi {{newValueTemplate ? 'bi-plus-circle' : 'bi-search'}}"></span>
            </span>
            <ng-content select=".input-group-suffix"></ng-content>
            <ng-content select=".invalid-feedback"></ng-content>
            <ng-content select=".valid-feedback"></ng-content>
        </div>
        <ng-content select=".dropdown-menu"></ng-content>
    </div>
</div>


<ng-content select=".input-group-prepend"></ng-content>