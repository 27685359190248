import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {DeleteAlertChannel, SlackAlertChannel, UpsertSlackAlertChannel} from "@flowmaps/flowmaps-typescriptmodels";
import {publishEvent, sendCommand} from "../../../../../flux/flux-utils";
import {closeModal, openConfirmationModalWithCallback, openModal} from "../../../../../app-utils";
import {checkValidity, uuid} from "../../../../../common/utils";
import {QueryGateway} from "../../../../../flux/query-gateway";
import {
    ModalConfirmAutofocus,
    ModalConfirmAutofocusData
} from "../../../../../common/modal-confirm/modal-confirm.component";
import {AnimationTypes} from "../../../../../common/modal/modal.component";

@Component({
  selector: 'app-slack-alert-channel-details',
  templateUrl: './slack-alert-channel-details.component.html',
  styleUrls: ['./slack-alert-channel-details.component.css']
})
export class SlackAlertChannelDetailsComponent implements OnInit {
  @Input() data: SlackAlertChannelDetailsComponentData;
  isNewRecord: boolean;

  constructor(private queryGateway: QueryGateway, private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.isNewRecord = !this.data.slackAlertChannel || !this.data.slackAlertChannel.alertChannelId;
    if (this.isNewRecord) {
      this.data.slackAlertChannel = {
        alertChannelId: uuid(),
        info: {}
      };
    }
  }

  updateInfo = () => sendCommand("com.flowmaps.api.monitoring.errors.slack.UpsertSlackAlertChannel", <UpsertSlackAlertChannel>{
    alertChannelId: this.data.slackAlertChannel.alertChannelId,
    info: this.data.slackAlertChannel.info
  }, () => {
    closeModal();
    this.queryGateway.removeFromCache("com.flowmaps.api.monitoring.errors.FindAlertChannels");
    publishEvent('reloadOverview');
  });

  deleteChannel = () => {
    if (checkValidity(this.elementRef)) {
      openConfirmationModalWithCallback((confirmed) => {
        if (confirmed) {
          const command: DeleteAlertChannel = {
            alertChannelId: this.data.slackAlertChannel.alertChannelId,
          };
          sendCommand("com.flowmaps.api.monitoring.errors.DeleteAlertChannel", command, () => {
            closeModal();
            this.queryGateway.removeFromCache("com.flowmaps.api.monitoring.errors.FindAlertChannels");
            publishEvent('reloadOverview');
          });
        }
      }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
        type: "info",
        title: "Delete channel",
        innerHtmlMessage: `<p><span>You are about to delete</span><span class="notranslate fw-bold"> </span><span> this channel.</span></p><p class="fw-bold">Are you sure you want to execute this action?</p>`,
        confirmText: "Delete channel",
        cancelText: "Cancel"
      }, 'static');
    }
  }
}
export interface SlackAlertChannelDetailsComponentData {
  slackAlertChannel: SlackAlertChannel;
}
export function openSlackDetailsModal (record?: SlackAlertChannel) {
    openModal(SlackAlertChannelDetailsComponent, <SlackAlertChannelDetailsComponentData> {
        slackAlertChannel: record
    }, {
        cssClass: "modal-xl custom-modal-wide",
        animation: AnimationTypes.fade
    });
}