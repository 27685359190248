import {Component} from '@angular/core';
import {Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../refdata-utils";

@Component({
    selector: 'app-organisations-overview',
    templateUrl: './organisations-overview.component.html',
    styleUrls: ['./organisations-overview.component.scss']
})
export class OrganisationsOverviewComponent {
    data: Organisation[];
    loadCount: number = 0;

    ngOnInit() {
        RefdataUtils.getMyOrganisations().subscribe(d => {
            this.data = d;
            this.loadCount++;
        });
    }
}
