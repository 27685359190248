import {Component} from '@angular/core';
import {SignInUser} from '@flowmaps/flowmaps-typescriptmodels';
import {AuthService} from "../../../utils/auth.service";

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.css']
})
export class SignInComponent {
    command: SignInUser = {};
    showPassword: boolean;

  constructor(private authService: AuthService) {
  }

  signInUser() {
    this.authService.signIn(this.command);
  }
}
