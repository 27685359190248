import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "./views/dashboard/dashboard.component";
import {SignInComponent} from './views/signed-out/sign-in/sign-in.component';
import {ForgotPasswordComponent} from './views/signed-out/forgot-password/forgot-password.component';
import {SignedOutComponent} from './views/signed-out/signed-out.component';
import {HomeComponent} from './views/home/home.component';
import {EditProfileComponent} from './views/edit-profile/edit-profile.component';
import {EditProfileSecurityComponent} from './views/edit-profile/edit-profile-security/edit-profile-security.component';
import {EditProfileGeneralComponent} from './views/edit-profile/edit-profile-general/edit-profile-general.component';
import {SignUpComponent} from './views/signed-out/sign-up/sign-up.component';
import {
    OrganisationsOverviewComponent
} from "./views/refdata/organisations/organisations-overview/organisations-overview.component";
import {
    ConnectionsOverviewComponent
} from "./views/refdata/connections/connections-overview/connections-overview.component";
import {
    OrganisationDetailsComponent
} from "./views/refdata/organisations/organisation-details/organisation-details.component";
import {LocationsOverviewComponent} from "./views/refdata/locations/locations-overview/locations-overview.component";
import {ReportRunnerComponent} from "./views/reports/report-runner/report-runner.component";
import {ReportComponent} from "./views/reports/report/report.component";
import {LocationDashboardComponent} from "./views/location-dashboard/location-dashboard.component";
import {ConfirmUserComponent} from "./views/signed-out/confirm-user/confirm-user.component";
import {ResetPasswordComponent} from "./views/signed-out/reset-password/reset-password.component";
import {UsersOverviewComponent} from './views/refdata/users/users-overview/users-overview.component';
import {AlertsOverviewComponent} from './views/monitoring/alerting/alerts-overview/alerts-overview.component';
import {ErrorsOverviewComponent} from "./views/monitoring/error-monitoring/errors-overview/errors-overview.component";
import {ErrorNavigatorComponent} from "./views/monitoring/error-monitoring/error-navigator/error-navigator.component";
import {ContractsOverviewComponent} from "./views/refdata/contracts/contracts-overview/contracts-overview.component";
import {ContractDetailsComponent} from "./views/refdata/contracts/contract-details/contract-details.component";
import {
    ContractPeriodsListComponent
} from "./views/refdata/contracts/contract-periods-list/contract-periods-list.component";
import {LinkedContractsComponent} from "./views/refdata/contracts/linked-contracts/linked-contracts.component";
import {TaxesOverviewComponent} from "./views/refdata/taxes/taxes-overview/taxes-overview.component";

const routes: Routes = [
    {
        path: '', component: HomeComponent,
        children: [
            {path: '', component: DashboardComponent},
            {path: 'dashboard/force/:dashboard', component: DashboardComponent, data: {reUseRoute: false}},
            {path: 'dashboard/:dashboard', component: DashboardComponent},
            {path: 'location/dashboard', component: LocationDashboardComponent, data: {reUseRoute: false}},
            {path: 'location/dashboard/:dashboard', component: LocationDashboardComponent},
            {
                path: 'profile', component: EditProfileComponent, children: [
                    {path: "", component: EditProfileGeneralComponent},
                    {path: "settings", component: EditProfileSecurityComponent}
                ]
            },
            {path: "report/:reportId", component: ReportComponent, data: {reUseRoute: false}},
            {path: "reporting/run", component: ReportRunnerComponent},
            {path: 'organisations', component: OrganisationsOverviewComponent},
            {path: 'organisation/:organisationId', component: OrganisationDetailsComponent},
            {path: 'locations', component: LocationsOverviewComponent},
            {path: 'connections', component: ConnectionsOverviewComponent},
            {path: 'users', component: UsersOverviewComponent},
            {path: 'alerts', component: AlertsOverviewComponent},
            {path: 'errors', component: ErrorsOverviewComponent},
            {path: 'errors/:errorId', component: ErrorNavigatorComponent},
            {path: 'contracts', component: ContractsOverviewComponent},
            {path: 'contract/:contractId', component: ContractDetailsComponent,
                children: [
                    { path: 'details', component: ContractDetailsComponent },
                    { path: 'periods', component: ContractPeriodsListComponent },
                    { path: 'links', component: LinkedContractsComponent },
                    { path: '', redirectTo: 'details', pathMatch: 'full' }
                ]},
            {path: 'taxes', component: TaxesOverviewComponent},

        ]
    },
    {
        path: '', component: SignedOutComponent, children: [
            {path: 'login', component: SignInComponent},
            {path: 'signup', component: SignUpComponent},
            {path: 'forgot-password', component: ForgotPasswordComponent},
            {path: 'reset-password', component: ResetPasswordComponent},
            {path: 'confirm', component: ConfirmUserComponent},
            {path: 'confirm/:token', component: ConfirmUserComponent},
            {path: '**', redirectTo: ''},
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
