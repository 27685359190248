import {Component, ElementRef, Input} from '@angular/core';
import {Subject} from 'rxjs';
import {AppContext} from '../../app-context';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-screenshot-button',
  templateUrl: './screenshot-button.component.html',
  styleUrls: ['./screenshot-button.component.css']
})
export class ScreenshotButtonComponent {
  @Input() element : HTMLElement | ElementRef;
  @Input() fileName : string;

  downloadImage() {
    const subject = new Subject<void>();
    const process = AppContext.waitForProcess(subject);
    process.subscribe();
    const element : HTMLElement = this.element ? this.element['nativeElement'] || this.element : document.body;
    html2canvas(element).then(canvas => {
      try {
        let downloadLink = document.createElement('a');
        downloadLink.setAttribute('download', this.fileName || 'screenshot.png');
        canvas.toBlob(function(blob) {
          let url = URL.createObjectURL(blob);
          downloadLink.setAttribute('href', url);
          downloadLink.click();
        });
      } finally {
        subject.next();
      }
    });
  }
}
