import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {MeasurementsDataProvider} from "../../../../utils/measurements-data-provider";
import {lodash} from "../../../../common/utils";
import {MeasurementDataset} from "../../../charts/base-measurement-chart";
import {DashboardContext} from "../../dashboard.context";
import {DataType, MeasurementUnit} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-energy-summary-widget',
  templateUrl: './energy-summary-widget.component.html',
  styleUrls: ['./energy-summary-widget.component.scss']
})
export class EnergySummaryWidgetComponent {
    appContext = AppContext;
    context = DashboardContext;
    _dataProvider: MeasurementsDataProvider<any>;

    values: SummedValues = {
        electricityConsumption: 0,
        electricityProduction: 0,
        electricityFeedIn: 0,
        gas: 0,
        heat: 0,
        water: 0
    }

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    @Input() set dataProvider(provider: MeasurementsDataProvider<any>) {
        this._dataProvider = provider;
        this._dataProvider.subscribeToData(r => {
            this.values = this.mergeSummedValues(this.getValues(provider.dashboardData.currentData.measurements), this.getValues(provider.dashboardData.currentData.estimatedMeasurements));
            this.changeDetector.detectChanges();
        });
    }

    private getValues(measurements: {[P in DataType]?: MeasurementDataset[] }): SummedValues {
        return {
            electricityConsumption: this.sumData((measurements.electricityConsumption || []).concat(measurements.electricityConsumptionOffPeak)),
            electricityFeedIn: this.sumData((measurements.electricityFeedIn || []).concat(measurements.electricityFeedInOffPeak)),
            electricityProduction: this.sumData((measurements.electricityGrossProduction || [])),
            gas: this.sumData(measurements.gasConsumption),
            heat: this.sumData(measurements.heatConsumption),
            water: this.sumData(measurements.waterConsumption)
        };
    }

    private mergeSummedValues = (values: SummedValues, other: SummedValues): SummedValues => {
        return {
            electricityConsumption: values.electricityConsumption + other.electricityConsumption,
            electricityFeedIn: values.electricityFeedIn + other.electricityFeedIn,
            electricityProduction: values.electricityProduction + other.electricityProduction,
            gas: values.gas + other.gas,
            heat: values.heat + other.heat,
            water: values.water + other.water
        }
    }

    sumData = (dataset: MeasurementDataset[]) => dataset ? lodash.sum(dataset.flatMap(d => d?.dataset.data)) || 0 : 0;

    electricityConsumptionUnit = () => this.context.getMeasurementUnit(DataType.electricityConsumption);
    electricityProductionUnit = () => this.context.getMeasurementUnit(DataType.electricityFeedIn);
    gasUnit = () => this.context.getMeasurementUnit(DataType.gasConsumption);
    waterUnit = () => this.context.getMeasurementUnit(DataType.waterConsumption);
    heatUnit = () => MeasurementUnit.GJ;
}

interface SummedValues {
    electricityConsumption: number;
    electricityFeedIn: number;
    electricityProduction: number;
    gas: number;
    heat: number;
    water: number;
}