import { Injectable } from "@angular/core";
@Injectable()
export class EventGateway  {
  private handlers: any[] = [];

  publish(type: string, payload: any) {
    this.handlers.filter(h => h[type]).forEach(h => h[type](payload));
  }

  registerLocalHandler(handler: any) {
    this.handlers.push(handler);
    return () => this.handlers = this.handlers.filter(o => o !== handler);
  }

  registerLocalHandlers(...handlers: any[]) {
    const subscriptions : any[] = handlers.map(h => this.registerLocalHandler(h));
    return () => subscriptions.forEach(s => s());
  }
}

