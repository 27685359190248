import {Component, ElementRef, NgZone, OnInit, Renderer2, TemplateRef, ViewChild,    SimpleChanges,
} from '@angular/core';
import {Error, ErrorEntry, MuteErrors, ResolveErrors} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../../refdata/refdata-utils";
import {publishEvent} from "../../../../flux/flux-utils";
import {Observable, of, switchMap} from "rxjs";
import {catchError} from "rxjs/operators";
import {closeModal} from "../../../../app-utils";
import {Router} from "@angular/router";

@Component({
    selector: 'app-error-navigator',
    templateUrl: './error-navigator.component.html',
    styleUrls: ['./error-navigator.component.css']
})
export class ErrorNavigatorComponent implements OnInit {
    data: { errorId: string };
    error: Error;
    errorId: string;

    entries: ErrorEntry[];
    index: number = 0;
    ascending: boolean = false;

    @ViewChild("muteErrorTemplate") muteErrorTemplate: TemplateRef<any>;
    @ViewChild('modalElement') modalElement: ElementRef;

    constructor(private router: Router, private renderer: Renderer2, private el: ElementRef,private zone: NgZone) {}

    ngOnInit(): void {
        if (this.data) {
            this.errorId = this.data.errorId;
        }
        this.fetchErrorEntries().subscribe(() => {
            this.fetchError();
        });
        const modalDialog = this.el.nativeElement.closest('.modal-dialog');
        if (modalDialog) {
            this.renderer.removeClass(modalDialog, 'modal-fullscreen-md-down');
        }
    }

    ngOnDestroy() {
        const modalDialog = this.el.nativeElement.closest('.modal-dialog');
        if (modalDialog) {
            this.renderer.addClass(modalDialog, 'modal-fullscreen-md-down');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'] && changes['data'].currentValue) {
            this.errorId = changes['data'].currentValue.errorId;
            this.fetchErrorEntries().subscribe(() => {
                this.fetchError();
            });
        }
    }

    fetchErrorEntries(ascending: boolean = false): Observable<any> {
        if (this.errorId) {
            return RefdataUtils.getErrorEntries(this.errorId, ascending).pipe(
                switchMap((newEntries: ErrorEntry[]) => {
                    if (this.entries) {
                        const existingIds = new Set(this.entries.map(entry => entry.sequence));
                        newEntries.forEach(entry => {
                            if (!existingIds.has(entry.sequence)) {
                                this.entries.push(entry);
                            }
                        });
                    } else {
                        this.entries = newEntries;
                    }
                    this.entries.sort((a, b) => (a.sequence > b.sequence ? -1 : 1));
                    return of(void 0);
                }),
                catchError(error => {
                    return of(void 0);
                })
            );
        } else {
            return of(void 0);
        }
    }

    nextEntry() {
        if (this.entries && this.entries.length > 0) {
            this.index = (this.index - 1 + this.entries.length) % this.entries.length;
        }
    }

    prevEntry() {
        if (this.entries && this.entries.length > 0) {
            this.index = (this.index + 1) % this.entries.length;
        }
    }

    hasNextEntry(): boolean {
        return this.index > 0;
    }

    hasPrevEntry(): boolean {
        return this.index < this.entries.length - 1;
    }

    goToBeginning(): void {
        this.ascending = true;
        this.fetchErrorEntries(this.ascending).subscribe(() => {
            this.index = this.entries.length - 1;
        });
    }

    goToEnd(): void {
        this.ascending = false;
        this.fetchErrorEntries(this.ascending).subscribe(() => {
            this.index = 0;
        });
    }

    fetchError() {
        if (this.errorId) {
            RefdataUtils.getError(this.errorId)
                .subscribe(
                    (error: Error) => {
                        this.error = error;
                    });
        }
    }

    recordUpdated(upsertCommand: MuteErrors | ResolveErrors) {
        publishEvent('reloadOverview');
    }

    protected readonly closeModal = () => {
        closeModal();
        this.router.navigate(['/errors']);
        const location = new Location();
        location.replace('/errors');
    };
}

