<ul class="dropdown-menu" *ngIf="error">
    <li *ngIf="error.status === 'RESOLVED'; else notResolved">
        <button class="dropdown-item" (click)="reopenError(error)">
            Reopen
        </button>
    </li>
    <ng-template #notResolved>
        <li>
            <button class="dropdown-item" (click)="resolveError(error)">
                Resolve
            </button>
        </li>

        <li *ngIf="error.status === 'MUTED' else unmuted">
            <button class="dropdown-item" (click)="unmuteError(error)">
                Unmute errors
            </button>
        </li>
    </ng-template>
    <ng-template #unmuted>
        <li>
            <button class="dropdown-item" (click)="setMuteDeadline('1d')">Mute for 1 day</button>
        </li>
        <li>
            <button class="dropdown-item" (click)="setMuteDeadline('1w')">Mute for 1 week</button>
        </li>
        <li>
            <button class="dropdown-item" (click)="setMuteDeadline('1m')">Mute for 1 month</button>
        </li>
        <li>
            <button class="dropdown-item" (click)="setMuteDeadline('indefinite')">Mute indefinitely</button>
        </li>
    </ng-template>
</ul>
