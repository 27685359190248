<div *ngIf="!readonly" class="align-items-center row mb-3">
    <div class="col">
        <app-date-range localStorageKey="errors-overview-range" [(ngModel)]="dateRange" (ngModelChange)="reload()"></app-date-range>
    </div>
    <div class="col">
        <app-local-filter [appAutoFocus]="true" placeholder="Search" [data]="data"
                          (updated)="filteredData = $event"></app-local-filter>
    </div>

    <div class="my-2 w-100 d-lg-none"></div>

    <div class="col-auto">
        <button (click)="toggleSelection()" type="button" class="btn btn-secondary">
            <i class="bi bi-pencil me-md-2"></i>
            <span class="d-none d-md-inline">Bulk edit</span>
        </button>
    </div>
</div>
<div class="row mb-3 d-flex justify-content-between align-items-center">
    <ng-container *ngIf="dataSorted && dataSorted.length > 0">
        <div class="col-auto d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-exclamation-triangle mx-3" viewBox="0 0 16 16">
                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
            </svg>
            <span class="me-5 notranslate">{{ data.length }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hash"
                 viewBox="0 0 16 16">
                <path d="M8.39 12.648a1 1 0 0 0-.015.18c0 .305.21.508.5.508.266 0 .492-.172.555-.477l.554-2.703h1.204c.421 0 .617-.234.617-.547 0-.312-.188-.53-.617-.53h-.985l.516-2.524h1.265c.43 0 .618-.227.618-.547 0-.313-.188-.524-.618-.524h-1.046l.476-2.304a1 1 0 0 0 .016-.164.51.51 0 0 0-.516-.516.54.54 0 0 0-.539.43l-.523 2.554H7.617l.477-2.304c.008-.04.015-.118.015-.164a.51.51 0 0 0-.523-.516.54.54 0 0 0-.531.43L6.53 5.484H5.414c-.43 0-.617.22-.617.532s.187.539.617.539h.906l-.515 2.523H4.609c-.421 0-.609.219-.609.531s.188.547.61.547h.976l-.516 2.492c-.008.04-.015.125-.015.18 0 .305.21.508.5.508.265 0 .492-.172.554-.477l.555-2.703h2.242zm-1-6.109h2.266l-.515 2.563H6.859l.532-2.563z"/>
            </svg>
            <span class="notranslate">{{ calculateTotals() }}</span>
        </div>
    </ng-container>
    <div class="col-8 d-flex justify-content-end">
        <ng-container *ngFor="let facet of getFacets('right'); index as i; trackBy: trackByFacetName">
            <div class="col-auto notranslate">
                <app-facet-filter [facet]="facet" (selectedValuesChange)="onFacetValuesChange(facet, $event)"
                                  [(selectedValues)]="facetFilters.facets[facet.name]"
                                  [style.max-width.px]="200"></app-facet-filter>
            </div>
        </ng-container>
    </div>

    <div class="row mb-3 d-flex justify-content-end" *ngIf="selecting">
        <div class="col-12">
            <div class="form-check ms-2">
                <input class="form-check-input" type="checkbox" id="select-all"
                       [checked]="allItemsSelected()" (click)="toggleSelectAll()">
                <label class="form-check-label" for="select-all">
                    <span *ngIf="allItemsSelected()">Deselect all</span>
                    <span *ngIf="!allItemsSelected()">Select all</span>
                </label>
            </div>
        </div>
    </div>
</div>

<div class="row mb-3 d-flex justify-content-end">
    <app-scroller (endReached)="loadNextPage()" [windowScroll]="true">
        <div class="row">
            <ng-container *ngFor="let error of dataSorted; let i = index; trackBy: trackByForRecord">
                <div role="row" class="col-12">
                    <div class="card hover-show w-100"
                         [ngClass]="isMuted(error) || isResolved(error) ? 'text-muted' : ''">
                        <ng-container *ngIf="selecting && error.status !== 'RESOLVED'">
                            <div class="form-check position-absolute top-0 start-0 ms-2 mt-2">
                                <input class="form-check-input mt-0" [(ngModel)]="error['selected']"
                                       (ngModelChange)="toggleSelectedItem(error, $event)" type="checkbox">
                            </div>
                        </ng-container>
                        <div class="card-body">
                            <div class="row gx-3">
                                <div class="col-auto notranslate d-flex align-items-center justify-content-between">
                                    <ng-container *ngIf="getErrorStatusConfig(error) as config">
                                    <span class="h2 m-0" [ngClass]="'text-'+config.colorClass">
                                        <i class="bi" [ngClass]="config.icon"></i>
                                    </span>
                                    </ng-container>
                                </div>
                                <div class="col text-truncate">
                                    <div class="row gx-3 mb-1">
                                        <ng-container>
                                            <div class="col-auto mb-1">
                                                <a [href]="'/errors?errorId=' + error.errorId"
                                                   (contextmenu)="onContextMenu($event, error.errorId)"
                                                   (click)="handleErrorClick($event, error.errorId)">
                                                    <span class="h3 notranslate hover-underline pointer">
                                                        {{ error.errorDetails.errorMessage || error.errorDetails.message }}
                                                    </span>
                                                </a>
                                            </div>
                                        </ng-container>
                                        <div class="row gx-3 mt-2">
                                            <div class="row gx-3 mt-2">
                                                <div class="text-muted col-2">
                                                    <span class="bi bi-hash pe-2"></span>
                                                    <span class="pe-6 notranslate">{{ error.count }}</span>
                                                </div>
                                                <div class="text-muted notranslate col-2">
                                                    <span class="bi bi-disc pe-3 "></span>
                                                    <span class="pe-6">{{ error.errorDetails.application }}</span>
                                                </div>
                                                <div class="text-muted notranslate col-2">
                                                    <span class="bi bi-geo-alt pe-3"></span>
                                                    <span class="pe-6 ">{{ error.errorDetails.environment }}</span>
                                                </div>
                                                <div class="text-muted col-2 notranslate">
                                                    <span class="bi bi-clock pe-3"></span>
                                                    <span class="pe-6">{{ formatTimestamp(error.lastSeen) }}</span>
                                                </div>
                                                <div *ngIf="isMuted(error) && error.muteDeadline"
                                                     class="text-muted notranslate col-3">
                                                    <span class="bi bi-volume-mute pe-3"></span>
                                                    <span class="pe-6">{{ formatTimestamp(error.muteDeadline) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto ms-auto mt-n3 me-n3 dropup">
                                    <button type="button" class="btn text-body" role="button" data-bs-toggle="dropdown"
                                            data-bs-auto-close="outside" aria-expanded="false">
                                        <i class="bi bi-three-dots-vertical"></i>
                                    </button>
                                    <app-error-actions-dropdown [error]="error"></app-error-actions-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </app-scroller>
    <div class="sticky-bottom bg-white mx-n3 mx-md-n5 border-top" *ngIf="selecting">
        <app-error-selection *ngIf="selecting" [selectedItems]="selectedItems"
                             [cancelMethod]="toggleSelection"></app-error-selection>
    </div>
</div>

<ng-template #muteErrorTemplate>
    <p>Are you sure you want to mute these errors?</p>
    <app-form-field label="Mute deadline">
        <app-date-field [(ngModel)]="muteDate" [minDate]="tomorrow()"></app-date-field>
    </app-form-field>
</ng-template>
