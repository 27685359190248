<div class="xl-content" style="flex: 1 1 auto;">
    <table *ngIf="this._data" class="table table-bordered m-0 text-center">
        <thead class="sticky-top">
            <tr>
                <th>Location</th>
                <th>Building type</th>
                <th>Energy label</th>
                <th>kWh/m<sup>2</sup></th>
                <th>Paris proof</th>
            </tr>
        </thead>
        <tbody class="overflow-auto">
        <ng-container *ngFor="let row of records(_data); index as i; trackBy : trackBy">
            <tr>
                <td class="text-start">{{row.locationName}}</td>
                <td>{{row.buildingTypeName}}</td>
                <td>{{row.energyLabel}}</td>
                <td [ngClass]="{'text-danger': row.value > row.parisProofValue, 'text-success': row.value < row.parisProofValue}">{{row.value}}</td>
                <td>{{row.parisProofValue}}</td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</div>
