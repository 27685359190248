<div class="row" *ngIf="length > 0">
    <span class="col-2 col-form-label">{{label | sentenceCase}}</span>
    <ng-container *ngIf="length > 10 else showLabels">
        <span class="col col-form-label notranslate">{{length}}</span>
    </ng-container>
    <ng-template #showLabels>
        <app-tag-labels [labels]="sourcesProvider.getAllSourcesByType(this.getSourceType())" [itemTemplate]="rt" class="col py-1">
            <ng-template #rt let-item>
                <span class="notranslate">
                    <i class="me-2 bi" [ngClass]="appContext.getIconOfSourceType(item.type, item.connectionType, item.meterType)"></i>
                    {{item.name}}
                </span>
            </ng-template>
        </app-tag-labels>
    </ng-template>
</div>