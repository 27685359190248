<div class="d-flex align-items-center min-vh-100 bg-auth">
    <div class="container">


        <div class="row justify-content-center">
            <div class="col-12 col-md-5 col-xl-4 my-5">

                <!-- Heading -->
                <h1 class="display-4 text-center mb-3">
                    Sign in
                </h1>

                <!-- Subheading -->
                <p class="text-muted text-center mb-5">
                    For access to your dashboard.
                </p>

                <!-- Form -->
                <app-form (submit)="signInUser()">

                    <!-- Email address -->
                    <app-form-field label="Email Address">
                        <input [(ngModel)]="command.userId" required type="email" class="form-control notranslate"
                               name="sign-in-email" placeholder="name@address.com">
                    </app-form-field>

                    <!-- Password -->
                    <app-form-field>
                        <div class="row">
                            <div class="col">

                                <!-- Label -->
                                <label class="form-label">Password</label>

                            </div>
                            <div class="col-auto">

                                <!-- Help text -->
                                <a routerLink="/forgot-password" class="form-text small text-muted">
                                    Forgot password?
                                </a>

                            </div>
                        </div> <!-- / .row -->

                        <!-- Input group -->
                        <div class="input-group input-group-merge">

                            <!-- Input -->
                            <input [(ngModel)]="command.password" required class="form-control"
                                   [type]="showPassword ? 'text' : 'password'"
                                   name="sign-in-password" placeholder="Enter your password">

                            <!-- Icon -->
                            <span class="input-group-text rounded-end pointer" (click)="showPassword = !showPassword">
                                <i class="fe fe-eye"></i>
                            </span>

                        </div>
                    </app-form-field>

                    <!-- Submit -->
                    <button type="submit" class="btn btn-lg w-100 btn-primary my-3" name="sign-in-button">
                        Sign in
                    </button>

                    <!-- Link -->
                    <div class="text-center">
                        <small class="text-muted text-center"><span class="me-1">Don't have an account yet?</span> <a routerLink="/signup">Sign up</a><span class="notranslate">.</span></small>
                    </div>
                </app-form>
            </div>
        </div>
    </div>
</div>
