import {Component, Input} from '@angular/core';
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-connection-image',
  templateUrl: './connection-image.component.html',
  styleUrls: ['./connection-image.component.scss']
})
export class ConnectionImageComponent {
  appContext = AppContext;
  @Input() connectionType: "Electricity" | "Gas" | "Heat" | "Water" | "Cooling";
  @Input() width: number = 20;
}
