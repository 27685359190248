<div class="main-content" *ngIf="organisation">
    <div class="container-fluid min-vh-100 d-flex flex-column">
        <div class="row justify-content-between align-items-center">
            <div class="col-12">
                <div class="header">
                    <div class="header-body">
                        <div class="align-items-center row">
                            <div class="col">
                                <h6 class="header-pretitle">Organisation</h6>
                                <h1 class="header-title text-truncate notranslate">{{organisation.info.name}}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-tab-panel class="d-flex flex-column flex-grow-1">
            <div class="d-flex nav-bar-prefix me-3">
                <a class="nav-link" routerLink="/organisations" style="padding-top: 1rem;"><i class="bi bi-arrow-left"></i></a>
            </div>
            <app-tab-item [templateRef]="organisationsRef">
                <span class="tab-item">Details</span>
                <ng-template #organisationsRef>
                    <div class="tab-content">
                        <app-form (submit)="updateInfo()">
                            <app-organisation-info [organisationInfo]="organisation.info"></app-organisation-info>
                            <div *ngIf="appContext.isManagerForOrganisation(organisation)" class="row mt-4">
                                <div class="col-auto">
                                    <button type="submit" class="btn btn-primary">
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </app-form>
                    </div>
                </ng-template>
            </app-tab-item>
            <app-tab-item [templateRef]="authorisationRef">
                <span class="tab-item">Authorisations</span>
                <ng-template #authorisationRef>
                    <div class="tab-content">
                        <app-authorisations-list [organisation]="organisation"
                                                 [data]="organisation.givenAuthorisations"></app-authorisations-list>
                    </div>
                </ng-template>
            </app-tab-item>
            <app-tab-item [templateRef]="locationsRef">
                <span class="tab-item">Locations</span>
                <ng-template #locationsRef>
                    <div class="tab-content">
                        <app-locations-list [organisationId]="organisationId" [data]="locations"></app-locations-list>
                    </div>
                </ng-template>
            </app-tab-item>
            <app-tab-item [templateRef]="connectionsRef">
                <span class="tab-item">Connections</span>
                <ng-template #connectionsRef>
                    <div class="tab-content d-flex flex-column flex-grow-1">
                        <app-connections-list [organisationId]="organisationId" [intermediaryId]="organisation?.intermediaryId"
                                              [data]="connections" class="d-flex flex-column flex-grow-1"></app-connections-list>
                    </div>
                </ng-template>
            </app-tab-item>
        </app-tab-panel>
    </div>
</div>
