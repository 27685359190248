<app-form (submit)="updateInfo()">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <app-form-field class="col-12 col-md-6" label="Name">
                    <input required [(ngModel)]="_data.info.name" type="text" class="form-control">
                </app-form-field>
                <app-form-field class="col-12 col-md-6" label="Send frequency">
                    <app-select required [(ngModel)]="frequency" [options]="frequencies" (ngModelChange)="onFrequencyChange($event)"></app-select>
                </app-form-field>
                <app-form-field class="col-12 col-md-6" label="Recipients">
                    <app-multi-input [(ngModel)]="_data.info.recipients" placeholder="Enter e-mail address"></app-multi-input>
                </app-form-field>
            </div>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>