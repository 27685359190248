import {Component, OnInit} from '@angular/core';
import {FindAlertChannels, SlackAlertChannel} from '@flowmaps/flowmaps-typescriptmodels';
import {sendQuery} from "../../../../flux/flux-utils";
import {
    openSlackDetailsModal
} from "../alert-channels/slack-alert-channel-details/slack-alert-channel-details.component";

@Component({
    selector: 'app-errors-overview',
    templateUrl: './errors-overview.component.html',
    styleUrls: ['./errors-overview.component.css']
})
export class ErrorsOverviewComponent implements OnInit {
    slackChannels: SlackAlertChannel[];

    ngOnInit(): void {
        sendQuery("com.flowmaps.api.monitoring.errors.FindAlertChannels", <FindAlertChannels>{})
            .subscribe((channels: any[]) => {
                this.slackChannels = channels;
            });
    }

    openSlackModal(record?: SlackAlertChannel): void {
        openSlackDetailsModal(record)
    }

    addNewSlackChannel(): void {
        const newChannel: SlackAlertChannel = {
            alertChannelId: '',
            info: {
                name: '',
                url: '',
            }
        };
        this.openSlackModal(newChannel);
    }
}