import {Plugin} from "chart.js";

export class CustomBackgroundColorPlugin implements Plugin<'scatter'> {
    id = 'customBackgroundColor';

    beforeDraw = (chart, args, options) => {
        const {ctx, chartArea: {left, top, width, height}} = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color;
        ctx.fillRect(left, top, width, height);
        ctx.restore();
    }
}