import {Component, Input} from '@angular/core';
import {EnergyLabels} from "../energy-label/energy-label.component";

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent {
  @Input() label: EnergyLabels | string;
  @Input() cssClass: string;
  @Input() translateContent: boolean = false;
}
