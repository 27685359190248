import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {EntityPerformanceChartComponent, PerformanceRecord} from "./entity-performance-chart.component";
import {AggregatedDataPoint, DataType, Location, MeasurementsResult} from "@flowmaps/flowmaps-typescriptmodels";
import {DashboardContext, SourceType} from '../../dashboard/dashboard.context';
import {ChartDataPerMeasurement} from "../../../utils/measurements-data-provider";
import {MeasurementDataset} from "../base-measurement-chart";
import {lodash} from "../../../common/utils";
import {map, Observable} from "rxjs";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {ActiveElement, Chart, ChartEvent} from "chart.js";
import {ChartUtilsService} from "../chart-utils.service";
import {Router} from "@angular/router";
import {LocationDashboardComponent} from "../../location-dashboard/location-dashboard.component";
import {Dashboard} from "../../dashboard/dashboard.types";
import {AppContext} from "../../../app-context";

@Component({
    selector: 'app-location-performance-chart',
    templateUrl: './entity-performance-chart.component.html',
    styleUrls: ['./entity-performance-chart.component.scss']
})
export class LocationPerformanceChartComponent extends EntityPerformanceChartComponent<Location> {
    @Input() selectedDataType: DataType;
    perSquareMeterEnabled = true;

    constructor(protected changeDetectorRef: ChangeDetectorRef, protected chartUtils: ChartUtilsService, private router: Router) {
        super(changeDetectorRef, chartUtils);
    }

    entityType(): SourceType {
        return SourceType.location;
    }

    get getSelectedDataType() {
        return this.selectedDataType || super.getSelectedDataType;
    }

    possibleDataTypes = (): DataType[] =>
        [DataType.electricityConsumption, DataType.electricityFeedIn, DataType.electricityConsumptionReactive, DataType.electricityGrossProduction]
            .concat([DataType.gasConsumption, DataType.heatConsumption, DataType.waterConsumption])
            .concat(AppContext.isAdmin() ? [DataType.gasConsumptionUncorrected] : [])
            .concat([DataType.electricityConsumptionCosts, DataType.gasConsumptionCosts, DataType.waterConsumptionCosts,
                DataType.heatConsumptionCosts])
            .concat([DataType.co2EmissionFromElectricity, DataType.co2EmissionFromGas, DataType.co2EmissionFromWater,
                DataType.co2EmissionFromHeat]);

    createData(result: ChartDataPerMeasurement, measurements: MeasurementDataset[], estimatedMeasurements: MeasurementDataset[]): Observable<PerformanceRecord[]> {
        const byLocation: MeasurementsResult[] = (result.byLocation || []).map(l => {
            const measurements: { [P in DataType]?: AggregatedDataPoint[] } = {};
            Object.entries(l.measurements)
                .filter(e => this.measurementTypes().includes(e[0] as DataType))
                .forEach(e => measurements[e[0]] = e[1]);
            const estimatedMeasurements: { [P in DataType]?: AggregatedDataPoint[] } = {};
            Object.entries(l.estimatedMeasurements)
                .filter(e => this.measurementTypes().includes(e[0] as DataType))
                .forEach(e => estimatedMeasurements[e[0]] = e[1]);
            return <MeasurementsResult>{
                entityId: l.entityId,
                measurements: measurements,
                estimatedMeasurements: estimatedMeasurements
            };
        })
        return this.getEntities().pipe(map(locs => {
            return Object.entries(lodash.groupBy(byLocation, d => d.entityId))
                .map(e => {
                    const location = locs.find(l => l.locationId === e[0] || l.aliasIds.includes(e[0]));
                    const area = RefdataUtils.getLocationArea(location);
                    const value = lodash.sum(e[1].flatMap(d => this.measurementTypes()
                        .flatMap(m => this.getMeasurementsForType(d.measurements, m) || [])
                        .map(r => r.value)));
                    const estimatedValue = lodash.sum(e[1].flatMap(d => this.measurementTypes()
                        .flatMap(m => this.getMeasurementsForType(d.estimatedMeasurements, m) || [])
                        .map(r => r.value)));
                    return location ? {
                        entityId: e[0],
                        value: this.showPerSquareMeter() ? (area ? lodash.round(value / area, 1) : null) : value,
                        estimatedValue: this.showPerSquareMeter() ? (area ? lodash.round(estimatedValue / area, 1) : null) : estimatedValue,
                        label: RefdataUtils.addressFormatter(location.info.address, false)
                    } : null;
                }).filter(e => e);
        }))
    }

    getAllEntities(): Observable<Location[]> {
        return RefdataUtils.getAllLocations();
    }

    navigateToEntity = (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
        const points = chart.getElementsAtEventForMode(event.native, 'nearest', {intersect: true}, true);

        if (points && elements.length > 0) {
            const clickedRecord: PerformanceRecord = this.currentPageRecords[points[0].index];
            const locationDashboard: Dashboard = LocationDashboardComponent.defaultDashboard();
            locationDashboard.info.sources.locationIds = [clickedRecord.entityId];
            this.router.navigateByUrl(`/location/dashboard/${DashboardContext.dashboardToBase64(locationDashboard)}`);
        }
    }

    onHover = (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
        const target: any = event.native.target;
        target.style.cursor = elements.length > 0 ? "pointer" : "auto";
    }

    private getMeasurementsForType(m: { [P in DataType]?: AggregatedDataPoint[] }, dataType: DataType): AggregatedDataPoint[] {
        const measurements = m[dataType] || [];
        if (dataType === DataType.heatConsumption) {
            return measurements.map(m => (<AggregatedDataPoint>{
                timeRange: m.timeRange,
                value: m.value * DashboardContext.gjToM3Rate
            }));
        }
        return measurements;
    }
}
