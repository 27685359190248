import {Component, ElementRef, ViewChild} from '@angular/core';
import {Attachment, GetMyReports, Report} from "@flowmaps/flowmaps-typescriptmodels";
import {ActivatedRoute, Router} from "@angular/router";
import {sendQuery} from "../../../flux/flux-utils";
import {ReportHtmlToPdfComponent} from "../report-html-to-pdf/report-html-to-pdf.component";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {combineLatest, map, mergeMap, Observable} from "rxjs";
import {ExtendedConnection} from "../../refdata/connections/connections-overview/connections-overview.component";
import {SourcesProvider} from "../../../utils/source-providers/sources-provider";
import {SourceType} from "../../dashboard/dashboard.context";
import {writeBulkMonthDataAsExcel} from "../../refdata/connections/connections-list/bulk-data-download";
import moment from "moment";
import {writeConnectionsAsExcel} from "../../refdata/connections/connections-list/connections-list-excel";

@Component({
    selector: 'app-report-runner',
    templateUrl: './report-runner.component.html',
    styleUrls: ['./report-runner.component.scss']
})
export class ReportRunnerComponent {
    reportId: string;
    report: Report;
    requestId: number;
    target: string;

    @ViewChild(ReportHtmlToPdfComponent) htmlToPdfComponent: ReportHtmlToPdfComponent;

    constructor(private router: Router, private route: ActivatedRoute, private elementRef: ElementRef) {
        route.params.subscribe(params => {
            route.queryParams.subscribe(queryParams => {
                console.info("Report page has been opened");

                this.reportId = queryParams['reportId'];
                this.requestId = queryParams['requestId'];
                this.target = queryParams['target'];

                if (!this.reportId) {
                    router.navigateByUrl("/report/default");
                    return;
                }

                sendQuery("com.flowmaps.api.reporting.GetMyReports", <GetMyReports>{}, {caching: false})
                    .subscribe((reports: Report[]) => {
                        this.report = reports.find(r => r.reportId === this.reportId);
                    });
            });
        });
    }

    sendReport = async () => {
        console.info("Creating PDF");
        let jsPDF, pdfFile, error;
        try {
            jsPDF = await this.htmlToPdfComponent.createPdf();
            pdfFile = await this.blobToBase64(jsPDF.output('blob')) as string;
        } catch (e) {
            error = e;
        }
        const sourceProvider = this.htmlToPdfComponent.chartDataProvider.dataProvider.sourceProvider;
        const diffInMonths = moment(this.htmlToPdfComponent.timeRange.end).diff(moment(this.htmlToPdfComponent.timeRange.start), 'month', true);
        const attachments: Observable<Attachment>[] = diffInMonths >= 1
            ? [writeBulkMonthDataAsExcel(sourceProvider, this.htmlToPdfComponent.timeRange).pipe(map(d => ({
                fileName: "bulk-data-monthly.xlsx",
                mediaType: "application/vnd.ms-excel",
                base64Data: d
            })))] : [getConnectionsExcel(sourceProvider, writeConnectionsAsExcel).pipe(map(c => ({
                fileName: "selected-connections.xlsx",
                mediaType: "application/vnd.ms-excel",
                base64Data: c
            })))];
        combineLatest(attachments).subscribe(attachments => {
            const container = document.createElement('div');
            container.id = "report-result";
            const allAttachments = attachments.concat({
                fileName: "report.pdf",
                mediaType: "application/pdf",
                base64Data: pdfFile.substring(pdfFile.indexOf(',') + 1)
            });
            container.setAttribute("data-attachments", JSON.stringify(allAttachments));
            if (error) {
                container.setAttribute("data-error", error);
            }
            this.elementRef.nativeElement.appendChild(container);
            console.info("Attachments added to DOM");
        });
    }

    blobToBase64 = (blob) => new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export function getConnectionsExcel(sourcesProvider: SourcesProvider<any>, writeMethod: (connections: ExtendedConnection[]) => any): Observable<any> {
    return RefdataUtils.getMyOrganisations()
        .pipe(map(orgs => orgs.flatMap(o => o.locations
            .flatMap(l => l.connections.map(c => (<ExtendedConnection>{
                intermediaryId: o.intermediaryId,
                organisationId: o.organisationId,
                locationId: l.locationId,
                organisationInfo: o.info,
                locationInfo: l.info,
                connection: c
            }))))))
        .pipe(map(cons =>
            sourcesProvider.getAllSourcesByType(SourceType.connection)
                .map(s => cons.find(c => c.connection.connectionId === s.id))
                .filter(s => s)))
        .pipe(mergeMap(s => writeMethod(s) || s));
}