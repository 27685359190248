import {RequestGateway} from './request-gateway';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, shareReplay} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AppContext} from '../app-context';

@Injectable()
export class QueryGateway extends RequestGateway {
  private cache: Map<string, any> = new Map();

  constructor(http: HttpClient) {
    super(http, '/api/query');
  }

  override send(type: string, payload: any, options?: QueryOptions): Observable<any> {
    options = options || {
      caching: true,
      showSpinner: false
    };
    let o: Observable<any>;
    if (options.caching) {
      const key = type + JSON.stringify(payload);
      const cachedResponse = this.cache.get(key);
      if (cachedResponse) {
        return cachedResponse;
      }
      console.log(`Querying: ${type}, payload: `, payload);
      this.cache.set(key, o = super.send(type, payload, options).pipe(shareReplay(1)));
      o = o.pipe(tap(() => {}, () => this.cache.delete(key)));
    } else {
      console.log(`Querying: ${type}, payload: `, payload);
      o = super.send(type, payload, options);
    }
    if (!options.hideError) {
      o = o.pipe(tap({error: e => AppContext.registerError(e)}));
    }
    if (options.showSpinner) {
      o = AppContext.waitForProcess(o);
    }
    return o;
  }

  removeFromCache(type: string, payload?: any) {
    let key = type;
    if (payload) {
      key += JSON.stringify(payload);
    }
    Array.from(this.cache.keys()).filter(k => k.startsWith(key)).forEach(k => this.cache.delete(k));
  }

  clearCache() {
    this.cache = new Map();
  }

  private handleError(e: HttpErrorResponse) {
  }
}

export interface QueryOptions {
  caching?: boolean;
  showSpinner?: boolean;
  hideError?: boolean;
  responseType?: string;
}
