import {Component, Input} from '@angular/core';
import {OrganisationInfo} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
    selector: 'app-organisation-info',
    templateUrl: './organisation-info.component.html',
    styleUrls: ['./organisation-info.component.scss']
})
export class OrganisationInfoComponent {
    @Input() organisationInfo: OrganisationInfo;

}
