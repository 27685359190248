import {AfterViewInit, Component, ElementRef, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {
    AbstractOverviewComponent,
    addZeroFacetResults
} from '../../../../common/abstract-overview/abstract-overview.component';
import {AddError, Error, ErrorStatus, MuteErrors, ResolveErrors} from '@flowmaps/flowmaps-typescriptmodels';
import {RefdataUtils} from '../../../refdata/refdata-utils';
import moment from 'moment';
import {AppContext} from "../../../../app-context";
import {EventGateway} from "../../../../flux/event-gateway";
import {SentenceCasePipe} from "../../../../common/sentence-case.pipe";
import {DateFieldRange} from "../../../../common/date/date-range/date-field-range";
import {Observable, of} from "rxjs";
import {QueryGateway} from "../../../../flux/query-gateway";
import {ActivatedRoute, Router} from "@angular/router";
import {FacetFilters, FacetStats} from "../../../../common/facets/facet-filter/facet-filter.component";
import {AnimationTypes, ModalOptions} from '../../../../common/modal/modal.component';
import {openModal} from '../../../../app-utils';
import {ErrorNavigatorComponent} from '../error-navigator/error-navigator.component';
import {InjectorProvider} from '../../../../flux/injector-provider';

@Component({
    selector: 'app-errors-list',
    templateUrl: './errors-list.component.html',
    styleUrls: ['./errors-list.component.css'],

})
export class ErrorsListComponent extends AbstractOverviewComponent<Error> implements AfterViewInit {

    comparator = RefdataUtils.errorsComparator;
    muteDate: string;
    dateRange: DateFieldRange;
    @ViewChild("muteErrorTemplate") muteErrorTemplate: TemplateRef<any>;

    filterLocalStorageKey: string = "errors-list";
    ignoredFacets: string[] = ["errorId"];

    tomorrow = (): string => moment().startOf("day").add(1, "day").toISOString();

    constructor(protected eventGateway: EventGateway, private route: ActivatedRoute, private router: Router,
                private queryGateway: QueryGateway, private renderer: Renderer2, private elementRef: ElementRef) {
        super(eventGateway);
        const currentError = route.snapshot.queryParamMap.get("errorId");
        if (currentError) {
            this.openErrorDetailsModal(currentError, true)
        }
    }

    ngAfterViewInit(): void {
        this.setFilters(this.getFilters());
        this.reload();
    }

    onFacetValuesChange(facet: FacetStats, selectedValues: string[]) {
        super.onFacetValuesChange(facet, selectedValues);
        this.reload();
    }

    calculateTotals(): number {
        return this.data.reduce((sum, error) => sum + error.count, 0);
    }

    trackByForRecord(index: number, record: Error) {
        return record.errorId;
    }

    getErrorStatusConfig = (Error: Error): ErrorStatusConfig => {
        switch (Error.status) {
            case ErrorStatus.RESOLVED:
                return {
                    colorClass: "success",
                    icon: "bi-check-circle"
                };
            case ErrorStatus.MUTED:
                return {
                    colorClass: "muted",
                    icon: "bi-volume-mute"
                };
            default:
                return {
                    colorClass: this.badgeColorForType(Error),
                    icon: "bi-record-circle"
                };
        }
    }

    openErrorDetails(event: MouseEvent, errorId: string): void {
        event.preventDefault();
        this.openErrorDetailsModal(errorId, true);
        this.router.navigate(['/errors'], {queryParams: {errorId}});
    }

    onContextMenu(event: MouseEvent, errorId: string): void {
    }

    formatTimestamp = (date: string) => moment(date).format(AppContext.displayFormat);

    badgeColorForType = (Error: Error) => {
        if (Error.status === ErrorStatus.RESOLVED) {
            return "light";
        }
        switch (Error.errorDetails.level) {
            case "error":
                return "danger";
            case "warning":
                return "warning";
            default:
                return "info";
        }
    }

    isResolved = (Error: Error): boolean => ErrorStatus.RESOLVED == Error.status;
    isMuted = (Error: Error): boolean => ErrorStatus.MUTED == Error.status;

    facetNameFormatter = (name: string): string => {
        switch (name) {
            case "status":
                return "Status";
            case "errorDetails/environment":
                return "Environment";
            case "errorDetails/application":
                return "Application";
            case "errorDetails/level":
                return "Level";
            default:
                return SentenceCasePipe.formatWithSpaces(name);
        }
    }

    handleErrorClick(event: MouseEvent, errorId: string) {
        event.stopPropagation();
        this.openErrorDetails(event, errorId);
    }

    getFacetValueFormatter = (name: string): (value: string) => Observable<string> => {
        switch (name) {
            case "status":
                return v => of(RefdataUtils.enumFormatter(v));
            case "errorDetails/level":
                return v => of(RefdataUtils.enumFormatter(v));
            case "errorDetails/environment":
                return v => of(RefdataUtils.enumFormatter(v));
        }
    }

    getDefaultFacetFilters = (): FacetFilters => ({
        facets: {
            status: ["OPEN"]
        }
    });

    reloadOverview = () => {
        this.reload(true);
    }

    recordAdded(upsertCommand: AddError) {
        this.reload(true);
    }

    recordUpdated(upsertCommand: MuteErrors | ResolveErrors) {
        this.reload(true);
    }

    reload = (clearCache = false) => {
        if (clearCache) {
            AppContext.removeFromCache("com.flowmaps.api.monitoring.errors.GetErrors");
        }
        if (!this.dateRange?.start) {
            return;
        }
        RefdataUtils.getErrors(this.getFacetFilters(), this.dateRange)
            .subscribe(result => {
                this.data = result.errors;
                this.facetResult = addZeroFacetResults(result.facets, "status", "OPEN", "RESOLVED", "MUTED");
                this.loadCount++;
            });
    }

    openErrorDetailsModal = (errorId: string, fullScreen: boolean = false) => {
        if (errorId) {
            const modalConfig: ModalOptions = {
                cssClass: fullScreen ? "modal-dialog-centered  modal-xl" : "modal-dialog-centered modal-xl",
                style: fullScreen ? "height: 90vh; min-height: 90vh; width: 90vw; min-width: 90vw;" : "",
                animation: AnimationTypes.zoomIn,
                closeCallback: () => InjectorProvider.injector.get(Router).navigate([], {
                    queryParams: { 'errorId': null },
                    queryParamsHandling: 'merge'
                })
            };
            openModal(ErrorNavigatorComponent, {errorId: errorId}, modalConfig);
        }
    };
}

interface ErrorStatusConfig {
    colorClass: string;
    icon: string;
}