<div class="d-flex align-items-center min-vh-100 bg-auth">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-5 col-xl-4 my-5">

                <!-- Heading -->
                <h1 class="display-4 text-center mb-3">
                    Confirm user account
                </h1>

                <!-- Subheading -->
                <p class="text-muted text-center mb-5">
                    Please check your inbox at the e-mail address you provided to confirm your account.
                </p>

                <!-- Link -->
                <div class="text-center">
                    <small class="text-muted text-center">Did you not receive the confirmation e-mail? Check your
                        spam folder, or <a href="" (click)="resendConfirmationEmail();$event.preventDefault()">
                            resend the confirmation e-mail</a>.
                    </small>
                </div>
                <div class="text-center">
                    <small class="text-muted text-center">or</small>
                </div>
                <div class="text-center">
                    <small class="text-muted text-center"><a [routerLink]="'/'">go back to login page</a></small>
                </div>
            </div>
        </div> <!-- / .row -->
    </div>
</div>
