<nav class="mb-4">
  <div class="nav nav-tabs" role="tablist">
    <ng-content select=".nav-bar-prefix"></ng-content>
    <a [ngClass]="{active: tab == activeComponent}" class="nav-link me-4" *ngFor="let tab of tabs"
       (click)="activateTab(tab)" style="cursor: pointer">
      <ng-template [ngTemplateOutlet]="tab.tabHeader"></ng-template>
    </a>
    <ng-content select=".nav-bar-prefix"></ng-content>
  </div>
</nav>

<ng-template [ngTemplateOutlet]="activeComponent.templateRef"></ng-template>
