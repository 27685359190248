<app-form (submit)="updateInfo()">
    <div class="card">
        <div class="card-header">
            <h3 class="card-header-title">
                <app-connection-image [connectionType]="data.connection.info.connectionType"></app-connection-image>
                Sub meter information</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-form-field label="Label">
                        <input [(ngModel)]="data.meterDetails.label" type="text" class="form-control">
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Meter type">
                        <app-select [(ngModel)]="data.meterDetails.type" [options]="meterTypes" [selectedFormatter]="meterTypeFormatter"></app-select>
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Meter numbers" [renderOptional]="false">
                        <ng-container *ngFor="let m of data.meterDetails.meterNumbers">
                            <input disabled [ngModel]="m" type="text" class="form-control">
                        </ng-container>
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Covered area">
                        <input [(ngModel)]="data.meterDetails.area" type="number" class="form-control">
                    </app-form-field>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>