import {Component, Input, OnInit} from '@angular/core';
import {QueryGateway} from "../../../../flux/query-gateway";
import {ConsumptionTax, CreateConsumptionTax} from "@flowmaps/flowmaps-typescriptmodels";
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {closeModal} from "../../../../app-utils";
import {AppContext} from "../../../../app-context";
import {RefdataUtils} from "../../refdata-utils";
import {cloneDeep} from "lodash";
import {uuid} from "../../../../common/utils";

@Component({
    selector: 'app-add-tax',
    templateUrl: './add-tax.component.html',
    styleUrls: ['./add-tax.component.css']
})
export class AddTaxComponent implements OnInit {
    @Input() data: ConsumptionTax;
    isNewRecord: boolean;

    constructor(private queryGateway: QueryGateway) {
        if (!this.data) {
            this.data = AddTaxComponent.newTax();
        }
    }

    static newTax = (): ConsumptionTax => {
        return {
            taxId: uuid(),
            taxInfo: {},
            taxPeriods: []
        }
    }

    ngOnInit(): void {
        this.isNewRecord = !this.data || !this.data.taxId;
    }

    connectionTypes: string[] = ["Electricity", "Gas", "Heat", "Water", "Cooling"];

    updateInfo = () => {
        const command: CreateConsumptionTax = {
            taxId: this.data.taxId,
            info: this.data.taxInfo,
        }
        sendCommand("com.flowmaps.api.organisation.CreateConsumptionTax", cloneDeep(command), () => {
            closeModal();
            this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyConsumptionTaxes");
            publishEvent("taxUpdated", command)
        });
    }

    protected readonly appContext = AppContext;
    protected readonly refDataUtils = RefdataUtils;
}
