import {ChangeDetectorRef, Component, Input, OnInit} from "@angular/core";
import {AppContext, CompletenessInfo} from "../../app-context";
import {ChartDataset} from "chart.js";
import {
    BuildingType,
    Connection,
    ConnectionType,
    DataType,
    MeterType,
    TimeRange
} from "@flowmaps/flowmaps-typescriptmodels";
import {cloneObject, localTimeFormat, lodash} from "../../common/utils";
import {DashboardContext, SourceType} from "../dashboard/dashboard.context";
import moment from "moment/moment";
import {ChartDataPerMeasurement, MeasurementsDataProvider} from "../../utils/measurements-data-provider";
import {cloneDeep} from "lodash";
import {closeModal, openModal} from "src/app/app-utils";
import {AnimationTypes} from "../../common/modal/modal.component";
import {ChartDataProvider} from "../../utils/chart-data-provider";
import {RefdataUtils} from "../refdata/refdata-utils";
import {combineLatest} from "rxjs";
import {ChartCompare, ChartOptions, ChartOptionType} from "../dashboard/dashboard.types";
import {SourceInfo} from "../../utils/source-providers/sources-provider";
import {ChartUtilsService} from "./chart-utils.service";
import {TooltipModel} from "chart.js/dist/types";
import {TranslateDirective} from "../../common/translate.directive";
import {Rgba} from "../../common/rgba";

@Component({
    template: "",
})
export abstract class BaseMeasurementChartComponent implements OnInit {
    appContext = AppContext;
    context = DashboardContext;
    _dataOptions: ChartMeasurementData;
    @Input() showInReport: boolean;

    chartDataProvider: ChartDataProvider = new ChartDataProvider();
    _data: MeasurementDataset[];
    _rawData: MeasurementDataset[];
    _rawEstimatedData: MeasurementDataset[];
    _rawPreviousData: MeasurementDataset[];
    _rawPreviousEstimatedData: MeasurementDataset[];

    weatherData: { [key: string]: MeasurementDataset } = {};
    previousWeatherData: { [key: string]: MeasurementDataset } = {};
    weatherTypes: DataType[] = [
        DataType.temperature,
        DataType.windSpeed,
        DataType.rainfall,
        DataType.sunHours,
        DataType.solarRadiation
    ];

    compareYearOptions: ChartCompare[] = [{
        comparedYear: 1,
        enabled: true,
        relative: true
    }].concat(AppContext.getYearsFrom(moment().year() - DashboardContext.maximumYears).map(y => ({
        comparedYear: y,
        enabled: true,
        relative: false
    })).reverse());

    completenessInfo: ChartCompleteness;

    @Input()
    set data(dataOptions: ChartMeasurementData) {
        this._dataOptions = dataOptions;
        this.subscribeToData();
    }

    compareFormatter = (c: ChartCompare) => c.relative
        ? (c.comparedYear === 1
            ? TranslateDirective.getTranslation("Last year", true)
            : `${c.comparedYear} ${TranslateDirective.getTranslation("years ago", true)}`)
        : `${c.comparedYear}`;

    compareEquals = (c: ChartCompare, o: ChartCompare) => c && o && c.relative === o.relative && c.comparedYear === o.comparedYear;

    isDataTypeSelected = (type: DataType): boolean => this.options.selectedWeatherTypes.includes(type);

    abstract measurementTypes(): DataType[];

    abstract connectionType(): ConnectionType;

    abstract productionDataTypes(): DataType[];

    abstract consumptionProductionLink(): Map<DataType, DataType>;

    abstract measurementUnit(): string;

    abstract measurementIntermediateLink(): Map<DataType, DataType[]>;

    completenessTypes = (): ConnectionType[] => [this.connectionType()];

    optionsType = (): ChartOptionType => this.connectionType() as any as ChartOptionType;

    showConsumption = (): boolean => this.options.showConsumption || this.options.showConsumption === undefined;

    showFeedIn = (): boolean => this.options.showFeedIn || this.options.showFeedIn === undefined;

    showDataNormally = (): boolean => false;

    measurementTypesMapped = (): { [key: string]: DataType[] } => {
        const measurementTypes = this.measurementTypes();
        return {
            [measurementTypes[0]]: measurementTypes
        };
    }

    productionDataTypesMapped = (): { [key: string]: DataType[] } => {
        const measurementTypes = this.productionDataTypes();
        return {
            [measurementTypes[0]]: measurementTypes
        };
    }

    addConnectionTypeSpecificData(data: MeasurementDataset[]) {
    };

    get options(): ChartOptions {
        const chartOption = (this._dataOptions.dataProvider.info.chartOptions || {})[this.optionsType()];
        return chartOption || DashboardContext.defaultDashboard().info.chartOptions[this.optionsType()];
    }

    getWeatherInputId = (weatherType: DataType) => this.getInputId('checkbox-' + weatherType + '-' + this.optionsType());

    private uniqueId = lodash.uniqueId();
    getInputId = (id: string) => `${this.uniqueId}-${id}`;

    constructor(protected changeDetectorRef: ChangeDetectorRef, protected chartUtils: ChartUtilsService) {
    }

    ngOnInit(): void {
        this.subscribeToData();
    }

    private subscribeToData() {
        this._dataOptions.dataProvider.subscribeToSourceUpdates(
            sources => this.updateGroupByEntityIdPossible(this._dataOptions.dataProvider.sourceProvider.getSelection()));
        this._dataOptions.dataProvider.subscribeToData(r => {
            this.setData(r.currentData,
                r.currentData.measurements ? Object.values(r.currentData.measurements).flatMap(m => m) : null,
                r.currentData.estimatedMeasurements ? Object.values(r.currentData.estimatedMeasurements).flatMap(m => m) : null);
            this.updateCompleteness();
        });
    }

    private updateGroupByEntityIdPossible(sources: SourceInfo[]) {
        if (!this.options) {
            return;
        }
        this.options.groupByEntityIdPossible = sources.some(s => s.type === SourceType.meter
            && [MeterType.INTERMEDIATE, MeterType.GROSS_PRODUCTION].includes(s.meterType)
            && (<Connection>s.source.connection).info.connectionType === this.connectionType());
        if (this._rawData) {
            this.refreshData(true);
        }
    }

    setData(result: ChartDataPerMeasurement, measurements: MeasurementDataset[], estimatedMeasurements: MeasurementDataset[]) {
        const emitData = !this.options.compare.enabled;
        this._rawData = measurements;
        this._rawEstimatedData = estimatedMeasurements;
        this.refreshData(emitData);
        this.addWeatherData(this._dataOptions.dataProvider.info.timeRange, false, true, emitData);
        if (this.options.compare.enabled) {
            this.getData(this._dataOptions.dataProvider.getForYear(this._dataOptions.dataProvider.getComparedYear(this.options.compare)), true);
        }
    }

    private processConsumptionData(consumptionData: MeasurementDataset[], allData: MeasurementDataset[]) {
        const link = this.measurementIntermediateLink();
        if (!this.groupByEntityIdEnabled() || !consumptionData.some(c => link.has(c.measurementType))) {
            return consumptionData;
        }
        const consumptionDataAggregated = this.getAggregatedConsumptionData(consumptionData, "y1");
        let data: MeasurementDataset[] = [];
        link.forEach((value, key) => {
            const intermediateConsumptions = allData
                .filter(m => value.includes(m.measurementType));
            let mainConsumptionSet = consumptionDataAggregated.find(c => c.measurementType === key);
            if (mainConsumptionSet) {
                mainConsumptionSet = cloneDeep(mainConsumptionSet);
                mainConsumptionSet.dataset.order = 10000;
                mainConsumptionSet.dataset.tooltip.data = cloneDeep(mainConsumptionSet.dataset.data as number[]);
                this.applyDiagonalPattern(mainConsumptionSet, mainConsumptionSet.dataset.backgroundColor as string, 'rtl');
                intermediateConsumptions.forEach(d => {
                    d.dataset.data.forEach((d, i) => {
                        mainConsumptionSet.dataset.data[i] = Math.max((mainConsumptionSet.dataset.data[i] as number) - (d as number), 0);
                    });
                });
                data = data.concat(mainConsumptionSet);
            }
        });
        return data;
    }

    private applyDiagonalPattern(d: MeasurementDataset, color: string = d.dataset.backgroundColor as string, direction: 'ltr' | 'rtl' = 'ltr') {
        d.dataset.backgroundColor = this.createDiagonalPattern(color, direction);
        d.dataset.borderColor = color;
        d.dataset.borderWidth = 1;
    }

    private processProductionData(productionData: MeasurementDataset[]) {
        return productionData.map(p => {
            const d = cloneDeep(p);
            if (this.options.nettedConsumption) {
                this.applyDiagonalPattern(d, "#adadad");
                d.dataset.tooltip.valuesInverted = false;
            } else {
                d.dataset.data = d.dataset.data.map(r => -r);
            }
            return d;
        });
    }

    private applyProductionData(dataset: MeasurementDataset[], productionDataset: MeasurementDataset[]): MeasurementDataset[] {
        if (!this.options.nettedConsumption) {
            return dataset;
        }
        return cloneDeep(dataset).map(d => {
            const productionType = this.consumptionProductionLink().get(d.measurementType);
            if (productionType) {
                const prodDataset = productionDataset.find(s => s.measurementType === productionType);
                if (prodDataset) {
                    d.dataset.data = d.dataset.data.map((d, i) => (d as number) - (prodDataset.dataset.data[i] as number));
                    d.dataset.label = d.measurementType === DataType.electricityConsumption ? "Net consumption" : "Net consumption off-peak";
                }
            }
            return d;
        })
    }

    private getAggregatedConsumptionData(dataset: MeasurementDataset[], axis: string) {
        const mappedData = this.measurementTypesMapped();
        return Object.entries(mappedData).flatMap(e => {
            return this.getAggregatedData(dataset.filter(d => e[1].includes(d.measurementType)), axis, e[0] as DataType);
        });
    }

    private getAggregatedProductionData(dataset: MeasurementDataset[], axis: string) {
        const mappedData = this.productionDataTypesMapped();
        return Object.entries(mappedData).flatMap(e => {
            return this.getAggregatedData(dataset.filter(d => e[1].includes(d.measurementType)), axis, e[0] as DataType);
        });
    }

    private getAggregatedData(dataset: MeasurementDataset[], axis: string, m: DataType): MeasurementDataset[] {
        const axisData = dataset.filter(d => d && d.dataset["yAxisID"] === axis);
        if (axisData.length > 0) {
            const aggregatedData = cloneObject(axisData.find(a => a.measurementType === m) || axisData[0]);
            aggregatedData.dataset["yAxisID"] = axis + "Aggregated";
            aggregatedData.dataset.data = aggregatedData.dataset.data.map((r, i) =>
                lodash.sum(axisData.flatMap(r => r.dataset.data[i])));
            return [aggregatedData];
        }
        return [];
    }

    splitOffPeakChange(splitOffPeak: boolean) {
        this.options.splitOffPeak = splitOffPeak;
        this.refreshData(true);
    }

    protected refreshData(emitData: boolean) {
        this._data = this.getConsumptionAndProductionData(this._rawData)
            .concat(this.getConsumptionAndProductionData(this._rawEstimatedData));
        if (this._rawPreviousData && this.options.compare.enabled) {
            this._data = this._data.concat(this.getConsumptionAndProductionData(this._rawPreviousData))
                .concat(this.getConsumptionAndProductionData(this._rawPreviousEstimatedData));
            const previousData = this._dataOptions.dataProvider.dashboardData.previousData;
            if (this.options.showCompleteness && previousData?.completeness) {
                this._data = this._data.concat(this.computeCompleteness(previousData.completeness, true,
                    this._dataOptions.dataProvider.getComparedYear(this.options.compare)));
            }
        }
        if (this.options.showCompleteness && this._dataOptions.dataProvider.dashboardData.currentData?.completeness) {
            this._data = this._data.concat(this.computeCompleteness(this._dataOptions.dataProvider.dashboardData.currentData.completeness));
        }
        this._data = this._data.concat(Object.values(this.weatherData));
        if (this.options.compare.enabled) {
            this._data = this._data.concat(Object.values(this.previousWeatherData));
        }
        this.showCorrectAggregatedData();
        this.addConnectionTypeSpecificData(this._data);
        if (emitData) {
            this.chartDataProvider.emit({
                datasets: this._data
            });
        }
    }

    private computeCompleteness = (completeness: CompletenessInfo[], isPrevious: boolean = false, comparedYear: number = null): MeasurementDataset => {
        let color = Rgba.fromString("rgba(163, 98, 201, 100)");
        if (isPrevious) {
            color = color.tint(0.25).greyScale();
        }
        const completenessTypes = this.completenessTypes();
        const allConnectionsSelected = this._dataOptions.dataProvider.sourceProvider.getAllSourcesByType(SourceType.connection)
            .map(s => s.source as Connection)
            .filter(s => completenessTypes.includes(s.info.connectionType as ConnectionType));
        const selection = allConnectionsSelected.map(s => s.connectionId);
        const completenessRecords = completeness
            .filter(c => selection.includes(c.connectionId))
            .filter(c => completenessTypes.includes(c.connectionType))
            .flatMap(c => c.meterCompleteness.map(c => c.meterView)
                .filter(m => m));
        const timeRange = isPrevious ? this._dataOptions.dataProvider.getPreviousDateTimeRange() : this._dataOptions.dataProvider.info.timeRange;
        return {
            dataset: {
                type: "line",
                borderDash: [5],
                order: 10000,
                yAxisID: "completeness",
                spanGaps: false,
                label: TranslateDirective.getTranslation("Completeness", true),
                borderColor: color.toString(),
                backgroundColor: color.toString(),
                pointBorderColor: color.toString(),
                data: MeasurementsDataProvider.getSlots(timeRange, this._dataOptions.dataProvider.info.resolution)
                    .map(i => {
                        return lodash.round(AppContext.computeCompleteness(completenessRecords, {
                            start: moment(i.startTime).toISOString(),
                            end: moment(i.endTime).toISOString()
                        }, allConnectionsSelected.filter(c => !c.meters.length)), 3);
                    }),
                stack: isPrevious ? DashboardContext.stacks.lastYear : DashboardContext.stacks.currentPeriod,
                tooltip: {
                    stack: isPrevious ? DashboardContext.stacks.lastYear : DashboardContext.stacks.currentPeriod,
                    stackYear: comparedYear,
                    formatter: (ctx: TooltipModel<any>, value: number) => this.chartUtils.percentageFormatter(value),
                    higherValueIsBetter: true
                }
            }
        };
    }

    getDataOfMeasurements = (rawData: MeasurementDataset[], measurementTypes: DataType[]): MeasurementDataset[] =>
        rawData.filter(d => measurementTypes.includes(d.measurementType));

    private getConsumptionAndProductionData(rawData: MeasurementDataset[]): MeasurementDataset[] {
        return this.groupByEntityIdEnabled()
            ? Object.entries(lodash.groupBy(rawData, r => r.entityId))
                .flatMap(s => this.createDatasets(s[1], rawData))
            : this.createDatasets(rawData, rawData);
    }

    private createDatasets(dataset: MeasurementDataset[], rawData: MeasurementDataset[]) {
        const consumptionData = this.processConsumptionData(this.getDataOfMeasurements(dataset, this.measurementTypes()), rawData);
        const prodData = this.processProductionData(this.getDataOfMeasurements(dataset, this.productionDataTypes()));
        const aggregatedProdData = this.getAggregatedProductionData(prodData, "y1");
        return this.applyProductionData(consumptionData, prodData)
            .concat(prodData)
            .concat(this.applyProductionData(this.getAggregatedConsumptionData(consumptionData, "y1"), aggregatedProdData))
            .concat(aggregatedProdData);
    }

    private showCorrectAggregatedData() {
        const axisKey = this.showDataNormally() ? "y1Aggregated" : "y1";
        this._data = this._data.filter(d => d && d.dataset["yAxisID"] !== axisKey);
    }

    get y2AxisTitle(): string {
        return this.options.selectedWeatherTypes?.length === 1
            ? DashboardContext.getMeasurementUnit(this.options.selectedWeatherTypes[0]) : null;
    }

    toggleWeather(type: DataType) {
        const options = this.options;
        if (options.selectedWeatherTypes.includes(type)) {
            options.selectedWeatherTypes = options.selectedWeatherTypes.filter(t => t !== type);
        } else {
            options.selectedWeatherTypes.push(type);
        }
        this.addWeatherData(this._dataOptions.dataProvider.info.timeRange, false);
        if (options.compare.enabled) {
            this.addWeatherData(this._dataOptions.dataProvider.getForYear(this._dataOptions.dataProvider.getComparedYear(this.options.compare)), true);
        }
    }

    compareChange(compare: boolean) {
        this.options.compare.enabled = compare;
        if (!this.options.compare.comparedYear) {
            this.options.compare.comparedYear = 1;
            this.options.compare.relative = true;
        }
        if (compare) {
            this.getData(this._dataOptions.dataProvider.getForYear(this._dataOptions.dataProvider.getComparedYear(this.options.compare)));
        } else {
            this._dataOptions.dataProvider.dashboardData.previousData = {};
            this.refreshData(true);
        }
    }

    comparedYearChange(compareInfo: ChartCompare) {
        this.options.compare = compareInfo
        this.options.compare.enabled = true;
        this.getData(this._dataOptions.dataProvider.getForYear(this._dataOptions.dataProvider.getComparedYear(this.options.compare)), true);
    }

    showPowerChange(showPower: boolean) {
        this.options.showPower = showPower;
        this.options.showPowerForEan = null;
        this.refreshData(true);
    }

    showPowerForEanChange(ean: string) {
        this.options.showPower = true;
        this.options.showPowerForEan = ean;
        this.refreshData(true);
    }

    groupByEntityIdChange(groupByEntityId: boolean) {
        this.options.groupByEntityId = groupByEntityId;
        this.options.splitOffPeak = false;
        this.refreshData(true);
    }

    showUncorrectedConsumptionChange(showUncorrectedConsumption: boolean) {
        this.options.showUncorrectedConsumption = showUncorrectedConsumption;
        this.refreshData(true);
    }

    showConsumptionChange(showConsumption: boolean) {
        this.options.showConsumption = showConsumption;
        this.options.nettedConsumption = showConsumption ? this.options.nettedConsumption : false;
        this.refreshData(true);
    }

    showFeedInChange(showFeedIn: boolean) {
        this.options.showFeedIn = showFeedIn;
        this.refreshData(true);
    }

    showGrossProductionChange(showGrossProduction: boolean) {
        this.options.showGrossProduction = showGrossProduction;
        this.refreshData(true);
    }

    nettedConsumptionChange(nettedConsumption: boolean) {
        this.options.nettedConsumption = nettedConsumption;
        this.refreshData(true);
    }

    showReactiveChange(showReactive: boolean) {
        this.options.showReactive = showReactive;
        this.refreshData(true);
    }

    showCompletenessChange(showCompleteness: boolean) {
        this.options.showCompleteness = showCompleteness;
        this.refreshData(true);
    }

    showBySquareMeterChange(showBySquareMeter: boolean) {
        this.options.showBySquareMeter = showBySquareMeter;
        this.refreshData(true);
    }

    groupByEntityIdEnabled = (): boolean => this.options.groupByEntityId && this.options.groupByEntityIdPossible;

    getData(timeRange: TimeRange, force: boolean = false) {
        const dataProvider = this._dataOptions.dataProvider;
        combineLatest([dataProvider.getDataForRange(timeRange), dataProvider.getCompleteness(timeRange), RefdataUtils.getMyOrganisations()])
            .subscribe((result) => {
                dataProvider.dashboardData.previousData = dataProvider.createChartData(result[0], result[1], result[2], timeRange, DashboardContext.stacks.lastYear, dataProvider.getComparedYear(this.options.compare));
                this._rawPreviousData = Object.values(dataProvider.dashboardData.previousData.measurements).flatMap(m => m)
                this._rawPreviousEstimatedData = Object.values(dataProvider.dashboardData.previousData.estimatedMeasurements).flatMap(m => m)
                this.refreshData(false);
                this.addWeatherData(timeRange, true, force, true);
            });
    }

    private addWeatherData(timeRange: TimeRange, isPrevious: boolean, force: boolean = false, refresh: boolean = true) {
        const stack = isPrevious ? DashboardContext.stacks.lastYear : DashboardContext.stacks.currentPeriod;
        const weatherData = isPrevious ? this.previousWeatherData : this.weatherData;
        const alreadyWeatherTypesDone = Object.keys(weatherData);
        this.options.selectedWeatherTypes?.filter(s => force || !alreadyWeatherTypesDone.includes(s))
            .forEach(s => {
                const color = DashboardContext.getMeasurementColor(stack, s);
                weatherData[s] = {
                    measurementType: s,
                    dataset: {
                        type: "line",
                        order: AppContext.indexOfDataType(s),
                        yAxisID: s,
                        spanGaps: false,
                        borderColor: color,
                        backgroundColor: color,
                        pointBorderColor: color,
                        label: AppContext.measurementName(s),
                        data: this._dataOptions.dataProvider.getChartDataForMeasurement(s, {
                            start: moment(timeRange.start).format(localTimeFormat),
                            end: moment(timeRange.end).format(localTimeFormat)
                        }, isPrevious).data,
                        stack: stack,
                        tooltip: {
                            formatter: this.chartUtils.getCustomTooltipFormatter(s),
                            stackYear: isPrevious ? this._dataOptions.dataProvider.getComparedYear(this.options.compare) : null
                        }
                    }
                };
            });

        alreadyWeatherTypesDone.filter(s => !this.options.selectedWeatherTypes.includes(s as DataType))
            .forEach(m => {
                delete weatherData[m];
            });

        this.refreshData(refresh);
    }

    openModalWithType(type: typeof BaseMeasurementChartComponent) {
        const data = cloneDeep(this._dataOptions);
        data.fullScreen = true;
        data.dataProvider = data.dataProvider.copy();
        data.dataProvider.chartUtils = this.chartUtils;
        data.dataProvider.ranges = DashboardContext.defaultRanges;
        openModal(type, data, {
            cssClass: "modal-dialog-centered modal-xl",
            style: "height: 90vh; min-height: 90vh; width: 90vw; min-width: 90vw;",
            animation: AnimationTypes.zoomIn
        });
        setTimeout(() => data.dataProvider.sourceProvider.collectData(), 0);
    }

    closeModal = () => closeModal();

    getSelectedConnections = (c: ConnectionType = null): Connection[] => {
        const connectionType: string = c || this.connectionType();
        return lodash.uniq(lodash.flatMap(this._dataOptions.dataProvider.sourceProvider.getTreeViewSelection().map(s => s.info), s => {
            switch (s.type) {
                case 'meter' :
                    return [];
                case 'connection' :
                    return s.source?.info?.connectionType == connectionType ? [s.source] : [];
                case 'location' :
                    return s.source?.connections.filter(c => c.info?.connectionType == connectionType);
                case 'organisation' :
                    return lodash.flatMap(s.source?.locations, l => l.connections.filter(c => c.info?.connectionType == connectionType));
            }
        }));
    }

    hasMultipleConnectionsSelected = (): boolean => this.getSelectedConnections().length !== 1;

    private updateCompleteness = () => {
        if (!this._dataOptions.dataProvider.dashboardData.currentData.completeness) {
            this.completenessInfo = null;
        }
        this.completenessInfo = {
            title: AppContext.completenessTitle(this._dataOptions.dataProvider.dashboardData.currentData.completeness,
                this._dataOptions.dataProvider.sourceProvider.getAllSourcesByType(SourceType.connection), this.completenessTypes()),
            color: AppContext.completenessColor(this._dataOptions.dataProvider.dashboardData.currentData.completeness,
                this._dataOptions.dataProvider.sourceProvider.getAllSourcesByType(SourceType.connection), this.completenessTypes()),
            completeness: AppContext.completeness(this._dataOptions.dataProvider.dashboardData.currentData.completeness,
                this._dataOptions.dataProvider.sourceProvider.getAllSourcesByType(SourceType.connection), this.completenessTypes())
        };
        this.changeDetectorRef.detectChanges();
    }

    toggleShowAsTable() {
        this.options.showAsTable = !this.options.showAsTable;
        setTimeout(() => this.chartDataProvider.aggregatedDataChanged.emit({
            datasets: this._data
        }), 0);
    }

    createDiagonalPattern(color = 'black', direction: 'ltr' | 'rtl' = 'ltr'): CanvasPattern {
        let shape = document.createElement('canvas');
        shape.width = shape.height = 10;
        let c = shape.getContext('2d');
        c.strokeStyle = color;
        c.beginPath();
        if (direction === "ltr") {
            c.moveTo(2, 0);
            c.lineTo(10, 8);
            c.stroke();
            c.beginPath();
            c.moveTo(0, 8);
            c.lineTo(2, 10);
        } else {
            c.moveTo(8, 0);
            c.lineTo(0, 8);
            c.stroke();
            c.beginPath();
            c.moveTo(10, 8);
            c.lineTo(8, 10);
        }
        c.stroke();
        return c.createPattern(shape, 'repeat');
    }
}

export interface ChartMeasurementData {
    dataProvider: MeasurementsDataProvider<any>;
    stacked?: boolean;
    fullScreen?: boolean;
}

export interface MeasurementData {
    labels?: string[];
    datasets: MeasurementDataset[];
}

export interface MeasurementDataset {
    measurementType?: DataType;
    entityId?: string;
    estimated?: boolean;
    dataset: ChartDatasetExtended;
}

export interface ChartDatasetExtended extends ChartDataset<'line'> {
    measurementType?: DataType;
    buildingType?: BuildingType;
    tooltipLabels?: string[];
    tooltip?: ChartDatasetTooltip;
}

interface ChartDatasetTooltip {
    stack?: string;
    valuesInverted?: boolean;
    higherValueIsBetter?: boolean;
    estimated?: boolean;
    stackYear?: number;
    formatter?: (ctx: TooltipModel<any>, value: number, index: number) => string;
    labelOverride?: string | string[];
    borderColors?: string[];
    data?: number[];
}

interface ChartCompleteness {
    completeness: number;
    color: string;
    title: string;
}