import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Connection, LocationInfo, OrganisationInfo} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../refdata-utils";
import {map} from "rxjs";
import {QueryGateway} from "../../../../flux/query-gateway";
import {EventGateway} from "../../../../flux/event-gateway";

@Component({
    selector: 'app-connections-overview',
    templateUrl: './connections-overview.component.html',
    styleUrls: ['./connections-overview.component.scss']
})
export class ConnectionsOverviewComponent implements OnInit, OnDestroy {
    connections: ExtendedConnection[];
    loadCount: number = 0;

    private readonly registration: () => void;

    constructor(protected queryGateway: QueryGateway, protected eventGateway: EventGateway) {
        this.registration = this.eventGateway.registerLocalHandler(this);
    }

    ngOnDestroy(): void {
        this.registration();
    }

    ngOnInit() {
        this.reload();
    }

    reloadOverview = () => {
        this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
        this.reload();
    }

    reload = () => RefdataUtils.getMyOrganisations()
        .pipe(map(orgs => orgs.flatMap(o => o.locations.flatMap(l => l.connections.map(c => (<ExtendedConnection>{
            intermediaryId: o.intermediaryId,
            organisationId: o.organisationId,
            locationId: l.locationId,
            organisationInfo: o.info,
            locationInfo: l.info,
            connection: c
        }))))))
        .subscribe(m => {
            this.connections = m;
            this.loadCount++;
        });
}

export interface ExtendedConnection {
    intermediaryId: string;
    organisationId: string;
    locationId: string;
    locationInfo: LocationInfo;
    organisationInfo: OrganisationInfo;
    connection: Connection;
}