import {ActivatedRouteSnapshot, BaseRouteReuseStrategy} from "@angular/router";

export class RouteStrategyService extends BaseRouteReuseStrategy {

    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        if (future.routeConfig?.data) {
            return future.routeConfig.data.reUseRoute;
        }
        return super.shouldReuseRoute(future, current);
    }
}