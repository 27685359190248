<app-form (submit)="updateInfo()">
    <div class="card">
        <div class="card-header">
            <h3 *ngIf="isNew" class="card-header-title">New user</h3>
            <h3 *ngIf="!isNew" class="card-header-title notranslate">{{info.firstName + ' ' + info.lastName}}</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-form-field label="First name">
                        <input required [(ngModel)]="info.firstName" type="text" class="form-control">
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Last name">
                        <input required [(ngModel)]="info.lastName" type="text" class="form-control">
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Email">
                        <input required [disabled]="!isNew" [(ngModel)]="info.email" type="email" class="form-control">
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Language">
                        <app-select class="notranslate"
                                    required [options]="['en', 'nl']" [(ngModel)]="info.language"></app-select>
                    </app-form-field>
                </div>
                <ng-container *ngIf="isNew">
                    <div class="col-12 col-md-6">
                        <app-form-field label="Receive monthly report" [renderOptional]="false">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="createDefaultReport" [(ngModel)]="createDefaultReport"/>
                            </div>
                        </app-form-field>
                    </div>
                    <h6 class="header-pretitle mt-3">Authorisation</h6>
                    <div class="row m-0 p-0">
                        <div class="col-12 col-md-6">
                            <app-form-field label="Organisation">
                                <app-search [minCharacters]="0" [(ngModel)]="organisation" (ngModelChange)="setOrganisation($event)"
                                            [searchFunction]="refDataUtils.searchOrganisation" [inputFormatter]="refDataUtils.organisationFormatter"
                                            dataKey="info.name" placeholder="Search for an organisation" ></app-search>
                            </app-form-field>
                        </div>
                        <ng-container *ngIf="organisation">
                            <div class="col-12 col-md-6">
                                <app-form-field label="Role">
                                    <app-select required [options]="refDataUtils.authorisationOptions()"
                                                [(ngModel)]="authorisation.role"></app-select>
                                </app-form-field>
                            </div>
                            <div class="row col-12 col-md-6 pe-0">
                                <app-form-field class="col-6" label="Start">
                                    <app-date-field [(ngModel)]="authorisation.timeRange.start" [minDate]="getMinStartDate()" [dateOnly]="true"></app-date-field>
                                </app-form-field>
                                <app-form-field class="col-6 pe-0" label="End">
                                    <app-date-field [(ngModel)]="authorisation.timeRange.end" [minDate]="authorisation.timeRange.start" [dateOnly]="true"></app-date-field>
                                </app-form-field>
                            </div>
                            <div class="col-12">
                                <app-form-field class="col-6" label="Sources">
                                    <app-sources-selector positionClass="position-fixed" [sourceProvider]="sourceProvider" (updated)="updateEntityIds($event)"></app-sources-selector>
                                </app-form-field>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-primary">{{isNew ? 'Create user' : 'Save changes'}}</button>
            </div>
        </div>
    </div>
</app-form>