<app-form (submit)="updateConnectionContractPeriod()">
    <div class="card">
        <div class="card-header">
            <h3 class="card-header-title">
                <span>{{ isNewRecord ? 'Link contract' : 'Edit contract period' }}</span>
            </h3>
        </div>
        <div class="card-body">
            <div class="row mb-3">
                <div class="col-12 notranslate">
                    <app-form-field label="Contract">
                        <app-search [minCharacters]="0" [(ngModel)]="contract" required
                                    [searchFunction]="searchContract" dataKey="contractData.name"
                                    [inputFormatter]="refDataUtils.contractsFormatter"
                                    (ngModelChange)="onContractSelected($event)"
                                    [disabled]="!isNewRecord || (data.contract !== null && data.contract!==undefined)"
                                    placeholder="Search for a contract"></app-search>
                        <small *ngIf="contract && contract.contractData.connectionType.toString() !== connection.info.connectionType"
                               class="text-danger">Contract type does not match connection type</small>
                    </app-form-field>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <app-contract-range [dateRange]="data.linkedContract.dateRange"
                                        [isReadOnly]="false"></app-contract-range>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-between">
            <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
            <div>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>
