<app-form (submit)="sendCommand()">
    <div class="card">
        <div class="card-header">
            <h3  class="card-header-title">New alert</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-form-field label="Type">
                        <app-select required [options]="refdataUtils.alertTypeOptions()"
                                    [(ngModel)]="command.details.type"></app-select>
                    </app-form-field>
                </div>
                <div class="col-12">
                    <app-form-field label="Message">
                        <textarea required class="form-control" [(ngModel)]="command.details.message"></textarea>
                    </app-form-field>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-primary">Add alert</button>
            </div>
        </div>
    </div>
</app-form>