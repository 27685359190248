import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {RefdataUtils} from "../../refdata-utils";
import {ExtendedContract} from "../contracts-list/contracts-list.component";
import {QueryGateway} from "../../../../flux/query-gateway";
import {EventGateway} from "../../../../flux/event-gateway";

@Component({
    selector: 'app-contracts-overview',
    templateUrl: './contracts-overview.component.html',
    styleUrls: ['./contracts-overview.component.scss']
})
export class ContractsOverviewComponent implements OnDestroy, OnInit {

    contracts: ExtendedContract[];
    filteredContracts: ExtendedContract[];
    loadCount: number = 0;
    filterCriteria: string[] = [];

    private readonly registration: () => void;

    constructor(protected queryGateway: QueryGateway, protected eventGateway: EventGateway, private ngZone: NgZone,  private cdr: ChangeDetectorRef) {
        this.registration = this.eventGateway.registerLocalHandler(this);
    }

    ngOnDestroy(): void {
        this.registration();
    }

    reloadOverview = () => {
        this.reload();
    }

    reload = () => {
        this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
        this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyContracts");
        RefdataUtils.getMyContracts()
            .subscribe(
                l => {
                    this.contracts = l;
                    this.filteredContracts = this.applyFilter(l);
                    this.loadCount++;
                }
            );
    }

    applyFilter(contracts: ExtendedContract[]): ExtendedContract[] {
        if (this.filterCriteria.length > 0) {
            return contracts.filter(contract => this.filterCriteria.includes(contract.contractData.connectionType));
        }
        return this.contracts;
    }

    handleFilterChange(newFilterCriteria: string[]) {
        this.ngZone.run(() => {
            this.filterCriteria = newFilterCriteria;
            this.filteredContracts = this.applyFilter(this.contracts);
            this.cdr.detectChanges();
        });
    }

    ngOnInit() {
        RefdataUtils.getMyContracts()
            .subscribe(
                l => {
                    this.contracts = l;
                    this.filteredContracts = this.applyFilter(l);
                    this.loadCount++;
                }
            );
        this.registration()
        this.eventGateway.registerLocalHandler({reloadOverview: this.reloadOverview});
    }
}


