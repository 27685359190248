import {Component, ContentChildren, Input, QueryList} from '@angular/core';
import {BaseMeasurementChartComponent} from "../../charts/base-measurement-chart";

@Component({
  selector: 'app-report-html-page',
  templateUrl: './report-html-page.component.html',
  styleUrls: ['./report-html-page.component.scss']
})
export class ReportHtmlPageComponent {
  @Input() pageBreak: boolean;
  @ContentChildren(BaseMeasurementChartComponent) charts: QueryList<BaseMeasurementChartComponent>;
}
