import {Component, Input} from '@angular/core';
import {
    DeleteLocation,
    GetOrganisation,
    Location,
    MergeLocations,
    Organisation,
    OrganisationInfo,
    UpsertConnection,
    UpsertLocation
} from "@flowmaps/flowmaps-typescriptmodels";
import {closeModal, openConfirmationModalWithCallback, openModal} from "../../../../app-utils";
import {LocationComponentData, LocationDetailsComponent} from "../location-details/location-details.component";
import {
    ConnectionComponentData,
    ConnectionDetailsComponent
} from "../../connections/connection-details/connection-details.component";
import {AnimationTypes} from "../../../../common/modal/modal.component";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {AbstractOverviewComponent} from "../../../../common/abstract-overview/abstract-overview.component";
import {RefdataUtils} from "../../refdata-utils";
import {ExtendedConnection} from "../../connections/connections-overview/connections-overview.component";
import {AppContext} from '../../../../app-context';
import {publishEvent, sendCommand, sendQuery} from '../../../../flux/flux-utils';
import {DashboardContext} from '../../../dashboard/dashboard.context';
import {DndDropEvent} from "ngx-drag-drop";
import {
    ModalConfirmAutofocus,
    ModalConfirmAutofocusData
} from "../../../../common/modal-confirm/modal-confirm.component";
import {downloadLocationsExcel, uploadLocationsExcel} from './locations-list-excel';
import {EventGateway} from "../../../../flux/event-gateway";
import {QueryGateway} from "../../../../flux/query-gateway";

@Component({
    selector: 'app-locations-list',
    templateUrl: './locations-list.component.html',
    styleUrls: ['./locations-list.component.scss']
})
export class LocationsListComponent extends AbstractOverviewComponent<ExtendedLocation> {
    comparator: ComparatorChain = RefdataUtils.locationsComparator;
    @Input() organisationId: string;
    downloadLocationsAsExcel = downloadLocationsExcel;

    constructor(protected eventGateway: EventGateway, private queryGateway: QueryGateway) {
        super(eventGateway);
    }

    onDrop = (dropEvent: DndDropEvent, toLocation: ExtendedLocation) => {
        const fromLocation = dropEvent.data as ExtendedLocation;
        openConfirmationModalWithCallback((confirmed) => {
            if (confirmed) {
                sendCommand("com.flowmaps.api.organisation.MergeLocations", <MergeLocations>{
                    organisationId: this.organisationId,
                    locationId: toLocation.locationId,
                    oldLocationId: fromLocation.locationId
                }, () => {
                    sendQuery("com.flowmaps.api.organisation.GetOrganisation", <GetOrganisation>{
                        organisationId: this.organisationId
                    }, {caching: false})
                        .subscribe((o: Organisation) => {
                            this.data = o.locations.map(l => RefdataUtils.addOrganisationIdsToLocation(l, o));
                            this.dataChanged.emit(this.data);
                        });
                });
            }
        }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
            type: "warning",
            title: "Merge locations",
            innerHtmlMessage: `<p><span>You are about to merge location</span><span class="notranslate">:<br/><b>${RefdataUtils.locationsFormatter(fromLocation)}</b><br/></span> <span>into</span><span class="notranslate">:<br/><b>${RefdataUtils.locationsFormatter(toLocation)}</b></span></p>`,
            innerHtmlQuestion: `<p><span>Are you sure you want to move all connections to</span> <span class="notranslate"><b>${RefdataUtils.locationsFormatter(toLocation)}</b></span>?</p><p><span>This cannot be undone!</span></p>`,
            confirmText: "Yes",
            cancelText: "No"
        }, 'static');
    }

    authorisedForLocationMerge = () => !!this.organisationId && AppContext.isAdminOrIntermediary();

    sortedConnectionsForLocation = (location: ExtendedLocation) => location.connections.sort(RefdataUtils.connectionsComparator.compare)
        .map(c => (<ExtendedConnection>{
            organisationId: location.organisationId,
            intermediaryId: location.intermediaryId,
            locationId: location.locationId,
            locationInfo: location.info,
            connection: c,
            organisationInfo: null
        }));

    openLocationDetails = (location: ExtendedLocation) => openModal(LocationDetailsComponent, <LocationComponentData>{
        organisationId: location.organisationId,
        intermediaryId: location.intermediaryId,
        location: location
    }, {
        cssClass: "modal-xl",
        animation: AnimationTypes.fade
    });

    addConnectionToLocation = (location: ExtendedLocation) => openModal(ConnectionDetailsComponent, <ConnectionComponentData>{
        organisationId: location.organisationId,
        locationId: location.locationId,
        connection: null
    }, {
        cssClass: "modal-xl",
        animation: AnimationTypes.fade
    });

    openConnectionDetails = (connection: ExtendedConnection) => openModal(ConnectionDetailsComponent, <ConnectionComponentData>{
        organisationId: connection.organisationId,
        locationId: connection.locationId,
        connection: connection.connection
    }, {
        cssClass: "modal-xl",
        animation: AnimationTypes.fade
    });

    newLocation = (): ExtendedLocation => ({
        organisationId: this.organisationId,
        intermediaryId: null,
        organisationInfo: null
    });

    trackByForConnection: any = (index: number, item: ExtendedConnection) => {
        return item?.connection.connectionId;
    };

    trackByForRecord = (index: number, record: ExtendedLocation) => record.locationId;

    recordAdded(command: UpsertLocation | UpsertConnection) {
        const commandClass = command["@class"] as string;
        if (commandClass.endsWith("UpsertLocation")) {
            const upsertCommand = command as UpsertLocation;
            RefdataUtils.getOrganisation(upsertCommand.organisationId).subscribe(o => {
                this.addItem({
                    locationId: upsertCommand.locationId,
                    intermediaryId: o.intermediaryId,
                    organisationInfo: o.info,
                    info: upsertCommand.info,
                    organisationId: upsertCommand.organisationId,
                    connections: [],
                    refdata: upsertCommand.refdata
                });
            });
        } else if (commandClass.endsWith("UpsertConnection")) {
            const upsertCommand = command as UpsertConnection;
            RefdataUtils.getOrganisation(upsertCommand.organisationId).subscribe(o => {
                const location = this.data.find(l => l.locationId === upsertCommand.locationId);
                const savedLocation = o.locations.find(l => l.locationId === location.locationId);
                location.connections = savedLocation.connections;
            });
        }
    }

    deleteLocation = (location: ExtendedLocation) => sendCommand("com.flowmaps.api.organisation.DeleteLocation", <DeleteLocation>{
        organisationId: location.organisationId,
        locationId: location.locationId
    }, () => {
        this.removeItem(location);
        AppContext.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
    });

    onUploadLocationsList = (event: any) => {
        uploadLocationsExcel(event.files[0]).subscribe(command => {
            if (command.updates.length === 0) {
                AppContext.registerError("No changes have been found between uploaded file and current data",
                    'warning', 3000);
                return;
            }
            const locations = command.updates.map(c => RefdataUtils.locationInfoFormatter(c.info)).map(name => `<li>${name}</li>`).join("");
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    sendCommand("com.flowmaps.api.organisation.UpsertLocations", command, () => {
                        AppContext.registerSuccess('Locations were uploaded successfully');
                        closeModal();
                        this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
                        publishEvent("recordUpdated", command);
                    });
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "warning",
                title: "Bulk update locations",
                innerHtmlMessage: `<p><span>You are about to bulk update the following locations</span></p><ul class="notranslate fw-bold">${locations}</ul><p class="fw-bold">Are you sure you want to execute this action?</p>`,
                confirmText: "Update locations",
                cancelText: "Cancel"
            }, 'static');
        });
        event.value = '';
    }

    openLocationGraph = (location: ExtendedLocation) => AppContext.navigateToUrl('/location/dashboard/' + DashboardContext.dashboardToBase64(DashboardContext.locationDashboard(location.locationId)));
}

export interface ExtendedLocation extends Location {
    organisationInfo: OrganisationInfo;
    intermediaryId: string;
    organisationId: string;
}
