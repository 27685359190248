<div #downloadDataBar class="row mx-0 p-3 px-md-5 g-3 gy-md-0 justify-content-between">
    <div class="col-auto col-md-auto d-none d-md-flex align-items-center">
        <span class="notranslate">{{selectedItems.length}}</span>
        <span class="notranslate">&nbsp;</span>
        <span>selected</span>
    </div>
    <div class="col-12 col-md" style="max-width: 25rem">
        <app-date-range [(ngModel)]="timeRange" [ranges]="dashboardContext.defaultRanges" [dateOnly]="true"></app-date-range>
    </div>
    <div class="col-12 col-md-3">
        <app-select placeholder="Select interval" [nullOption]="true" [appDefaultValue]="'minute'" required
                    [(ngModel)]="resolution" [options]="appContext.supportedResolutions"></app-select>
    </div>
    <div class="col d-flex d-md-none align-items-center">
        <span class="notranslate">{{selectedItems.length}}</span>
        <span class="notranslate">&nbsp;</span>
        <span>connections selected</span>
    </div>
    <div class="col-md-auto ms-auto">
        <button class="btn btn-outline-danger mx-1 w-100" (click)="cancelMethod()" type="button">
            Cancel
        </button>
    </div>
    <div class="col-md-auto">
        <button class="btn btn-outline-info mx-1 w-100" (click)="downloadData()" type="button">
            Download
        </button>
    </div>
</div>