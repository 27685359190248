import {Component, Input, OnInit} from '@angular/core';
import {ExtendedContract,} from "../contracts-list/contracts-list.component";
import {
    DateRange,
    LinkContract,
    LinkedContract,
    Organisation,
    UnlinkContract
} from "@flowmaps/flowmaps-typescriptmodels";
import {AppContext} from "../../../../app-context";
import {ExtendedConnection} from "../../connections/connections-overview/connections-overview.component";
import {openLinkContractModal} from "../../organisations/organisations-list/organisations-list.component";
import {openLinkContractToConnectionModal} from "../../connections/connections-list/connections-list.component";
import {AbstractOverviewComponent} from "../../../../common/abstract-overview/abstract-overview.component";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {QueryGateway} from "../../../../flux/query-gateway";
import {ExtendedLinkedContract} from "../linked-contracts-overview/linked-contracts-overview.component";
import {closeModal, openModal} from "../../../../app-utils";
import {ActivatedRoute} from "@angular/router";
import {EventGateway} from "../../../../flux/event-gateway";
import {LinkContractComponent} from "../link-contract/link-contract.component";
import {AnimationTypes} from "../../../../common/modal/modal.component";
import {cloneDeep} from "lodash";
import {computeItem} from "../../../../common/utils";

@Component({
    selector: 'app-linked-contracts',
    templateUrl: './linked-contracts.component.html',
    styleUrls: ['./linked-contracts.component.css']
})
export class LinkedContractsComponent extends AbstractOverviewComponent<ExtendedLinkedContract> implements OnInit {

    @Input() contract!: ExtendedContract;
    contractId: string;
    appContext = AppContext;
    comparator: ComparatorChain = new ComparatorChain('linkId');

    trackByForRecord = (index: number, record: any) => record.organisationId || record.connectionId;

    contractLinked = (event: LinkContract) => {
        event.organisationCommands.forEach(command => {
            this.refDataUtils.getOrganisation(command.organisationId)
                .subscribe(organisation => {
                    this.computeItem(
                        l => l.organisation?.organisationId === organisation.organisationId,
                        l => {
                            if (!l) {
                                l = {
                                    linkedContracts: [],
                                    organisation: organisation
                                };
                            }
                            l.linkedContracts = computeItem(
                                l.linkedContracts,
                                lc => lc.linkId === command.linkId,
                                () => command.linkedContract
                            );
                            return l;
                        });
                });
        });

        event.connectionCommands.forEach(command => {
            this.refDataUtils.getOrganisation(command.organisationId).subscribe(organisation => {
                const location = organisation.locations.find(loc => loc.locationId === command.locationId);
                if (location) {
                    const connection = location.connections.find(conn => conn.connectionId === command.connectionId);
                    if (connection) {
                        this.computeItem(
                            l => l.connection?.connection.connectionId === command.connectionId &&
                                l.organisation?.organisationId === command.organisationId,
                            l => {
                                if (!l) {
                                    const extendedConnection: ExtendedConnection = {
                                        intermediaryId: organisation.intermediaryId,
                                        organisationId: organisation.organisationId,
                                        locationId: location.locationId,
                                        organisationInfo: organisation.info,
                                        locationInfo: location.info,
                                        connection: connection
                                    };
                                    l = {
                                        linkedContracts: [],
                                        connection: extendedConnection,
                                        organisation: organisation
                                    };
                                }
                                l.linkedContracts = computeItem(
                                    l.linkedContracts,
                                    lc => lc.linkId === command.linkedContract.linkId,
                                    () => command.linkedContract
                                );
                                return l;
                            });
                    }
                }
            });
        });
    }


    recordAdded(upsertCommand: any) {
        publishEvent('reloadOverview', upsertCommand);
    }

    recordUpdated(command: any) {
        publishEvent('reloadOverview', command);
    }

    constructor(private route: ActivatedRoute, private queryGateway: QueryGateway, eventGateway: EventGateway) {
        super(eventGateway);
    }

    ngOnInit() {
        super.ngOnInit();
        this.route.paramMap.subscribe(params => {
            this.contractId = params.get('contractId');
        });
    }

    protected readonly openLinkContractModal = openLinkContractModal;

    protected readonly AppContext = AppContext;

    protected readonly openLinkContractToConnectionModal = openLinkContractToConnectionModal;

    linkContract(contract: ExtendedContract) {
        openLinkModal(contract);
    }

    linkContractToOrganisation(organisation: Organisation) {
        const linkedContract: LinkedContract = {
            contractId: this.contractId,
            linkId: '',
            dateRange: {
                start: organisation.linkedContractsLastEndDate || '',
                end: ''
            },
        } as LinkedContract;
        openLinkContractModal(organisation.organisationId, linkedContract)
    }

    linkContractToConnection(connection: ExtendedConnection) {
        const linkedContract: LinkedContract = {
            contractId: this.contractId,
            linkId: '',
            dateRange: {
                start: connection.connection.linkedContractsLastEndDate || '',
                end: ''
            }
        } as LinkedContract;
        openLinkContractToConnectionModal(connection, linkedContract)
    }

    unlinkContractFromOrganisation(linkedContract: LinkedContract, organisation: Organisation) {
        let command = <UnlinkContract>{
            linkId: linkedContract.linkId,
            organisationId: organisation.organisationId
        };
        sendCommand("com.flowmaps.api.organisation.UnlinkContract", command, () => {
            this.computeItem(
                item => item.organisation?.organisationId === organisation.organisationId &&
                    item.linkedContracts.some(lc => lc.linkId === linkedContract.linkId),
                item => {
                    item.linkedContracts = item.linkedContracts.filter(
                        lc => lc.linkId !== linkedContract.linkId
                    );
                    if (item.linkedContracts.length === 0) {
                        return null;
                    }
                    return item;
                }
            );
            closeModal();
        });
    }

    unlinkContractFromConnection = (linkedContract: LinkedContract, organisationId: string) => {
        const command: UnlinkContract = {
            linkId: linkedContract.linkId,
            organisationId: organisationId
        };
        sendCommand('com.flowmaps.api.organisation.UnlinkContract', command, () => {
            this.computeItem(
                item => item.organisation?.organisationId === organisationId,
                item => {
                    if (item.connection) {
                        item.linkedContracts = item.linkedContracts.filter(
                            lc => lc.linkId !== linkedContract.linkId
                        );
                        if (item.linkedContracts.length === 0) {
                            return null;
                        }
                    }
                    return item;
                }
            );
            closeModal();
        });

    }
}

export function openLinkModal(contract: ExtendedContract) {
    openModal(LinkContractComponent, <LinkContractInBulkData>{
        contract: cloneDeep(contract),
        dateRange: {
            start: "",
            end: ""
        }
    }, {
        cssClass: 'modal-xl custom-modal-wide',
        animation: AnimationTypes.fade
    });
}

export class LinkContractInBulkData {
    contract: ExtendedContract;
    dateRange?: DateRange;
}