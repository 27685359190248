<div *ngIf="!readonly"  class="align-items-center mb-4 row">
    <div class="col">
        <app-local-filter [appAutoFocus]="true" placeholder="Search" [data]="data" (updated)="filteredData = $event"></app-local-filter>
    </div>
    <div *ngIf="appContext.isAdminOrIntermediary()" class="col-auto">
        <button (click)="openUserDetails(newUser())" type="button" class="btn btn-primary">
            <i class="bi bi-plus me-md-2"></i>
            <span class="d-none d-md-inline">Add User</span>
        </button>
    </div>
</div>
<app-scroller (endReached)="loadNextPage()" [windowScroll]="true">
    <div class="row">
        <ng-container *ngFor="let user of dataSorted; let i = index; trackBy: trackByForRecord">
            <div role="row" class="col-xl-4 col-md-6 col-12">
                <div class="h-100 d-flex">
                    <div class="card hover-show w-100 overflow-auto">
                        <div class="card-body">
                            <app-confirmation-toggle *ngIf="appContext.isAdminOrIntermediary()" (confirmed)="deleteUser(user)">
                                <button type="button" class="btn position-absolute top-0 start-0 to-show"
                                        role="button" aria-expanded="false">
                                    <i class="bi bi-trash"></i>
                                </button>
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h3 class="modal-title">Delete user</h3>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                                    </div>
                                    <div class="modal-body">
                                        <p>Are you sure you want to delete this user?</p>
                                    </div>
                                    <div class="modal-footer justify-content-between">
                                        <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                                        <button type="button" class="btn btn-primary" (click)="deleteUser(user)" data-bs-dismiss="modal">
                                            Delete user
                                        </button>
                                    </div>
                                </div>
                            </app-confirmation-toggle>
                            <div *ngIf="appContext.isAdminOrIntermediary()" class="position-absolute top-0 end-0 to-show">
                                <button type="button" class="btn" role="button" data-bs-title="Impersonate user"
                                        aria-expanded="false" (click)="impersonate(user)">
                                    <i class="bi bi-person-badge"></i>
                                </button>
                                <button type="button" class="btn" role="button"
                                        aria-expanded="false" (click)="openUserDetails(user)">
                                    <i class="bi bi-pencil"></i>
                                </button>
                            </div>
                            <div class="text-center mb-3 notranslate">
                                <h4 class="card-title">{{user.info.firstName}} {{user.info.lastName}}</h4>
                                <h5 class="text-muted">{{user.info.email}}</h5>
                            </div>
                            <hr class="card-divider mb-0">
                            <div class="list-group-flush mb-n3 list-group">
                                <div *ngFor="let authorisation of organisationAuthorisations(user); trackBy: trackByForAuthorisation"
                                     class="list-group-item py-3 pointer hover-underline" (click)="editAuthorisation(authorisation)">
                                    <div class="row">
                                        <div class="col-auto">
                                            <i class="bi me-2 bi-diagram-3"></i>
                                        </div>
                                        <div class="col text-truncate notranslate"><small>{{refDataUtils.organisationFormatter(authorisation.nominatorOrganisation)}}</small></div>
                                        <div class="col-4 text-end text-truncate"><small>{{authorisation.authorisation.role}}</small></div>
                                    </div>
                                </div>
                                <div *ngIf="appContext.isAdminOrIntermediary()" class="list-group-item py-3 pointer hover-underline" (click)="openAuthoriseUserDetails(user)">
                                    <div class="row">
                                        <div class="col-auto">
                                            <i class="bi me-2 bi-plus-circle"></i>
                                        </div>
                                        <div class="col text-truncate"><small>Add new authorisation</small></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</app-scroller>