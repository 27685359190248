import {ChangeDetectorRef, Component, forwardRef} from '@angular/core';
import {BaseMeasurementChartComponent, ChartDatasetExtended, MeasurementDataset} from "../base-measurement-chart";
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {AppContext} from "../../../app-context";
import {lodash} from "../../../common/utils";
import {ChartUtilsService} from "../chart-utils.service";
import {ChartDataPerMeasurement} from "../../../utils/measurements-data-provider";
import {TranslateDirective} from "../../../common/translate.directive";

@Component({
    selector: 'app-co2-chart',
    templateUrl: './co2-chart.component.html',
    styleUrls: ['./co2-chart.component.scss'],
    providers: [{provide: BaseMeasurementChartComponent, useExisting: forwardRef(() => Co2ChartComponent)}]
})
export class Co2ChartComponent extends BaseMeasurementChartComponent {
    totalCo2: number = 0;

    constructor(protected changeDetectorRef: ChangeDetectorRef, protected chartUtils: ChartUtilsService) {
        super(changeDetectorRef, chartUtils);
    }

    connectionType(): ConnectionType {
        return null;
    }

    consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>();

    measurementIntermediateLink = (): Map<DataType, DataType[]> => new Map<DataType, DataType[]>();

    measurementTypes(): DataType[] {
        return [DataType.co2EmissionFromElectricity, DataType.co2EmissionFromGas,
            DataType.co2EmissionFromHeat, DataType.co2EmissionFromWater];
    }

    measurementUnit(): string {
        return "tn";
    }

    productionDataTypes(): DataType[] {
        return [];
    }

    setData(result: ChartDataPerMeasurement, measurements: MeasurementDataset[], estimatedMeasurements: MeasurementDataset[]) {
        const datasets = this.measurementTypes().map((m, i) => {
            const d = measurements.find(d => d.measurementType === m) || this.defaultDataset(m);
            const estimatedData = estimatedMeasurements.find(d => d.measurementType === m) || this.defaultDataset(m);
            const value = this.roundValueIfNeeded(lodash.divide(lodash.sum(d.dataset.data) || 0, 1000));
            const estimatedValue = this.roundValueIfNeeded(lodash.divide(lodash.sum(estimatedData.dataset.data) || 0, 1000));
            return <MeasurementDataset>{
                measurementType: d.measurementType,
                dataset: this.getDataset(d.measurementType, d.dataset.label, [value].concat(estimatedValue > 0 ? [estimatedValue] : []))
            }
        });
        this.totalCo2 = this.roundValueIfNeeded(lodash.sum(datasets.flatMap(d => d.dataset.data)));
        this.chartDataProvider.emit({
            datasets: datasets.flatMap(d => [d, <any>{
                dataset: {
                    weight: 0.7
                }
            }])
        });
        this.changeDetectorRef.detectChanges();
    }

    private roundValueIfNeeded = (value: number) => value >= 100 ? lodash.round(value, 0) : lodash.round(value, 2);

    private defaultDataset = (m: DataType): MeasurementDataset => ({
        measurementType: m,
        dataset: this.getDataset(m, AppContext.measurementName(m), [0])
    });

    private getDataset = (measurementType: DataType, label: string, data: number[]): ChartDatasetExtended => {
        const measurementName = AppContext.measurementName(measurementType);
        return ({
            label: label,
            data: data,
            backgroundColor: [AppContext.getChartColorForMeasurementAsString(measurementType), "#FFFFFF"],
            borderColor: AppContext.getChartColorForMeasurementAsString(measurementType),
            borderWidth: 1,
            order: AppContext.indexOfDataType(measurementType),
            measurementType: measurementType,
            tooltip: {
                formatter: this.chartUtils.getCustomTooltipFormatter(measurementType),
                labelOverride: data.length > 1
                    ? [measurementName, `${measurementName} (${TranslateDirective.getTranslation("estimated", true)})`]
                    : measurementName
            }
        });
    };
}
