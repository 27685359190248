<ng-container *ngIf="context.userProfile">
    <nav class="navbar navbar-vertical fixed-start navbar-expand-md navbar-light" id="sidebar">
        <app-main-menu style="max-width: 500px" class="container-fluid"></app-main-menu>
    </nav>
    <div class="position-fixed sticky-top" style="left: 0; right: 0; z-index: 15000;">
        <app-alerting></app-alerting>
    </div>
    <div class="main-content">
        <router-outlet></router-outlet>
    </div>
</ng-container>
