import {SourcesProvider, TreeViewItem} from "./sources-provider";
import {ConnectionType, Location} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../views/refdata/refdata-utils";
import {lodash} from "../../common/utils";
import {AppContext} from "../../app-context";
import {SourceType} from "../../views/dashboard/dashboard.context";

export class LocationProvider extends SourcesProvider<Location> {

    collectData() {
        const locationId = this.getSelectedSourcesByType(SourceType.location)[0];
        RefdataUtils.getLocation(locationId).subscribe(l => {
            this.setData([l]);
            this.dataCollected.emit([l]);
        });
    }

    setData(locations: Location[]) {
        this.hierarchyData = this.treeViewItemMapper(locations);
        this.flattenedData = this.flatMapData(this.hierarchyData);
        this.data = this.getData();
        this.selectionUpdated.emit(this.getSelection());
    }

    treeViewItemMapper(locations: Location[]): TreeViewItem[] {
        return lodash.chain(locations)
            .map(l => {
                const locTreeItem = <TreeViewItem>{
                    icon: AppContext.getIconOfSourceType(SourceType.location),
                    checkbox: true,
                    visible: true,
                    info: RefdataUtils.locationToSourceInfo(l)
                };

                locTreeItem.subItems = lodash.chain(l.connections)
                    .map(c => {
                        const connectionItem = <TreeViewItem>{
                            icon: AppContext.getIconOfSourceType(SourceType.connection, c.info.connectionType as ConnectionType),
                            checkbox: true,
                            visible: true,
                            parent: locTreeItem,
                            info: RefdataUtils.connectionToSourceInfo(c, l.info)
                        };

                        connectionItem.subItems = lodash.chain(c.meters)
                            .map(m => (<TreeViewItem>{
                                icon: AppContext.getIconOfSourceType(SourceType.meter, null, m.info.type),
                                checkbox: true,
                                visible: true,
                                parent: connectionItem,
                                info: RefdataUtils.meterToSourceInfo({
                                    connection: c,
                                    meter: m,
                                    location: l
                                })
                            }))
                            .sort(this.treeViewComparator)
                            .value();

                        return connectionItem;
                    })
                    .sort(this.treeViewComparator)
                    .value();

                return locTreeItem;
            })
            .sort(this.treeViewComparator)
            .value();
    }
}