<div class="row">
    <div class="col-12 col-lg d-flex align-items-stretch">
        <div class="card flex-fill">
            <div class="card-body">
                <div class="row align-items-center gx-0">
                    <div class="col">
                        <h6 class="text-uppercase text-muted mb-2">Electricity consumption</h6>
                        <span class="h2 mb-0 notranslate">{{values.electricityConsumption | number: '1.0-0'}}
                            {{electricityConsumptionUnit()}}</span>
                    </div>
                    <div class="col-auto">
                        <app-connection-image [connectionType]="'Electricity'" [width]="30"></app-connection-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg d-flex align-items-stretch">
        <div class="card flex-fill">
            <div class="card-body">
                <div class="row align-items-center gx-0">
                    <div class="col">
                        <h6 class="text-uppercase text-muted mb-2">Electricity production</h6>
                        <span class="h2 mb-0 notranslate">{{values.electricityProduction | number: '1.0-0'}}
                            {{electricityProductionUnit()}}</span>
                    </div>
                    <div class="col-auto">
                        <img src="assets/images/icons/renewable-energy.png" width="30" height="30"
                             alt="Electricity production icon">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg d-flex align-items-stretch">
        <div class="card flex-fill">
            <div class="card-body">
                <div class="row align-items-center gx-0">
                    <div class="col">
                        <h6 class="text-uppercase text-muted mb-2">Electricity feed-in</h6>
                        <span class="h2 mb-0 notranslate">{{values.electricityFeedIn | number: '1.0-0'}}
                            {{electricityProductionUnit()}}</span>
                    </div>
                    <div class="col-auto">
                        <img src="assets/images/icons/feed-in.png" width="40" height="40"
                             translate="no" alt="Electricity feed-in icon">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 col-lg d-flex align-items-stretch">
        <div class="card flex-fill">
            <div class="card-body">
                <div class="row align-items-center gx-0">
                    <div class="col">
                        <h6 class="text-uppercase text-muted mb-2">Gas</h6>
                        <span class="h2 mb-0 notranslate">{{values.gas | number: '1.0-0'}}
                            {{gasUnit()}}</span>
                    </div>
                    <div class="col-auto">
                        <app-connection-image [connectionType]="'Gas'" [width]="30"></app-connection-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg d-flex align-items-stretch">
        <div class="card flex-fill">
            <div class="card-body">
                <div class="row align-items-center gx-0">
                    <div class="col">
                        <h6 class="text-uppercase text-muted mb-2">Heat</h6>
                        <span class="h2 mb-0 notranslate">{{values.heat | number: '1.0-0'}}
                            {{heatUnit()}}</span>
                    </div>
                    <div class="col-auto">
                        <app-connection-image [connectionType]="'Heat'" [width]="30"></app-connection-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg d-flex align-items-stretch">
        <div class="card flex-fill">
            <div class="card-body">
                <div class="row align-items-center gx-0">
                    <div class="col">
                        <h6 class="text-uppercase text-muted mb-2">Water</h6>
                        <span class="h2 mb-0 notranslate">{{values.water | number: '1.0-0'}}
                            {{waterUnit()}}</span>
                    </div>
                    <div class="col-auto">
                        <app-connection-image [connectionType]="'Water'" [width]="30"></app-connection-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>