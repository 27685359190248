import {Component, Input, OnInit} from '@angular/core';
import {
    Authorisation,
    AuthorisationType,
    GiveAuthorisation,
    Organisation,
    RevokeAuthorisation,
    TimeRange
} from "@flowmaps/flowmaps-typescriptmodels";
import {AppContext} from "../../../../app-context";
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {closeModal, openConfirmationModalWithCallback, openModal} from "../../../../app-utils";
import {Observable, of} from "rxjs";
import {uuid} from "../../../../common/utils";
import {RefdataUtils} from "../../refdata-utils";
import {QueryGateway} from "../../../../flux/query-gateway";
import moment from 'moment';
import {SourceInfo, SourcesProvider} from '../../../../utils/source-providers/sources-provider';
import {OrganisationProvider} from '../../../../utils/source-providers/organisation-provider';
import {
    ModalConfirmAutofocus,
    ModalConfirmAutofocusData
} from '../../../../common/modal-confirm/modal-confirm.component';
import {AnimationTypes} from '../../../../common/modal/modal.component';

@Component({
    selector: 'app-authorisation-details',
    templateUrl: './authorisation-details.component.html',
    styleUrls: ['./authorisation-details.component.scss']
})
export class AuthorisationDetailsComponent implements OnInit {
    appContext = AppContext;
    refdataUtils = RefdataUtils;
    isNewAuthorisation: boolean;
    showNominatorField: boolean;

    nominatorOrganisation: Organisation;
    nomineeOrganisation: Organisation;
    sourceProvider: SourcesProvider<any>;

    @Input() data: Authorisation;

    constructor(private queryGateway: QueryGateway) {
    }

    ngOnInit(): void {
        if (!this.data.authorisationId) {
            this.isNewAuthorisation = true;
            this.data.authorisationId = uuid();
            this.showNominatorField = !this.data.nominator;
        } else {
            this.showNominatorField = this.data["showNominator"];
        }
        this.data.entityIds = this.data.entityIds || [];
        this.data.timeRange = this.data.timeRange || {};
        if (this.data.nominator) {
            RefdataUtils.getOrganisation(this.data.nominator).subscribe(o => {
                this.nominatorOrganisation = o;
            });
        }
        if (this.data.nominee && this.data.type === AuthorisationType.organisation) {
            RefdataUtils.getOrganisation(this.data.nominee).subscribe(o => {
                this.nomineeOrganisation = o;
            });
        }
        this.sourceProvider = new OrganisationProvider(this.data.nominator,
            this.data.entityIds.length ? this.data.entityIds : []);
    }

    getMinStartDate = (): string => this.data.timeRange?.start ? this.data.timeRange?.start : AppContext.today();

    setNominee(organisation: Organisation) {
        this.nomineeOrganisation = organisation;
        this.data.nominee = organisation?.organisationId;
    }

    setNominator(organisation: Organisation) {
        this.nominatorOrganisation = organisation;
        this.data.nominator = organisation?.organisationId;
        this.sourceProvider = new OrganisationProvider(this.data.nominator,
            this.data.entityIds.length ? this.data.entityIds : []);
    }

    updateInfo = () => {
        this.data.timeRange = asTimeRange(this.data.timeRange);
        const command: GiveAuthorisation = {
            organisationId: this.data.nominator,
            authorisation: this.data
        }
        sendCommand("com.flowmaps.api.organisation.GiveAuthorisation", command, () => {
            closeModal();
            this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
            publishEvent(this.isNewAuthorisation ? "recordAdded" : "recordUpdated", command);
        });

        function asTimeRange(dateRange: TimeRange): TimeRange {
            if (dateRange?.start || dateRange?.end) {
                return {
                    start: dateRange.start && moment(dateRange.start).startOf('day').toISOString(),
                    end: dateRange.end && moment(dateRange.end).endOf('day').toISOString(),
                }
            }
            return null;
        }
    }

    authorisationHeader(): Observable<string> {
        return this.data.type === AuthorisationType.organisation
            ? RefdataUtils.organisationFormatterAsync(RefdataUtils.getOrganisation(this.data.nominee))
            : of(this.data.nominee);
    }

    updateEntityIds(sources: SourceInfo[]) {
        const sourceIds = this.sourceProvider.sourceSelectionAfterCleanup();
        this.data.entityIds = sourceIds.locationIds.concat(sourceIds.connectionIds).concat(sourceIds.meterIds);
    }

    deleteAuthorisation = () => {
        openConfirmationModalWithCallback(confirmed => {
            if (confirmed) {
                const command = <RevokeAuthorisation>{
                    authorisationId: this.data.authorisationId,
                    organisationId: this.data.nominator
                };
                sendCommand("com.flowmaps.api.organisation.RevokeAuthorisation", command, () => {
                    closeModal();
                    this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
                    publishEvent("recordDeleted", command);
                });
            } else {
                this.reopenAuthoriseUserDetails();
            }
        }, ModalConfirmAutofocus, <ModalConfirmAutofocusData> {
            type: "danger",
            title: "Delete authorisation",
            innerHtmlMessage: `<p>Are you sure you want to delete this authorisation?</p>`,
            confirmText: "Delete authorisation",
            cancelText: "Cancel"
        }, 'static');
    };

    reopenAuthoriseUserDetails = () => openModal(AuthorisationDetailsComponent, this.data, {
        cssClass: "modal-xl",
        animation: AnimationTypes.fade
    });

    protected readonly closeModal = closeModal;
}