import {Component} from '@angular/core';
import {AppContext} from '../../app-context';

@Component({
  selector: 'app-signed-out',
  templateUrl: './signed-out.component.html',
  styleUrls: ['./signed-out.component.css']
})
export class SignedOutComponent {
  context = AppContext;

}
