<div *ngIf="!readonly"  class="align-items-center mb-4 row g-3">
    <div class="col">
        <app-local-filter [appAutoFocus]="true" placeholder="Search" [data]="data" (updated)="filteredData = $event"></app-local-filter>
    </div>
    <div class="col-auto">
        <div class="dropdown">
            <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                <i class="bi bi-download me-md-2"></i>
                <span class="d-none d-md-inline">Download</span>
            </button>
            <ul class="dropdown-menu">
                <li>
                    <button class="dropdown-item" (click)="downloadLocationsAsExcel(_filteredData)">Locations list</button>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-auto" *ngIf="appContext.isAdminOrIntermediary()">
        <div class="dropdown">
            <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                <i class="bi bi-upload me-md-2"></i>
                <span class="d-none d-md-inline">Upload</span>
            </button>
            <ul class="dropdown-menu">
                <li>
                    <a type="button" class="dropdown-item" (click)="uploadLocationsListInput.click()">
                        <span>Locations list</span>
                        <input type="file" hidden (change)="onUploadLocationsList($event.target);" #uploadLocationsListInput>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div *ngIf="appContext.isAdminOrIntermediary()" class="col-auto">
        <button (click)="openLocationDetails(newLocation())" type="button" class="btn btn-primary">
            <i class="bi bi-plus me-md-2"></i>
            <span class="d-none d-md-inline">Add Location</span>
        </button>
    </div>
</div>
<app-scroller (endReached)="loadNextPage()" [windowScroll]="true">
    <div class="row">
        <ng-container *ngFor="let location of dataSorted; let i = index; trackBy: trackByForRecord">
            <div role="row" class="col-xl-4 col-md-6 col-12" dndDropzone (dndDrop)="onDrop($event, location)"
                 [dndDisableDropIf]="!authorisedForLocationMerge()" [dndDisableDragIf]="!authorisedForLocationMerge()" [dndDraggable]="location">
                <div class="h-100 d-flex">
                    <div class="card hover-show w-100 overflow-auto">
                        <div class="position-absolute start-0" style="top: 2.5rem">
                            <app-energy-label [label]="location.info.energyLabel || location.refdata?.energyLabel"></app-energy-label>
                        </div>
                        <div class="card-body">
                            <button type="button"
                                    class="btn position-absolute top-0 end-0 to-show" role="button" style="right: 24px !important;"
                                    aria-expanded="false" (click)="openLocationGraph(location)">
                                <i class="bi bi-bar-chart-line"></i>
                            </button>
                            <app-confirmation-toggle *ngIf="appContext.isManagerForLocation(location)"
                                                     (confirmed)="deleteLocation(location)">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h3 class="modal-title">Remove location</h3>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                                    </div>
                                    <div class="modal-body">
                                        <p>Are you sure you want to remove this location?</p>
                                    </div>
                                    <div class="modal-footer justify-content-between">
                                        <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                                        <button type="button" class="btn btn-primary" (click)="deleteLocation(location)" data-bs-dismiss="modal">
                                            Remove location
                                        </button>
                                    </div>
                                </div>

                                <button type="button" class="btn position-absolute top-0 start-0 to-show"
                                        role="button" aria-expanded="false">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </app-confirmation-toggle>
                            <button *ngIf="appContext.isManagerForLocation(location)" type="button" class="btn position-absolute top-0 end-0 to-show" role="button"
                                    aria-expanded="false" (click)="openLocationDetails(location)">
                                <i class="bi bi-pencil"></i>
                            </button>
                            <div class="text-center mb-3 notranslate">
                                <h4 class="card-title">{{location.info.address.street}} {{location.info.address.number}} {{location.info.address.addition}}</h4>
                                <h4 class="card-title">{{location.info.address.zipCode}} {{location.info.address.city}}</h4>
                                <h5 class="text-muted">{{location.info.name}}</h5>
                            </div>
                            <hr class="card-divider mb-0">
                            <div class="list-group-flush mb-n3 list-group">
                                <div *ngFor="let connection of sortedConnectionsForLocation(location); trackBy : trackByForConnection"
                                     class="list-group-item py-3" [style]="appContext.isManagerForConnection(connection) ? 'cursor: pointer;' : ''"
                                     (click)="appContext.isManagerForConnection(connection) ? openConnectionDetails(connection) : null">
                                    <div class="row">
                                        <div class="col-auto">
                                            <small>
                                                <app-connection-image [connectionType]="connection.connection.info.connectionType"></app-connection-image>
                                            </small>
                                        </div>
                                        <div class="col notranslate"><small>{{connection.connection.info.code}}</small></div>
                                        <div class="col-auto notranslate"><small>{{connection.connection.info.operator?.name}}</small></div>
                                    </div>
                                </div>
                                <div *ngIf="appContext.isAdminOrIntermediary()" class="list-group-item py-3 pointer hover-underline" (click)="addConnectionToLocation(location)">
                                    <div class="row">
                                        <div class="col-auto">
                                            <i class="bi me-2 bi-plus-circle"></i>
                                        </div>
                                        <div class="col text-truncate"><small>Add new connection</small></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</app-scroller>