import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {QueryGateway} from "../../../../flux/query-gateway";
import {EventGateway} from "../../../../flux/event-gateway";
import {Connection, LinkedContract, Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import {ExtendedConnection} from "../../connections/connections-overview/connections-overview.component";
import {RefdataUtils} from "../../refdata-utils";
import {ExtendedContract} from "../contracts-list/contracts-list.component";

@Component({
    selector: 'app-linked-contracts-overview',
    templateUrl: './linked-contracts-overview.component.html',
    styleUrls: ['./linked-contracts-overview.component.css']
})
export class LinkedContractsOverviewComponent implements OnDestroy, OnInit {
    @Input() contract: ExtendedContract;
    extendedLinkedContracts: ExtendedLinkedContract[] = [];

    private readonly registration: () => void;

    constructor(protected queryGateway: QueryGateway, protected eventGateway: EventGateway) {
        this.registration = this.eventGateway.registerLocalHandler(this);
    }

    ngOnInit(): void {
        if (this.contract) {
            this.populateData();
        }
        this.registration();
        this.eventGateway.registerLocalHandler({reloadOverview: this.reloadOverview});

    }

    ngOnDestroy(): void {
        this.registration();
    }

    reloadOverview = () => {
        setTimeout(() => this.reload(), 200);
    }

    reload = () => {
        this.populateData();
    }

    populateData(): void {
        this.extendedLinkedContracts = [];

        RefdataUtils.getMyOrganisations().subscribe((organisations: Organisation[]) => {
            organisations.forEach((org: Organisation) => {
                const filteredOrgLinkedContracts = org.linkedContracts.filter(
                    (contract) => contract.contractId === this.contract.contractId
                );

                if (filteredOrgLinkedContracts.length > 0) {
                    const extendedLinkedContract: ExtendedLinkedContract = {
                        linkedContracts: filteredOrgLinkedContracts,
                        organisation: org,
                    };

                    this.extendedLinkedContracts.push(extendedLinkedContract);
                }

                org.locations.forEach((location) => {
                    location.connections.forEach((connection: Connection) => {
                        const filteredConnectionLinkedContracts = connection.linkedContracts.filter(
                            (contract) => contract.contractId === this.contract.contractId
                        );

                        if (filteredConnectionLinkedContracts.length > 0) {
                            const extendedConnection: ExtendedConnection = {
                                intermediaryId: org.intermediaryId,
                                organisationId: org.organisationId,
                                locationId: location.locationId,
                                organisationInfo: org.info,
                                locationInfo: location.info,
                                connection: {
                                    ...connection,
                                    linkedContracts: filteredConnectionLinkedContracts,
                                },
                            };

                            const extendedLinkedContract: ExtendedLinkedContract = {
                                linkedContracts: filteredConnectionLinkedContracts,
                                connection: extendedConnection,
                                organisation: org,
                            };

                            this.extendedLinkedContracts.push(extendedLinkedContract);
                        }
                    });
                });
            });
            this.extendedLinkedContracts = [...this.extendedLinkedContracts];
        });
    }
}


export interface ExtendedLinkedContract {
    linkedContracts: LinkedContract[];
    connection?: ExtendedConnection;
    organisation?: Organisation;
}
