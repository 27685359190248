import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SetUserPassword} from "@flowmaps/flowmaps-typescriptmodels";
import {AuthService} from "../../../utils/auth.service";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
    userId: string;
    token: string;
    password: string;

    constructor(private route: ActivatedRoute, private authService: AuthService) {
    }

    ngOnInit(): void {
        this.userId = this.route.snapshot.queryParamMap.get('userId');
        this.token = this.route.snapshot.queryParamMap.get('token');
    }

    resetPassword() {
        this.authService.resetPassword(<SetUserPassword>{
            userId: this.userId,
            confirmationCode: this.token,
            password: this.password
        });
    }
}
