import {Component} from '@angular/core';
import {SignUpUser} from '@flowmaps/flowmaps-typescriptmodels';
import {Router} from "@angular/router";
import {AuthService} from "../../../utils/auth.service";
import {AppContext} from "../../../app-context";

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent {
    command: SignUpUser = {
        info: {
            language: AppContext.getPreferredLanguage()
        }
    };

    constructor(private router: Router, private authService: AuthService) {
    }

    signUpUser() {
        this.authService.signUp(this.command);
    }
}
