<app-form (submit)="updateInfo()">
    <div class="card">
        <div class="card-header">
            <h3 class="card-header-title">Add organisation</h3>
        </div>
        <div class="card-body">
            <app-organisation-info [organisationInfo]="data.organisation.info"></app-organisation-info>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>