import {Component, Input} from '@angular/core';
import {BuildingType, Location, LocationInfo, Organisation, UpsertLocation} from "@flowmaps/flowmaps-typescriptmodels";
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {closeModal} from "../../../../app-utils";
import {uuid} from "../../../../common/utils";
import {QueryGateway} from "../../../../flux/query-gateway";
import {RefdataUtils} from "../../refdata-utils";
import {EnergyLabels} from "../../../../common/energy-label/energy-label.component";
import {AppContext} from "../../../../app-context";
import {EnergyCompassUtils} from "../../../charts/energy-compass-chart/energy-compass.utils";

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss']
})
export class LocationDetailsComponent {
  appContext = AppContext;
  refDataUtils = RefdataUtils;
  energyCompassUtils = EnergyCompassUtils;
  @Input() data: LocationComponentData;
  isNewLocation: boolean;
  info: LocationInfo;
  organisation: Organisation;
  buildingType: BuildingType;

  energyLabels: string[] = Object.values(EnergyLabels).filter((v) => isNaN(Number(v)));

  constructor(private queryGateway: QueryGateway) {
  }

  ngOnInit(): void {
    if (!this.data.location.locationId) {
      this.isNewLocation = true;
    }
    this.info = this.data.location?.info || LocationDetailsComponent.newLocation();
    if (this.data.organisationId) {
      RefdataUtils.getOrganisation(this.data.organisationId).subscribe(o => this.organisation = o);
    }
    if (this.info.buildingType) {
      EnergyCompassUtils.getBuildingType(this.info.buildingType).subscribe(b => this.buildingType = b);
    }
  }

  buildingTypeFormatter = (buildingType: BuildingType) => buildingType.info.name;

  updateInfo = () => {
    if (this.buildingType) {
      this.info.buildingType = this.buildingType.buildingTypeId;
    }
    const command: UpsertLocation = {
      organisationId: this.organisation.organisationId,
      locationId: this.isNewLocation ? uuid() : this.data.location.locationId,
      info: this.info,
      refdata: this.data.location?.refdata
    };
    sendCommand("com.flowmaps.api.organisation.UpsertLocation", command, () => {
      closeModal();
      this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
      if (this.isNewLocation) {
        publishEvent("recordAdded", command);
      }
    });
  };

  static newLocation = (): LocationInfo => ({
    address: {
      geoLocation: {

      }
    }
  })
}

export interface LocationComponentData {
  organisationId: string;
  intermediaryId: string;
  location: Location;
}