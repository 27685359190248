import {Plugin} from "chart.js";

export class ChartAreaBorderPlugin implements Plugin<'scatter'> {
    id: 'chartAreaBorder';

    beforeDraw = (chart, args, options) => {
        const {ctx, chartArea: {left, top, width, height}} = chart;
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = options.borderColor;
        ctx.lineWidth = options.borderWidth;
        ctx.setLineDash(options.borderDash || []);
        ctx.lineDashOffset = options.borderDashOffset;
        ctx.strokeRect(left, top, width, height);
        ctx.restore();
    }
}