import {CustomAxesPlugin} from "../scatter-chart/plugins/custom-axes.plugin";
import {Chart} from "chart.js";

export class EnergyCompassParisProofLinePlugin extends CustomAxesPlugin {
    id = "parisProofLine";
    static unknownLabel: string = "Unknown";

    beforeDraw = (chart: Chart) => {
        const {ctx, chartArea: {top, height, left, right}, scales: {x, y}} = chart;
        const heightPerTick = y.getPixelForTick(1) - y.getPixelForTick(2);
        const widthPerTick = x.getPixelForTick(2) - x.getPixelForTick(1);
        const hasUnknown = x.getTicks().some(t => t.label === EnergyCompassParisProofLinePlugin.unknownLabel);
        const end = hasUnknown ? right - widthPerTick : right;

        let positions: Positions = {
            start: {
                x: x.getPixelForTick(0),
                y: y.getPixelForTick(2) + (heightPerTick / 2)
            },
            end: {
                x: x.getPixelForTick(x.getTicks().filter(t => t.label !== EnergyCompassParisProofLinePlugin.unknownLabel).length-1),
                y: y.getPixelForTick(4)
            }
        }

        positions.start = {
            x: left,
            y: this.interpolateLinear(positions, left)
        };
        positions.end = {
            x: end,
            y: this.interpolateLinear(positions, end)
        };

        ctx.save();
        this.drawFill(ctx, positions, top + height);
        this.drawLine(ctx, positions);
        ctx.restore();
    }

    private drawFill(ctx: CanvasRenderingContext2D, positions: Positions, maxY: number) {
        ctx.beginPath();
        ctx.moveTo(positions.start.x, positions.start.y);
        ctx.lineTo(positions.end.x, positions.end.y);
        ctx.lineTo(positions.end.x, maxY);
        ctx.lineTo(positions.start.x, maxY);
        ctx.fillStyle = 'rgb(226,255,218, 0.8)';
        ctx.globalCompositeOperation = "source-over";
        ctx.fill();
    }

    private drawLine(ctx, positions) {
        ctx.beginPath();
        ctx.lineWidth = 3;
        ctx.strokeStyle = "grey";
        ctx.moveTo(positions.start.x, positions.start.y);
        ctx.lineTo(positions.end.x, positions.end.y);
        ctx.stroke();
    }

    interpolateLinear = (positions: Positions, x3: number): number => positions.start.y +
        ((x3 - positions.start.x) / (positions.end.x - positions.start.x)) * (positions.end.y - positions.start.y);
}

interface Positions {
    start: Position;
    end: Position;
}

interface Position {
    x: number;
    y: number;
}