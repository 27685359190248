import {Component, Input} from '@angular/core';
import {ReportInfo, UpsertReport} from "@flowmaps/flowmaps-typescriptmodels";
import {publishEvent, sendCommand} from "../../../flux/flux-utils";
import {uuid} from "../../../common/utils";
import {closeModal} from "../../../app-utils";
import {SourcesProvider} from "../../../utils/source-providers/sources-provider";
import {AppContext} from "../../../app-context";
import {QueryGateway} from "../../../flux/query-gateway";
import {Frequency, ReportUtils} from "../report-utils";
import {Router} from "@angular/router";
import {OrganisationProvider} from "../../../utils/source-providers/organisation-provider";

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent {
  appContext = AppContext;
  _data: ReportComponentData;
  frequencies: Frequency[] = Object.values(Frequency).filter((v) => isNaN(Number(v)))
      .filter(s => AppContext.isAdmin() ? true : s !== Frequency.day);
  sourcesProvider: SourcesProvider<any> = new OrganisationProvider();

  frequency: Frequency;
  private reportId: string;

  constructor(private queryGateway: QueryGateway, private router: Router) {

  }

  @Input()
  set data(data: ReportComponentData) {
    this._data = data;
    this.frequency = ReportUtils.cronToFrequency(this._data.info.scheduleCron);
    this.sourcesProvider.selectedSources = this._data.info.query.sources;
    this.reportId = this._data.reportId || uuid();
  }

  updateInfo = () => sendCommand("com.flowmaps.api.reporting.UpsertReport", <UpsertReport> {
    reportId: this.reportId,
    info: this._data.info
  }, () => {
    closeModal();
    publishEvent('reportChange', {});
    this.router.navigateByUrl(`/report/${this.reportId}`);
  });

  onFrequencyChange = (frequency: Frequency) => {
    this._data.info.scheduleCron = ReportUtils.frequencyToCron(frequency);
  }
}

export interface ReportComponentData {
  reportId: string;
  info: ReportInfo;
}