import {Component, Input} from '@angular/core';

export enum EnergyLabels {
  AAAAAA = "A+++++",
  AAAAA = "A++++",
  AAAA = "A+++",
  AAA = "A++",
  AA = "A+",
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G"
}

@Component({
  selector: 'app-energy-label',
  templateUrl: './energy-label.component.html',
  styleUrls: ['./energy-label.component.scss']
})
export class EnergyLabelComponent {
  @Input() label: EnergyLabels | string;

  get cssClass() {
    return this.label.replace(/\+/g, "A").substring(0, 4);
  }
}
