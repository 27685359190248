import {AfterViewInit, Component, ElementRef, OnDestroy, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {EventGateway} from '../../flux/event-gateway';
import {closeConfirmationModal} from '../../app-utils';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements AfterViewInit, OnDestroy {

  @ViewChild('modal', {static: true}) modal: ElementRef;
  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;
  private readonly registration: () => void;

  constructor(private eventGateway: EventGateway) {
    this.registration = this.eventGateway.registerLocalHandler(this);
  }

  ngAfterViewInit(): void {
    $(this.modal.nativeElement).on('hidden.bs.modal', () => this.container.clear());
  }

  'openConfirmationModal' = (event: ConfirmationModalEvent) => {
    this.container.clear();
    const componentRef = this.container.createComponent(<any>event.component);
    componentRef.instance['data'] = event.data;
    componentRef.instance['callback'] = close => {
      closeConfirmationModal();
      if (event.callback) {
        event.callback(close);
      }
    }
    $(componentRef.location.nativeElement).addClass('modal-content');
    $(this.modal.nativeElement).modal('show');
  }

  'closeConfirmationModal' = () => {
    $(this.modal.nativeElement).modal('hide');
  }

  ngOnDestroy(): void {
    this.registration();
  }
}

export interface ConfirmationModalEvent {
  component : Type<any>;
  data? : any;
  callback : (boolean) => any;
}
