<div class="row">
    <div class="col-12 col-md-6" *ngIf="info.marketSegment === 'KV'">
        <app-form-field label="Connection value">
            <app-connection-value-select [(ngModel)]="info.connectionValue" class="notranslate"
                                         [type]="info.connectionType"></app-connection-value-select>
        </app-form-field>
    </div>
    <div class="col-12 col-md-6">
        <ng-container *ngIf="info.marketSegment === 'KV' else gv">
            <app-form-field label="Contracted capacity" [tooltip]="info.connectionValue ? null : 'Contracted capacity can be changed by selecting the correct connection value'">
                <input [ngModel]="contractedCapacity" type="number" class="form-control" disabled>
            </app-form-field>
        </ng-container>
        <ng-template #gv>
            <app-form-field label="Contracted capacity">
                <input [(ngModel)]="info.contractedCapacity" type="number" class="form-control">
            </app-form-field>
        </ng-template>
    </div>
    <div class="col-12 col-md-6">
        <app-form-field label="Residential function">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value=""
                       [(ngModel)]="info.withResidentialFunction">
            </div>
        </app-form-field>
    </div>
</div>