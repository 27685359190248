import {Component, Input} from '@angular/core';
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {Connection, UpsertSolarEdgeMeter} from "@flowmaps/flowmaps-typescriptmodels";
import {closeModal} from "../../../../app-utils";
import {QueryGateway} from "../../../../flux/query-gateway";

@Component({
  selector: 'app-upsert-solar-edge-meter',
  templateUrl: './upsert-solar-edge-meter.component.html',
  styleUrls: ['./upsert-solar-edge-meter.component.css']
})
export class UpsertSolarEdgeMeterComponent {
  @Input() data: UpsertSolarEdgeMeterComponentData;
  kioskUrl: string;
  siteId: string;
  apiKey: string;

  constructor(private queryGateway: QueryGateway) {
  }

  updateInfo = () => {
    const command: UpsertSolarEdgeMeter = {
      ean: this.data.connection.info.code,
      kioskUrl: this.kioskUrl,
      siteId: this.siteId,
      apiKey: this.apiKey
    };
    sendCommand("com.flowmaps.api.organisation.UpsertSolarEdgeMeter", command, () => {
      closeModal();
      this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
      publishEvent("recordAdded", command);
    });
  };
}

export interface UpsertSolarEdgeMeterComponentData {
  connection: Connection;
}