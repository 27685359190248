import {Component, Input, NgZone} from '@angular/core';
import {SourceInfo, TreeViewItem} from "../../../../utils/source-providers/sources-provider";
import {DateRange, LinkContract, LinkedContract, Location, SourceIds} from "@flowmaps/flowmaps-typescriptmodels";
import {SourceType} from "../../../dashboard/dashboard.context";
import {LinkContractInBulkData} from "../linked-contracts/linked-contracts.component";
import {QueryGateway} from "../../../../flux/query-gateway";
import {closeModal} from "../../../../app-utils";
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {uuid} from "../../../../common/utils";
import {ExtendedConnection} from "../../connections/connections-overview/connections-overview.component";
import {RefdataUtils} from "../../refdata-utils";
import {OrganisationProvider} from "../../../../utils/source-providers/organisation-provider";

@Component({
    selector: 'app-link-contract',
    templateUrl: './link-contract.component.html',
    styleUrls: ['./link-contract.component.css']
})
export class LinkContractComponent {
    @Input() data: LinkContractInBulkData;
    sourceProvider: OrganisationProvider;
    addresses: Location[] = [];
    sources: SourceIds;
    connections: ExtendedConnection[] = []

    constructor(private ngZone: NgZone, private queryGateway: QueryGateway) {
        const sourceFilter = (item: SourceInfo) => {
            const connectionType = this.data.contract.contractData.connectionType;
            return item.connectionType === connectionType || !item.connectionType;
        };
        this.sourceProvider = new OrganisationProvider('', [], sourceFilter);
        RefdataUtils.getAllConnections().subscribe(r => this.connections = r)
    }

    private getAddresses() {
        const sourceSelection = this.sourceProvider.getTreeViewSelection();
        const items = sourceSelection.length > 0 ? sourceSelection : this.sourceProvider.flattenedData;
        return items.flatMap(this.getLocations);
    }

    getNewLinkedContract(dateRange: DateRange): LinkedContract {
        return {
            contractId: this.data.contract.contractId,
            linkId: uuid(),
            dateRange: dateRange
        } as LinkedContract;
    }

    sourceSelectionUpdated(sources: SourceInfo[]) {
        this.ngZone.runOutsideAngular(() => {
            this.addresses = this.getAddresses();
            this.sources = this.sourceProvider.sourceSelectionAfterCleanup();
        });
    }

    private getLocations(item: TreeViewItem): Location[] {
        switch (item.info.type) {
            case SourceType.organisation:
                return item.info.source.locations;
            case SourceType.location:
                return [item.info.source];
            case SourceType.connection:
                return [item.parent.info.source];
            case SourceType.meter:
                return [item.parent.parent.info.source];
        }
    }

    linkContract() {
        const connectionCommands = this.sources.connectionIds.map(connectionId => {
            const linkedContract = this.getNewLinkedContract(this.data.dateRange);
            const connection = this.connections.find(conn => conn.connection.connectionId === connectionId);

            if (!connection) {
                throw new Error(`Connection with ID ${connectionId} not found.`);
            }

            return {
                organisationId: connection.organisationId,
                locationId: connection.locationId,
                connectionId: connectionId,
                linkedContract: linkedContract
            };
        });

        const locationCommands = this.sources.locationIds.flatMap(locationId => {
            const linkedContract = this.getNewLinkedContract(this.data.dateRange);
            const location = this.addresses.find(loc => loc.locationId === locationId);
            if (!location) {
                throw new Error(`Location with ID ${locationId} not found.`);
            }

            const matchingConnections = location.connections
                .map(conn => this.connections.find(extConn => extConn.connection.connectionId === conn.connectionId))
                .filter(extConn => extConn && extConn.connection.info.connectionType === this.data.contract.contractData.connectionType);

            if (matchingConnections.length === 0) {
                return [];
            }

            return matchingConnections.map(connection => ({
                organisationId: connection.organisationId,
                locationId: location.locationId,
                connectionId: connection.connection.connectionId,
                linkedContract: linkedContract
            }));
        });
        const allConnectionCommands = [...connectionCommands, ...locationCommands];

        const organisationCommands = this.sources.organisationIds.map(organisationId => {
            const linkedContract = this.getNewLinkedContract(this.data.dateRange);

            return {
                organisationId: organisationId,
                linkedContract: linkedContract
            };
        });

        let command = <LinkContract>{
            connectionCommands: allConnectionCommands,
            organisationCommands: organisationCommands,
        };
        sendCommand("com.flowmaps.api.organisation.LinkContract", command, () => {
            closeModal();
            publishEvent('contractLinked', command);
        });
    }
}