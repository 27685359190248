import {Component} from '@angular/core';
import {AddAlert} from '@flowmaps/flowmaps-typescriptmodels';
import {uuid} from '../../../../common/utils';
import {publishEvent, sendCommand} from '../../../../flux/flux-utils';
import {AppContext} from '../../../../app-context';
import {RefdataUtils} from '../../../refdata/refdata-utils';
import {closeModal} from "../../../../app-utils";
import {QueryGateway} from "../../../../flux/query-gateway";

@Component({
    selector: 'app-add-alert',
    templateUrl: './add-alert.component.html',
    styleUrls: ['./add-alert.component.css']
})
export class AddAlertComponent {
    refdataUtils = RefdataUtils;

    command: AddAlert = {
        alertId: uuid(),
        details: {}
    }

    constructor(private queryGateway: QueryGateway) {
    }

    sendCommand = () => {
        sendCommand('com.flowmaps.api.monitoring.alerting.AddAlert', this.command,
            () => {
                closeModal();
                this.queryGateway.removeFromCache("com.flowmaps.api.monitoring.alerting.GetMyAlerts");
                publishEvent("recordAdded", this.command);
                AppContext.registerSuccess('Alert was added');
            });
    };
}
