import {AfterViewInit, Component, ElementRef, Input} from '@angular/core';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements AfterViewInit {

  @Input() label: string;
  @Input() tooltip: string;
  @Input() renderOptional: boolean = true;
  @Input() customClass: string;

  required: boolean;

  constructor(public element: ElementRef) {
  }

  ngAfterViewInit(): void {
    const el = this.element.nativeElement.querySelector('textarea, app-date-field, app-radio, app-multiselect, input, select, app-toggle');
    if (el) {
      const labels = this.element.nativeElement.querySelectorAll('label:not(.btn)');

      if (el.hasAttribute('required')) {
        this.required = true;
        labels.forEach(label => label.classList.add('required'));
      }

      if (!this.element.nativeElement.querySelector('.invalid-feedback')) {
        const feedbackElement = document.createElement('div');
        el.parentElement.appendChild(feedbackElement);
        feedbackElement.outerHTML = '<div class="invalid-feedback">Please enter ' + (labels ? renderLabel(labels) : 'a value') + '</div>';
      }
    }

    function renderLabel(labels: NodeList): string {
      let name: string = "";
      labels.forEach(node => name += node.textContent + " and ");
      name = name.slice(0, name.length - 4).toLowerCase();
      switch (name.charAt(0)) {
        case 'a':
        case 'e':
        case 'i':
        case 'o':
        case 'u':
          return 'an ' + name;
      }
      return 'a ' + name;
    }
  }
}

