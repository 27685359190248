import {Component, Input} from '@angular/core';
import {BaseChart} from "../base-chart.component";
import {ArcBorderRadius, Chart, ChartConfiguration, Plugin} from "chart.js";
import {AppContext} from "../../../app-context";
import lodash from "lodash";
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {ChartDatasetExtended} from "../base-measurement-chart";
import {TooltipOptions} from "chart.js/dist/types";

@Component({
    selector: 'app-doughnut-chart',
    templateUrl: './doughnut-chart.component.html',
    styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent extends BaseChart<'doughnut'> {
    @Input() doughnutText: string;
    @Input() doughnutHeader: string;
    @Input() doughnutTextColor: string;
    @Input() height: number = 300;
    @Input() withCircumference: boolean;
    @Input() animationDuration: number = 1000;
    useDataLabels = false;
    tooltipCentered = true;
    refreshOptionsOnDataChange = true;

    getOptions(): ChartConfiguration<'doughnut'>['options'] {
        return {
            parsing: false,
            normalized: true,
            maintainAspectRatio: false,
            rotation: this.withCircumference ? 90 : 0,
            animation: {
                duration: this.animationDuration
            },
            elements: {
                arc: {
                    borderRadius: this.withCircumference ? (tick) =>
                        this.borderRadius(tick, this.chartData.datasets) : 0,
                    borderWidth: this.withCircumference ? 1 : 0
                }
            },
            plugins: {
                datalabels: {
                    display: false,
                    color: "rgba(45,45,45,100)",
                    anchor: "center",
                    textAlign: "center"
                },
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: this.chartTitle,
                    font: {
                        size: 16
                    }
                },
                decimation: {
                    enabled: true,
                    algorithm: 'lttb',
                    samples: 500
                },
                tooltip: this.tooltipOptions()
            },
            circumference: this.withCircumference ? this.circumference as any : undefined
        }
    };

    tooltipOptions = (): TooltipOptions<'doughnut'> => (this.withCircumference ? {
        callbacks: {
            labelOverride(dataset: ChartDatasetExtended): string | string[] | void {
                return Array.isArray(dataset.tooltip.labelOverride)
                    ? dataset.tooltip.labelOverride.map(l => `${l}: `) : `${dataset.tooltip.labelOverride}: `;
            }
        }
    } : {
        enabled: false,
        external: null
    }) as any;

    labelsAsIconsPlugin: Plugin<'doughnut'> = {
        id: "labelsAsIconsPlugin",
        afterDraw: (chart) => {
            const {
                ctx,
                data: {
                    datasets,
                },
            } = chart;

            const chartData = chart.getDatasetMeta(0).data;
            const dataSets = datasets.filter(d => d["measurementType"]);

            const widthPerAxis = 66 / dataSets.length;
            const offsets = {
                x: 68 + (widthPerAxis / 2.2),
                y: -10
            };

            chartData.forEach((datapoint) => {
                if (this.withCircumference) {
                    dataSets.map(d => d)
                        .sort((a, b) => a.order < b.order ? 1 : -1)
                        .forEach((dataset, i) => {
                            const measurementType = dataset["measurementType"] as DataType;
                            const name = AppContext.measurementName(measurementType, false);
                            const connectionConfig = AppContext.getConnectionConfig(name as ConnectionType);
                            ctx.textBaseline = 'middle';
                            ctx.fillStyle = AppContext.connectionIconColorFromString(name);
                            ctx.font = '14px bootstrap-icons';
                            ctx.fillText(connectionConfig.icon,
                                datapoint.x + offsets.x + (i * widthPerAxis), datapoint.y + offsets.y);
                        });
                }

                if (this.doughnutText) {
                    ctx.textBaseline = 'middle';
                    ctx.textAlign = "center";
                    ctx.fillStyle = this.doughnutTextColor ? this.doughnutTextColor : "rgb(18, 38, 63)";
                    ctx.font = '500 20px ' + Chart.defaults.font.family;
                    ctx.fillText(this.doughnutText, datapoint.x, datapoint.y - (this.withCircumference ? 8 : 0) + (this.doughnutHeader ? 10 : 0));
                }

                if (this.doughnutHeader) {
                    ctx.textBaseline = 'middle';
                    ctx.textAlign = "center";
                    ctx.fillStyle = "rgb(18, 38, 63)";
                    ctx.font = '500 20px ' + Chart.defaults.font.family;
                    ctx.fillText(this.doughnutHeader, datapoint.x, datapoint.y - (this.withCircumference ? 8 : 0) - (this.doughnutHeader ? 10 : 0));
                }
            });
        },
    }

    circumference = (ctx): number => lodash.sum(ctx.dataset.data) / this.getMaxValue() * 270

    getMaxValue = (): number => lodash.max(this.chartData.datasets.flatMap(d => lodash.sum(d["data"] as number[])));

    borderRadius = (tick, datasets: ChartDatasetExtended[]): number | ArcBorderRadius => {
        const dataset = datasets[tick.datasetIndex];

        const visibleIndices = dataset.data
            .map((value, index) => ({ value, index }))
            .filter(d => d.value !== null && d.value !== undefined && d.value !== 0)
            .map(d => d.index);

        const visibleIndex = visibleIndices.indexOf(tick.index);

        if (visibleIndices.length <= 1) {
            return 10;
        }

        if (visibleIndex === 0) {
            return { outerEnd: 0, outerStart: 10, innerEnd: 0, innerStart: 10 };
        } else if (visibleIndex === visibleIndices.length - 1) {
            return { outerEnd: 10, outerStart: 0, innerEnd: 10, innerStart: 0 };
        } else {
            return 0;
        }
    };
}
