import {Component, ElementRef, Input, TemplateRef, ViewChild} from '@angular/core';
import {Error, ErrorStatus, MuteErrors, ResolveErrors, UnmuteErrors} from "@flowmaps/flowmaps-typescriptmodels";
import moment from "moment";
import {AppContext} from "../../../../app-context";
import {QueryGateway} from "../../../../flux/query-gateway";
import {checkValidity} from "../../../../common/utils";
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {closeModal, openConfirmationModalWithCallback} from "../../../../app-utils";
import {
    ModalConfirmAutofocus,
    ModalConfirmAutofocusData
} from "../../../../common/modal-confirm/modal-confirm.component";

@Component({
    selector: 'app-error-selection',
    templateUrl: './error-selection.component.html',
    styleUrls: ['./error-selection.component.scss']
})
export class ErrorSelectionComponent {
    appContext = AppContext;

    @Input() selectedItems: Error[] = [];
    @Input() cancelMethod: () => void;
    @ViewChild("muteErrorTemplate") muteErrorTemplate: TemplateRef<any>;

    muteDate: string;
    tomorrow = (): string => moment().startOf("day").add(1, "day").toISOString();

    constructor(private elementRef: ElementRef, private queryGateway: QueryGateway) {
    }

    get hasAnyMutedErrors() {
        return this.selectedItems.some(s => s.status === ErrorStatus.MUTED);
    }

    get hasAnyOpenErrors() {
        return this.selectedItems.some(s => s.status === ErrorStatus.OPEN);
    }

    unmuteErrors = () => {
        if (this.selectedItems.length === 0) {
            AppContext.registerError("Please select at least 1 record");
            return;
        }
        if (checkValidity(this.elementRef)) {
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    const command: UnmuteErrors = {
                        errorIds: this.selectedItems.map(s => s.errorId)
                    };
                    sendCommand("com.flowmaps.api.monitoring.errors.UnmuteErrors", command, () => {
                        closeModal();
                        this.queryGateway.removeFromCache("com.flowmaps.api.monitoring.errors.GetErrors");
                        publishEvent("recordUpdated", command);
                        this.cancelMethod();
                    });
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "info",
                title: "Unmute errors",
                innerHtmlMessage: `<p><span>You are about to unmute</span><span class="notranslate fw-bold"> ${this.selectedItems.length} </span><span>bulk errors</span></p><p class="fw-bold">Are you sure you want to execute this action?</p>`,
                confirmText: "Unmute errors",
                cancelText: "Cancel"
            }, 'static');
        }
    }

    muteErrors = () => {
        if (this.selectedItems.length === 0) {
            AppContext.registerError("Please select at least 1 record");
            return;
        }
        if (checkValidity(this.elementRef)) {
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    const command: MuteErrors = {
                        errorIds: this.selectedItems.map(s => s.errorId),
                        deadline: this.muteDate
                    };
                    sendCommand("com.flowmaps.api.monitoring.errors.MuteErrors", command, () => {
                        closeModal();
                        this.queryGateway.removeFromCache("com.flowmaps.api.monitoring.errors.GetErrors");
                        publishEvent("recordUpdated", command);
                        this.muteDate = null;
                        this.cancelMethod();
                    });
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "info",
                title: "Mute errors",
                body: this.muteErrorTemplate,
                confirmText: "Mute errors",
                cancelText: "Cancel"
            }, 'static');
        }
    }

    resolveErrors = () => {
        if (this.selectedItems.length === 0) {
            AppContext.registerError("Please select at least 1 record");
            return;
        }
        if (checkValidity(this.elementRef)) {
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    const command: ResolveErrors = {
                        errorIds: this.selectedItems.map(s => s.errorId)
                    };
                    sendCommand("com.flowmaps.api.monitoring.errors.ResolveErrors", command, () => {
                        closeModal();
                        this.queryGateway.removeFromCache("com.flowmaps.api.monitoring.errors.GetErrors");
                        publishEvent("recordUpdated", command);
                        this.cancelMethod();
                    });
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "info",
                title: "Resolve error",
                innerHtmlMessage: `<p><span>You are about to resolve</span><span class="notranslate fw-bold"> ${this.selectedItems.length} </span><span>bulk errors</span></p><p class="fw-bold">Are you sure you want to execute this action?</p>`,
                confirmText: "Resolve error",
                cancelText: "Cancel"
            }, 'static');
        }
    }
}
