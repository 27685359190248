<div class="form-group mb-3 row" [class]="customClass">
  <div class="col-12 col-form-label">
    <ng-container *ngIf="tooltip; else labelText">
      <span class="form-field-label">
          <ng-container *ngTemplateOutlet="labelText"></ng-container>
        </span>
      <app-tooltip [placement]="'top'">
        <i class="bi bi-info-circle info-icon"></i>
        <div class="tooltipContent">
          <div class="tooltip-card">
            <div class="tooltip-card-body">
              {{tooltip}}
            </div>
          </div>
        </div>
      </app-tooltip>
    </ng-container>
  </div>
  <div class="col-12">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #labelText>
  <ng-container *ngIf="label; else fromTemplate"><label>{{label}}</label></ng-container>
  <span *ngIf="!required && renderOptional" class="form-field-optional text-secondary ">(optional)</span>
</ng-template>

<ng-template #fromTemplate>
  <ng-content select="label"></ng-content>
  <ng-content select=".formGroupLabel"></ng-content>
</ng-template>
