import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ComparatorChain} from "../comparator-chain";
import {AppContext} from "../../app-context";
import {removeItem, replaceItem} from '../utils';
import {EventGateway} from "../../flux/event-gateway";
import {RefdataUtils} from "../../views/refdata/refdata-utils";
import {FacetMatch, FacetResult} from "@flowmaps/flowmaps-typescriptmodels";
import {FacetFilters, FacetStats} from "../facets/facet-filter/facet-filter.component";
import {Observable} from "rxjs";

@Component({
    template: ''
})
export abstract class AbstractOverviewComponent<T> implements OnDestroy, OnInit {
    appContext = AppContext;
    refDataUtils = RefdataUtils;

    @Input() data: T[];
    @Input() readonly: boolean;
    @Input() paging: boolean = false;
    @Input() facetFilters: FacetFilters;
    @Input() loadCount: number = 0;
    @Output() facetFiltersUpdate: EventEmitter<FacetMatch[]> = new EventEmitter<FacetMatch[]>();

    dataSorted: T[];

    _filteredData: T[];
    from: number = 0;
    maxItems: number = 100;
    endReached: boolean;
    loading: boolean;

    maxSelectableItems: number;
    selecting: boolean = false;
    selectedItems: T[] = [];
    filterLocalStorageKey: string;
    ignoredFacets: string[] = [];
    facets: FacetStats[] = [];

    private readonly registration: () => void;

    @Output() dataChanged: EventEmitter<T[]> = new EventEmitter<T[]>();

    constructor(protected eventGateway: EventGateway) {
        this.registration = this.eventGateway.registerLocalHandler(this);
    }

    ngOnDestroy(): void {
        this.registration();
    }

    ngOnInit(): void {
    }

    @Input() set facetResult(facets: FacetResult) {
        this.facets = facets?.facets ? Object.entries(facets.facets).map(e => (<FacetStats>{
            name: e[0],
            values: e[1],
            position: ["alertStatus"].includes(e[0]) ? 'left' : 'right',
            nameFormatter: this.facetNameFormatter,
            valueFormatter: this.getFacetValueFormatter(e[0]),
            translateValues: this.getTranslateValues
        })).filter(e => !this.ignoredFacets?.includes(e.name)) : [];
    }

    @Input() set filteredData(data: T[]) {
        this._filteredData = data;
        this.dataSorted = this._filteredData?.sort(this.comparator.compare).slice(0, Math.max(this.from, this.maxItems));
        if (this.loadCount === 1 && this.facetFilters) {
            this.setFilters(this.getFilters());
        }
    }

    loadNextPage = () => {
        if (!this.endReached && !this.loading) {
            this.from += this.maxItems;
            this.filteredData = this._filteredData;
        }
    }

    removeItem(item: T) {
        removeItem(this.data, item);
        removeItem(this.dataSorted, item);
    }

    addItem(item: T) {
        this.data.unshift(item);
        this.dataSorted.unshift(item);
    }

    replaceItem(predicate: (T) => boolean, item: T) {
        const oldItem = this.data.find(predicate);
        replaceItem(this.data, oldItem, item);
        replaceItem(this.dataSorted, oldItem, item);
    }

    computeItem(predicate: (t: T) => boolean, mapper: (t: T) => T) {
        const oldItem = this.data.find(predicate);
        let newValue = mapper(oldItem);
        replaceItem(this.data, oldItem, newValue);
        replaceItem(this.dataSorted, oldItem, newValue);
    }

    allItemsSelected = (): boolean => this.selectedItems.length === this._filteredData.length;

    toggleSelectAll = (): void => {
        const allSelected = this.allItemsSelected();
        this._filteredData.forEach(d => this.toggleSelectedItem(d, !allSelected));
    };

    toggleSelection = () => {
        this.selecting = !this.selecting;
        if (!this.selecting) {
            this.selectedItems.forEach(c => delete c['selected']);
            this.selectedItems = [];
        }
    };

    toggleSelectedItem = (record: T, value: boolean) => {
        if (value) {
            if (this.maxSelectableItems && this.selectedItems.length === this.maxSelectableItems) {
                AppContext.registerError("A maximum of 10 records is allowed to be selected");
                setTimeout(() => delete record['selected'], 0);
                return;
            }
            if (!this.selectedItems.includes(record)) {
                this.selectedItems.push(record);
            }
        } else {
            this.selectedItems = removeItem(this.selectedItems, record);
        }
        record['selected'] = value;
    }

    recordAdded(upsertCommand: any) {
    }

    recordUpdated(updateCommand: any) {
    }

    recordDeleted(deleteCommand: any) : void {

    }

    abstract comparator: ComparatorChain;

    abstract trackByForRecord(index: number, record: T);

    getTranslateValues: (name: string) => boolean;
    facetNameFormatter: (name: string) => string = null;
    getFacetValueFormatter = (name: string): (value: string) => Observable<string> => null;
    trackByFacetName = (index: number, record: FacetStats) => record.name;

    getFacets = (position: 'left' | 'right' = null): FacetStats[] =>
        position ? this.facets.filter(f => f.position === position) : this.facets;

    onFacetValuesChange(facet: FacetStats, selectedValues: string[]) {
        this.facetFilters = this.facetFilters || {facets: {}};
        if (selectedValues?.length > 0) {
            this.facetFilters.facets[facet.name] = selectedValues;
        } else {
            delete this.facetFilters.facets[facet.name];
        }
        this.endReached = false;
        this.from = 0;
        this.setFilters(this.facetFilters);
    }

    getFacetFilters() {
        return Object.entries(this.facetFilters.facets)
            .map(e => (<FacetMatch>{
                facetName: e[0],
                values: e[1]?.filter(v => v)
            }))
            .filter(f => f.values?.length > 0)
            .filter(f => !this.loadCount || (this.facets || []).find(fs => f.facetName === fs.name));
    }

    getFilters = (): FacetFilters => {
        return (this.filterLocalStorageKey ? JSON.parse(localStorage.getItem(this.filterLocalStorageKey)) : null)
            || this.getDefaultFacetFilters();
    }

    setFilters = (filters: FacetFilters) => {
        this.facetFilters = filters;
        if (this.filterLocalStorageKey) {
            localStorage.setItem(this.filterLocalStorageKey, JSON.stringify(filters));
        }
        this.facetFiltersUpdate.emit(this.getFacetFilters());
    }

    getDefaultFacetFilters = (): FacetFilters => ({
        facets: {}
    });
}

export function addZeroFacetResults(result: FacetResult, facetName: string, ...values: string[]): FacetResult {
    let statusFacets = result.facets[facetName];
    if (!statusFacets) {
        statusFacets = result.facets[facetName] = [];
    }
    values.forEach(value => {
        if (!statusFacets.some(f => f.value === value)) {
            statusFacets.push({value: value, count: 0});
        }
    });
    return result;
}
