import {Component, Input, OnInit} from '@angular/core';
import {AbstractOverviewComponent} from '../../../../common/abstract-overview/abstract-overview.component';
import {ComparatorChain} from '../../../../common/comparator-chain';
import {RefdataUtils} from '../../refdata-utils';
import {openModal} from '../../../../app-utils';
import {AnimationTypes} from '../../../../common/modal/modal.component';
import {
    Authorisation,
    AuthorisationType,
    DeleteUser,
    GiveAuthorisation,
    ManagerSignUpUser,
    Organisation,
    RevokeAuthorisation,
    UserProfile
} from '@flowmaps/flowmaps-typescriptmodels';
import {sendCommand} from '../../../../flux/flux-utils';
import {AppContext} from '../../../../app-context';
import {UserDetailsComponent} from '../user-details/user-details.component';
import {EventGateway} from '../../../../flux/event-gateway';
import {AuthService} from '../../../../utils/auth.service';
import {
    AuthorisationDetailsComponent
} from "../../authorisations/authorisation-details/authorisation-details.component";
import {cloneDeep} from "lodash";
import {removeItem} from "../../../../common/utils";

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent extends AbstractOverviewComponent<UserProfile> implements OnInit {
    refDataUtils = RefdataUtils;
    comparator: ComparatorChain = RefdataUtils.usersComparator;
    @Input() organisationId: string;

    organisations: Organisation[] = [];
    authorisationComparator: ComparatorChain = new ComparatorChain('nominatorOrganisation.info.name', 'nominatorOrganisation.organisationId');

    constructor(eventGateway: EventGateway, private authService: AuthService) {
        super(eventGateway);
    }

    ngOnInit(): void {
        RefdataUtils.getMyOrganisations().subscribe(o => this.organisations = o);
    }

    openUserDetails = (user: UserProfile) => openModal(UserDetailsComponent, user, {
        cssClass: "modal-xl",
        animation: AnimationTypes.fade
    });

    openAuthoriseUserDetails = (user: UserProfile) => openModal(AuthorisationDetailsComponent, <Authorisation>{
        nominee: user.userId,
        type: AuthorisationType.user
    }, {
        cssClass: "modal-xl",
        animation: AnimationTypes.fade
    });

    editAuthorisation = (authorisation: AuthorisationWithOrganisation) => {
        const data = cloneDeep(authorisation.authorisation);
        data["showNominator"] = true;
        openModal(AuthorisationDetailsComponent, data, {
            cssClass: "modal-xl",
            animation: AnimationTypes.fade
        });
    };

    newUser = (): UserProfile => ({
        intermediaryId: AppContext.userProfile.intermediaryId,
        info: {
            language: AppContext.userProfile.info.language,
        },
        receivedAuthorisations: []
    });

    trackByForRecord = (index: number, record: UserProfile) => record.userId;

    recordAdded(command: ManagerSignUpUser | GiveAuthorisation) {
        const commandClass = command["@class"] as string;
        if (commandClass.endsWith("ManagerSignUpUser")) {
            const managerSignupCommand = (command as ManagerSignUpUser);
            this.addItem({
                    userId: managerSignupCommand.info.email,
                    confirmed: true,
                    info: managerSignupCommand.info,
                    receivedAuthorisations: [managerSignupCommand.authorisation]
                }
            );
        } else if (commandClass.endsWith("GiveAuthorisation")) {
            const authorisationCommand = (command as GiveAuthorisation);
            const userProfile = this.data.find(u => u.userId === authorisationCommand.authorisation.nominee);
            userProfile.receivedAuthorisations.push(authorisationCommand.authorisation);
        }
    }

    organisationAuthorisations = (record: UserProfile): AuthorisationWithOrganisation[] => record.receivedAuthorisations
        .filter(a => !a.revoked)
        .filter(a => this.organisations.map(o => o.organisationId).includes(a.nominator))
        .map(a => ({
            authorisation: a,
            nominatorOrganisation: this.organisations.find(o => o.organisationId === a.nominator),
            nomineeUser: record
        }))
        .sort(this.authorisationComparator.compare);

    trackByForAuthorisation = (index: number, authorisation: AuthorisationWithOrganisation) => authorisation.authorisation.authorisationId;

    deleteUser = (user: UserProfile) => sendCommand("com.flowmaps.api.user.DeleteUser", <DeleteUser>{
        userId: user.userId
    }, () => {
        this.removeItem(user);
        AppContext.removeFromCache("com.flowmaps.api.user.FindUsers");
    });

    impersonate = (user: UserProfile) => this.authService.impersonate({userId: user.userId});

    recordUpdated(command: GiveAuthorisation) {
        const commandClass = command["@class"] as string;
        if (commandClass.endsWith("GiveAuthorisation")) {
            const authorisationCommand = (command as GiveAuthorisation);
            const userProfile = this.data.find(u => u.userId === authorisationCommand.authorisation.nominee);
            const oldValue = userProfile.receivedAuthorisations.find(a => a.authorisationId === authorisationCommand.authorisation.authorisationId);
            const indexOfOldValue = userProfile.receivedAuthorisations.indexOf(oldValue);
            userProfile.receivedAuthorisations = removeItem(userProfile.receivedAuthorisations, oldValue);
            setTimeout(() => userProfile.receivedAuthorisations.splice(indexOfOldValue, 0, authorisationCommand.authorisation), 0);
        }
    }

    recordDeleted(command: RevokeAuthorisation) {
        const commandClass = command["@class"] as string;
        if (commandClass.endsWith("RevokeAuthorisation")) {
            this.data.forEach(u => {
                const authorisation = u.receivedAuthorisations.find(
                    a => a.authorisationId === command.authorisationId);
                if (authorisation) {
                    removeItem(u.receivedAuthorisations, authorisation);
                }
            })
        }
    }
}

interface AuthorisationWithOrganisation {
    authorisation?: Authorisation;
    nominatorOrganisation?: Organisation;
    nomineeUser?: UserProfile;
}