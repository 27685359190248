<div class="chart-wrapper xl-content" [ngClass]="{'p-5 pt-0': fullScreen, 'd-flex align-items-center justify-content-center': !showChart}">
    <canvas baseChart class="bar mw-100 h-100 notranslate" [ngClass]="showChart ? '' : 'd-none'"
            [data]="chartData"
            [type]="'bar'"
            [plugins]="[barAxesScalerPlugin, barAxesPercentagePlugin]">
    </canvas>
    <ng-container *ngIf="!showChart">
        <span class="inline-flex">No data to show</span>
    </ng-container>
</div>
