import {Component, EventEmitter, Input, Output} from '@angular/core';
import lodash from "lodash";
import {FacetUtils} from "../facet-utils";
import {removeItem} from "../../utils";
import {FacetValueResult} from "@flowmaps/flowmaps-typescriptmodels";
import {combineLatest, forkJoin, Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {TranslateDirective} from "../../translate.directive";

@Component({
    selector: 'app-facet-filter',
    templateUrl: './facet-filter.component.html',
    styleUrls: ['./facet-filter.component.scss']
})
export class FacetFilterComponent {
    @Output() selectedValuesChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    private _selectedValues: string[];
    private _hasValue: boolean;
    private _facet: FacetStats;

    @Input()
    set selectedValues(values: string[]) {
        values = (Array.isArray(values) ? values : [values]).filter(a => a);
        this._selectedValues = values;
        this._hasValue = values?.length > 0;
    }

    get selectedValues() {
        return this._selectedValues;
    }

    @Input()
    set facet(facet: FacetStats) {
        this._facet = facet;
        this._facet.values = lodash.sortBy(this._facet.values, f => f.value);
        this.setDefaultValueFormatter(facet);
    }

    get facet() {
        return this._facet;
    }

    get hasValue() {
        return this._hasValue;
    }

    protected removeFilter = () => {
        this.selectedValues = null;
        this.selectedValuesChange.emit(this.facet.valuesSelectedCallback
            ? this.facet.valuesSelectedCallback(this.facet, this.selectedValues) : this.selectedValues);
    }

    trackByFacetValue = (index: number, record: FacetValueResult) => record.value;

    facetValueSelected(facetValue: FacetValueResult, selected: boolean) {
        const currentSelection = this.selectedValues || [];
        if (selected) {
            currentSelection.push(facetValue.value);
        } else {
            removeItem(currentSelection, facetValue.value);
        }
        this.selectedValues = currentSelection?.length > 0 ? currentSelection : null;
        this.selectedValuesChange.emit(this.facet.valuesSelectedCallback
            ? this.facet.valuesSelectedCallback(this.facet, this.selectedValues) : this.selectedValues);
    }

    getFacetName = (): string => this.facet.nameFormatter ? this.facet.nameFormatter(this.facet.name) : this.facet.name;

    translateValue = (): boolean => this.facet.translateValues ? this.facet.translateValues(this.facet.name) : false;

    getSelectedValueFormatted(): Observable<string> {
        return this.selectedValues
            ? forkJoin(this.selectedValues.map(v => this.facet.valueFormatter ? this.facet.valueFormatter(v) : of(v)))
                .pipe(map(r => r
                    .map(r => this.translateValue() ? TranslateDirective.getTranslation(r, true) : r)
                    .join(", ")))
            : null;
    }

    private setDefaultValueFormatter = (facet: FacetStats) => {
        if (facet.valueFormatter) {
            return;
        }
        if (FacetUtils.isBooleanFacet(facet)) {
            facet.valueFormatter = (value: string) => of("true" === value ? "Yes" : "false" === value ? "No" : null);
        }
    }
}

export interface FacetStats {
    name: string;
    values: FacetValueResult[];
    position?: 'left' | 'right';
    nameFormatter?: (name: string) => string;
    valueFormatter?: (value: string) => Observable<string>;
    valuesSelectedCallback?: (facet: FacetStats, selectedValues: string[]) => string[];
    translateValues?: (name: string) => boolean;
}

export interface FacetFilters {
    facets: { [key: string]: string[] };
}
