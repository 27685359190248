import {Component, Input} from '@angular/core';
import {GasConnectionInfo} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-gas-details',
  templateUrl: './gas-details.component.html',
  styleUrls: ['./gas-details.component.scss']
})
export class GasDetailsComponent {
  @Input() info: GasConnectionInfo;

  get contractedCapacity() {
    return this.info.connectionValue?.contractedCapacity || this.info.contractedCapacity;
  }
}
