import {Component, Input, OnInit} from '@angular/core';
import {AppContext} from '../../app-context';
import {Router} from '@angular/router';
import {UpdateUserInfo} from "@flowmaps/flowmaps-typescriptmodels";
import {cloneObject} from "../utils";
import {sendCommand} from "../../flux/flux-utils";

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent implements OnInit {
  appContext = AppContext;
  @Input() language;


  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.setLanguage(this.language || AppContext.getPreferredLanguage());
  }

  changeLanguage(language: string) {
    this.setLanguage(language);
    if (AppContext.userProfile) {
      AppContext.userProfile.info.language = language;
      this.updateInfo();
    }
    location.reload();
  }

  setLanguage(language: string) {
    this.language = language;
    if (AppContext.userProfile) {
      AppContext.userProfile.info.language = language;
    }
    AppContext.initialiseLanguage(language);
    const selectField : HTMLSelectElement = document.querySelector("#google_translate_element select");
    for (let i = 0; i < selectField?.children.length; i++) {
      const option = <HTMLOptionElement> selectField.children[i];
      if (option.value === language) {
        selectField.selectedIndex = i;
        selectField.dispatchEvent(new Event('change'));
        //for some reason switching from english to another language requires a 2nd change event
        selectField.dispatchEvent(new Event('change'));
        break;
      }
    }
  }

  updateInfo() {
    const command: UpdateUserInfo = {userId: AppContext.userProfile.userId, info: cloneObject(AppContext.userProfile.info)};
    sendCommand("com.flowmaps.api.user.UpdateUserInfo", command, () => {
      AppContext.userProfile.info = cloneObject(command.info);
    });
  }
}
