import {EventEmitter} from "@angular/core";
import {MeasurementData} from "../views/charts/base-measurement-chart";

export class ChartDataProvider {
    lastValue: MeasurementData = {
        datasets: []
    };
    aggregatedDataChanged = new EventEmitter<MeasurementData>();

    emit(value: MeasurementData) {
        this.aggregatedDataChanged.emit(this.lastValue = value);
    }
}