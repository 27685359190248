import {ChangeDetectorRef, Component} from '@angular/core';
import {BaseMeasurementChartComponent, ChartDatasetExtended, MeasurementDataset} from "../base-measurement-chart";
import {ChartUtilsService} from "../chart-utils.service";
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {ChartDataPerMeasurement} from "../../../utils/measurements-data-provider";
import lodash from "lodash";
import {AppContext} from "../../../app-context";
import {TranslateDirective} from "../../../common/translate.directive";
import {TooltipModel} from "chart.js/dist/types";

@Component({
  selector: 'app-costs-chart-legacy',
  templateUrl: './costs-chart-legacy.component.html',
  styleUrls: ['./costs-chart-legacy.component.css']
})
export class CostsChartLegacyComponent extends BaseMeasurementChartComponent {
  totalCosts: number = 0;

  constructor(protected changeDetectorRef: ChangeDetectorRef, protected chartUtils: ChartUtilsService) {
    super(changeDetectorRef, chartUtils);
  }

  connectionType(): ConnectionType {
    return null;
  }

  consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>();

  measurementIntermediateLink = (): Map<DataType, DataType[]> => new Map<DataType, DataType[]>();

  measurementTypes(): DataType[] {
    return [DataType.electricityConsumptionCosts, DataType.gasConsumptionCosts,
      DataType.heatConsumptionCosts, DataType.waterConsumptionCosts];
  }

  measurementUnit(): string {
    return "€";
  }

  productionDataTypes(): DataType[] {
    return [];
  }

  setData(result: ChartDataPerMeasurement, measurements: MeasurementDataset[], estimatedMeasurements: MeasurementDataset[]) {
    const data = this.measurementTypes().map((m, i) => {
      const d = measurements.find(d => d.measurementType === m) || this.defaultDataset(m);
      const estimatedData = estimatedMeasurements.find(d => d.measurementType === m) || this.defaultDataset(m);
      const value = lodash.round(lodash.sum(d.dataset.data));
      const estimatedValue = lodash.round(lodash.sum(estimatedData.dataset.data));
      return <MeasurementDataset>{
        measurementType: d.measurementType,
        dataset: this.getDataset(d.measurementType, d.dataset.label,
            [value].concat(estimatedValue > 0 ? [estimatedValue] : []), i + 1)
      }
    });
    this.totalCosts = lodash.sum(data.flatMap(d => d.dataset.data));
    this.changeDetectorRef.detectChanges();
    this.chartDataProvider.emit({
      datasets: data.flatMap(d => [d, <any>{
        dataset: {
          weight: 0.7
        }
      }])
    });
  }

  private defaultDataset = (m: DataType): MeasurementDataset => ({
    measurementType: m,
    dataset: this.getDataset(m, AppContext.measurementName(m), [0])
  });

  private getDataset = (measurementType: DataType, label: string, data: number[], order?: number): ChartDatasetExtended => {
    const measurementName = AppContext.measurementName(measurementType);
    return ({
      label: label,
      data: data,
      backgroundColor: [AppContext.getChartColorForMeasurementAsString(measurementType), "#FFFFFF"],
      borderColor: AppContext.getChartColorForMeasurementAsString(measurementType),
      borderWidth: 1,
      order: order,
      measurementType: measurementType,
      tooltip: {
        formatter: this.getTooltipFormatter(),
        labelOverride: data.length > 1
            ? [measurementName, `${measurementName} (${TranslateDirective.getTranslation("estimated", true)})`]
            : measurementName
      }
    });
  };

  getTooltipFormatter(): (ctx: TooltipModel<any>, value: number) => string {
    return (ctx, value) => `${this.measurementUnit()} ${this.chartUtils.transformDecimal(value)}`;
  }
}
