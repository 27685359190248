import {Component, ElementRef, Input, TemplateRef, ViewChild} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {AlertStatus, MuteAlerts, ResolveAlerts, UnmuteAlerts} from "@flowmaps/flowmaps-typescriptmodels";
import {checkValidity} from "../../../../common/utils";
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import moment from "moment";
import {closeModal, openConfirmationModalWithCallback} from "../../../../app-utils";
import {
  ModalConfirmAutofocus,
  ModalConfirmAutofocusData
} from "../../../../common/modal-confirm/modal-confirm.component";
import {QueryGateway} from "../../../../flux/query-gateway";
import {ExtendedAlert} from '../alerts-list/alerts-list.component';

@Component({
  selector: 'app-alert-selection',
  templateUrl: './alert-selection.component.html',
  styleUrls: ['./alert-selection.component.scss']
})
export class AlertSelectionComponent {
  appContext = AppContext;

  @Input() selectedItems: ExtendedAlert[] = [];
  @Input() cancelMethod: () => void;
  @ViewChild("muteAlertTemplate") muteAlertTemplate: TemplateRef<any>;
  muteDate: string;

  tomorrow = (): string => moment().startOf("day").add(1, "day").toISOString();

  constructor(private elementRef: ElementRef, private queryGateway: QueryGateway) {
  }

  get hasAnyMutedAlert() {
    return this.selectedItems.some(s => s.alertStatus === AlertStatus.MUTED);
  }

  get hasAnyOpenAlert() {
    return this.selectedItems.some(s => s.alertStatus === AlertStatus.OPEN);
  }

  unmuteAlerts = () => {
    if (this.selectedItems.length === 0) {
      AppContext.registerError("Please select at least 1 record");
      return;
    }

    if (checkValidity(this.elementRef)) {
      openConfirmationModalWithCallback((confirmed) => {
        if (confirmed) {
          const command: UnmuteAlerts = {
            alertIds: this.selectedItems
                .filter(s => s.alertStatus == AlertStatus.MUTED)
                .map(s => s.alertId)
          };
          sendCommand("com.flowmaps.api.monitoring.alerting.UnmuteAlerts", command,
              () => this.exit(command));
        }
      }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
        type: "info",
        title: "Unmute alerts",
        innerHtmlMessage: `<p><span>You are about to unmute</span><span class="notranslate fw-bold"> ${this.selectedItems.length} </span><span>bulk unmute alerts</span></p><p class="fw-bold">Are you sure you want to execute this action?</p>`,
        confirmText: "Unmute alert",
        cancelText: "Cancel"
      }, 'static');
    }
  }

  muteAlerts = () => {
    if (this.selectedItems.length === 0) {
      AppContext.registerError("Please select at least 1 record");
      return;
    }

    if (checkValidity(this.elementRef)) {
      openConfirmationModalWithCallback((confirmed) => {
        if (confirmed) {
          const command: MuteAlerts = {
            alertIds: this.selectedItems
                .filter(s => s.alertStatus != AlertStatus.RESOLVED)
                .map(s => s.alertId),
            deadline: this.muteDate
          };
          sendCommand("com.flowmaps.api.monitoring.alerting.MuteAlerts", command,
              () => this.exit(command));
        }
      }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
        type: "info",
        title: "Mute alerts",
        body: this.muteAlertTemplate,
        confirmText: "Mute alert",
        cancelText: "Cancel"
      }, 'static');
    }
  }

  resolveAlerts = () => {
    if (this.selectedItems.length === 0) {
      AppContext.registerError("Please select at least 1 record");
      return;
    }
    if (checkValidity(this.elementRef)) {
      openConfirmationModalWithCallback((confirmed) => {
        if (confirmed) {
          const command: ResolveAlerts = {
            alertIds: this.selectedItems
                .filter(s => s.alertStatus != AlertStatus.RESOLVED)
                .map(s => s.alertId)
          };
          sendCommand("com.flowmaps.api.monitoring.alerting.ResolveAlerts", command,
              () => this.exit(command));
        }
      }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
        type: "info",
        title: "Resolve alert",
        innerHtmlMessage: `<p><span>You are about to resolve</span><span class="notranslate fw-bold"> ${this.selectedItems.length} </span><span>bulk resolve alerts</span></p><p class="fw-bold">Are you sure you want to execute this action?</p>`,
        confirmText: "Resolve alert",
        cancelText: "Cancel"
      }, 'static');
    }
  }

  exit = (lastCommand? : any) => {
    closeModal();
    this.muteDate = null;
    if (lastCommand) {
      this.queryGateway.removeFromCache("com.flowmaps.api.monitoring.alerting.GetMyAlerts");
      publishEvent("recordUpdated", lastCommand);
    }
    this.cancelMethod();
  };
}
