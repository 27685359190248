<app-form (submit)="encrypt()">
    <div class="card mb-0">
        <div class="card-header">
            Encrypt value
        </div>
        <div class="card-body">
            <app-form-field label="Value">
                <textarea required class="form-control" [(ngModel)]="value" placeholder="Enter a value"></textarea>
            </app-form-field>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-primary">Encrypt</button>
            </div>
        </div>
    </div>
</app-form>