import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {RefdataUtils} from "../../refdata-utils";
import {Connection, MeterDetails, MeterInfo, MeterType, UpdateMeterDetails} from "@flowmaps/flowmaps-typescriptmodels";
import {QueryGateway} from "../../../../flux/query-gateway";
import {sendCommand} from "../../../../flux/flux-utils";
import {closeModal} from "../../../../app-utils";
import {SentenceCasePipe} from "../../../../common/sentence-case.pipe";

@Component({
    selector: 'app-meter-details',
    templateUrl: './meter-details.component.html',
    styleUrls: ['./meter-details.component.scss']
})
export class MeterDetailsComponent {
    appContext = AppContext;
    refDataUtils = RefdataUtils;
    @Input() data: MeterComponentData;

    meterTypes: MeterType[] = [MeterType.PRIMARY, MeterType.INTERMEDIATE, MeterType.GROSS_PRODUCTION];

    constructor(private queryGateway: QueryGateway, private sentenceCasePipe: SentenceCasePipe) {
    }

    meterTypeFormatter = (v: string): string => this.sentenceCasePipe.transform(v);

    updateInfo = () => sendCommand("com.flowmaps.api.organisation.UpdateMeterDetails", <UpdateMeterDetails>{
        organisationId: this.data.organisationId,
        locationId: this.data.locationId,
        meterId: this.data.meterId,
        connectionId: this.data.connection.connectionId,
        details: this.data.meterDetails
    }, () => {
        closeModal();
        this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
    });
}

export interface MeterComponentData {
    meterId: string;
    organisationId: string;
    locationId: string;
    connection: Connection;
    meterInfo: MeterInfo;
    meterDetails: MeterDetails
}