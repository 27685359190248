<div class="main-content">
    <div class="container-fluid">
        <div class="justify-content-center row">
            <div class="col-12">
                <div class="header">
                    <div class="header-body">
                        <div class="align-items-center row">
                            <div class="col">
                                <h6 class="header-pretitle">Overview</h6>
                                <h1 class="header-title text-truncate">Contracts</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <app-contracts-list [loadCount]="loadCount" [data]="filteredContracts"
                                    (filterChanged)="handleFilterChange($event)">
                </app-contracts-list>
            </div>
        </div>
    </div>
</div>