import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {ChartMeasurementData} from "../../../charts/base-measurement-chart";
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {SourceType} from "../../../dashboard/dashboard.context";

@Component({
    selector: 'app-report-entity-performance-page',
    templateUrl: './report-entity-performance-page.component.html',
    styleUrls: ['./report-entity-performance-page.component.scss']
})
export class ReportEntityPerformancePageComponent {
    appContext = AppContext;
    @Input() chartDataProvider: ChartMeasurementData;
    @Input() consumptionType: DataType;
    @Input() productionType: DataType;
    @Input() connectionType: ConnectionType | string;

    locationType: SourceType = SourceType.location;

    pageSize: number = 10;

    hasSubMeters = (): boolean => this.chartDataProvider.dataProvider.sourceProvider.hasSubMetersForConnectionType(
        this.connectionType as ConnectionType);

    hasSourceForConnectionType = () => this.chartDataProvider.dataProvider.sourceProvider.hasSourceForConnectionType(
        this.connectionType as ConnectionType);

    get showByLocation(): boolean {
        return this.chartDataProvider.dataProvider.sourceProvider.getAllSourcesByType(this.locationType).length > 1;
    }

    get entityConsumptionType(): DataType {
        if (!this.showByLocation) {
            return this.consumptionType === DataType.electricityConsumption
                ? DataType.electricityIntermediateConsumption : this.consumptionType;
        }
        return this.consumptionType;
    }

    get entityProductionType(): DataType {
        if (!this.showByLocation) {
            return this.productionType === DataType.electricityFeedIn
                ? DataType.electricityGrossProduction : this.productionType;
        }
        return this.productionType;
    }
}
