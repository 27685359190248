<ng-container *ngFor="let item of data; index as i; trackBy: trackByTreeViewItemId">
    <div *ngIf="item.visible" class="d-flex flex-column">
        <div class="d-flex flex-row justify-content-end treeview-item" [style]="'padding-left: ' + (depth * 0.75) + 'rem;'"
             [ngClass]="sourceSelectionType(item) === 'all' ? 'bg-info-soft' : ''" [classList]="recordCls">
            <div class="col-auto ms-3 mx-1" style="min-width: 15px; cursor: pointer;" (click)="toggleExpand(item)">
                <i *ngIf="visibleSubItems(item).length > 0" class="bi" [ngClass]="item.expanded ? 'bi-arrow-down' : 'bi-arrow-right'"></i>
            </div>
            <div class="row col g-0" role="button" (click)="toggleSource(item)">
                <div class="col-auto mx-1" style="min-width: 15px;">
                    <i *ngIf="item.icon" class="bi" [ngClass]="item.icon"></i>
                </div>
                <div class="col text-truncate notranslate"><app-highlight [result]="filterTerm && item.info.filteredNameOverride ? item.info.filteredNameOverride : item.info.name" [term]="filterTerm"></app-highlight></div>
            </div>
            <div *ngIf="item.checkbox" style="cursor: pointer;">
                <div class="checkbox" [ngClass]="sourceSelectionType(item) === 'none' ? 'd-none' : 'd-inline-block'"
                     (click)="toggleSource(item)">
                    <ng-container [ngSwitch]="sourceSelectionType(item)">
                        <div *ngSwitchCase="'all'">
                            <i class="text-primary bi bi-check-square"></i>
                        </div>
                        <div *ngSwitchCase="'partially'">
                            <i class="text-primary bi bi-dash-square"></i>
                        </div>
                        <div *ngSwitchCase="'none'">
                            <i class="bi bi-square"></i>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <app-dashboard-sources-list *ngIf="visibleSubItems(item).length > 0 && item.expanded" [data]="visibleSubItems(item)" [depth]="depth + 1"
                                    [dataProvider]="dataProvider" [filterTerm]="filterTerm" [recordCls]="recordCls"
                                    (selectionChange)="selectionChange.emit($event)"></app-dashboard-sources-list>
    </div>
</ng-container>