<div class="row mb-3" *ngIf="!isReadOnly">
    <div class="col-12 col-md-6">
        <app-form-field label="Start Date">
            <app-date-field [(ngModel)]="dateRange.start" [dateOnly]="true" [yearSpan]="15" [required]="isRequired"></app-date-field>
        </app-form-field>
    </div>
    <div class="col-12 col-md-6">
        <app-form-field label="End Date">
            <app-date-field [(ngModel)]="dateRange.end" [atEndOfDay]="true" [dateOnly]="true" [yearSpan]="15" [required]="isRequired"></app-date-field>
        </app-form-field>
    </div>
</div>
<div class="col notranslate hover-show text-nowrap overflow-hidden text-truncate" *ngIf="isReadOnly">
        <ng-container *ngIf="dateRange.start; else infinityTemplate">
            <app-date-field [ngModel]="dateRange.start" [dateOnly]="true" [readonly]="true"></app-date-field>
        </ng-container>
        /
        <ng-container *ngIf="dateRange.end; else infinityTemplate">
            <app-date-field [ngModel]="dateRange.end" [dateOnly]="true" [readonly]="true" [atEndOfDay]="true"></app-date-field>
        </ng-container>
</div>

<ng-template #infinityTemplate>
    <span>∞</span>
</ng-template>
