<div class="row mx-0 p-3 px-md-5 g-3 gy-md-0 justify-content-between">
    <div class="col-12 col-md-auto d-flex align-items-center">
        <span class="notranslate">{{ selectedItems.length }}</span>
        <span class="notranslate">&nbsp;</span>
        <span>selected</span>
    </div>
    <div class="col-md-auto ms-auto">
        <button class="btn btn-outline-danger mx-1 w-100" (click)="cancelMethod()" type="button">
            Cancel
        </button>
    </div>
    <div class="col-md-auto" *ngIf="hasAnyMutedErrors">
        <button class="btn btn-outline-info mx-1 w-100" (click)="unmuteErrors()" type="button">
            Unmute errors
        </button>
    </div>
    <div class="col-md-auto" *ngIf="hasAnyOpenErrors">
        <button class="btn btn-outline-info mx-1 w-100" (click)="muteErrors()" type="button">
            Mute errors
        </button>
    </div>
    <div class="col-md-auto">
        <button class="btn btn-outline-info mx-1 w-100" (click)="resolveErrors()" type="button">
            Resolve errors
        </button>
    </div>
</div>


<ng-template #muteErrorTemplate>
    <p>
        <span>You are about to mute</span>
        <span class="notranslate fw-bold"> {{ selectedItems.length }} </span>
        <span>bulk errors</span>
    </p>
    <p class="fw-bold">Are you sure you want to execute this action?</p>
    <app-form-field label="Mute deadline">
        <app-date-field [(ngModel)]="muteDate" [minDate]="tomorrow()"></app-date-field>
    </app-form-field>
</ng-template>
