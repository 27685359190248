<div class="d-flex align-items-center min-vh-100 bg-auth">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-5 col-xl-4 my-5">

                <!-- Heading -->
                <h1 class="display-4 text-center mb-3">
                    Reset password
                </h1>


                <ng-container *ngIf="token; else noToken">
                    <!-- Subheading -->
                    <p class="text-muted text-center mb-5">
                        Please select a new password for your account.
                    </p>

                    <!-- Form -->
                    <app-form (submit)="resetPassword()">

                        <!-- Email address -->
                        <app-form-field label="Email Address">
                            <input [(ngModel)]="userId" required type="email" class="form-control" [disabled]="true"
                                   placeholder="name@address.com">
                        </app-form-field>

                        <app-form-field label="New Password">
                            <input [(ngModel)]="password" required type="password" class="form-control">
                        </app-form-field>

                        <!-- Submit -->
                        <button type="submit" class="btn btn-lg w-100 btn-primary mb-3">Confirm</button>
                    </app-form>
                </ng-container>

                <ng-template #noToken>
                    <!-- Subheading -->
                    <p class="text-muted text-center mb-5">
                        Please check your inbox at the e-mail address you provided to reset your password.
                    </p>

                    <div class="text-center">
                        <small class="text-muted text-center">
                            <span class="me-1">Did you not receive an e-mail with a password reset link? Check your spam folder, or</span>
                            <a routerLink="/forgot-password">request a password reset again</a><span class="notranslate">.</span></small>
                    </div>
                </ng-template>


            </div>
        </div> <!-- / .row -->
    </div>
</div>
