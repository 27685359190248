import {DataType} from "@flowmaps/flowmaps-typescriptmodels";
import lodash from "lodash";
import {Injectable} from "@angular/core";
import {DecimalPipe} from "@angular/common";
import {DashboardContext} from "../dashboard/dashboard.context";
import {TooltipModel} from "chart.js/dist/types";

@Injectable()
export class ChartUtilsService {

    constructor(private decimalPipe: DecimalPipe) {

    }

    percentageFormatter = (value: number): string => `${this.transformDecimal(lodash.round(value * 100, 1))}%`;

    getCustomTooltipFormatter(measurementType: DataType, showBySquareMeter: boolean = false): (ctx: TooltipModel<any>, value: number) => string {
        switch(measurementType) {
            case DataType.sunHours: return (ctx: TooltipModel<any>, value: number) => this.percentageFormatter(value);
            default: return (ctx: TooltipModel<any>, value: number) => `${this.transformDecimal(value)} ${DashboardContext.getMeasurementUnit(measurementType)}${showBySquareMeter ? '/m²' : ''}`;
        }
    }

    getNumberTooltipFormatter(): (ctx: TooltipModel<any>, value: number) => string {
        return (ctx: TooltipModel<any>, value) => `${this.transformDecimal(value)}`;
    }

    transformDecimal = (value: number) => this.decimalPipe.transform(value);
}