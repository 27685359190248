import {Component, Input} from '@angular/core';
import {ErrorEntry} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../../refdata/refdata-utils";

@Component({
    selector: 'app-error-entry',
    templateUrl: './error-entry.component.html',
    styleUrls: ['./error-entry.component.css']
})
export class ErrorEntryComponent {
    @Input() entry: ErrorEntry;

    formattedLevel: string;
    formattedEnvironment: string;

    ngOnInit(): void {
        this.formatEnumValues();
    }

    isFullStackTraceDisplayed: boolean = true;

    toggleStackTrace() {
        this.isFullStackTraceDisplayed = !this.isFullStackTraceDisplayed;
    }

    getStackTrace(): string {
        if (this.isFullStackTraceDisplayed) {
            return this.entry.errorDetails.stackTrace;
        } else {
            return this.entry.errorDetails.stackTrace
                .split('\n')
                .filter(line => {
                    const trimmedLine = line.trim();
                    return !trimmedLine.startsWith('at ') || trimmedLine.startsWith('at com.flowmaps.');
                })
                .join('\n');
        }
    }

    formatEnumValues(): void {
        this.formattedLevel = RefdataUtils.enumFormatter(this.entry.errorDetails.level);
        this.formattedEnvironment = RefdataUtils.enumFormatter(this.entry.errorDetails.environment);
    }

    getKibanaUrl(fieldValue: string): string {
        return `https://kibana.flowmaps.com/_dashboards/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now%2Fd,to:now%2Fd))&_a=(columns:!(type,messageType,payload),filters:!(),index:'91805d20-606b-11ee-a969-e30f3e4e5628',interval:auto,query:(language:kuery,query:\"${encodeURIComponent(fieldValue)}\"),sort:!())`;
    }
}

