import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {closeModal} from "../../../../app-utils";
import {getConsumptionUnit, uuid} from "../../../../common/utils";
import {QueryGateway} from "../../../../flux/query-gateway";
import {RefdataUtils} from "../../refdata-utils";
import {
    ContractPeriod,
    ContractPeriodData,
    ServiceProvider,
    SustainabilitySource,
    UpsertContractPeriod
} from "@flowmaps/flowmaps-typescriptmodels";
import {ExtendedContract} from "../contracts-list/contracts-list.component";
import {DashboardContext} from "../../../dashboard/dashboard.context";

@Component({
    selector: 'app-contract-period-details',
    templateUrl: './contract-period-details.component.html',
    styleUrls: ['./contract-period-details.component.css']
})
export class ContractPeriodDetailsComponent implements OnInit {
    @Input() data: ContractPeriodComponentData;
    isNewRecord: boolean;
    protected readonly refDataUtils = RefdataUtils;
    context = DashboardContext;

    constructor(private queryGateway: QueryGateway, private elementRef: ElementRef) {}

    ngOnInit(): void {
        this.isNewRecord = !this.data.period || !this.data.period.periodId;
        if (this.isNewRecord) {
            this.data.period = {
                periodId: uuid(),
                data: {
                    dateRange: {
                        start: this.data.contract.lastEndDate || '',
                        end: ''
                    },
                    offPeakRate: null,
                    rate: null,
                    feedInRate: null,
                    feedInOffPeakRate: null,
                    standingCharge: null,
                    sustainabilitySource: this.data.contract.lastSustainabilitySource || null,
                    supplier: null
                } as ContractPeriodData,
            };
        }
    }

    standingChargeUnit = () => "p.m."
    costUnit = () => "€"
    getConsumptionUnit = getConsumptionUnit;

    updateContractPeriod = () => {
        const command: UpsertContractPeriod = {
            contractId: this.data.contract.contractId,
            organisationId: this.data.organisationId,
            periodId: this.data.period.periodId,
            data: this.data.period.data
        }
        sendCommand("com.flowmaps.api.organisation.UpsertContractPeriod", command, () => {
            const periodIndex = this.data.contract.periods.findIndex(p => p.periodId === this.data.period.periodId);
            if (periodIndex > -1) {
                this.data.contract.periods[periodIndex] = this.data.period;
            } else {
                this.data.contract.periods.push(this.data.period);
            }
            closeModal();
            publishEvent('contractPeriodUpdated', command);
        });
    }
}

export interface ContractPeriodComponentData {
    period: ContractPeriod;
    contract: ExtendedContract;
    organisationId: string;
    sustainabilitySource: SustainabilitySource;
    supplier: ServiceProvider;
}
