<div #container class="d-flex align-items-stretch" style="flex: 1" [ngClass]="{'mb-0': _data.fullScreen || showInReport, 'card': !showInReport, 'report-view': showInReport}">
    <ng-container *ngIf="!showInReport">
        <div class="card-header dropdown">
            <h4 class="card-header-title">
                <img src="assets/images/icons/leaf.png" width="20px" class="me-2">
                Energy compass
            </h4>
            <button *ngIf="!_data.fullScreen" type="button" class="btn" role="button" (click)="openModal()"><i class="bi bi-arrows-angle-expand"></i></button>
            <app-screenshot-button [element]="container"></app-screenshot-button>
            <button type="button" class="btn" role="button" (click)="toggleShowAsTable()"><i class="bi" [ngClass]="options.showAsTable ? 'bi-bar-chart-line' : 'bi-table'"></i></button>
            <button type="button" class="btn" role="button" data-bs-toggle="dropdown"
                    data-bs-auto-close="outside" aria-expanded="false">
                <i class="bi bi-three-dots-vertical"></i>
            </button>
            <div class="dropdown-menu" style="z-index: 1050;" (change)="saveSettings()">
                <div><h4 class="dropdown-header">Energy labels</h4></div>
                <div class="dropdown-item">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="getInputId('show-unknown')"
                               [checked]="settings.showUnknown" (click)="toggleShowUnknown()">
                        <label class="form-check-label" [for]="getInputId('show-unknown')">
                            Show unknown
                        </label>
                    </div>
                </div>
                <div><h4 class="dropdown-header">Building types</h4></div>
                <div *ngFor="let plottedBuildingType of plottedBuildingTypes" class="dropdown-item">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="getInputId(plottedBuildingType.buildingTypeId)"
                               [checked]="settings.shownBuildingTypes[plottedBuildingType.buildingTypeId]" (click)="togglePlottedBuildingType(plottedBuildingType)">
                        <label class="form-check-label" [for]="getInputId(plottedBuildingType.buildingTypeId)">
                            {{plottedBuildingType.info.name}}
                        </label>
                    </div>
                </div>
            </div>
            <button *ngIf="_data.fullScreen" type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()"></button>
        </div>
    </ng-container>
    <div class="chart-body pt-0 d-flex align-items-stretch" [ngClass]="{'p-0': options.showAsTable, 'card-body': !showInReport}">
        <ng-container *ngIf="showInReport else notInReport">
            <ng-container [ngTemplateOutlet]="chart"></ng-container>
            <ng-container [ngTemplateOutlet]="tableView"></ng-container>
        </ng-container>
        <ng-template #notInReport>
            <ng-container *ngIf="options.showAsTable else showChart">
                <ng-container [ngTemplateOutlet]="tableView"></ng-container>
            </ng-container>
            <ng-template #showChart>
                <ng-container [ngTemplateOutlet]="chart"></ng-container>
            </ng-template>
        </ng-template>
    </div>
</div>

<ng-template #chart>
    <app-scatter-chart class="chart chart-appended h-100 w-100" [xLabels]="energyLabels" [yLabels]="weiiLabels"
                       [measurementsProvider]="_data.dataProvider" [dataProvider]="chartDataProvider" [fullScreen]="_data.fullScreen"
                       [visibleBuildingTypes]="visibleBuildingTypes" [plugins]="plugins" [pluginOptions]="pluginOptions"
                       [gridLineColor]="gridLineColor" [suggestedMinY]="suggestedMinY" [scales]="scales"></app-scatter-chart>
</ng-template>

<ng-template #tableView>
    <app-energy-compass-table class="w-100 h-100 d-flex align-items-stretch flex-column" [dataProvider]="chartDataProvider"
                              [settings]="settings" [energyLabels]="energyLabels"></app-energy-compass-table>
</ng-template>