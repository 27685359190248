<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-8">

            <!-- Header -->
            <div class="header mt-md-5">
                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col">

                            <!-- Pretitle -->
                            <h6 class="header-pretitle">
                                Modify
                            </h6>

                            <!-- Title -->
                            <h1 class="header-title">
                                Account
                            </h1>

                        </div>
                    </div> <!-- / .row -->
                    <div class="row align-items-center">
                        <div class="col">

                            <!-- Nav -->
                            <ul class="nav nav-tabs nav-overflow header-tabs">
                                <li class="nav-item">
                                    <a routerLink="/profile" class="nav-link">
                                        General
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/profile/settings" class="nav-link">
                                        Security
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <router-outlet></router-outlet>



            <br><br>

        </div>
    </div> <!-- / .row -->
</div>
