<app-form (submit)="updateInfo()">
    <div class="card">
        <div class="card-header">
            <h3 class="card-header-title">
                <span>{{ isNewRecord ? 'Add tax' : 'Edit tax' }}</span>
            </h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-form-field label="Country">
                        <input required [(ngModel)]="data.taxInfo.country" appDefaultValue="NL" type="text" class="form-control">
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Connection type">
                        <app-select required [(ngModel)]="data.taxInfo.connectionType" [options]="connectionTypes">
                        </app-select>
                    </app-form-field>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>