import {AfterContentInit, Component, ContentChildren, Input, QueryList} from '@angular/core';
import {TabItemComponent} from "./tab-item/tab-item.component";

@Component({
  selector: 'app-tab-panel',
  templateUrl: './tab-panel.component.html',
  styleUrls: ['./tab-panel.component.scss'],
})
export class TabPanelComponent implements AfterContentInit {
  @ContentChildren(TabItemComponent) tabs!: QueryList<TabItemComponent>;
  activeComponent!: TabItemComponent;
  @Input() activeIndex: number = 0;

  ngAfterContentInit() {
    this.activeComponent = this.tabs.toArray()[this.activeIndex];
  }

  activateTab(tab: TabItemComponent) {
    this.activeComponent = tab;
  }
}
