import {Component} from '@angular/core';
import {GetOrganisation, Organisation, UpdateOrganisationInfo} from "@flowmaps/flowmaps-typescriptmodels";
import {Observable} from "rxjs";
import {sendCommand, sendQuery} from "../../../../flux/flux-utils";
import {ActivatedRoute, Router} from "@angular/router";
import {ExtendedConnection} from "../../connections/connections-overview/connections-overview.component";
import {QueryGateway} from "../../../../flux/query-gateway";
import {AppContext} from "../../../../app-context";
import {ExtendedLocation} from "../../locations/locations-list/locations-list.component";
import {RefdataUtils} from "../../refdata-utils";

@Component({
    selector: 'app-organisation-details',
    templateUrl: './organisation-details.component.html',
    styleUrls: ['./organisation-details.component.scss']
})
export class OrganisationDetailsComponent {
    appContext = AppContext;
    organisationId: string;
    organisation: Organisation;

    locations: ExtendedLocation[];
    connections: ExtendedConnection[];

    constructor(private router: Router, private route: ActivatedRoute, private queryGateway: QueryGateway) {
        route.params.subscribe(params => {
            route.queryParams.subscribe(queryParams => {
                this.organisationId = params['organisationId'];

                if (!this.organisationId) {
                    router.navigateByUrl("/organisations");
                    return;
                }

                this.getOrganisation().subscribe(o => {
                    this.organisation = o;
                    this.locations = this.getLocations();
                    this.connections = this.getConnections();
                });
            });
        });
    }

    getOrganisation = (): Observable<Organisation> => sendQuery("com.flowmaps.api.organisation.GetOrganisation", <GetOrganisation>{
        organisationId: this.organisationId
    });

    getLocations = (): ExtendedLocation[] => this.organisation.locations
        .map(l => RefdataUtils.addOrganisationIdsToLocation(l, this.organisation));

    getConnections = (): ExtendedConnection[] => this.organisation.locations.flatMap(l => l.connections
        .map(m => (<ExtendedConnection>{
            intermediaryId: this.organisation?.intermediaryId,
            organisationId: this.organisationId,
            organisationInfo: this.organisation?.info,
            locationId: l.locationId,
            locationInfo: l.info,
            connection: m
        })));

    updateInfo = () => sendCommand("com.flowmaps.api.organisation.UpdateOrganisationInfo", <UpdateOrganisationInfo>{
        organisationId: this.organisation.organisationId,
        info: this.organisation.info
    }, () => {
        AppContext.registerSuccess("Organisation details updated");
        this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
    });
}