import {EnergyLabels} from "../../../common/energy-label/energy-label.component";
import {map, Observable} from "rxjs";
import {BuildingType, DataType, FindBuildingTypes, GetBuildingType} from "@flowmaps/flowmaps-typescriptmodels";
import {sendQuery} from "../../../flux/flux-utils";
import lodash from "lodash";
import {RefdataUtils} from "../../refdata/refdata-utils";

export class EnergyCompassUtils {
    static labels = Object.values(EnergyLabels).filter((v) => isNaN(Number(v)));

    static getBuildingType = (buildingTypeId: string): Observable<BuildingType> =>
        sendQuery("com.flowmaps.api.refdata.GetBuildingType", <GetBuildingType>{
            buildingTypeId: buildingTypeId
        }, {
            caching: true
        });

    static findBuildingTypes = (term?: string): Observable<BuildingType[]> =>
        sendQuery("com.flowmaps.api.refdata.FindBuildingTypes", <FindBuildingTypes>{term: term})
            .pipe(map(buildingTypes => buildingTypes.sort(RefdataUtils.buildingTypesComparator.compare)));

    static getValueWithFactor(measurementType: DataType, values: number[]): number {
        switch (measurementType) {
            case DataType.electricityConsumption:
            case DataType.electricityConsumptionOffPeak:
                return lodash.sum(values);
            case DataType.gasConsumption:
                return lodash.sum(values) * 9.77;
            case DataType.heatConsumption:
                return (lodash.sum(values) * 278) * 0.33;
            case DataType.electricityFeedIn:
            case DataType.electricityFeedInOffPeak:
                return -(lodash.sum(values));
            default:
                return 0;
        }
    }
}