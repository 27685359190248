<app-form (submit)="updateInfo()">
    <div class="card">
        <div class="card-header">
            <h3 class="card-header-title">
                <app-connection-image [connectionType]="data.connection.info.connectionType"></app-connection-image>
                <span>Add solar edge meter</span>
            </h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-form-field label="Site id">
                        <input [(ngModel)]="siteId" required type="text" class="form-control">
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Kiosk URL">
                        <input [(ngModel)]="kioskUrl" type="text" class="form-control">
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Api key">
                        <input [(ngModel)]="apiKey" required type="text" class="form-control">
                    </app-form-field>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>