import {Component, Input} from '@angular/core';
import {
    Error,
    ErrorStatus,
    MuteError,
    MuteErrors,
    ReopenError,
    ResolveError,
    ResolveErrors,
    UnmuteError
} from "@flowmaps/flowmaps-typescriptmodels";
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import moment from "moment";
import {closeModal} from "../../../../app-utils";
import {Router} from "@angular/router";

@Component({
    selector: 'app-error-actions-dropdown',
    templateUrl: './error-actions-dropdown.component.html',
    styleUrls: ['./error-actions-dropdown.component.css']
})
export class ErrorActionsDropdownComponent {
    @Input() error: Error;
    muteDate: string;

    protected readonly closeModal = () => {
        closeModal();
        this.router.navigate(['/errors']);
    };

    constructor(private router: Router) {}


    resolveError = (error: Error) => {
        sendCommand("com.flowmaps.api.monitoring.errors.ResolveError", <ResolveError>{
            errorId: error.errorId
        }, () => {
            error.status = ErrorStatus.RESOLVED;
            publishEvent('reloadOverview');
            this.closeModal();
        });
    }

    unmuteError = (error: Error) => {
        sendCommand("com.flowmaps.api.monitoring.errors.UnmuteError", <UnmuteError>{
            errorId: error.errorId
        }, () => {
            error.status = ErrorStatus.OPEN;
            error.muteDeadline = null;
            publishEvent('reloadOverview');
            this.closeModal();

        });
    }

    muteError(error: Error) {
        sendCommand("com.flowmaps.api.monitoring.errors.MuteError", <MuteError>{
            errorId: error.errorId,
            deadline: this.muteDate
        }, () => {
            error.status = ErrorStatus.MUTED;
            error.muteDeadline = this.muteDate;
            this.muteDate = null;
            publishEvent('reloadOverview');
            this.closeModal();

        });
    }

    reopenError = (error: Error) => {
        sendCommand("com.flowmaps.api.monitoring.errors.ReopenError", <ReopenError>{
            errorId: error.errorId,
            errorDetails: error.errorDetails,
        }, () => {
            error.status = ErrorStatus.OPEN;
            publishEvent('reloadOverview');
            this.closeModal();

        });
    }
    setMuteDeadline(duration: string) {
        switch (duration) {
            case '1d':
                this.muteDate = moment().add(1, 'day').toISOString();
                break;
            case '1w':
                this.muteDate = moment().add(1, 'week').toISOString();
                break;
            case '1m':
                this.muteDate = moment().add(1, 'month').toISOString();
                break;
            case 'indefinite':
                this.muteDate = null;
                break;
        }
        this.muteError(this.error);
    }

    recordUpdated(upsertCommand: MuteErrors | ResolveErrors) {
        publishEvent('reloadOverview');
    }
}
