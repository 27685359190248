import {Component} from '@angular/core';
import {ChangeUserPassword} from '@flowmaps/flowmaps-typescriptmodels';
import {sendCommand} from '../../../flux/flux-utils';
import {AppContext} from '../../../app-context';

@Component({
  selector: 'app-edit-profile-security',
  templateUrl: './edit-profile-security.component.html',
  styleUrls: ['./edit-profile-security.component.css']
})
export class EditProfileSecurityComponent {
  changePasswordCommand : ChangeUserPassword= {
    userId: AppContext.userProfile.userId
  };

  changePassword = () => {
    sendCommand("com.flowmaps.api.authentication.ChangeUserPassword", this.changePasswordCommand, () => {
      AppContext.registerSuccess("You have successfully changed your password");
      this.changePasswordCommand = {
        userId: AppContext.userProfile.userId
      };
    });
  };

}
