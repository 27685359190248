<div class="d-flex align-items-center min-vh-100 bg-auth">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-5 col-xl-4 my-5">

                <!-- Heading -->
                <h1 class="display-4 text-center mb-3">
                    Sign up
                </h1>

                <!-- Subheading -->
                <p class="text-muted text-center mb-5">
                    For access to your dashboard.
                </p>

                <!-- Form -->
                <app-form (submit)="signUpUser()">

                    <!-- Email address -->
                    <app-form-field label="Email Address">
                        <input [(ngModel)]="command.info.email" required type="email" class="notranslate form-control"
                               placeholder="name@address.com">
                    </app-form-field>

                    <app-form-field label="First name">
                        <input [(ngModel)]="command.info.firstName" required type="text" class="form-control">
                    </app-form-field>

                    <app-form-field label="Last name">
                        <input [(ngModel)]="command.info.lastName" required type="text" class="form-control">
                    </app-form-field>

                    <!-- Password -->
                    <app-form-field>
                        <div class="row">
                            <div class="col">

                                <!-- Label -->
                                <label class="form-label">Password</label>

                            </div>
                        </div> <!-- / .row -->

                        <!-- Input group -->
                        <div class="input-group input-group-merge">

                            <!-- Input -->
                            <input [(ngModel)]="command.password" required class="form-control" type="password"
                                   autocomplete="new-password"
                                   placeholder="Enter your password">

                            <!-- Icon -->
                            <span class="input-group-text rounded-end">
                                <i class="fe fe-eye"></i>
                            </span>

                        </div>
                    </app-form-field>

                    <!-- Submit -->
                    <button type="submit" class="btn btn-lg w-100 btn-primary my-3">
                        Sign up
                    </button>

                    <!-- Link -->
                    <div class="text-center">
                        <small class="text-muted text-center"><span class="me-1">Already have an account?</span> <a href="sign-in.html">Sign in</a><span class="notranslate">.</span></small>
                    </div>

                </app-form>

            </div>
        </div> <!-- / .row -->
    </div>
</div>
