import {Component, Input, OnInit} from '@angular/core';
import {ExtendedContract} from "../../contracts/contracts-list/contracts-list.component";
import {LinkContractToOrganisation, LinkedContract, Organisation,} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../refdata-utils";
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {closeModal} from "../../../../app-utils";
import {Router} from "@angular/router";
import {QueryGateway} from "../../../../flux/query-gateway";
import {uuid} from "../../../../common/utils";
import {map, Observable} from "rxjs";

@Component({
    selector: 'app-link-contract-organisation',
    templateUrl: './link-contract-organisation.component.html',
    styleUrls: ['./link-contract-organisation.component.css']
})
export class LinkContractOrganisationComponent implements OnInit {
    refDataUtils = RefdataUtils;

    contract: ExtendedContract;
    organisation: Organisation;
    @Input() data: LinkContractToOrganisationData;
    isNewRecord: boolean;

    constructor(private queryGateway: QueryGateway, private router: Router) {
    }

    ngOnInit(): void {
        this.isNewRecord = !this.data || !this.data.linkedContract.linkId;
        if (this.data.linkedContract.contractId){
            RefdataUtils.getOrganisation(this.data.organisationId).subscribe(o => {
                this.organisation = o
            })
            RefdataUtils.getContract(this.data.linkedContract.contractId)
                .pipe(map(c => RefdataUtils.addOrganisationIdsToContracts(c, this.organisation)))
                .subscribe(contract => {
                    this.contract = contract;
                    this.data.contract = contract;
                });

        }
        if (this.isNewRecord) {
            this.data = {
                linkedContract: {
                    linkId: uuid(),
                    contractId: this.data.linkedContract.contractId || '',
                    dateRange: {
                        start: this.data.linkedContract.dateRange.start || '',
                        end: ''
                    },
                } as LinkedContract,
                organisationId: this.data.organisationId,
                contract: this.data.contract || null
            };
        }
    }

    onContractSelected = (selectedContract: ExtendedContract) => {
        this.contract = selectedContract;
        this.data.linkedContract.contractId = selectedContract.contractId;
    }

    updateLinkedContract() {
        let command = <LinkContractToOrganisation>{
            organisationId: this.data.organisationId,
            linkedContract: this.data.linkedContract,
            contractName: this.contract.contractData.name,
            linkId: this.data.linkedContract.linkId
        };
        sendCommand("com.flowmaps.api.organisation.LinkContractToOrganisation", command, () => {
            publishEvent('contractLinked', { organisationCommands: [command], connectionCommands: [] });
            closeModal();
        });
    }

    getNonDefaultContracts = (term?: string): Observable<ExtendedContract[]> => {
        return RefdataUtils.getMyContracts(term).pipe(
            map(contracts => contracts.filter(contract => !contract.defaultContract))
        );
    }
}

export class LinkContractToOrganisationData {
    organisationId: string;
    linkedContract: LinkedContract;
    contract?: ExtendedContract;
}

