import {AfterViewInit, Component, EventEmitter, Input, NgZone, Output, ViewChild} from '@angular/core';
import * as L from "leaflet";
import {LatLng, latLng, Layer, MapOptions, tileLayer} from "leaflet";
import {Location} from "@flowmaps/flowmaps-typescriptmodels";
import {LeafletDirective} from "@asymmetrik/ngx-leaflet";
import 'leaflet.markercluster';

@Component({
    selector: 'app-dashboard-maps',
    templateUrl: './dashboard-maps.component.html',
    styleUrls: ['./dashboard-maps.component.scss']
})
export class DashboardMapsComponent implements AfterViewInit {
    private _locations: Location[];
    private _cachedMarkers: { [id: string]: Marker };

    @ViewChild(LeafletDirective) private map: LeafletDirective;
    @Output() visibleLayers: EventEmitter<Location[]> = new EventEmitter<Location[]>();
    @Input() height: number = 300;

    private markers: any;

    options: MapOptions = {
        layers: [
            tileLayer('https://b.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {maxZoom: 18, attribution: '...'})
        ],
        zoom: 6,
        center: latLng(52.1595, 5.1670)
    };

    constructor(private ngZone: NgZone) {
    }

    ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this.setMarkers();
            setTimeout(() => this.emitVisibleLayers(), 0);
        });
    }

    protected emitVisibleLayers() {
        const foundMarkers: Location[] = [];
        this.map.map.eachLayer(layer => {
            const latLong: LatLng = layer["_latlng"];
            if (latLong && this.map.map.getBounds().contains(latLong)) {
                const foundMarker = Object.keys(this._cachedMarkers).find(key => this._cachedMarkers[key]?.layer === layer);
                if (foundMarker) {
                    foundMarkers.push(this._locations.find(s => s.locationId === foundMarker));
                }
            }
        });
        this.visibleLayers.emit(foundMarkers);
    }

    @Input()
    set locations(sources: Location[]) {
        this._locations = sources;

        if (this.map) {
            this.ngZone.runOutsideAngular(() => this.setMarkers());
        }
    }

    private setMarkers() {
        Object.values(this._cachedMarkers || {})
            .filter(m => !this._locations.map(this.keyFromAddress).includes(m.id))
            .forEach(v => this.map.map.removeLayer(v.layer));
        this._cachedMarkers = {};

        if (!this.markers) {
            this.markers = (L as any).markerClusterGroup({
                chunkedLoading: true
            });
            this.map.map.addLayer(this.markers);
        }

        this.markers.clearLayers();
        const markers: L.Marker[] = [];
        this._locations.filter(l => l.info.address.geoLocation).forEach((a, i) => {
            const marker = L.marker([a.info.address.geoLocation.latitude, a.info.address.geoLocation.longitude]);
            const id = this.keyFromAddress(a);
            this._cachedMarkers[id] = {
                id: id,
                layer: marker
            };
            markers.push(marker);
        });
        this.markers.addLayers(markers);
    }

    private keyFromAddress = (a: Location) => `${a.info.address.geoLocation.latitude}-${a.info.address.geoLocation.longitude}`;
}

interface Marker {
    id: string;
    layer: Layer;
}
