import {CoreScaleOptions, Plugin, Scale, Tick} from "chart.js";
import {Chart} from "chart.js/dist/types";

export abstract class CustomAxesPlugin implements Plugin<'scatter'> {
    id = "customAxesPlugin";

    getPositionOfTickX(chart: Chart, tick: Tick, axis: Scale<CoreScaleOptions>) {
        const {chartArea: {left, top, width, height}} = chart;
        const widthOfAxis = ((width * 0.92) / (axis.ticks.length - 1));
        return {
            x: left + (axis.ticks.indexOf(tick) * widthOfAxis),
            y: top + height
        }
    }

    getPositionOfTickY(chart: Chart, tick: Tick, axis: Scale<CoreScaleOptions>) {
        const {chartArea: {top, height}} = chart;
        const bottom = top + height;
        const heightOfAxis = (height / (axis.ticks.length - 1));
        return {
            x: 0,
            y: bottom - ((axis.ticks.indexOf(tick) + 1) * heightOfAxis)
        }
    }
}