import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {
    Connection,
    Contract,
    LinkContractToConnection,
    LinkedContract,
    Organisation,
} from "@flowmaps/flowmaps-typescriptmodels";
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {closeModal} from "../../../../app-utils";
import {QueryGateway} from "../../../../flux/query-gateway";
import {uuid} from "../../../../common/utils";
import {RefdataUtils} from "../../refdata-utils";
import {ExtendedContract} from "../../contracts/contracts-list/contracts-list.component";
import {map, Observable} from "rxjs";

@Component({
    selector: 'app-link-contract-connection',
    templateUrl: './link-contract-connection.component.html',
    styleUrls: ['./link-contract-connection.component.css']
})
export class LinkContractConnectionComponent implements OnInit {

    @Input() data: LinkContractToConnectionComponentData;
    isNewRecord: boolean;
    contract: ExtendedContract;
    connection: Connection
    organisation: Organisation;
    protected readonly refDataUtils = RefdataUtils;

    constructor(private queryGateway: QueryGateway, private elementRef: ElementRef) {
    }

    ngOnInit(): void {
        if (this.data.linkedContract.contractId) {
            RefdataUtils.getOrganisation(this.data.organisationId).subscribe(o => {
                this.organisation = o
            })
            RefdataUtils.getContract(this.data.linkedContract.contractId)
                .pipe(map(c => RefdataUtils.addOrganisationIdsToContracts(c, this.organisation)))
                .subscribe(contract => {
                    this.contract = contract;
                    this.data.contract = contract;
                });

        }
        this.isNewRecord = !this.data.linkedContract.linkId;
        if (this.isNewRecord) {
            this.data = {
                linkedContract: {
                    linkId: uuid(),
                    contractId: this.data.linkedContract.contractId || '',
                    dateRange: {
                        start: this.data.linkedContract.dateRange.start || '',
                        end: ''
                    },
                } as LinkedContract,
                organisationId: this.data.organisationId,
                connectionId: this.data.connectionId,
                locationId: this.data.locationId,
                contract: this.data.contract || null
            };
        }
        RefdataUtils.getConnection(this.data.organisationId, this.data.connectionId).subscribe(con => {
            this.connection = con;
        })
    }

    updateConnectionContractPeriod = () => {
        if (this.contract && this.contract.contractData.connectionType === this.connection.info.connectionType) {
            let command = <LinkContractToConnection>{
                linkedContract: this.data.linkedContract,
                connectionId: this.data.connectionId,
                locationId: this.data.locationId,
                organisationId: this.data.organisationId,
                linkId: this.data.linkedContract.linkId
            }
            sendCommand("com.flowmaps.api.organisation.LinkContractToConnection", command, () => {
                publishEvent('contractLinked', {organisationCommands: [], connectionCommands: [command]});
                closeModal();
            });
        } else {
            console.error('Contract type does not match connection type');
        }
    }

    searchContract = (term: string): Observable<Contract[]> =>
        RefdataUtils.getMyContracts(term)
            .pipe(
                map(c => c.filter(contract => contract.contractData.connectionType === this.connection.info.connectionType
                    && !contract.defaultContract)
                    .sort(RefdataUtils.contractsComparator.compare))
            );

    onContractSelected = (contract: ExtendedContract) => {
        this.contract = contract;
        this.data.linkedContract.contractId = contract.contractId;
    }
}

export interface LinkContractToConnectionComponentData {
    linkedContract: LinkedContract;
    connectionId: string;
    locationId: string;
    organisationId: string;
    contract?: ExtendedContract;
}
