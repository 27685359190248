import {Component} from '@angular/core';
import {RequestUserReset} from '@flowmaps/flowmaps-typescriptmodels';
import {sendCommand} from '../../../flux/flux-utils';
import {AppContext} from '../../../app-context';
import {Router} from "@angular/router";

@Component({
    selector: 'app-password-reset',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
    command: RequestUserReset = {};

    constructor(private router: Router) {
    }

    sendLoginLink = () => {
        sendCommand('com.flowmaps.api.authentication.RequestUserReset', this.command,
            () => this.router.navigate(["/reset-password"], {queryParams: {email: this.command.email}}));
    };
}
