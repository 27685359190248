<div #container class="d-flex align-items-stretch" style="flex: 1" [ngClass]="{'mb-0': _dataOptions.fullScreen || showInReport, 'card': !showInReport, 'report-view': showInReport}">
    <ng-container *ngIf="!showInReport">
        <div class="card-header dropdown">
            <h4 class="card-header-title">
                <app-connection-image [connectionType]="'Water'"></app-connection-image>
                Water consumption
                <app-tooltip class="ms-2" placement="top" *ngIf="completenessInfo">
                    <span class="notranslate" [style.color]="completenessInfo.color">{{completenessInfo.completeness}}%</span>
                    <div class="tooltipContent">
                        <span class="notranslate">{{completenessInfo.title}}</span>
                    </div>
                </app-tooltip>
            </h4>
            <app-data-provider-date-range class="ms-2" *ngIf="_dataOptions.fullScreen" [dataProvider]="_dataOptions.dataProvider"></app-data-provider-date-range>
            <button *ngIf="!_dataOptions.fullScreen" type="button" class="btn" role="button" (click)="openModal()"><i class="bi bi-arrows-angle-expand"></i></button>
            <app-screenshot-button [element]="container"></app-screenshot-button>
            <button type="button" class="btn" role="button" (click)="toggleShowAsTable()"><i class="bi" [ngClass]="options.showAsTable ? 'bi-bar-chart-line' : 'bi-table'"></i></button>
            <button type="button" class="btn" role="button" data-bs-toggle="dropdown"
                    data-bs-auto-close="outside" aria-expanded="false">
                <i class="bi bi-three-dots-vertical"></i>
            </button>
            <div class="dropdown-menu" style="z-index: 1050;">
                <div class="dropdown-item" *ngIf="_dataOptions.dataProvider.groupByEntityIdEnabled()">
                    <div class="form-check">
                        <ng-container *ngIf="options.groupByEntityIdPossible else disabledGroupByEntityId">
                            <input class="form-check-input" type="checkbox" value="" [id]="getInputId('groupByEntityId')"
                                   [ngModel]="options.groupByEntityId" (ngModelChange)="groupByEntityIdChange($event)">
                            <label class="form-check-label" [for]="getInputId('groupByEntityId')">
                                Split by sub meters
                            </label>
                        </ng-container>
                        <ng-template #disabledGroupByEntityId>
                            <input class="form-check-input" type="checkbox" value="false" disabled>
                            <label class="form-check-label me-2">Split by sub meters</label>
                            <i class="bi bi-exclamation-triangle-fill" data-bs-toggle="tooltip" data-bs-placement="top"
                               title="No sub-meters found for this measurement type"></i>
                        </ng-template>
                    </div>
                </div>
                <div class="dropdown-item">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="getInputId('showCompleteness')"
                               [ngModel]="options.showCompleteness" (ngModelChange)="showCompletenessChange($event)">
                        <label class="form-check-label" [for]="getInputId('showCompleteness')">
                            Completeness
                        </label>
                    </div>
                </div>
                <div class="dropdown-item">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="getInputId('checkboxCompareWater')"
                               [ngModel]="options.compare.enabled" (ngModelChange)="compareChange($event)">
                        <div class="row">
                            <label class="form-check-label col-auto" [for]="getInputId('checkboxCompareWater')">Compare with</label>
                            <app-select class="col px-0 notranslate" [ngModel]="options.compare" (ngModelChange)="comparedYearChange($event)"
                                        [options]="compareYearOptions" [small]="true" [formatter]="compareFormatter" [equalsFunction]="compareEquals"></app-select>
                        </div>
                    </div>
                </div>
                <hr />
                <div><h4 class="dropdown-header">Weather</h4></div>
                <div *ngFor="let weatherType of weatherTypes" class="dropdown-item">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="getWeatherInputId(weatherType)"
                               [checked]="isDataTypeSelected(weatherType)" (click)="toggleWeather(weatherType)">
                        <label class="form-check-label" [for]="getWeatherInputId(weatherType)">
                            {{appContext.measurementName(weatherType, false)}}
                        </label>
                    </div>
                </div>
            </div>
            <button *ngIf="_dataOptions.fullScreen" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
    </ng-container>
    <div class="chart-body pt-0" [ngClass]="{'p-0': options.showAsTable, 'card-body': !showInReport}">
        <ng-container *ngIf="showInReport else notInReport">
            <ng-container [ngTemplateOutlet]="chart"></ng-container>
            <ng-container [ngTemplateOutlet]="tableView"></ng-container>
        </ng-container>
        <ng-template #notInReport>
            <ng-container *ngIf="options.showAsTable else showChart">
                <ng-container [ngTemplateOutlet]="tableView"></ng-container>
            </ng-container>
            <ng-template #showChart>
                <ng-container [ngTemplateOutlet]="chart"></ng-container>
            </ng-template>
        </ng-template>
    </div>
</div>

<ng-template #chart>
    <app-bar-chart class="chart h-100 w-100" [y1AxisMeasurement]="measurementUnit()"
                   [dataProvider]="chartDataProvider" style="flex: 1" [showNormalAxis]="showDataNormally()"
                   [y2AxisMeasurement]="y2AxisTitle" [measurementsProvider]="_dataOptions.dataProvider"
                   [selectedWeatherTypes]="options.selectedWeatherTypes" [weatherTypes]="weatherTypes" [showCompleteness]="options.showCompleteness"
                   [fullScreen]="_dataOptions.fullScreen" [skipPaddingX]="_dataOptions.fullScreen || showInReport"
                   [animationDuration]="showInReport ? 0 : 1000"></app-bar-chart>
</ng-template>

<ng-template #tableView>
    <app-chart-table class="w-100" [dataProvider]="chartDataProvider"
                     [measurementsProvider]="_dataOptions.dataProvider"
                     [compare]="options.compare"></app-chart-table>
</ng-template>