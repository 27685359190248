<app-form (submit)="updateTaxPeriod()">
    <div class="card">
        <div class="card-header">
            <h3 class="card-header-title">
                <span>{{ isNewRecord ? 'Add period' : 'Edit period' }}</span>
            </h3>
        </div>
        <div class="card-body">
            <app-contract-range
                    [dateRange]="data.period.data.period"
                    [isReadOnly]="false" [isRequired]="true"
            >
            </app-contract-range>
            <div class="row">
                <ng-container *ngIf="data.tax.taxInfo.connectionType === 'Electricity'">
                    <div class="col-12 col-md-6">
                        <app-form-field label="Residential Rate">
                            <div class="input-group">
                                <input [(ngModel)]="data.period.data.residentialRate" type="number"
                                       class="form-control">
                                <span class="input-group-text notranslate rounded-end"> €
                                    </span>
                            </div>
                        </app-form-field>
                    </div>
                </ng-container>
            </div>
            <app-form-field label="Tax Brackets">
                <ng-container
                        *ngIf="(data.tax.taxInfo.connectionType === 'Electricity' || data.tax.taxInfo.connectionType === 'Gas') && data.period.data.taxBrackets.length>0">
                    <div class="mb-2 d-flex align-items-center">
                        <span class="text-danger notranslate me-1">*</span>
                        <small class="form-text text-muted">Rate includes ODE <span class="notranslate"> (Opslag duurzame energie- en klimaattransitie).</span></small>
                    </div>
                </ng-container>
                <div *ngFor="let taxBracket of data.period.data.taxBrackets; let i = index" class="row mb-3 align-items-center">
                    <div class="col-md-3">
                        <div class="input-group">
                            <input [(ngModel)]="taxBracket.minLimit" type="number" class="form-control" placeholder="Min">
                            <span class="input-group-text notranslate">{{ getConsumptionUnit(data.tax.taxInfo.connectionType) }}</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="input-group">
                            <input [(ngModel)]="taxBracket.maxLimit" type="number" class="form-control" placeholder="Max">
                            <span class="input-group-text notranslate">{{ getConsumptionUnit(data.tax.taxInfo.connectionType) }}</span>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="input-group">
                            <input [(ngModel)]="taxBracket.rate" type="number" class="form-control" placeholder="Rate" required>
                            <span class="input-group-text notranslate">{{ costUnit() }}</span>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <button type="button" class="btn bi-trash" (click)="removeTaxBracket(i)"></button>
                    </div>
                </div>
                <button type="button" class="btn btn-outline-primary mt-2 notranslate" (click)="addTaxBracket()">+</button>
            </app-form-field>
        </div>
        <div class="card-footer d-flex justify-content-between">
            <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
            <div>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>
