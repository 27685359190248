import {Component} from '@angular/core';
import {DeleteUser, UpdateUserInfo} from '@flowmaps/flowmaps-typescriptmodels';
import {AppContext} from '../../../app-context';
import {sendCommand} from '../../../flux/flux-utils';
import {cloneObject} from '../../../common/utils';

@Component({
    selector: 'app-edit-profile-general',
    templateUrl: './edit-profile-general.component.html',
    styleUrls: ['./edit-profile-general.component.css']
})
export class EditProfileGeneralComponent {

    command: UpdateUserInfo = {userId: AppContext.userProfile.userId, info: cloneObject(AppContext.userProfile.info)};

    updateInfo() {
        sendCommand("com.flowmaps.api.user.UpdateUserInfo", this.command, () => {
            AppContext.registerSuccess("You have successfully updated your profile");
            AppContext.userProfile.info = cloneObject(this.command.info);
        });
    }

    deleteAccount() {
        sendCommand("com.flowmaps.api.user.DeleteUser", <DeleteUser>{userId: AppContext.userProfile.userId},
            () => {
                AppContext.registerSuccess("Your account has been deleted successfully");
                AppContext.setUserProfile(undefined);
                AppContext.navigateToUrl("/login");
            });
    }
}
