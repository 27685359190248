<div class="chart-wrapper" [ngClass]="{'d-flex align-items-center justify-content-center': !showChart}">
    <canvas baseChart class="bar h-100 notranslate" [height]="height + 'px'" [style]="{'min-height': height+'px'}"
            [ngClass]="showChart ? '' : 'd-none'"
            [data]="chartData"
            [type]="'doughnut'"
            [plugins]="[labelsAsIconsPlugin]">
    </canvas>
    <ng-container *ngIf="!showChart">
        <div class="d-flex align-items-center justify-content-center" [style]="{'min-height': height+'px'}">
            <span class="inline-flex">No data to show</span>
        </div>
    </ng-container>
</div>
