import {AfterViewInit, Component, Input} from '@angular/core';
import {
    AbstractOverviewComponent,
    addZeroFacetResults
} from "../../../../common/abstract-overview/abstract-overview.component";
import {
    ConsumptionTax,
    CreateConsumptionTax,
    DeleteConsumptionTax,
    DeleteTaxPeriod,
    FacetResult,
    TaxPeriod,
    UpsertTaxPeriod
} from "@flowmaps/flowmaps-typescriptmodels";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {RefdataUtils} from "../../refdata-utils";
import {FacetFilters, FacetStats} from "../../../../common/facets/facet-filter/facet-filter.component";
import {sendCommand} from "../../../../flux/flux-utils";
import {closeModal, openModal} from "../../../../app-utils";
import {AddTaxComponent} from "../add-tax/add-tax.component";
import {AnimationTypes} from "../../../../common/modal/modal.component";
import {cloneDeep} from "lodash";
import {AddTaxPeriodComponent, TaxPeriodComponentData} from "../add-tax-period/add-tax-period.component";
import {computeItem} from "../../../../common/utils";
import {QueryGateway} from "../../../../flux/query-gateway";
import {EventGateway} from "../../../../flux/event-gateway";
import {SentenceCasePipe} from "../../../../common/sentence-case.pipe";

@Component({
    selector: 'app-taxes-list',
    templateUrl: './taxes-list.component.html',
    styleUrls: ['./taxes-list.component.css']
})
export class TaxesListComponent extends AbstractOverviewComponent<ConsumptionTax> implements AfterViewInit {
    comparator: ComparatorChain = RefdataUtils.taxesComparator;

    constructor(protected eventGateway: EventGateway, protected queryGateway: QueryGateway) {
        super(eventGateway);
    }

    trackByForRecord(index: number, record: ConsumptionTax) {
        return record.taxId;
    }

    onFacetValuesChange(facet: FacetStats, selectedValues: string[]) {
        super.onFacetValuesChange(facet, selectedValues);
        this.reload()
    }

    getDefaultFacetFilters = (): FacetFilters => ({
        facets: {
            "taxInfo/country": ["NL"]
        }
    });

    facetNameFormatter = (name: string): string => {
        switch (name) {
            case "taxInfo/country":
                return "Country";
            case "taxInfo/connectionType":
                return "Connection Type";
            default:
                return SentenceCasePipe.formatWithSpaces(name);
        }
    }

    reload = () => {
        this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyConsumptionTaxes");
        RefdataUtils.getConsumptionTaxes(
            this.getFacetFilters()
        )
            .subscribe(
                l => {
                    this.data = l.taxes;
                    this.loadCount++;
                    // this.facetResult = addZeroFacetResults(l.facets, "taxInfo/country", "NL", "BE", "BG");
                }
            );
    }

    ngAfterViewInit(): void {
        this.setFilters(this.getFilters());
        this.reload();
    }

    ngOnInit() {
        RefdataUtils.getConsumptionTaxes().subscribe(
            l => {
                this.data = l.taxes;
                this.facetResult = l.facets;
                this.loadCount++;
            }
        );
    }

    ignoredFacets: string[] = ["intermediaryId"];

    @Input() set facetResult(result: FacetResult) {
        super.facetResult = addZeroFacetResults(result, "taxInfo/country", "NL");
    }

    addTax = () => openModal(AddTaxComponent, AddTaxComponent.newTax(), {
        cssClass: "modal-xl",
        animation: AnimationTypes.fade
    });

    addNewTaxPeriod(tax: ConsumptionTax): void {
        const newPeriod: TaxPeriod = {
            periodId: '',
            data: {
                period: {
                    start: "",
                    end: ""
                },
                residentialRate: null,
                taxBrackets: []
            }
        };
        this.openTaxPeriodModal(tax, newPeriod);
    }

    taxPeriodUpdated = (event: UpsertTaxPeriod) => {
        this.computeItem(i => i.taxId === event.taxId, c => {
            c.taxPeriods = computeItem(
                c.taxPeriods, p => p.periodId === event.periodId, p => {
                    p = p || {periodId: event.periodId}
                    p.data = event.data;
                    return p;
                });
            return c;
        })
    }

    taxUpdated = (event: CreateConsumptionTax) => {
        this.computeItem(i => i.taxId === event.taxId, c => {
            c = c || {taxId: event.taxId};
            c.taxInfo = event.info;
            c.taxPeriods = [];
            return c;
        })
    }

    deleteTaxPeriod = (record: TaxPeriod, tax: ConsumptionTax) => {
        const command: DeleteTaxPeriod = {
            periodId: record.periodId,
            taxId: tax.taxId
        };
        sendCommand("com.flowmaps.api.organisation.DeleteTaxPeriod", command, () => {
            const taxToUpdate = this.data.find(c => c.taxId === tax.taxId);
            if (taxToUpdate) {
                taxToUpdate.taxPeriods = taxToUpdate.taxPeriods.filter(
                    period => period.periodId !== record.periodId
                );
            }
            closeModal();
        });
    }

    deleteTax = (tax: ConsumptionTax) => {
        const command: DeleteConsumptionTax = {
            taxId: tax.taxId,
        };
        sendCommand("com.flowmaps.api.organisation.DeleteConsumptionTax", command, () => {
            closeModal();
            this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyConsumptionTaxes");
            this.data = this.data.filter(item => item.taxId !== tax.taxId);
        });
    }

    openTaxPeriodModal(tax: ConsumptionTax, taxPeriod?: TaxPeriod): void {
        openModal(AddTaxPeriodComponent, cloneDeep(<TaxPeriodComponentData>{
            period: taxPeriod,
            tax: tax,
        }), {
            cssClass: "modal-xl custom-modal-wide",
            animation: AnimationTypes.fade
        });
    }
}
