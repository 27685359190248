import {Component} from '@angular/core';
import {AppContext} from '../../app-context';
import {sendQuery} from '../../flux/flux-utils';
import {AuthService} from "../../utils/auth.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent {
    context = AppContext;

    constructor(private authService: AuthService) {
        sendQuery("com.flowmaps.api.user.GetUserProfile", {}, {caching: false}).subscribe({
            next: r => {
                AppContext.setUserProfile(r);
                if (!r) {
                    authService.navigateToLogin();
                }
            },
            error: () => authService.navigateToLogin()
        });
    }
}
