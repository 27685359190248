<!-- Toggler -->
<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse"
        aria-controls="sidebarCollapse" aria-expanded="false">
    <span class="navbar-toggler-icon"></span>
</button>

<!-- Brand -->
<a class="navbar-brand" [routerLink]="'/'">
    <img [src]="'/assets/images/logos/' + context.brand + '-logo-500.png'" class="navbar-brand-img mx-auto" alt="Logo">
</a>

<!-- User (xs) -->
<div class="navbar-user d-md-none">

    <!-- Dropdown -->
    <div class="dropdown">

        <!-- Toggle -->
        <a href="#" id="sidebarIcon" class="dropdown-toggle notranslate" role="button" data-bs-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside">
            <div class="avatar avatar-sm avatar-online">
                <span class="avatar-title rounded-circle">{{ context.initials }}</span>
            </div>
        </a>

        <!-- Menu -->
        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
            <a routerLink="/profile" class="dropdown-item">Profile</a>
            <button *ngIf="context.isAdmin()" class="dropdown-item" (click)="openEncrypt()">Encrypt</button>
            <button *ngIf="context.impersonator" class="dropdown-item" (click)="stopImpersonatingUser()">Stop
                impersonating
            </button>
            <app-language-selector></app-language-selector>
            <hr class="dropdown-divider">
            <button class="dropdown-item" (click)="signOut()">Logout</button>
        </div>

    </div>

</div>

<!-- Collapse -->
<div class="collapse navbar-collapse" id="sidebarCollapse">

    <!-- Form -->
    <form class="mt-4 mb-3 d-md-none">
        <div class="input-group input-group-rounded input-group-merge input-group-reverse">
            <input class="form-control" type="search" placeholder="Search" aria-label="Search">
            <div class="input-group-text">
                <span class="fe fe-search"></span>
            </div>
        </div>
    </form>

    <!-- Navigation -->
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" href="#sidebarDashboards" data-bs-toggle="collapse" role="button" aria-expanded="true"
               aria-controls="sidebarDashboards">
                <img src="assets/images/icons/portfolio.svg" alt="Portfolio icon" width="15" height="15" class="me-2"/>
                Portfolio
            </a>
            <div class="collapse show" id="sidebarDashboards">
                <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link">Default</a>
                    </li>
                    <li *ngFor="let dashboard of getDashboardsForType('Portfolio'); trackBy : trackBy"
                        class="nav-item notranslate">
                        <a [routerLink]="'/dashboard/force/' + encoded(dashboard)" class="nav-link">
                            {{ dashboard.info.name }}
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#sidebarLocationDashboards" data-bs-toggle="collapse" role="button"
               aria-expanded="true" aria-controls="sidebarLocationDashboards">
                <i class="bi bi-building me-2"></i>
                Locations
            </a>
            <div class="collapse show" id="sidebarLocationDashboards">
                <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                        <a routerLink="/location/dashboard" class="nav-link">Default</a>
                    </li>
                    <li *ngFor="let dashboard of getDashboardsForType('Location'); trackBy : trackBy"
                        class="nav-item notranslate">
                        <a [routerLink]="'/location/dashboard/' + encoded(dashboard)" class="nav-link">
                            {{ dashboard.info.name }}
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#sidebarReports" data-bs-toggle="collapse" role="button" aria-expanded="true"
               aria-controls="sidebarReports">
                <i class="bi bi-file-earmark-bar-graph me-2"></i>
                Reports
            </a>
            <div class="collapse show" id="sidebarReports">
                <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                        <a routerLink="/report/default" class="nav-link">{{ defaultReport.info.name }}</a>
                    </li>
                    <li *ngFor="let report of reports" class="nav-item notranslate">
                        <a [routerLink]="'/report/' + report.reportId" class="nav-link">
                            <i *ngIf="reportUtils.schedulingEnabled(report)" class="bi bi-clock me-2"
                               data-bs-toggle="tooltip" data-bs-placement="top"
                               [title]="(reportUtils.cronToFrequency(report.info.scheduleCron) | titlecase) + ' scheduled'"></i>
                            {{ report.info.name }}
                        </a>
                    </li>
                </ul>
            </div>
        </li>

        <li class="nav-item" *ngIf="context.isAdminOrIntermediary()">
            <a class="nav-link" href="#sidebarDashboards" data-bs-toggle="collapse" role="button" aria-expanded="true"
               aria-controls="sidebarDashboards">
                <img src="assets/images/icons/portfolio.svg" alt="Portfolio icon" width="15" height="15" class="me-2"/>
                Monitoring
            </a>
            <div class="collapse show" id="sidebarMonitorDashboards">
                <ul class="nav nav-sm flex-column">
                    <li class="nav-item" *ngIf="context.isAdminOrIntermediary()">
                        <a routerLink="/alerts" class="nav-link">
                            <i class="bi bi-bell me-2"></i>
                            <span>Alerts</span>
                            <ng-container *ngIf="refdataUtils.getMyOpenAlerts() | async as alerts">
                            <span *ngIf="alerts.alerts.length as alertCount"
                                  class="badge rounded-pill text-bg-danger ms-2 notranslate">
                        {{ alertCount }}
                    </span>
                            </ng-container>
                        </a>
                    </li>

                    <li class="nav-item" *ngIf="context.isAdmin()">
                        <a routerLink="/errors" class="nav-link">
                            <i class="bi bi-exclamation-triangle me-2"></i>
                            <span>Errors</span>
                            <ng-container *ngIf="refdataUtils.getOpenErrors() | async as errors">
                                <span *ngIf="errors.errors.length as errorsCount"
                                      class="badge rounded-pill text-bg-danger ms-2 notranslate">
                                                    {{ errorsCount }}
                                </span>
                            </ng-container>
                        </a>
                    </li>

                </ul>
            </div>
        </li>


        <li class="nav-item">
            <a class="nav-link" href="#configuration" data-bs-toggle="collapse" role="button" aria-expanded="true"
               aria-controls="configuration">
                <i class="bi bi-gear me-2"></i>
                Configuration
            </a>
            <div class="collapse show" id="configuration">
                <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                        <a routerLink="/organisations" class="nav-link">
                            <i class="bi me-2" [ngClass]="context.getIconOfSourceType('organisation')"></i>
                            Organisations
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/locations" class="nav-link">
                            <i class="bi me-2" [ngClass]="context.getIconOfSourceType('location')"></i>
                            Locations
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/connections" class="nav-link">
                            <i class="bi bi-speedometer2 me-2"></i>
                            Connections
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="context.isAdminOrIntermediary()">
                        <a routerLink="/contracts" class="nav-link">
                            <i class="bi bi-clipboard-check me-2"></i>
                            Contracts
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="context.isAdminOrIntermediary()">
                        <a routerLink="/taxes" class="nav-link">
                            <i class="bi bi-calculator me-2"></i>
                            Taxes
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="context.isAdminOrIntermediary()">
                        <a routerLink="/users" class="nav-link">
                            <i class="bi me-2" [ngClass]="context.getIconOfSourceType('user')"></i>
                            Users
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>

    <!-- Push content down -->
    <div class="mt-auto"></div>


    <!-- User (md) -->
    <div class="navbar-user d-none d-md-flex" id="sidebarUser">

        <!-- Icon -->
        <app-language-selector></app-language-selector>

        <div style="width: 40px;">
            <!-- Dropup -->
            <div class="dropup position-fixed translate-middle-y">

                <!-- Toggle -->
                <a href="#" id="sidebarIconCopy" class="dropdown-toggle notranslate" role="button"
                   data-bs-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false" data-bs-offset="100,20">
                    <div class="avatar avatar-sm avatar-online">
                        <span class="avatar-title rounded-circle">{{ context.initials }}</span>
                    </div>
                </a>

                <!-- Menu -->
                <div class="dropdown-menu" aria-labelledby="sidebarIconCopy">
                    <a routerLink="/profile" class="dropdown-item">Profile</a>
                    <button *ngIf="context.isAdmin()" class="dropdown-item" (click)="openEncrypt()">Encrypt</button>
                    <button *ngIf="context.impersonator" class="dropdown-item" (click)="stopImpersonatingUser()">Stop
                        impersonating
                    </button>
                    <hr class="dropdown-divider">
                    <button class="dropdown-item" (click)="signOut()">Logout</button>
                </div>

            </div>
        </div>

    </div>

</div> <!-- / .navbar-collapse -->