<div class="xl-content">
    <table *ngIf="this._data" class="table table-bordered m-0 text-center">
        <thead class="sticky-top">
            <tr>
                <th class="fit" style="width: 100px;">Period</th>
                <th [colSpan]="this.previousComparePeriod.length > 0 ? 3 : 1" *ngFor="let col of columns(); index as i; trackBy: trackByIndex" class="notranslate">
                    {{col}}
                </th>
            </tr>
            <tr *ngIf="previousComparePeriod.length > 0" id="top-header" class="notranslate">
                <th></th>
                <ng-container *ngFor="let col of columns(); index as i; trackBy: trackByIndex">
                    <th>{{calculatedColumnTimes[0].previous}}</th>
                    <th>{{calculatedColumnTimes[0].current}}</th>
                    <th>%</th>
                </ng-container>
            </tr>
        </thead>
        <tbody class="overflow-auto notranslate">
            <ng-container *ngFor="let row of records(currentPeriod, previousComparePeriod); index as i; trackBy : trackBy">
                <ng-container *ngIf="previousComparePeriod.length > 0 && row.periodChange">
                    <tr class="sticky-top table-header" [style.top]="headerTop">
                        <th></th>
                        <ng-container *ngFor="let col of columns(); index as j; trackBy: trackByIndex">
                            <th>{{calculatedColumnTimes[i].previous}}</th>
                            <th>{{calculatedColumnTimes[i].current}}</th>
                            <th>%</th>
                        </ng-container>
                    </tr>
                </ng-container>
                <tr>
                    <td class="fit text-center">{{row.label}}</td>
                    <ng-container *ngIf="previousComparePeriod.length > 0 else normalView">
                        <ng-container *ngFor="let record of row.data; index as j; trackBy: trackByIndex">
                            <td>{{record.prevVal | number}}</td>
                            <td>{{record.val | number}}</td>
                            <td>
                                <span *ngIf="record.finite else nullValue" [ngClass]="record.cssClass">
                                    {{record.percentage | number}}%
                                </span>
                                <ng-template #nullValue>
                                    <span>-</span>
                                </ng-template>
                            </td>
                        </ng-container>
                    </ng-container>
                    <ng-template #normalView>
                        <ng-container *ngFor="let val of row.data; index as j; trackBy: trackByIndex">
                            <td>{{val.val | number}}</td>
                        </ng-container>
                    </ng-template>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
