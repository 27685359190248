import {Component, Input} from '@angular/core';
import {CreateOrganisation, Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import {sendCommand} from "../../../../flux/flux-utils";
import {uuid} from "../../../../common/utils";
import {closeModal} from "../../../../app-utils";
import {Router} from "@angular/router";
import {QueryGateway} from "../../../../flux/query-gateway";

@Component({
  selector: 'app-add-organisation',
  templateUrl: './add-organisation.component.html',
  styleUrls: ['./add-organisation.component.scss']
})
export class AddOrganisationComponent {
  @Input() data: AddOrganisationComponentData;

  constructor(private router: Router, private queryGateway: QueryGateway) {
  }

  static newOrganisation = (): Organisation  => {
    return {
      info: {
      },
      receivedAuthorisations: [],
      locations: [],
      givenAuthorisations: []
    }
  }

  updateInfo = () => {
    const organisationId =  uuid();
    sendCommand("com.flowmaps.api.organisation.CreateOrganisation", <CreateOrganisation>{
      organisationId: organisationId,
      info: this.data.organisation.info,
      owner: null
    }, () => {
      closeModal();
      this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
      this.router.navigateByUrl(`/organisation/${organisationId}`);
    });
  }
}

export interface AddOrganisationComponentData {
  organisation: Organisation;
}