import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filterByTerm, lodash} from '../../utils';
import {AbstractValueAccessorComponent} from "../../component/value-accessor.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import { DebouncedFunc } from 'lodash';

@Component({
  selector: 'app-local-filter',
  templateUrl: './local-filter.component.html',
  styleUrls: ['./local-filter.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LocalFilterComponent), multi: true}
  ],
  host: {'class' : 'input-group'}
})
export class LocalFilterComponent extends AbstractValueAccessorComponent<any> {
  private filterTerm: string = "";

  @Input() selectedItems: any[] = [];
  private outputSubject = new Subject<any[]>();
  private outputObservable = this.outputSubject.asObservable();
  private _inputBuffer: number = 0;

  @Input() disabled: boolean;
  @Input() itemTemplate;
  @Input() formatter;
  @Input() newValueTemplate : TemplateRef<any>;

  @ViewChild("dropdownToggle") dropdownToggle: ElementRef;
  private debouncedFilter: DebouncedFunc<any> = lodash.debounce(this.emitUpdated, this._inputBuffer);

  get inputModel(): any {
    return this.filterTerm;
  }

  @Input() set inputModel(value: string) {
    this.filterTerm = value;
    this.termChanged.emit(value);
    this.onUpdate();
  }

  @Input() filterFunction: (term) => (value : any) => boolean = t => filterByTerm(t);

  @Input()
  set data(value: any[] | Observable<any[]>) {
    if (lodash.isArray(value)) {
      this.input = value;
      this.onUpdate();
    } else {
      this.searching = true;
      value?.subscribe({
        next: (r) => {
          this.input = r;
          this.searching = false;
          this.onUpdate();
        },
        error: (e) => this.searching = false
      });
    }
  }

  @Input() placeholder = "";

  @Output() updated = new EventEmitter<any[]>();
  @Output() termChanged = new EventEmitter<string>();
  @Output() deleted: EventEmitter<any> = new EventEmitter<any>();

  @Input() set inputBufferTime(value: number) {
    this._inputBuffer = value;
    this.debouncedFilter = lodash.debounce(this.emitUpdated, this._inputBuffer);
  }

  searching: boolean;
  private input: any[] = [];

  onInput = (text$: Observable<string>) => {
    text$.subscribe(term => {
      this.inputModel = term;
    });
    return this.outputObservable;
  };

  private onUpdate() {
    this.filterTerm ? this.debouncedFilter() : this.emitUpdated();
  }

  clearSelection = () => {
    this.deleted.emit(this.selectedItems);
    this.selectedItems = [];
  }

  deleteValue(value: any) {
    this.selectedItems = this.selectedItems.filter(v => v !== value);
    this.deleted.emit(value);
  }

  removeSelectedItemWhenEmpty = () => {
    if (!lodash.isEmpty(this.filterTerm)) {
      return;
    }
    if (this.selectedItems.length > 0) {
      const lastItem = this.selectedItems[this.selectedItems.length-1];
      this.selectedItems = this.selectedItems.filter(t => t !== lastItem);
      this.deleted.emit(lastItem);
    }
  }

  onSelect = (value) => {
    if (value) {
      this.selectedItems.push(value);
    }
  };

  get value(): any {
    return this.selectedItems;
  }

  writeValue(value: any): void {
    this.selectedItems = value === undefined ? null : value;
  }

  private emitUpdated() {
    return this.updated.emit(this.input.filter(this.filterFunction(this.filterTerm)));
  }
}
