<div class="dropdown">
  <button class="btn text-start d-flex justify-content-between align-items-center w-100 p-2 pe-4" type="button"
          [ngClass]="{'active': hasValue}" data-bs-toggle="dropdown" [style.max-width.px]="200">
    <div class="d-flex flex-column mw-0 flex-grow-1 me-3 mw-100">
      <span class="d-inline-block text-truncate">{{getFacetName()}}</span>
      <div *ngIf="hasValue" class="mt-1 text-secondary d-inline-block text-truncate notranslate">
        <ng-container *ngIf="getSelectedValueFormatted() | async as value">
          {{value}}
        </ng-container>
      </div>
    </div>
    <i *ngIf="!hasValue" class="fa fa-fw fa-plus text-secondary"></i>
  </button>
  <div class="btn-close-wrapper rounded-circle position-absolute end-0 me-1 mt-1 top-0" [ngClass]="{'d-none': !hasValue}">
    <button type="button" class="btn-close" (click)="removeFilter()" aria-label="Remove filter"></button>
  </div>
  <div class="dropdown-menu min-w-100 overflow-auto" [style.max-height.px]="320" [style.z-index]="1050">
    <ul class="list-group">
      <ng-container *ngFor="let facetValue of facet.values; index as i; trackBy: trackByFacetValue">
        <li class="list-group-item list-group-item-action border-0 d-flex align-items-stretch">
          <app-facet-filter-value [facet]="facet" [facetValue]="facetValue" [selectedValues]="selectedValues"
                                  (valueSelected)="facetValueSelected(facetValue, $event)"></app-facet-filter-value>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
