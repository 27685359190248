<app-form *ngIf="data" (submit)="updateInfo()">
    <div class="card">
        <div class="card-header">
            <h3 *ngIf="isNewAuthorisation" class="card-header-title">New authorisation</h3>
            <h3 *ngIf="!isNewAuthorisation" class="card-header-title notranslate">{{authorisationHeader() | async}}</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-6" *ngIf="showNominatorField">
                    <app-form-field label="Organisation">
                        <app-search [minCharacters]="0" [(ngModel)]="nominatorOrganisation" required [searchFunction]="refdataUtils.searchOrganisation"
                                    [inputFormatter]="refdataUtils.organisationFormatter" dataKey="info.name" (ngModelChange)="setNominator($event)"
                                    placeholder="Search for an organisation" [disabled]="data['showNominator']"></app-search>
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Type">
                        <app-select required [disabled]="!isNewAuthorisation" [options]="['organisation', 'user']" [(ngModel)]="data.type"></app-select>
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field label="Role">
                        <app-select required [options]="refdataUtils.authorisationOptions()"
                                    [(ngModel)]="data.role"></app-select>
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-field *ngIf="data.type === 'user'" label="Authorisation for">
                        <input [(ngModel)]="data.nominee" required [disabled]="!isNewAuthorisation" placeholder="Email address"
                               type="email" class="form-control notranslate">
                    </app-form-field>
                    <app-form-field *ngIf="data.type === 'organisation'" label="Authorisation for">
                        <app-search [minCharacters]="0" [(ngModel)]="nomineeOrganisation" required [disabled]="!isNewAuthorisation"
                                    [searchFunction]="refdataUtils.getMyOrganisations"
                                    [inputFormatter]="refdataUtils.organisationFormatter" (ngModelChange)="setNominee($event)"
                                    placeholder="Select an organisation" dataKey="info.name"></app-search>
                    </app-form-field>
                </div>
                <div class="row col-12 col-md-6 pe-0" *ngIf="data.timeRange">
                    <app-form-field class="col-6" label="Start">
                        <app-date-field [(ngModel)]="data.timeRange.start" [minDate]="getMinStartDate()" [dateOnly]="true"></app-date-field>
                    </app-form-field>
                    <app-form-field class="col-6 pe-0" label="End">
                        <app-date-field [(ngModel)]="data.timeRange.end" [minDate]="data.timeRange.start" [dateOnly]="true"></app-date-field>
                    </app-form-field>
                </div>
                <div class="col-12">
                    <app-form-field class="col-6" label="Sources">
                        <app-sources-selector positionClass="position-fixed" [sourceProvider]="sourceProvider" (updated)="updateEntityIds($event)"></app-sources-selector>
                    </app-form-field>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <div>
                    <button *ngIf="!isNewAuthorisation" type="button" data-bs-dismiss="modal" (click)="deleteAuthorisation()" class="btn btn-danger">Delete authorisation</button>
                </div>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>