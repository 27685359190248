<div class="d-flex align-items-center min-vh-100 bg-auth">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-5 col-xl-4 my-5">

                <!-- Heading -->
                <h1 class="display-4 text-center mb-3">
                    Password reset
                </h1>

                <!-- Subheading -->
                <p class="text-muted text-center mb-5">
                    Enter your email to get a password reset link.
                </p>

                <!-- Form -->
                <app-form (submit)="sendLoginLink()">

                    <!-- Email address -->
                    <app-form-field label="Email Address">
                        <input [(ngModel)]="command.email"
                               required type="email" class="form-control" placeholder="name@address.com">
                    </app-form-field>

                    <!-- Submit -->
                    <button type="submit" class="btn btn-lg w-100 btn-primary mb-3">
                        Reset Password
                    </button>

                    <!-- Link -->
                    <div class="text-center">
                        <small class="text-muted text-center">
                            <span class="me-1">Remember your password?</span> <a routerLink="/login">Log in</a><span class="notranslate">.</span>
                        </small>
                    </div>

                </app-form>

            </div>
        </div> <!-- / .row -->
    </div>
</div>