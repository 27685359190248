import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import {MeasurementDataset} from "../base-measurement-chart";
import {DashboardContext} from "../../dashboard/dashboard.context";
import {ChartDataProvider} from "../../../utils/chart-data-provider";
import {MeasurementsDataProvider} from "../../../utils/measurements-data-provider";
import moment from "moment";
import lodash from "lodash";
import {ChartCompare} from "../../dashboard/dashboard.types";
import {AppContext} from "../../../app-context";
import {trackByIndex} from "../../../common/utils";

@Component({
    selector: 'app-chart-table',
    templateUrl: './chart-table.component.html',
    styleUrls: ['./chart-table.component.scss']
})
export class ChartTableComponent implements OnInit, AfterViewInit {
    protected readonly trackByIndex = trackByIndex;
    context = DashboardContext;
    @Input() dataProvider: ChartDataProvider;
    @Input() measurementsProvider: MeasurementsDataProvider<any>;
    @Input() compare: ChartCompare;
    _data: MeasurementDataset[];
    headerTop: string;
    calculatedColumnTimes: ColumnTime[] = [];

    currentPeriod: MeasurementDataset[] = [];
    previousComparePeriod: MeasurementDataset[] = [];

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        this.dataProvider.aggregatedDataChanged.subscribe(d => {
            this._data = d.datasets.filter(d => !d.dataset.hidden);
            this.calculatedColumnTimes = this.columnTimes();
            this.currentPeriod = this._data
                .sort((a, b) => a.dataset.order < b.dataset.order ? -1 : 1)
                .filter(r => r.dataset.stack === DashboardContext.stacks.currentPeriod);
            this.previousComparePeriod = this._data
                .sort((a, b) => a.dataset.order < b.dataset.order ? -1 : 1)
                .filter(r => r.dataset.stack === DashboardContext.stacks.lastYear);
            setTimeout(() => this.headerTop = this.getHeaderTop() + "px", 0);
        });
    }

    ngAfterViewInit() {
        setTimeout(() => this.headerTop = this.getHeaderTop() + "px", 1000);
    }

    records(dataset: MeasurementDataset[], previousDataset: MeasurementDataset[]): Data[] {
        const slots = this.rows();
        return slots.map((r, i) => {
            let periodChange = false;
            if (slots[i - 1]) {
                periodChange = moment(slots[i - 1].startTime).year() < moment(r.startTime).year()
            }
            return {
                label: r.tableHeader,
                periodChange: periodChange,
                data: dataset
                    .map((d, j) => {
                        const val: number = (d.dataset.tooltip.valuesInverted ? -d.dataset.data[i] : d.dataset.data[i]) as number;
                        const prevVal: number = (previousDataset[j]?.dataset.tooltip.valuesInverted ? -previousDataset[j]?.dataset.data[i] : previousDataset[j]?.dataset.data[i]) as number;
                        let diffPercentage = lodash.round(((val - prevVal) / prevVal) * 100, 1);
                        const percentage = lodash.round(diffPercentage, 1);
                        return <Record>{
                            val: val,
                            prevVal: prevVal,
                            percentage: percentage,
                            finite: lodash.isFinite(diffPercentage) && val !== 0,
                            cssClass: percentage > 0
                                ? (d.dataset.tooltip.valuesInverted || d.dataset.tooltip.higherValueIsBetter ? 'text-positive' : 'text-danger')
                                : (percentage < 0 ? (d.dataset.tooltip.valuesInverted || d.dataset.tooltip.higherValueIsBetter ? 'text-danger' : 'text-positive') : '')
                        };
                    })
            }
        });
    }

    columns = () => this.currentPeriod.map(
        r => `${this.getLabel(r)}${(r.measurementType ? " (" + this.context.getMeasurementUnit(r.measurementType) + ")" : "")}`);

    getLabel = (r: MeasurementDataset) => r.measurementType ? AppContext.measurementName(r.measurementType) : r.dataset.label;

    rows = () => MeasurementsDataProvider.getSlots(
        this.measurementsProvider.info.timeRange, this.measurementsProvider.info.resolution);

    columnTimes = (): ColumnTime[] => {
        const previousTimeRange = MeasurementsDataProvider
            .getSlots(this.measurementsProvider.getForYear(this.measurementsProvider.getComparedYear(this.compare)), this.measurementsProvider.info.resolution)
            .map(s => moment(s.startTime).year());
        return MeasurementsDataProvider
            .getSlots(this.measurementsProvider.info.timeRange, this.measurementsProvider.info.resolution)
            .map((s, i) => (<ColumnTime>{
                previous: previousTimeRange[i],
                current: moment(s.startTime).year()
            }));
    }

    getHeaderTop = () => {
        return this.el ? $(this.el.nativeElement).find('#top-header').position()?.top : 0;
    }

    trackBy: any = (index: number, item: any) => {
        return item?.label;
    };
}

interface ColumnTime {
    previous: number;
    current: number;
}

interface Data {
    label: string;
    periodChange: boolean;
    data: Record[];
}

interface Record {
    val: number;
    prevVal: number;
    percentage: number;
    finite: boolean;
    cssClass: string;
}