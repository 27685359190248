import {Report} from "@flowmaps/flowmaps-typescriptmodels";
import {MomentDateFieldRange} from "../../common/date/date-range/date-field-range";
import {DashboardContext} from "../dashboard/dashboard.context";

export class ReportUtils {

    static defaultRanges: MomentDateFieldRange[] = [DashboardContext.ranges.lastWeek, DashboardContext.ranges.thisMonth,
        DashboardContext.ranges.previousMonth, DashboardContext.ranges.thisYear, DashboardContext.ranges.previousYear,
        DashboardContext.ranges.lastTwelveMonths];

    static schedulingEnabled = (report: Report) => !!report.info.scheduleCron;

    static frequencyToCron = (frequency: Frequency): string => {
        switch (frequency) {
            case Frequency.year: return "0 0 1 1 *";
            case Frequency.month: return "0 0 1 * *";
            case Frequency.week: return "0 0 * * 0";
            case Frequency.day: return "0 0 * * *";
            case Frequency.never: return null;
        }
    }

    static cronToFrequency = (cron: string): Frequency => {
        switch (cron) {
            case "0 0 1 1 *": return Frequency.year;
            case "0 0 1 * *": return Frequency.month;
            case "0 0 * * 0": return Frequency.week;
            case "0 0 * * *": return Frequency.day;
            case null: case undefined: return Frequency.never;
        }
    }
}

export enum Frequency {
    year = "yearly",
    month = "monthly",
    week = "weekly",
    day = "daily",
    never = "never"
}