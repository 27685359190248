<div *ngIf="!readonly" class="align-items-center mb-4 row g-3">
    <div class="col">
        <app-local-filter [appAutoFocus]="true" placeholder="Search" [data]="data"
                          (updated)="filteredData = $event"></app-local-filter>
    </div>
    <div class="col-auto">
        <div class="dropdown">
            <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                <i class="bi bi-download me-md-2"></i>
                <span class="d-none d-md-inline">Download</span>
            </button>
            <ul class="dropdown-menu">
                <li>
                    <button class="dropdown-item" (click)="downloadConnectionsAsExcel(_filteredData)">Connections list
                    </button>
                </li>
                <li *ngIf="appContext.isAdminOrIntermediary() && !organisationId">
                    <button class="dropdown-item notranslate" (click)="downloadAsEdsnCsv(data)">EDSN CSV</button>
                </li>
                <hr class="dropdown-divider"/>
                <li>
                    <button class="dropdown-item" (click)="toggleSelection()">Measurement data</button>
                </li>
                <li>
                    <button class="dropdown-item" (click)="bulkDownloading = !bulkDownloading">Monthly measurement
                        data
                    </button>
                </li>
                <li *ngIf="appContext.isAdminOrIntermediary()">
                    <a href="/assets/templates/data-import-template.xlsx" class="dropdown-item">
                        Connection monthly data template
                    </a>
                </li>
                <li *ngIf="appContext.isAdminOrIntermediary()">
                    <a href="/assets/templates/data-import-raw-template.xlsx" class="dropdown-item">
                        Raw connection data template
                    </a>
                </li>
                <li *ngIf="appContext.isAdminOrIntermediary()">
                    <button class="dropdown-item"
                            (click)="downloadMeterAuthorisationPeriods()">Meter authorisation periods
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-auto" *ngIf="appContext.isAdminOrIntermediary()">
        <div class="dropdown">
            <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                <i class="bi bi-upload me-md-2"></i>
                <span class="d-none d-md-inline">Upload</span>
            </button>
            <ul class="dropdown-menu">
                <li>
                    <a type="button" class="dropdown-item" (click)="uploadConnectionsListInput.click()">
                        <span>Connections list</span>
                        <input type="file" hidden (change)="onUploadConnectionsList($event.target);"
                               #uploadConnectionsListInput>
                    </a>
                </li>
                <li>
                    <a type="button" class="dropdown-item" (click)="uploadConnectionDataInput.click()">
                        <span>Monthly connection data</span>
                        <input type="file" hidden (change)="onUploadConnectionData($event.target);"
                               #uploadConnectionDataInput>
                    </a>
                </li>
                <li>
                    <a type="button" class="dropdown-item" (click)="uploadMinuteDataInput.click()">
                        <span>Raw connection data</span>
                        <input type="file" hidden (change)="uploadMinuteData($event.target);" #uploadMinuteDataInput>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div *ngIf="appContext.isAdminOrIntermediary()" class="col-auto">
        <button (click)="openConnectionDetails(newConnection())" type="button" class="btn btn-primary">
            <i class="bi bi-plus me-md-2"></i>
            <span class="d-none d-md-inline">Add connection</span>
        </button>
    </div>
</div>
<app-scroller (endReached)="loadNextPage()" [windowScroll]="true" class="flex-grow-1">
    <div class="row">
        <ng-container *ngFor="let connection of dataSorted; let i = index; trackBy: trackByForRecord">
            <div role="row" class="col-xl-4 col-md-6 col-12">
                <div class="h-100 d-flex">
                    <div class="card hover-show w-100 overflow-auto">
                        <div class="position-absolute start-0" style="top: 2.5rem">
                            <app-label [label]="connection.connection.info.marketSegment"
                                       [translateContent]="false"></app-label>
                        </div>
                        <div class="card-body">
                            <ng-container *ngIf="selecting else normalView">
                                <div class="form-check position-absolute top-0 start-0 ms-2 mt-2">
                                    <input class="form-check-input mt-0" [(ngModel)]="connection['selected']"
                                           (ngModelChange)="toggleSelectedItem(connection, $event)" type="checkbox">
                                </div>
                            </ng-container>
                            <ng-template #normalView>
                                <app-confirmation-toggle *ngIf="appContext.isManagerForConnection(connection)">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h3 class="modal-title">Remove connection</h3>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <p>Are you sure you want to remove this connection?</p>
                                        </div>
                                        <div class="modal-footer justify-content-between">
                                            <a type="button" class="btn btn-secondary"
                                               data-bs-dismiss="modal">Cancel</a>
                                            <button type="button" class="btn btn-primary"
                                                    (click)="deleteConnection(connection)" data-bs-dismiss="modal">
                                                Remove connection
                                            </button>
                                        </div>
                                    </div>

                                    <button type="button" class="btn position-absolute top-0 start-0 to-show"
                                            role="button"
                                            aria-expanded="false">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </app-confirmation-toggle>
                                <button type="button"
                                        class="btn position-absolute top-0 end-0 to-show" role="button"
                                        style="right: 24px !important;"
                                        aria-expanded="false" (click)="openConnectionGraph(connection)">
                                    <i class="bi bi-bar-chart-line"></i>
                                </button>
                                <button *ngIf="appContext.isManagerForConnection(connection)" type="button"
                                        class="btn position-absolute top-0 end-0 to-show" role="button"
                                        aria-expanded="false" (click)="openConnectionDetails(connection)">
                                    <i class="bi bi-pencil"></i>
                                </button>
                            </ng-template>
                            <div class="text-center mb-3">
                                <h2 class="card-title notranslate">
                                    <app-connection-image
                                            [connectionType]="connection.connection.info.connectionType"></app-connection-image>
                                    {{ connection.connection.info.code }}
                                </h2>
                                <h5 class="text-muted notranslate">{{ connection.locationInfo.name }}</h5>
                                <span *ngIf="connection.connection.info.label"
                                      class="text-muted notranslate text-truncate">
                                    <i class="bi bi-tag"></i>
                                    {{ connection.connection.info.label }}
                                </span>
                            </div>
                            <hr class="card-divider m-0 mb-3">

                            <div *ngIf="linkedContracts(connection).length>0"
                                 class="list-group-flush list-group overflow-y-auto overflow-x-hidden"
                                 [style.max-height.px]="200">
                                <div *ngFor="let linkedContract of linkedContracts(connection); trackBy: trackByLinkedContractId"
                                     class="list-group-item py-3">
                                    <div class="row">
                                        <div class="col-auto">
                                            <i class="bi me-2 bi-paperclip"></i>
                                        </div>
                                        <div class="col text-truncate notranslate">
                                            <small>{{ getContractDescription(linkedContract.contractId) | async }}</small>
                                        </div>
                                        <ng-container *ngIf="appContext.isAdminOrIntermediary()">
                                            <div class="col-auto my-3">
                                                <button type="button" class="btn position-absolute top-0 end-0 to-show"
                                                        style="right: 24px !important;"
                                                        role="button" aria-expanded="false"
                                                        (click)="updateLinkedContract(connection,linkedContract)">
                                                    <i class="bi bi-pencil"></i>
                                                </button>
                                            </div>
                                            <div class="col-auto my-3">
                                                <app-confirmation-toggle *ngIf="allowContractDelete(linkedContract)">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h3 class="modal-title">Remove contract</h3>
                                                            <button type="button" class="btn-close"
                                                                    data-bs-dismiss="modal"
                                                                    aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <p>Are you sure you want to remove this contract?</p>
                                                        </div>
                                                        <div class="modal-footer justify-content-between">
                                                            <a type="button" class="btn btn-secondary"
                                                               data-bs-dismiss="modal">Cancel</a>
                                                            <button type="button" class="btn btn-primary"
                                                                    (click)="unlinkContract(linkedContract, connection)"
                                                                    data-bs-dismiss="modal">
                                                                Remove contract
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <button type="button"
                                                            class="btn position-absolute top-0 end-0 to-show"
                                                            role="button" aria-expanded="false">
                                                        <i class="bi bi-trash"></i>
                                                    </button>
                                                </app-confirmation-toggle>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <hr *ngIf="linkedContracts(connection).length>0" class="card-divider m-0 mb-3">

                            <div class="list-group-flush list-group overflow-y-auto overflow-x-hidden"
                                 [style.max-height.px]="200">
                                <div *ngFor="let meter of meters(connection); trackBy: trackByMeterId"
                                     class="list-group-item py-3">
                                    <div class="row">
                                        <div class="col-auto">
                                            <i class="bi me-2 bi-speedometer2"></i>
                                        </div>
                                        <div class="col text-truncate notranslate">
                                            <small>{{ meter.meter.details?.label || meter.meter.info.accessPointId }}</small>
                                        </div>
                                        <app-confirmation-toggle *ngIf="allowMeterDelete(meter)">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h3 class="modal-title">Remove meter</h3>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Are you sure you want to remove this meter?</p>
                                                </div>
                                                <div class="modal-footer justify-content-between">
                                                    <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                                                    <button type="button" class="btn btn-primary"
                                                            (click)="deleteMeter(meter, connection)"
                                                            data-bs-dismiss="modal">
                                                        Remove meter
                                                    </button>
                                                </div>
                                            </div>

                                            <button type="button" class="btn position-absolute top-0 end-0 to-show"
                                                    role="button" aria-expanded="false">
                                                <i class="bi bi-trash"></i>
                                            </button>
                                        </app-confirmation-toggle>
                                    </div>
                                </div>
                                <div *ngIf="appContext.isAdminOrIntermediary() && allowAddMeter(connection)"
                                     class="list-group-item py-3 pointer hover-underline"
                                     (click)="openUpsertSolarEdgeMeter(connection)">
                                    <div class="row">
                                        <div class="col-auto">
                                            <i class="bi me-2 bi-plus-circle"></i>
                                        </div>
                                        <div class="col text-truncate"><small>Add new solaredge meter</small></div>
                                    </div>
                                </div>
                                <div *ngIf="appContext.isAdminOrIntermediary()"
                                     class="list-group-item py-3 pointer hover-underline"
                                     (click)="linkContract(connection)">
                                    <div class="row">
                                        <div class="col-auto">
                                            <i class="bi me-2 bi-plus-circle"></i>
                                        </div>
                                        <div class="col text-truncate"><small>Link contract</small></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</app-scroller>
<div class="sticky-bottom bg-white mx-n3 mx-md-n5 border-top">
    <app-connection-selection *ngIf="selecting" [comparator]="comparator" [selectedItems]="selectedItems"
                              [csvMapper]="connectionCsvDataToJson" [downloadDataMethod]="downloadData"
                              [cancelMethod]="toggleSelection"
                              [sheetTemplate]="connectionDataTemplate"></app-connection-selection>
</div>
<div *ngIf="bulkDownloading" class="sticky-bottom bg-white mx-n3 mx-md-n5 border-top">
    <div class="row mx-0 p-3 px-md-5 g-3 gy-md-0 justify-content-between">
        <div class="col-12 col-md" style="max-width: 25rem">
            <app-date-range [(ngModel)]="bulkDownloadTimeRange" [ranges]="dashboardContext.defaultRanges"
                            [dateOnly]="true"></app-date-range>
        </div>
        <div class="col-12 col-md">
            <app-sources-selector positionClass="position-fixed"
                                  [sourceProvider]="sourceProvider"></app-sources-selector>
        </div>
        <div class="col-md-auto">
            <button class="btn btn-outline-info mx-1 w-100" (click)="bulkDownloadMonthData($event)" type="button">
                <i class="bi bi-download me-md-2"></i>
                Download
            </button>
        </div>
    </div>
</div>