<div *ngIf="!readonly" class="align-items-center mb-4 d-flex justify-content-between">
    <div class="flex-grow-1">
        <app-local-filter [appAutoFocus]="true" placeholder="Search" [data]="data"
                          (updated)="filteredData = $event"></app-local-filter>
    </div>
    <div *ngIf="appContext.isAdminOrIntermediary()" class="ms-4">
        <button class="btn btn-primary" (click)="linkContract(contract)">
            <i class="bi bi-link-45deg me-1"></i>
            <span class="d-none d-lg-inline">Link contract</span>
        </button>
    </div>
</div>

<div class="row">
    <ng-container *ngFor="let extendedLinkedContract of dataSorted">
        <ng-container *ngIf="extendedLinkedContract.connection; else organisationTemplate">
            <div class="col-xl-4 col-md-6 col-12 mb-4">
                <div class="h-100 d-flex flex-column">
                    <div class="card hover-show w-100 overflow-auto h-100 d-flex flex-column">
                        <div class="position-absolute start-0" style="top: 2.5rem">
                            <app-label [label]="extendedLinkedContract.connection.connection.info.marketSegment"
                                       [translateContent]="false"></app-label>
                        </div>
                        <div class="card-body flex-grow-1 d-flex flex-column">
                            <div class="text-center mb-3">
                                <h2 class="card-title notranslate">
                                    <app-connection-image [connectionType]="extendedLinkedContract.connection.connection.info.connectionType"></app-connection-image>
                                    {{ extendedLinkedContract.connection.connection.info.code }}
                                </h2>
                                <h5 class="text-muted notranslate">{{ extendedLinkedContract.connection.locationInfo?.name }}</h5>
                                <span *ngIf="extendedLinkedContract.connection.connection.info.label"
                                      class="text-muted notranslate text-truncate">
                                    <i class="bi bi-tag"></i>
                                    {{ extendedLinkedContract.connection.connection.info.label }}
                                </span>
                            </div>
                            <hr class="card-divider m-0 mb-3">
                            <div class="list-group-flush list-group overflow-y-auto overflow-x-hidden flex-grow-1" style="max-height: 200px;">
                                <div *ngFor="let linkedContract of extendedLinkedContract.linkedContracts;"
                                     class="list-group-item py-3">
                                    <div class="row">
                                        <div class="col-auto">
                                            <i class="bi me-1 bi-calendar"></i>
                                        </div>
                                        <div class="col text-truncate notranslate text-muted">
                                            <small>
                                                <app-contract-range [dateRange]="linkedContract.dateRange" [isReadOnly]="true"></app-contract-range>
                                            </small>
                                        </div>
                                        <div class="col-auto my-3 ms-2" *ngIf="appContext.isAdminOrIntermediary()">
                                            <button type="button" class="btn position-absolute top-0 end-0 to-show"
                                                    style="right: 24px !important;"
                                                    (click)="openLinkContractToConnectionModal(extendedLinkedContract.connection, linkedContract)">
                                                <i class="bi bi-pencil"></i>
                                            </button>
                                        </div>
                                        <div class="col-auto my-3">
                                            <app-confirmation-toggle *ngIf="appContext.isAdminOrIntermediary()">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h3 class="modal-title">Remove period</h3>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                                aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <p>Are you sure you want to remove this contract period?</p>
                                                    </div>
                                                    <div class="modal-footer justify-content-between">
                                                        <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                                                        <button type="button" class="btn btn-primary"
                                                                (click)="unlinkContractFromConnection(linkedContract,extendedLinkedContract.connection.organisationId)"
                                                                data-bs-dismiss="modal">
                                                            Remove period
                                                        </button>
                                                    </div>
                                                </div>
                                                <button type="button" class="btn position-absolute top-0 end-0 to-show"
                                                        role="button" aria-expanded="false">
                                                    <i class="bi bi-trash"></i>
                                                </button>
                                            </app-confirmation-toggle>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="appContext.isAdminOrIntermediary()"
                                 class="list-group-item py-3 pointer hover-underline mt-auto"
                                 (click)="linkContractToConnection(extendedLinkedContract.connection)">
                                <div class="row">
                                    <div class="col-auto">
                                        <i class="bi me-2 bi-plus-circle"></i>
                                    </div>
                                    <div class="col text-truncate">
                                        <small>Add period</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #organisationTemplate>
            <div class="col-xl-4 col-md-6 col-12 mb-4">
                <div class="h-100 d-flex flex-column">
                    <div class="card hover-show w-100 overflow-auto h-100 d-flex flex-column">
                        <div class="card-body flex-grow-1 d-flex flex-column">
                            <div class="pointer">
                                <div [routerLink]="'/organisation/' + extendedLinkedContract.organisation.organisationId"
                                     class="text-center mb-3">
                                    <h2 class="card-title notranslate">{{ extendedLinkedContract.organisation.info.name }}</h2>
                                </div>
                                <hr class="card-divider mb-0">
                                <ul class="list-group-flush mb-n3 list-group flex-grow-1">
                                    <li class="list-group-item py-3" *ngFor="let contract of extendedLinkedContract.linkedContracts">
                                        <div class="row">
                                            <div class="col-auto">
                                                <i class="bi me-1 bi-calendar"></i>
                                            </div>
                                            <div class="col text-truncate notranslate hover-show text-muted">
                                                <small>
                                                    <app-contract-range [dateRange]="contract.dateRange" [isReadOnly]="true"></app-contract-range>
                                                </small>
                                            </div>
                                            <div class="col-auto my-3" *ngIf="appContext.isAdminOrIntermediary()">
                                                <button type="button" class="btn position-absolute top-0 end-0 to-show"
                                                        style="right: 24px !important;"
                                                        (click)="openLinkContractModal(extendedLinkedContract.organisation.organisationId, contract)">
                                                    <i class="bi bi-pencil"></i>
                                                </button>
                                            </div>
                                            <div class="col-auto my-3">
                                                <app-confirmation-toggle *ngIf="appContext.isAdminOrIntermediary()">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h3 class="modal-title">Remove period</h3>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                                    aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <p>Are you sure you want to remove this contract period?</p>
                                                        </div>
                                                        <div class="modal-footer justify-content-between">
                                                            <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                                                            <button type="button" class="btn btn-primary"
                                                                    (click)="unlinkContractFromOrganisation(contract, extendedLinkedContract.organisation)"
                                                                    data-bs-dismiss="modal">
                                                                Remove period
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="btn position-absolute top-0 end-0 to-show"
                                                            role="button" aria-expanded="false">
                                                        <i class="bi bi-trash"></i>
                                                    </button>
                                                </app-confirmation-toggle>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <hr class="card-divider mb-0">
                            <div *ngIf="appContext.isAdminOrIntermediary()"
                                 class="list-group-item py-3 pointer hover-underline mt-auto"
                                 (click)="linkContractToOrganisation(extendedLinkedContract.organisation)">
                                <div class="row">
                                    <div class="col-auto">
                                        <i class="bi me-2 bi-plus-circle"></i>
                                    </div>
                                    <div class="col text-truncate">
                                        <small>Add period</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</div>

