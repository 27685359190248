import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import moment from "moment/moment";
import {environment} from '../../environments/environment';

export abstract class RequestGateway {
  private handlers: any[] = [];

  protected constructor(private http: HttpClient, private path: string) {
  }

  send(type: string, message: any, options: any = {}): Observable<any> {
    console.debug("sending request", type, message);
    const handler = this.handlers.find(h => h[type]);
    if (handler) {
      try {
        const result = handler[type](getPayload(message));
        return result instanceof Observable ? result : of(result);
      } catch (e) {
        return new Observable(subscriber => subscriber.error(e));
      }
    } else {
      message = asMessage(message, type);
      message.metadata.localTime = moment().toLocaleString();
      options.withCredentials = true;

      const bearerHeaderName = 'Authorization';
      const bearerHeaderValue = localStorage.getItem(bearerHeaderName);
      if (bearerHeaderValue) {
        (options.headers = options.headers || {})[bearerHeaderName] = bearerHeaderValue;
      }
      return this.http.post<any>(environment.apiHost + this.path, message, options);
    }
  }

  registerLocalHandler(handler: any) {
    this.handlers.push(handler);
    return () => this.handlers = this.handlers.filter(h => h !== handler);
  }

  registerLocalHandlers(...handlers: any[]) {
    const subscriptions: any[] = handlers.map(h => this.registerLocalHandler(h));
    return () => subscriptions.forEach(s => s());
  }
}

function getPayload(message: any) {
  return message['@class'] === 'io.fluxcapacitor.javaclient.common.Message' ? message.payload : message;
}

function asMessage(payload: any, type: string) {
  //payload is already a message
  if (payload['@class'] === 'io.fluxcapacitor.javaclient.common.Message') {
    payload.metadata = payload.metadata || {};
    payload.payload["@class"] = type;
    return payload;
  }

  //payload should be converted to a message
  payload["@class"] = type;
  return {
    "@class": 'io.fluxcapacitor.javaclient.common.Message',
    payload: payload,
    metadata: {}
  }
}
