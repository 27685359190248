import {publishEvent} from './flux/flux-utils';
import {
    ConfirmationModalComponent,
    ConfirmationModalEvent
} from './common/confirmation-modal/confirmation-modal.component';
import {AnimationTypes, ModalEvent, ModalOptions} from "./common/modal/modal.component";


export function openModal(component, data?, options: ModalOptions = {animation: AnimationTypes.fade}) {
    publishEvent('openModal', <ModalEvent>{component: component, data: data, options: options});
}

export function closeModal() {
    publishEvent('closeModal');
}


export function openConfirmationModal(component, data?) {
    publishEvent('openConfirmationModal', <ConfirmationModalEvent>{component: component, data: data});
}

export function openConfirmationModalWithCallback(callback: (boolean, string?) => any, component?, data?, backdrop?: boolean | 'static') {
    publishEvent('openConfirmationModal', <ConfirmationModalEvent>{
        component: component || ConfirmationModalComponent,
        data: data,
        backdrop: backdrop,
        modalSize: data?.modalSize,
        callback: callback
    });
}

export function closeConfirmationModal() {
    publishEvent('closeConfirmationModal');
}
