<div *ngIf="!readonly" class="align-items-center mb-4 row">
    <div class="col">
        <app-local-filter [appAutoFocus]="true" placeholder="Search" [data]="data" (updated)="filteredData = $event"></app-local-filter>
    </div>
    <div *ngIf="appContext.isManagerForOrganisation(organisation)" class="col-auto">
        <button (click)="editAuthorisation(null)" type="button" class="btn btn-primary">
            <i class="bi bi-plus me-md-2"></i>
            <span class="d-none d-md-inline">Add authorisation</span>
        </button>
    </div>
</div>
<div class="row g-md-4">
    <ng-container *ngFor="let authorisation of dataSorted">
        <div class="col-12 col-md-6" *ngIf="authorisation.role !== 'intermediary' && !authorisation.revoked" >
            <div class="h-100 d-flex">
                <div class="mb-3 card w-100 hover-show">
                    <div class="card-body">
                        <app-confirmation-toggle *ngIf="appContext.isManagerForOrganisation(organisation)">
                            <button type="button" class="btn position-absolute top-0 start-0 to-show"
                                    role="button" aria-expanded="false">
                                <i class="bi bi-trash"></i>
                            </button>
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title">Revoke authorisation</h3>
                                    <a type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></a>

                                </div>
                                <div class="modal-body">
                                    <p>Are you sure you want to revoke the authorisation for <span class="notranslate">{{authorisationHeader(authorisation) | async}}?</span></p>
                                </div>
                                <div class="modal-footer justify-content-between">
                                    <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                                    <button type="button" class="btn btn-primary" (click)="revoke(authorisation)" data-bs-dismiss="modal">
                                        Revoke
                                    </button>
                                </div>
                            </div>
                        </app-confirmation-toggle>
                        <button *ngIf="appContext.isManagerForOrganisation(organisation)" type="button" class="btn position-absolute top-0 end-0 to-show" role="button"
                                aria-expanded="false" (click)="editAuthorisation(authorisation)">
                            <i class="bi bi-pencil"></i>
                        </button>
                        <div class="align-items-center row">
                            <div class="col-auto">
                                <h2><i class="bi" [ngClass]="getIcon(authorisation)"></i></h2>
                            </div>
                            <div class="ms-n2 col">
                                <h4 class="mb-1 notranslate">{{authorisationHeader(authorisation) | async}}</h4>
                                <p class="small text-muted mb-1 card-text">{{authorisation.role}}</p>
                                <p class="small card-text">
                                    <ng-container *ngIf="authorisation.revoked else enabled">
                                        <i class="bi bi-x-circle text-danger align-middle"></i> Revoked
                                    </ng-container>
                                    <ng-template #enabled>
                                        <i class="bi bi-check-circle text-success align-middle"></i> Active
                                    </ng-template>
                                </p>
                                <p *ngFor="let entityId of authorisation.entityIds" class="small card-text">
                                    <ng-template #c [ngTemplateOutlet]="c" let-entity [ngTemplateOutletContext]="{ $implicit: getEntity(entityId) | async }">
                                        <ng-container *ngIf="entity; else notFound">
                                            <ng-container *ngIf="entity.connectionInfo; else locationEntry">
                                                <app-connection-image [connectionType]="entity.connectionInfo.connectionType"></app-connection-image>
                                                <span class="notranslate">{{entity.connectionInfo.code}}</span>
                                            </ng-container>
                                            <ng-template #locationEntry>
                                                <i class="h2 bi me-2" [ngClass]="appContext.getIconOfSourceType('location')"></i>
                                                <span class="notranslate">{{refDataUtils.locationInfoFormatter(entity.locationInfo)}}</span>
                                            </ng-template>
                                        </ng-container>
                                        <ng-template #notFound>
                                            <i class="h2 me-2 bi bi-question"></i>Removed connection or location
                                        </ng-template>
                                    </ng-template>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>