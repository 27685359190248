import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {sendCommand} from "../../../flux/flux-utils";
import {AppContext} from "../../../app-context";
import {ConfirmUser, SendConfirmationEmail} from "@flowmaps/flowmaps-typescriptmodels";
import {AuthService} from "../../../utils/auth.service";

@Component({
    selector: 'app-confirm-user',
    templateUrl: './confirm-user.component.html',
    styleUrls: ['./confirm-user.component.css']
})
export class ConfirmUserComponent implements OnInit {
    userId: string;
    token: string;

    constructor(private route: ActivatedRoute, private authService: AuthService) {
    }

    ngOnInit(): void {
        this.userId = this.route.snapshot.queryParamMap.get('userId');
        this.token = this.route.snapshot.params['token'];
        if (this.token && this.userId) {
            this.confirmUser();
        }
        if (!this.userId) {
            this.authService.navigateToLogin();
        }
    }

    resendConfirmationEmail() {
        sendCommand('com.flowmaps.api.authentication.SendConfirmationEmail', <SendConfirmationEmail>{
            userId: this.userId
        }, () => AppContext.registerSuccess("Confirmation e-mail sent"))
    }

    confirmUser() {
        this.authService.confirm(<ConfirmUser>{
            userId: this.userId,
            token: this.token
        });
    }
}
