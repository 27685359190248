<div class="row justify-content-between align-items-center mb-5">
    <div class="col-12 col-md-9 col-xl-7">

        <!-- Heading -->
        <h2 class="mb-2">
            Change your password
        </h2>

        <!-- Text -->
        <p class="text-muted mb-xl-0">
            We will email you a confirmation when changing your password, so please expect that email after submitting.
        </p>

    </div>
    <div class="col-12 col-xl-auto">

        <!-- Button -->
        <button routerLink="/forgot-password" class="btn btn-white">
            Forgot your password?
        </button>

    </div>
</div> <!-- / .row -->

<div class="row">
    <div class="col-12 col-md-6 order-md-2">

        <!-- Card -->
        <div class="card bg-light border ms-md-4">
            <div class="card-body">

                <!-- Text -->
                <p class="mb-2">
                    Password requirements
                </p>

                <!-- Text -->
                <p class="small text-muted mb-2">
                    Please ensure that your password is at least 8 characters long
                </p>
            </div>
        </div>

    </div>
    <div class="col-12 col-md-6">

        <app-form (submit)="changePassword()">
            <app-form-field label="Current password">
                <input required [(ngModel)]="changePasswordCommand.oldPassword" type="password" class="form-control">
            </app-form-field>

            <app-form-field label="New password">
                <input required [(ngModel)]="changePasswordCommand.newPassword" type="password"
                       autocomplete="new-password" class="form-control">
            </app-form-field>

            <button class="btn w-100 btn-primary lift" type="submit">Update password</button>
        </app-form>

    </div>
</div>