<app-form (submit)="linkContract()">
    <div class="card">
        <div class="card-header">
            <h3 class="card-header-title">
                <span>Link Contract</span>
            </h3>
        </div>
        <div class="card-body">
            <div class="row gx-3 justify-content-between py-4">
                <div class="col-12 ">
                    <app-form-field label="Selection">
                        <app-sources-selector [(ngModel)]="sources" (updated)="sourceSelectionUpdated($event)"
                                              [sourceProvider]="sourceProvider" >
                        </app-sources-selector>
                    </app-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-contract-range [dateRange]="data.dateRange" [isReadOnly]="false"></app-contract-range>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-between">
            <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
            <div>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>



