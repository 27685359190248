import {Component, OnDestroy} from '@angular/core';
import {GetMyReports, Report, ReportScheduleResolution, TimeResolution} from "@flowmaps/flowmaps-typescriptmodels";
import {ActivatedRoute, Router} from "@angular/router";
import {sendQuery} from "../../../flux/flux-utils";
import {cloneDeep} from "lodash";
import {EventGateway} from "../../../flux/event-gateway";

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnDestroy {
    reportId: string;
    report: Report;

    private readonly registration: () => void;

    constructor(private eventGateway: EventGateway, private router: Router, private route: ActivatedRoute) {
        this.registration = this.eventGateway.registerLocalHandler(this);
        route.params.subscribe(params => {
            this.reportId = params['reportId'];

            if (!this.reportId) {
                router.navigateByUrl("/report/default");
                return;
            }

            if (this.reportId === "default") {
                this.report = cloneDeep(ReportComponent.defaultReport);
            } else {
                this.getReports();
            }
        });
    }

    ngOnDestroy(): void {
        this.registration();
    }

    'reportChange' = () => {
        this.getReports();
    }

    private getReports() {
        sendQuery("com.flowmaps.api.reporting.GetMyReports", <GetMyReports>{}, {caching: false})
            .subscribe((reports: Report[]) => {
                this.report = reports.find(r => r.reportId === this.reportId);
            });
    }

    static defaultReport: Report = (<Report>{
        info: {
            name: "Default",
            query: {
                range: {
                    resolution: ReportScheduleResolution.year,
                    value: 0
                },
                resolution: TimeResolution.month
            }
        }
    });
}
