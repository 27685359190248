<ng-container *ngIf="_location else noLocation">
    <div class="card-subtitle">
        <ng-container *ngIf="_location.info.energyLabel || _location.refdata?.energyLabel else noLabel">
            <h2>Energy label</h2>
        </ng-container>
        <ng-template #noLabel>
            <h2>This building has no known energy label</h2>
        </ng-template>
    </div>
    <div class="d-flex align-items-stretch flex-column">
        <div class="energy-label-wrapper row d-flex align-items-stretch justify-content-end align-self-center mb-3">
            <ng-container *ngFor="let label of energyLabels">
                <div class="col d-flex align-items-center justify-content-center h-100" [ngClass]="getEnergyLabelClass(label)">
                    <span class="notranslate">{{getLabel(label)}}</span>
                </div>
            </ng-container>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <span>Valid until</span>
                <span class="notranslate">{{_location.refdata?.energyLabelValidity?.end ? formatDate(_location.refdata.energyLabelValidity.end) : 'n/a'}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <span>Area</span>
                <span class="notranslate">{{(refdataUtils.getLocationArea(_location)) ? refdataUtils.getLocationArea(_location) + ' m&#178;' : 'n/a'}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                <span>Building type</span>
                <span>{{buildingType?.info.name}}</span>
            </li>
        </ul>
    </div>
</ng-container>
<ng-template #noLocation>
    <div class="card-subtitle">
        <h2>No location selected</h2>
    </div>
</ng-template>