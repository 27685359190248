<div class="position-fixed w-100 my-3">
    <div class="row justify-content-between">
        <div class="col-auto ms-3">
            <img [src]="'/assets/images/logos/' + context.brand + '-logo-500.png'" style="height: 3rem" alt="Logo">
        </div>
        <div class="col-auto me-4">
            <app-language-selector></app-language-selector>
        </div>
    </div>
</div>


<div class="main-content">
    <div class="position-fixed w-100">
        <app-alerting></app-alerting>
    </div>
    <router-outlet></router-outlet>
</div>