<div *ngIf="!readonly" class="align-items-center row mb-3">
    <div class="col">
        <app-date-range localStorageKey="alerts-overview-range" [(ngModel)]="dateRange" (ngModelChange)="reload()"></app-date-range>
    </div>
    <div class="col">
        <app-local-filter [appAutoFocus]="true" placeholder="Search" [data]="data" (updated)="filteredData = $event"></app-local-filter>
    </div>

    <div class="my-2 w-100 d-lg-none"></div>

    <div class="col-auto">
        <button (click)="toggleSelection()" type="button" class="btn btn-secondary">
            <i class="bi bi-pencil me-md-2"></i>
            <span class="d-none d-md-inline">Bulk edit</span>
        </button>
    </div>
    <div *ngIf="appContext.isAdmin()" class="col-auto">
        <button (click)="addAlert()" type="button" class="btn btn-primary">
            <i class="bi bi-plus me-md-2"></i>
            <span class="d-none d-md-inline">Add alert</span>
        </button>
    </div>
</div>
<div class="row mb-3 d-flex justify-content-end">
    <ng-container *ngFor="let facet of getFacets('left'); index as i; trackBy: trackByFacetName">
        <div class="col-auto">
            <app-facet-filter [facet]="facet" (selectedValuesChange)="onFacetValuesChange(facet, $event)"
                              [(selectedValues)]="facetFilters.facets[facet.name]"
                              [style.max-width.px]="200"></app-facet-filter>
        </div>
    </ng-container>
    <div class="col ms-auto"></div>
    <ng-container *ngFor="let facet of getFacets('right'); index as i; trackBy: trackByFacetName">
        <div class="col-auto">
            <app-facet-filter [facet]="facet" (selectedValuesChange)="onFacetValuesChange(facet, $event)"
                              [(selectedValues)]="facetFilters.facets[facet.name]"
                              [style.max-width.px]="200"></app-facet-filter>
        </div>
    </ng-container>
</div>

<div class="row mb-3 d-flex justify-content-end" *ngIf="selecting && hasSelectableItems">
    <div class="col-12">
        <div class="form-check ms-2">
            <input class="form-check-input" type="checkbox" id="select-all"
                   [checked]="allItemsSelected()" (click)="toggleSelectAll()">
            <label class="form-check-label" for="select-all">
                <span *ngIf="allItemsSelected()">Deselect all</span>
                <span *ngIf="!allItemsSelected()">Select all</span>
            </label>
        </div>
    </div>
</div>
<app-scroller class="d-block min-vh-100" (endReached)="loadNextPage()" [windowScroll]="true">
    <div class="row">
        <ng-container *ngFor="let alert of dataSorted; let i = index; trackBy: trackByForRecord">
            <div role="row" class="col-12">
                <div class="card hover-show w-100" [ngClass]="isMuted(alert) || isResolved(alert) ? 'text-muted' : ''">
                    <ng-container *ngIf="selecting && alert.alertStatus !== 'RESOLVED'">
                        <div class="form-check position-absolute top-0 start-0 ms-2 mt-2">
                            <input class="form-check-input mt-0" [(ngModel)]="alert['selected']"
                                   (ngModelChange)="toggleSelectedItem(alert, $event)" type="checkbox">
                        </div>
                    </ng-container>
                    <div class="card-body">
                        <div class="row gx-3">
                            <div class="col-auto notranslate d-flex align-items-center justify-content-between">
                                <ng-container *ngIf="getAlertStatusConfig(alert) as config">
                                    <span class="h2 m-0" [ngClass]="'text-'+config.colorClass">
                                        <i class="bi" [ngClass]="config.icon"></i>
                                    </span>
                                </ng-container>
                            </div>
                            <div class="col">
                                <div class="row gx-3 mb-1">
                                    <ng-container *ngIf="alert.meter?.location as location else noLocationHeader">
                                        <div class="col-auto">
                                            <a class="h3 notranslate hover-underline text-black" [href]="getLocationDashboardLink(alert)">
                                                {{refDataUtils.locationsFormatter(alert.meter.location)}}
                                            </a>
                                        </div>
                                        <div *ngIf="alert.details.message" class="col-auto text-truncate notranslate" [style.max-width.px]="200"
                                             data-bs-toggle="tooltip" data-bs-placement="top" [title]="alert.details.message">
                                            <span>{{alert.details.message}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-template #noLocationHeader>
                                        <div class="col-auto">
                                            <ng-container *ngIf="alert.details.message else emptyAlert">
                                                <span class="h3 notranslate">{{alert.details.message}}</span>
                                            </ng-container>
                                            <ng-template #emptyAlert>
                                                <span class="h3">No connection found, connection might be removed</span>
                                            </ng-template>
                                        </div>
                                    </ng-template>
                                    <div class="col-auto">
                                        <span class="badge rounded-pill" [ngClass]="('text-bg-'+badgeColorForAlertType(alert)) + (alert.alertStatus === 'MUTED' ? ' opacity-25 ' : '')">
                                            {{alert.details.type}}
                                        </span>
                                    </div>
                                </div>
                                <div class="row gx-3 fs-5">
                                    <div class="row col notranslate gx-3">
                                        <div class="col-auto" *ngIf="alert.meter as meterWrapper">
                                            <ng-container *ngIf="meterWrapper.meter as meter">
                                                <app-connection-image [connectionType]="meterWrapper.connection.info.connectionType"></app-connection-image>
                                                <span class="text-muted">{{meterWrapper.connection.info.code}}</span>
                                                <ng-container *ngIf="meter.info.type !== 'PRIMARY' && meter.info.type as meterType">
                                                    <img [src]="appContext.meterImage(meterType)" class="ms-3 me-2"
                                                         [alt]="appContext.meterName(meterType)" [width]="20/1.2">
                                                    <span class="text-muted">{{refDataUtils.meterFormatter(meterWrapper)}}</span>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                        <div class="col-auto text-muted">
                                            <span class="bi bi-clock me-2"></span>
                                            <span>{{formatTimestamp(alert.timestamp)}}</span>
                                        </div>
                                        <div *ngIf="isMuted(alert)" class="col-auto text-muted">
                                            <span class="bi bi-volume-mute me-2"></span>
                                            <span>{{formatTimestamp(alert.muteDeadline)}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto ms-auto mt-n3 me-n3" *ngIf="!isResolved(alert)">
                                <button type="button" class="btn text-body" role="button" data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside" aria-expanded="false">
                                    <i class="bi bi-three-dots-vertical"></i>
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <button class="dropdown-item" (click)="openResolveAlertModal(alert)">Resolve alert</button>
                                    </li>
                                    <li *ngIf="isMuted(alert) else unmutedAlert">
                                        <button class="dropdown-item" (click)="openUnmuteAlertModal(alert)">Unmute alert</button>
                                    </li>
                                    <ng-template #unmutedAlert>
                                        <li>
                                            <button class="dropdown-item" (click)="openMuteAlertModal(alert)">Mute alert</button>
                                        </li>
                                    </ng-template>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</app-scroller>
<div *ngIf="selecting" class="sticky-bottom bg-white mx-n3 mx-md-n5 border-top">
    <app-alert-selection [selectedItems]="selectedItems"
                         [cancelMethod]="toggleSelection"></app-alert-selection>
</div>

<ng-template #muteAlertTemplate>
    <p>Are you sure you want to mute this alert?</p>
    <app-form-field label="Mute deadline">
        <app-date-field [(ngModel)]="muteDate" [minDate]="tomorrow()"></app-date-field>
    </app-form-field>
</ng-template>
