import {Component} from '@angular/core';
import {sendCommand} from '../../../flux/flux-utils';
import {EncryptValue} from '@flowmaps/flowmaps-typescriptmodels';
import {AppContext} from '../../../app-context';
import {closeModal} from '../../../app-utils';
import {copyToClipboard} from '../../../common/utils';

@Component({
    selector: 'app-encrypt',
    templateUrl: './encrypt.component.html',
    styleUrls: ['./encrypt.component.css']
})
export class EncryptComponent {
    value: string;
    result: string;

    encrypt() {
        sendCommand("com.flowmaps.api.admin.EncryptValue", <EncryptValue>{value: this.value},
            result => {
                copyToClipboard(result).subscribe({
                    error: () => {
                        console.error("Failed to copy value to clipboard: " + result);
                    },
                    complete: () => {
                        AppContext.registerSuccess("Encrypted value was copied to your clipboard!");
                        closeModal();
                    }
                });
            });
    }
}
