<app-form (submit)="updateInfo()">
    <div class="card">
        <div class="card-header">
            <h3 class="card-header-title">
                <span>{{isNewRecord ? 'Create alert channel' : data.slackAlertChannel.info.name}}</span>
            </h3>
        </div>
        <div class="card-body">
            <div class="row mb-3">
                <div class="col-12">
                    <app-form-field label="Channel Type">
                        <app-select class="notranslate" [required]="true" [options]="['Slack']" [ngModel]="null" [disabled]="!isNewRecord"></app-select>
                    </app-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-form-field label="Name">
                        <input [(ngModel)]="data.slackAlertChannel.info.name" type="text" class="form-control" required>
                    </app-form-field>
                </div>
                <div class="col-12 col-md-6 notranslate">
                    <app-form-field label="URL">
                        <input [(ngModel)]="data.slackAlertChannel.info.url" type="text" class="form-control" required>
                    </app-form-field>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-between">
            <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
            <div>
                <button *ngIf="!isNewRecord" type="button" class="btn btn-danger me-2" (click)="deleteChannel()">Delete channel</button>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>
