<ng-container *ngIf="_location && refDataUtils.getLocationArea(_location) && values?.length > 0 else noLocation">
    <div class="row flex-grow-1 ms-n2">
        <ng-container *ngFor="let value of values; let i = index">
            <div class="col d-flex flex-column text-center">
                <span class="notranslate h3 m-0" [ngClass]="value.value < parisProofValue ? 'text-success' : ''">
                    <i *ngIf="value.value < parisProofValue" class="bi bi-check text-success"></i>
                    {{value.value | number}} kWh
                </span>
                <span>per m<sup>2</sup> per year</span>
                <div class="w-100 h-100 building-image mb-2 mt-n2"
                     [style.background-size]="'50px 100%'"
                     [style.clip-path]="'polygon(0 100%, 100% 100%, 100% ' + getBuildingBackgroundStyle(value.value) + '%, 0 ' + getBuildingBackgroundStyle(value.value) + '%'"
                ></div>
                <span class="notranslate">{{value.year}}</span>
            </div>
        </ng-container>
        <div class="col d-flex flex-column text-center">
            <span class="notranslate h3 m-0">{{parisProofValue}} kWh</span>
            <span>per m<sup>2</sup> per year</span>
            <div class="w-100 h-100 building-image my-2"
                 [style.background-size]="'50px 100%'"
                 [style.clip-path]="'polygon(0 100%, 100% 100%, 100% ' + getBuildingBackgroundStyle(parisProofValue) + '%, 0 ' + getBuildingBackgroundStyle(parisProofValue) + '%'"
            ></div>
            <span>Paris Proof</span>
        </div>
    </div>
</ng-container>
<ng-template #noLocation>
    <div class="card-subtitle d-flex text-center align-items-center w-100">
        <h2 class="flex-grow-1">{{noDataText}}</h2>
    </div>
</ng-template>