import {Component, Input, OnInit} from '@angular/core';
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {closeModal} from "../../../../app-utils";
import {uuid} from "../../../../common/utils";
import {QueryGateway} from "../../../../flux/query-gateway";
import {ExtendedContract} from "../contracts-list/contracts-list.component";
import {Router} from "@angular/router";

@Component({
    selector: 'app-add-contract',
    templateUrl: './add-contract.component.html',
    styleUrls: ['./add-contract.component.css']
})
export class AddContractComponent implements OnInit{
    @Input() data: AddContractComponentData;
    isNewRecord: boolean;

    ngOnInit() {
        this.isNewRecord = !this.data.contract.contractId

    }
    constructor(private router: Router, private queryGateway: QueryGateway) {
        if (!this.data) {
            this.data = { contract: AddContractComponent.newContract() };
        }
    }

    static newContract = (): ExtendedContract  => {
        return {
            contractData: {
                name: '',
                connectionType: null,
            },
            periods: [],
            defaultContract: false,
            contractId: "",
            organisationId: null,
            organisationInfo: {},
        }
    }

    updateInfo = () => {
        const contractId =  uuid();
        const command: any = {
            contractId: contractId,
            contractData: this.data.contract.contractData,
            defaultContract: this.data.contract.defaultContract,
            organisationId: this.data.contract.organisationId,
            organisationInfo: this.data.contract.organisationInfo
        }
        sendCommand("com.flowmaps.api.organisation.UpsertContract", command, () => {
            closeModal();
            this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyContracts");
            this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
            publishEvent( "recordAdded", command);
        });
    }
}
export interface AddContractComponentData {
    contract: ExtendedContract;
}