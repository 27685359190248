<div *ngIf="!readonly" class="align-items-center mb-4 row">
    <div class="col">
        <app-local-filter [appAutoFocus]="true" placeholder="Search" [data]="data" (updated)="filteredData = $event"></app-local-filter>
    </div>
    <div *ngIf="appContext.isAdminOrIntermediary()" class="col-auto">
        <button (click)="addOrganisation()" type="button" class="btn btn-primary">
            <i class="bi bi-plus me-md-2"></i>
            <span class="d-none d-md-inline">Add organisation</span>
        </button>
    </div>
</div>
<app-scroller (endReached)="loadNextPage()" [windowScroll]="true">
    <div class="row">
        <ng-container *ngFor="let organisation of dataSorted; let i = index; trackBy: trackByForRecord">
            <div role="row" class="col-xl-4 col-md-6 col-12">
                <div class="card hover-show w-100 overflow-auto">
                    <div class="card-body">
                        <button type="button"
                                class="btn position-absolute top-0 end-0 to-show" role="button"
                                aria-expanded="false" (click)="openOrganisationGraph(organisation)">
                            <i class="bi bi-bar-chart-line"></i>
                        </button>

                        <app-confirmation-toggle *ngIf="appContext.isAdminOrIntermediary()">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title">Delete organisation</h3>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                                </div>
                                <div class="modal-body">
                                    <p>Are you sure you want to delete this organisation?</p>
                                </div>
                                <div class="modal-footer justify-content-between">
                                    <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                                    <button type="button" class="btn btn-primary" (click)="deleteOrganisation(organisation)" data-bs-dismiss="modal">
                                        Delete organisation
                                    </button>
                                </div>
                            </div>

                            <button type="button" class="btn position-absolute top-0 start-0 to-show"
                                    role="button" aria-expanded="false">
                                <i class="bi bi-trash"></i>
                            </button>
                        </app-confirmation-toggle>
                        <div [routerLink]="'/organisation/'+organisation.organisationId" class="pointer">
                            <div class="text-center mb-3">
                                <h2 class="card-title notranslate">{{organisation.info.name}}</h2>
                            </div>
                            <hr class="card-divider mb-0">
                            <div class="list-group-flush mb-n3 list-group">
                                <div class="list-group-item py-3">
                                    <div class="row">
                                        <div class="col"><i class="bi me-1" [ngClass]="appContext.getIconOfSourceType('location')"></i><small>Locations</small></div>
                                        <div class="col-auto notranslate"><small>{{organisation.locations.length}}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-group-item py-3">
                                    <div class="row">
                                        <div class="col"><i class="bi bi-speedometer2 me-1"></i><small>Connections</small></div>
                                        <div class="col-auto notranslate"><small>{{getConnectionCount(organisation)}}</small></div>
                                    </div>
                                </div>
                                <div class="list-group-item py-3">
                                    <div class="row">
                                        <div class="col"><i class="bi bi-paperclip me-1"></i><small>Contracts</small></div>
                                        <div class="col-auto notranslate"><small>{{organisation.linkedContracts.length}}</small></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="card-divider mb-0">

                        <div *ngIf="appContext.isAdminOrIntermediary()"
                             class="list-group-item py-3 pointer hover-underline"
                             (click)="linkNewContract(organisation)">
                            <div class="row">
                                <div class="col-auto">
                                    <i class="bi me-2 bi-plus-circle"></i>
                                </div>
                                <div class="col text-truncate"><small>Link contract</small></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</app-scroller>