<ng-container *ngIf="hasSourceForConnectionType()">
    <app-report-html-page *ngIf="chartDataProvider.dataProvider.sourceProvider.getAllSourcesByType(locationType).length > 1 || hasSubMeters()" [pageBreak]="true">
        <ng-container *ngIf="chartDataProvider.dataProvider.sourceProvider.getAllSourcesByType(locationType).length > 1 else locationPage">
            <div class="row justify-content-between align-items-center mb-0">
                <h4 class="mt-3">
                    <img src="assets/images/icons/ranking.png" width="20px" class="me-2">
                    <span>Location ranking</span>
                    <span class="mx-2 notranslate">–</span>
                    <span class="notranslate">{{appContext.entityPerformanceMeasurementName(entityConsumptionType, true)}}</span>
                    <span class="notranslate">&nbsp;</span>
                    <span>per m²</span>
                </h4>
                <app-location-performance-chart [data]="chartDataProvider" [showInReport]="true"
                                                [selectedDataType]="entityConsumptionType" [pageSize]="pageSize"
                                                [showBySquareMeter]="true"></app-location-performance-chart>
            </div>
            <div class="row justify-content-between align-items-center mb-0">
                <h4 class="my-0">
                    <img src="assets/images/icons/ranking.png" width="20px" class="me-2">
                    <span>Location ranking</span>
                    <span class="mx-2 notranslate">–</span>
                    <span class="notranslate">{{appContext.entityPerformanceMeasurementName(entityConsumptionType, true)}}</span>
                </h4>
                <app-location-performance-chart [data]="chartDataProvider" [showInReport]="true"
                                                [selectedDataType]="entityConsumptionType" [pageSize]="pageSize"
                                                [showBySquareMeter]="false"></app-location-performance-chart>
            </div>
            <div class="row justify-content-between align-items-center mb-0" *ngIf="productionType">
                <h4 class="my-0">
                    <img src="assets/images/icons/ranking.png" width="20px" class="me-2">
                    <span>Location ranking</span>
                    <span class="mx-2 notranslate">–</span>
                    <span class="notranslate">{{appContext.entityPerformanceMeasurementName(entityProductionType, true)}}</span>
                </h4>
                <app-location-performance-chart [data]="chartDataProvider" [showInReport]="true"
                                                [selectedDataType]="entityProductionType" [pageSize]="pageSize"
                                                [showBySquareMeter]="false"></app-location-performance-chart>
            </div>
        </ng-container>
        <ng-template #locationPage>
            <div class="row justify-content-between align-items-center mb-0">
                <h4 class="my-0">
                    <img src="assets/images/icons/ranking.png" width="20px" class="me-2">
                    <span>Meter ranking</span>
                    <span class="mx-2 notranslate">–</span>
                    <span class="notranslate">{{appContext.entityPerformanceMeasurementName(entityConsumptionType, true)}}</span>
                    <span class="notranslate">&nbsp;</span>
                    <span>per m²</span>
                </h4>
                <app-meter-performance-chart [data]="chartDataProvider" [showInReport]="true"
                                             [selectedDataType]="entityConsumptionType" [pageSize]="pageSize"
                                             [showBySquareMeter]="false">
                </app-meter-performance-chart>
            </div>
            <div class="row justify-content-between align-items-center mb-0">
                <h4 class="my-0">
                    <img src="assets/images/icons/ranking.png" width="20px" class="me-2">
                    <span>Meter ranking</span>
                    <span class="mx-2 notranslate">–</span>
                    <span class="notranslate">{{appContext.entityPerformanceMeasurementName(entityConsumptionType, true)}}</span>
                </h4>
                <app-meter-performance-chart [data]="chartDataProvider" [showInReport]="true"
                                             [selectedDataType]="entityConsumptionType" [pageSize]="pageSize"
                                             [showBySquareMeter]="false">
                </app-meter-performance-chart>
            </div>
            <div class="row justify-content-between align-items-center mb-0" *ngIf="productionType">
                <h4 class="my-0">
                    <img src="assets/images/icons/ranking.png" width="20px" class="me-2">
                    <span>Meter ranking</span>
                    <span class="mx-2 notranslate">–</span>
                    <span class="notranslate">{{appContext.entityPerformanceMeasurementName(entityProductionType, true)}}</span>
                </h4>
                <app-meter-performance-chart [data]="chartDataProvider" [showInReport]="true"
                                             [selectedDataType]="entityProductionType" [pageSize]="pageSize"
                                             [showBySquareMeter]="false">
                </app-meter-performance-chart>
            </div>
        </ng-template>
    </app-report-html-page>
</ng-container>