<ng-container *ngIf="appContext.isAdminOrIntermediary()">
    <div *ngIf="!readonly" class="align-items-center mb-3 row g-3">
        <div class="col">
            <app-local-filter [appAutoFocus]="true" placeholder="Search" [data]="data"
                              (updated)="filteredData = $event"></app-local-filter>
        </div>
        <div *ngIf="appContext.isAdminOrIntermediary()" class="col-auto">
            <button (click)="addTax()" type="button" class="btn btn-primary">
                <i class="bi bi-plus me-md-2"></i>
                <span class="d-none d-md-inline">Add Tax</span>
            </button>
        </div>
    </div>
    <div class="row mb-3 d-flex justify-content-end">
        <ng-container *ngFor="let facet of getFacets('right'); index as i; trackBy: trackByFacetName">
            <div class="col-auto">
                <app-facet-filter [facet]="facet" (selectedValuesChange)="onFacetValuesChange(facet, $event)"
                                  [(selectedValues)]="facetFilters.facets[facet.name]"
                                  [style.max-width.px]="200"></app-facet-filter>
            </div>
        </ng-container>
    </div>

    <div class="col-12 d-flex justify-content-end mb-3">
    </div>

    <app-scroller (endReached)="loadNextPage()" [windowScroll]="true">
        <div class="row">
            <ng-container *ngFor="let tax of dataSorted; let i = index; trackBy: trackByForRecord">
                <div role="row" class="col-xl-4 col-md-6 col-12 mb-4">
                    <div class="card hover-show w-100 overflow-auto h-100 d-flex flex-column position-relative">
                        <div class="card-body flex-grow-1 d-flex flex-column">
                            <app-confirmation-toggle *ngIf="appContext.isAdminOrIntermediary()">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h3 class="modal-title">Remove tax</h3>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Are you sure you want to remove this item?</p>
                                    </div>
                                    <div class="modal-footer justify-content-between">
                                        <a type="button" class="btn btn-secondary"
                                           data-bs-dismiss="modal">Cancel</a>
                                        <button type="button" class="btn btn-primary"
                                                (click)="deleteTax(tax)" data-bs-dismiss="modal">
                                            Remove tax
                                        </button>
                                    </div>
                                </div>
                                <button type="button" class="btn position-absolute top-0 end-0 to-show"
                                        role="button"
                                        aria-expanded="false">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </app-confirmation-toggle>
                            <div class="text-center mb-3">
                                <h2 class="card-title">
                                    <app-connection-image [connectionType]="tax.taxInfo.connectionType">
                                    </app-connection-image>
                                    {{ tax.taxInfo.connectionType }}
                                </h2>
                                <h5 class="text-muted notranslate">
                                    {{tax.taxInfo.country}}
                                </h5>
                            </div>
                            <div class="list-group-flush mb-n3 list-group flex-grow-1">
                                <div *ngFor="let period of tax.taxPeriods;"
                                     class="list-group-item py-3">
                                    <div class="row">
                                        <div class="col-auto">
                                            <i class="bi bi-calendar"></i>
                                        </div>
                                        <div class="col">
                                            <small class="text-muted">
                                                <app-contract-range [dateRange]="period.data.period" [isReadOnly]="true"></app-contract-range>
                                            </small>
                                        </div>
                                        <div class="col-auto my-3 ms-2">
                                            <button type="button" class="btn position-absolute top-0 end-0 to-show"
                                                    style="right: 24px !important;"
                                                    role="button" aria-expanded="false"
                                                    (click)="openTaxPeriodModal(tax, period)">
                                                <i class="bi bi-pencil"></i>
                                            </button>
                                        </div>
                                        <div class="col-auto my-3">
                                            <app-confirmation-toggle *ngIf="appContext.isAdminOrIntermediary()">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h3 class="modal-title">Remove period</h3>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                                aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <p>Are you sure you want to remove this tax period?</p>
                                                    </div>
                                                    <div class="modal-footer justify-content-between">
                                                        <a type="button" class="btn btn-secondary"
                                                           data-bs-dismiss="modal">Cancel</a>
                                                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="deleteTaxPeriod(period, tax)">
                                                            Remove period
                                                        </button>
                                                    </div>
                                                </div>

                                                <button type="button" class="btn position-absolute top-0 end-0 to-show"
                                                        role="button" aria-expanded="false">
                                                    <i class="bi bi-trash"></i>
                                                </button>
                                            </app-confirmation-toggle>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="appContext.isAdminOrIntermediary()"
                                     class="list-group-item py-3 pointer hover-underline mt-auto" (click)="addNewTaxPeriod(tax)">
                                    <div class="row">
                                        <div class="col-auto">
                                            <i class="bi me-2 bi-plus-circle"></i>
                                        </div>
                                        <div class="col text-truncate"><small>Add period</small></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </app-scroller>
</ng-container>
