import {Chart, Plugin, Scale, Tick} from "chart.js";
import {AnyObject} from "chart.js/dist/types/basic";
import {EventEmitter} from "@angular/core";
import {ChartUtilsService} from "../chart-utils.service";

export class BarAxesScalerPlugin implements Plugin<'bar'> {
    id = "axesScaler";
    scaleSteps = 1000;

    axisScales: {[scaleId: string]: number} = {};

    scaleChanged: EventEmitter<{[scaleId: string]: number}> = new EventEmitter<{[p: string]: number}>();

    constructor(private chartUtilsService: ChartUtilsService) {
    }

    afterBuildTicks = (chart: Chart, args: { scale: Scale }, options: AnyObject) => {
        args.scale.afterTickToLabelConversion = () => this.applyScaleToTickLabels(args.scale);
        if (args.scale.ticks && args.scale.ticks.length > 0) {
            const lowestValue: Tick = args.scale.ticks.reduce((a, b) => a.value !== 0 && a.value < b.value ? a : b);
            const y = Math.floor(Math.log10(Math.abs(lowestValue.value)) / Math.log10(this.scaleSteps));
            const scale = Math.floor(Math.pow(this.scaleSteps, y));
            this.changeScale(args.scale.id, scale);

            if (scale > 10) {
                args.scale.ticks = args.scale.ticks.map(t => {
                    t['scale'] = scale;
                    return t;
                });
            }
        }
    }

    private applyScaleToTickLabels(scale: Scale) {
        const ticks = scale.ticks;
        if (ticks) {
            ticks.forEach(t => {
                if (t['scale'] > 0) {
                    t.label = this.chartUtilsService.transformDecimal(Number(t.value) / t['scale']).toString();
                }
            });
        }
    }

    changeScale(id: string, value: number) {
        const currentValue = this.axisScales[id];
        const changed = value !== currentValue;
        this.axisScales[id] = value;
        if (changed) {
            this.scaleChanged.emit(this.axisScales);
        }
    }

    axisTitle = (baseTitle: string, axisId: string): string => {
        const scale = this.axisScales[axisId];
        if (scale && scale > 1) {
            return `× ${this.chartUtilsService.transformDecimal(scale)} ${baseTitle}`;
        }
        return baseTitle;
    }
}