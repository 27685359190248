import {Component, OnInit} from '@angular/core';
import {RefdataUtils} from '../../refdata-utils';
import {UserProfile} from '@flowmaps/flowmaps-typescriptmodels';

@Component({
    selector: 'app-users-overview',
    templateUrl: './users-overview.component.html',
    styleUrls: ['./users-overview.component.css']
})
export class UsersOverviewComponent implements OnInit {
    users: UserProfile[];
    loadCount: number = 0;

    ngOnInit() {
        RefdataUtils.findUsers().subscribe(l => {
            this.users = l;
            this.loadCount++;
        });
    }
}
